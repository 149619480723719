import { clone, compose, isEmpty, isNil } from 'ramda';

import { constants as c, Coords } from './constants';
import { CardSelector } from './services';

import { Hand } from './hand';
import Suit from './suit';

import {
  BidHelper,
  BidderTypes,
  BidSystemConfig,
  PointRangeHelper,
} from './bid-system';

class Player {
  constructor(name, bidSystemConfig = BidSystemConfig.DefaultBidSystem) {
    this.name = name;
    this.bidSystemConfig = bidSystemConfig;

    this.gameService = null;
    this.contextQuestions = null;
    this.position = c.UNKNOWN;

    this.clearHand();
  }

  resetAuctionValues() {
    // your partners bid props
    this.partnersBid = null;

    // the opposition props
    this.interferenceBid = null;
    this.isCompetitiveAuction = false;

    this.opener = false;
    this.isDeclarer = false;
    this.isDummy = false;
    this.bidderType = null;
  }

  resetCardSelector() {
    this.cardSelector = null;
  }

  setGameService(gameService) {
    this.gameService = gameService;
  }

  setIsAdvancer(flag) {
    if (flag) {
      this.bidderType = BidderTypes.ADVANCER;
    } else {
      this.bidderType = null;
    }
  }

  setIsOpener(flag) {
    if (flag) {
      this.bidderType = BidderTypes.OPENER;
      this.getPartner().setIsResponder(true);
    } else {
      this.bidderType = null;
    }
  }

  setIsOvercaller(flag) {
    if (flag) {
      this.bidderType = BidderTypes.OVERCALLER;
      this.getPartner().setIsAdvancer(true);
    } else {
      this.bidderType = null;
    }
  }

  setIsResponder(flag) {
    if (flag) {
      this.bidderType = BidderTypes.RESPONDER;
    } else {
      this.bidderType = null;
    }
  }

  setIsDealer(flag) {
    this.isDealer = flag;
  }

  setIsDeclarer(flag) {
    this.isDeclarer = flag;
  }

  setIsDummy(flag) {
    this.isDummy = flag;
  }

  setIsLeadCard(flag) {
    this.isLeadCard = flag;
  }

  setAuctionService(auctionService) {
    this.resetAuctionValues();
    if (this.auctionService) {
      this.auctionService.disconnect();
    }
    this.auctionService = auctionService;
  }

  setCardPlay(cardPlayService) {
    this.resetCardSelector();
    this.cardPlayService = cardPlayService;
  }

  setPartnersBid(bid) {
    this.partnersBid = bid;
  }

  setPosition(value) {
    this.position = value;
  }

  /**
  * returns the players seat# in relation to dealer. dealer bids first = seat #1
  */
  getSeatNumber() {
    let seatNumber = 0;
    let position = Coords.prevCoord(this.gameService.getDealer().position);
    let isFound = false;
    while (!isFound) {
      seatNumber = seatNumber + 1;
      position = Coords.nextCoord(position);
      isFound = position === this.position;
    }
    return seatNumber;
  }

  getTeam() {
    return getTeamFor(this.position);
  }

  getIsNorthSouth() {
    return getIsTeamNorthSouth(this.getTeam());
  }

  getIsEastWest() {
    return getIsTeamEastWest(this.getTeam());
  }

  getPartnersBids() {
    return this.getOurBids().filter(bid => bid.player !== this);
  }

  getPartnersBidSuitLength(strain) {
    if (strain === c.VOID_SUIT) {
      return 0;
    }
    const values = this.getPartner().getBidValues();
    return values[ values.length -1 ].bidSuitValues[ strain ].total;
  }

  getBidValues() {
    const bidSuitValues = {
      [ c.CLUB ]: { total: 0, operator: '>=' },
      [ c.DIAMOND ]: { total: 0, operator: '>=' },
      [ c.HEART ]: { total: 0, operator: '>=' },
      [ c.SPADE ]: { total: 0, operator: '>=' },
    };

    const bidPointRange = {
      lower: 0,
      upper: 0,
    };

    return this.getBids().reduce((acc, bid) => {

      bid.getPromisedSuitProps().forEach(({ strain, suitLength, lessThan }) => {
        if (c.SUIT_STRAINS.find(s => s === strain)) {
          if (suitLength > 0) {
            bidSuitValues[ strain ].total = suitLength;
            bidSuitValues[ strain ].operator =  '>=';
          } else if (lessThan) {
            bidSuitValues[ strain ].total = lessThan;
            bidSuitValues[ strain ].operator =  '<';
          }
        }
      });

      const range = bid.getPointRange();
      if (!isEmpty(range)
        && range.lower > bidPointRange.lower
      ) {
        bidPointRange.lower = range.lower;
        bidPointRange.upper = range.upper;
      }

      return [
        ...acc,
        {
          bidIndex: bid.index,
          bidSuitValues: clone(bidSuitValues),
          bidPointRange: clone(bidPointRange),
        },
      ]
    }, []);

  }

  getBids() {
    return this.getOurBids().filter(bid => bid.player === this);
  }

  getIsFirstRebid() {
    return this.getBids().length === 1;
  }

  getFirstBid() {
    return BidHelper.getFirstBid(this.getBids());
  }

  getPartnersFirstBid() {
    return this.getPartner().getFirstBid();
  }

  getSuitsYouBid() {
    return this.hand.suits.filter(
      suit => this.getBids().find(
        bid => bid.getPromisedStrains().has(suit.strain))
      );
  }

  getBidCount(strain) {
    return this.getPartner().getPartnersBids().filter(bid =>
      bid.getPromisedStrains().has(strain)).length;
  }

  getHasBidSuitTwice(strain) {
    return this.getBidCount(strain) === 2;
  }

  getHasBidSuit(strain) {
    return this.getBidCount(strain) > 0;
  }

  getSuitsPartnerBid() {
    return this.hand.suits.filter(
      suit => this.getPartnersBids().find(
        bid => bid.getPromisedStrains().has(suit.strain))
      );
  }

  getSuitsTheyBid() {
    return this.hand.suits.filter(
      suit => Array.from(this.getTheirBidStrains()).find(strain => strain === suit.strain)
    );
  }

  getOurBids() {
    return this.auctionService
      ? this.getIsNorthSouth()
        ? this.auctionService.getNorthSouthBids()
        : this.auctionService.getEastWestBids()
      : [];
  }

  getAllAuctionBids() {
    return this.auctionService.bids;
  }

  getTheirBids() {
    return this.auctionService
      ? this.getIsNorthSouth()
        ? this.auctionService.getEastWestBids()
        : this.auctionService.getNorthSouthBids()
      : [];
  }

  getTheirBidStrains() {
    return this.getIsNorthSouth()
      ? this.auctionService.getEastWestBidStrains()
      : this.auctionService.getNorthSouthBidStrains();
  }

  getOurPointsWith(strain) {
    return BidHelper.getOurTeamPoints(this.getOurBids(), this.hand, strain);
  }

  getOurPoints() {
    if (this.partnersBid) {
      return this.getOurPointsWith(this.partnersBid.strain);
    }
    return this.hand.getTotalPoints();
  }

  getPartnersBidControls() {
    return BidHelper.getBidControls(this.getPartnersBids());
  }

  getTheirPoints() {
    return BidHelper.getTheirTeamPoints(this.getTheirBids());
  }

  getHasPartnerBidGame() {
    return this.partnersBid.getIsGameLevel();
  }

  getHasPartnerBidSlam() {
    return BidHelper.getIsSlamBid(this.partnersBid);
  }

  getHasPartnerPenaltyDoubled() {
    return this.partnersBid.getIsPenaltyDouble();
  }

  getIsForcedToBid() {
    return this.partnersBid
      && this.partnersBid.getIsForcing()
      && isNil(this.interferenceBid);
  }

  getIsBalancingSeat() {
    return this.auctionService.getPassCnt() === 2;
  }

  getPartner() {
    return this.gameService.players.find(player => this.getIsPartner(player));
  }

  getIsDefending() {
    return !(this.isDummy || this.isDeclarer);
  }

  getIsPartner(player) {
    return player !== this && player.getTeam() === this.getTeam();
  }

  getIsAdvancer() {
    return this.getIsBidderType(BidderTypes.ADVANCER);
  }

  getIsOpener() {
    return this.getIsBidderType(BidderTypes.OPENER);
  }

  getIsOvercaller() {
    return this.getIsBidderType(BidderTypes.OVERCALLER);
  }

  getIsResponder() {
    return this.getIsBidderType(BidderTypes.RESPONDER);
  }

  getIsBidderType(bidderType) {
    if (this.bidderType) {
      return this.bidderType === bidderType;
    }
    const partner = this.getPartner();
    if (partner.bidderType === BidderTypes.OPENER) {
      return bidderType === BidderTypes.RESPONDER;
    }
    if (partner.bidderType === BidderTypes.OVERCALLER) {
      return bidderType === BidderTypes.ADVANCER;
    }
  }

  getHasPartnerBidYourSuit(strain) {
    return this.getSuitsYouBid().some(suit => suit.strain === strain);
  }

  getPartnerShipValues(trumpStrain) {
    const bids = this.getOurBids();
    const ptsTeam = BidHelper.getOurTeamPoints(bids, this.hand, trumpStrain);
    const partnersPoints = BidHelper.getBidPoints(this.getPartner());
    const rawPoints = partnersPoints + this.hand.getHighCardPoints();
    const partnerIsMinimal = PointRangeHelper.getIsMinimal(this.getPartner(), partnersPoints);
    const ltc = BidHelper.getLosingTrickCount(bids, this.hand, trumpStrain);

    return {
      points: ptsTeam,
      rawPoints,
      partnersPoints,
      partnerIsMinimal,
      ltc,
      isSmallSlam: ptsTeam >= c.CONTRACT_MIN_PTS_REQS.SMALL_SLAM,
      getIsDistributional: (pts, strain) => pts < (Suit.getIsMajor(strain)
        ? c.CONTRACT_MIN_PTS_REQS.MAJOR_GAME
        : c.CONTRACT_MIN_PTS_REQS.MINOR_GAME),
    };
  }

  getCardSelectorInstance() {
    if (!this.cardSelector) {
      this.cardSelector = new CardSelector(this, this.gameService.getContract());
    }
    return this.cardSelector;
  }

  getCloneHand() {
    return this.hand.getClone();
  }

  getCard(trickStrain, currentTrick, lastTrick) {
    return this.getCardSelectorInstance().getCard(trickStrain, currentTrick, lastTrick);
  }

  signal(bid) {
    if (this.getIsPartner(bid.player)) {
      this.setPartnersBid(bid);
      if (!bid.getIsPassed()) {
        this.interferenceBid = null;
      }
    } else {
      this.interferenceBid = bid.getIsPassed()
        ? null : bid;
      this.isCompetitiveAuction = this.isCompetitiveAuction
        || !bid.getIsPassed();
    }
  }

  signalCard(trick) {
    const cardSelector = this.getCardSelectorInstance();

    cardSelector.mustCover = !this.getIsPartner(trick.getWinner().player);

    if (this.isDummy
      && !this.cardPlayService.dummyHand
    ) {
      this.cardPlayService.setDummyHand(cardSelector.hand, trick);
    }
  }

  clearHand() {
    this.hand = new Hand(this);
    this.contextQuestions = new Map();
    this.setAuctionService(null);
  }

  toString() {
    return ` ${this.name} <br> ${this.hand} <br> hcp = ${this.hand.highCardPoints}`;
  }
}

const getTeamFor = position => (position === Coords.NORTH || position === Coords.SOUTH)
  ? c.NORTH_SOUTH
  : c.EAST_WEST;

const getIsTeamNorthSouth = team => team === c.NORTH_SOUTH;

const getIsTeamEastWest = team => team === c.EAST_WEST;

//:: position -> boolean
const getIsOnNorthSouth = compose(
  getIsTeamNorthSouth,
  getTeamFor
);

export {
  Player as default,
  getTeamFor,
  getIsTeamNorthSouth,
  getIsTeamEastWest,
  getIsOnNorthSouth
};
