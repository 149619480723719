import React from "react";
import PropTypes from 'prop-types';

import { DialogTitleBar } from '../components';
import InfoContainer from '../components/InfoContainer.js';

const InfoSlidesModal = function InfoSlidesModal(props) {

  const [ slideIndex, setSlideIndex ] = React.useState(0);

  const getSlide = () => {
    const slide = props.content[ slideIndex ];
    if (typeof slide === 'string') {
      return { content: slide };
    }
    if (typeof slide === 'object') {
      let result = [];
      for (let [ key, value ] of Object.entries(slide)) {
        const display = `${key}:${value ? value : ''}`;
        result.push(<p key={key}>{display}</p>);
      }
      return result;
    }
    throw new Error('unsupported slide content!');
  };

  const onClickUpdateSlideIndex = (indexStr, testFn) => {
    const index = Number(indexStr);
    if (testFn(index)) {
      setSlideIndex(index);
    }
  };

  const onPreviousClick = () => onClickUpdateSlideIndex(getPreviousIndex().toString(), (index => index >= 0));

  const onNextClick = () => onClickUpdateSlideIndex(getNextIndex().toString(), (index => index < props.content.length));

  const getPreviousIndex = () => slideIndex - 1;

  const getNextIndex = () => slideIndex + 1;

  const getIsRequiresNavigation = () => props.content.length > 1;

  return (
    <div id="info-slide-modal" className="main-component__container">
      <DialogTitleBar title={props.title} onClose={props.onClose} />
      <div id="exception-content">
        {getIsRequiresNavigation()
           ? <InfoContainer
               onPrevClick={onPreviousClick}
               onNextClick={onNextClick}
               info={getSlide()} />
           : <InfoContainer
               info={getSlide()} />
        }
      </div>
    </div>
  );
};

InfoSlidesModal.propTypes = {
  title: PropTypes.string,
  content: PropTypes.array,
  onClose: PropTypes.func,
};

export default InfoSlidesModal;
