import React, { useState, useEffect } from 'react';

import { FallingSuits } from '../components/FallingSuits';

const SplashPage = function SplashPage({
  WrappedComponent,
  ...restProps
}) {
  const [ isVisible, setIsVisible ] = useState(true);

  useEffect(() => {
    setTimeout(() => setIsVisible(false), 2000);
  }, []);

  if (isVisible) {
    return <FallingSuits />
  }

  return <WrappedComponent {...restProps} />
};

export default function withSplashScreen(WrappedComponent) {
  return props => <SplashPage WrappedComponent={WrappedComponent} {...props} />;
};
