import { constants as uic } from '../../constants';

import React from 'react';
import PropTypes from 'prop-types';

const MoreLink = function MoreLink({id, onClick, label}) {
  const getLabel = () => `(${label
    ? label
    : uic.MORE_LABEL})`;

  return (
    <a
      id={id}
      href="#"
      title={uic.MORE}
      onClick={onClick}>
      {getLabel()}
    </a>
  );
};

MoreLink.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
};

export default MoreLink;
