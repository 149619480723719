import {
  BID_NEW_SUIT_TWO_LEVEL,
  RAISE_PARTNERS_SUIT_TO_TWO_LEVEL,
  BID_TWO_NO_TRUMP,
  PENALTY_DOUBLE,
} from './types';

export const createLabels = function createLabels(bidSystemConfig) {
  const BID_NEW_SUIT_TWO_LEVEL_LABEL = 'Bid a new suit at the two level. Weak bid 5-9 HCP points. Good six card suit or strong five card suit ';
  const RAISE_PARTNERS_SUIT_TO_TWO_LEVEL_LABEL = 'Raise partner`s suit. Promises 5-8 HCP and length in trump suit distributional hand ';
  const BID_TWO_NO_TRUMP_LABEL = 'Bid two no trump. Promises a two five cards suits or longer and playing strength ';
  const PENALTY_DOUBLE_LABEL = 'Penalty double. Promises good 9+ HCP ';

  return {
    [ BID_NEW_SUIT_TWO_LEVEL ]: BID_NEW_SUIT_TWO_LEVEL_LABEL,
    [ RAISE_PARTNERS_SUIT_TO_TWO_LEVEL ]: RAISE_PARTNERS_SUIT_TO_TWO_LEVEL_LABEL,
    [ BID_TWO_NO_TRUMP ]: BID_TWO_NO_TRUMP_LABEL,
    [ PENALTY_DOUBLE ]: PENALTY_DOUBLE_LABEL,
  };

};
