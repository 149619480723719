import { DATA_DEFINITION_PREFIX } from '../constants';

const PASS_OPTION = 'bid-options/PASS';
const DEF_PASS_OPTION = DATA_DEFINITION_PREFIX + PASS_OPTION;

const PASS_OPTION_LABEL = 'Otherwise - Pass.';
const DEF_PASS_OPTION_LABEL = 'no option';

const PassOption = {
  [ PASS_OPTION ]: PASS_OPTION_LABEL,
  [ DEF_PASS_OPTION ]: DEF_PASS_OPTION_LABEL,
};

const Labels = PassOption;

export { PASS_OPTION, Labels };
