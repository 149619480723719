import { constants as uic } from '../constants';

import { DealProgress } from '../presenter';

import { isEmpty } from 'ramda';

export const selectIsAuctionTableVisible = function selectIsAuctionTableVisible({ gameOptions, gameState }) {
  return DealProgress.getIsAuctionView(gameState.dealProgress)
    || gameOptions.bidType === uic.BID_OPTIONS.interactiveBid;
};

export const selectIsAuctionViewerVisible = function selectIsAuctionViewerVisible({ displayOptions, gameState }) {
  return displayOptions.isDisplayAuctionViewer
};

export const selectDealProgress = function selectDealProgress({ gameState }) {
  return gameState.dealProgress;
};

export const selectIsBidPractice = function selectIsBidPractice({ gameOptions }) {
  return uic.getIsPlayTypeBidPractice(gameOptions.playType);
};

export const selectIsAuctionPlayback = function selectIsAuctionPlayback({ gameState }) {
  return gameState.isAuctionPlayback;
};

export const selectAuction = function selectAuction({ displayOptions, gameState }, bidIndex = -1) {
  //if (selectIsAuctionViewerVisible({ displayOptions, gameState })) {
  if (!isEmpty(gameState.completeAuction)) {
    const { auction } = gameState.completeAuction;
    if (bidIndex > -1) {
      // remove the empty alignment bids - copy over only the real bids
      return {
        ...auction,
        bids: auction.bids.slice(0, auction.bidAlignCnt + bidIndex),
      }
    }
    return auction;
  }
  if (!isEmpty(gameState.auctionUpdate)) {
    return (({ auction, newBid }) => ({
      ...auction,
      bids: [ ...auction.bids, newBid ]
    }))(gameState.auctionUpdate.auctionData);
  }
};

export const selectCompleteAuction = function selectCompleteAuction({ gameState }) {
  return gameState.completeAuction;
};

export const selectIsDisplayHands = function selectIsDisplayHands({ gameState, displayOptions }) {
  return gameState.isOriginalHandsVisible
    || displayOptions.isDisplayHands;
};

export const selectIsBidHintsVisible = function selectIsBidHintsVisible({ displayOptions }) {
  return displayOptions.isDisplayBidHints;
};

export const selectIsDisplayHcp = ({ displayOptions }) => displayOptions[ uic.IS_DISPLAY_HCP ];

export const selectIsDisplayTenAsChar = ({ displayOptions }) => displayOptions[ uic.IS_DISPLAY_TEN_CARD_AS_CHAR ];

export const selectIsDisplayHandImg = ({ displayOptions }) => displayOptions[ uic.IS_DISPLAY_HAND_IMG ];

export const selectCurrentTrick = ({ gameState }) => gameState.tricks[gameState.tricks.length -1];

export const selectDealProps = ({ gameState }) => gameState.dealProps;

export const selectectVulnerability = ({ gameState }) => gameState.vulnerability;

export const selectGuideBid = ({ displayOptions, gameState }) => {
  const auction = selectAuction({ displayOptions, gameState });
  if (auction) {
    const cell = (({ selected, defaultCell }) => displayOptions.isDisplayBidHints
      && isEmpty(selected)
        ? defaultCell
        : selected
    )(gameState.selectedAuctionCell);

    return auction.bids.filter(bid =>
      bid.position === cell.position)[ cell.rowNum ];
  }
  return null;
};

export const selectHighlightPlayerPositon = ({ gameState }) => {
  const { selected } = gameState.selectedAuctionCell;
  if (selected.position) {
    return selected.position;
  }
  return gameState.highlightCardPlayerPositon;
};

export const selectectNextCardToPlay = ({ gameState }) => gameState.nextCardToPlay;

export const selectectHasDealQueue = ({ gameOptions }) => gameOptions.dealQueue.length > 0;

export const selectectNextQueueDeal = ({ gameOptions }) => {
  if (gameOptions.currentQueueDealId) {
    const index = gameOptions.dealQueue.findIndex(deal =>
      deal.id === gameOptions.currentQueueDealId
    );
    if (index !== -1
      && index + 1 !== gameOptions.dealQueue.length
    ) {
      return gameOptions.dealQueue[index + 1];
    }
    return;
  }
  if (gameOptions.dealQueue.length > 0) {
    return gameOptions.dealQueue[0];
  }
};

export const selectHands = function selectHands({ gameState }) {
  const { isOriginalHandsVisible, originalHands, playingHands } = gameState;
  return isOriginalHandsVisible
    ? originalHands
    : playingHands;
};

export const selectPlayingTrick = function selectPlayingTrick({ gameState }) {
  return gameState.playingTrick;
};

export const selectSelectedAuctionCellProps = function({ gameState }) {
  return gameState.selectedAuctionCell;
};
