import { constants as c } from './constants';

export class Card {
  constructor(strain, value) {
    this.strain = strain;
    this.value = value;
    this.hcpValue = Card.getHcpValue(value);
  }

  static getHcpValue(value) {
    let hcp = 0;

    switch (value) {
      case c.JACK:
        hcp = 1;
        break;
      case c.QUEEN:
        hcp = 2;
        break;
      case c.KING:
        hcp = 3;
        break;
      case c.ACE:
        hcp = 4;
        break;
      default:
        hcp = 0;
    }

    return hcp;
  }

  compare(that, trumpStrain) {
    let result = -1;

    let a = c.CardDeck.values.indexOf(this.value);
    let b = c.CardDeck.values.indexOf(that.value);
    result = (b - a);


    if (result === 0) {
      a = c.CardDeck.suits.indexOf(this.strain);
      b = c.CardDeck.suits.indexOf(that.strain);
      result = b - a;
    }

    if (trumpStrain) {
      if (this.strain === trumpStrain && that.strain !== trumpStrain) {
        return -1;
      }
      if (this.strain !== trumpStrain && that.strain === trumpStrain) {
        return 1;
      }
      // otherwise both are trumps and result has correct value
    }

    return result;
  }

  toString() {
    return `${this.value}${this.strain}`;
  }
}

export class Deck {
  constructor() {
    this.deck = [];
    this.reset();
    this.shuffle();
  }

  static getDeck() {
    return c.CardDeck.suits.flatMap(strain =>
      c.CardDeck.values.map(value => new Card(strain, value)));
  }

  reset() {
    this.deck = Deck.getDeck();
  }

  shuffle() {
    const {
      deck,
    } = this;
    let m = deck.length;
    let i;

    while (m) {
      i = Math.floor(Math.random() * m--);

      [deck[m], deck[i]] = [deck[i], deck[m]];
    }

    return this;
  }

  dealNextCard() {
    return this.deck.pop();
  }

  draw() {
    const pos = Math.floor(Math.random() * this.deck.length - 1);
    return this.deck.splice(pos, 1);
  }
}
