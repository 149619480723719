const DEAL_RANDOM_HANDS = 'deal-random-hands';
const DEAL_PRELOADED_HANDS = 'deal-preloaded-hands';
const DEAL_SAVED_HANDS = 'deal-saved-hands';
const DEAL_HISTORY_HANDS = 'deal-history-hands';
const REDEAL = 'deal-redeal';

const DealOptions = Object.freeze({
  DEAL_RANDOM_HANDS,
  DEAL_PRELOADED_HANDS,
  DEAL_SAVED_HANDS,
  DEAL_HISTORY_HANDS,
  REDEAL,
});

export { DealOptions };
