import { isNil } from 'ramda';

import { constants as c } from '../../constants';

function createLabel(pointsTypeLabel, { lower, upper,}) {
  if (!isNil(lower) && !isNil(upper)) {
    return `${lower} to ${upper} ${pointsTypeLabel}`;
  }
  if (!upper) {
    return `${lower}+ ${pointsTypeLabel}`;
  }
}

export const createRangeLabel = function createRangeLabel(range) {
  return createLabel(c.HCP_LABEL, range);
};

export const createTotalPointsLabel = function createTotalPointsLabel(range) {
  return createLabel(c.TOTAL_POINTS_LABEL, range);
};
