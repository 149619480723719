import React from 'react';
import PropTypes from 'prop-types';

import { constants as uic } from '../../constants';

const Contact = function Contact({ mailto, contactAddress }) {
  return (
    <React.Fragment>
    <span style={{fontWeight: 'bold'}}>{uic.CONTACT_LABEL}:</span>
    <span>
      <a style={{padding: '.2rem'}} href={`mailto:${mailto}`}>{contactAddress}</a>
    </span>
    </React.Fragment>
  );
};
Contact.propTypes = {
  mailto: PropTypes.string,
  contactAddress: PropTypes.string,
}

const BridgeBlvdContact = () => Contact({
  mailto: uic.CONTACT_ADDRESSS,
  contactAddress: uic.CONTACT_ADDRESSS,
});

export { BridgeBlvdContact, Contact };
