import { createBidOptions } from '../create-bid-options';

import {
  GAME_RAISE,
  SLOW_RAISE,
  REBID_SIX_CARD_SUIT,
  NEW_SUIT_NATURAL,
  TWO_NO_TRUMP,
  THREE_NO_TRUMP,
} from './types';

import { STRONG_JUMP_SHIFT_RANGES, createRanges } from './ranges';

import { createLabels } from './labels';

function getOptions(/*bidSystemConfig*/) {
  return createBidOptions([
    GAME_RAISE,
    SLOW_RAISE,
    REBID_SIX_CARD_SUIT,
    NEW_SUIT_NATURAL,
    TWO_NO_TRUMP,
    THREE_NO_TRUMP,
  ]);
};

function getRanges({ bidSystemConfig }) {
  return bidSystemConfig[ STRONG_JUMP_SHIFT_RANGES ];
}

export {
  GAME_RAISE,
  SLOW_RAISE,
  REBID_SIX_CARD_SUIT,
  NEW_SUIT_NATURAL,
  TWO_NO_TRUMP,
  THREE_NO_TRUMP,
  STRONG_JUMP_SHIFT_RANGES,
  createRanges,
  createLabels,
  getOptions,
  getRanges,
};
