import React from 'react';
import PropTypes from 'prop-types';

import { range } from 'ramda';

export const NumberDropDown = ({ keyName, value, onChange, style = {width: 'min-content'}}) => {
  return (
    <select style={style} name={keyName} id={keyName} className="select-css" value={value} onChange={onChange}>
      {range(0, 40).map(number => <option value={number} key={number}>{number}</option>)}
    </select>
  );
};
NumberDropDown.propTypes = {
  keyName: PropTypes.string,
  value: PropTypes.number,
  onChange: PropTypes.func,
};
