import { createBidOptions } from './create-bid-options';

export const THREE_CLUB = 'landy-overcaller/THREE_CLUB';
export const THREE_DIAMOND  = 'landy-overcaller/THREE_DIAMOND';
export const THREE_HEART = 'landy-overcaller/THREE_HEART';
export const THREE_SPADE = 'landy-overcaller/THREE_SPADE';
export const THREE_NT  = 'landy-overcaller/THREE_NT';

const THREE_CLUB_LABEL = '5-4 or 4-5 in major suits.';
const THREE_DIAMOND_LABEL = 'Strong 5-4 or 4-5 in major suits.';
const THREE_HEART_LABEL = 'Min point range with 5-5 in major suits.';
const THREE_SPADE_LABEL = '5-5 in major suits.';
const THREE_NT_LABEL =  'Strong 5-5 in major suits.';

export const Labels = {
  [ THREE_CLUB ]: THREE_CLUB_LABEL,
  [ THREE_DIAMOND ]: THREE_DIAMOND_LABEL,
  [ THREE_HEART ]: THREE_HEART_LABEL,
  [ THREE_SPADE ]: THREE_SPADE_LABEL,
  [ THREE_NT ]: THREE_NT_LABEL,
};

export const getOptions = function getOptions() {
  return createBidOptions([
    THREE_CLUB,
    THREE_DIAMOND,
    THREE_HEART,
    THREE_SPADE,
    THREE_NT,
  ]);
};
