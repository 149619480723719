import { DATA_DEFINITION_PREFIX } from '../../constants';

export const HAS_MAJOR_SUIT_TRUMP_FIT_WITH_PARTNERS_OVERCALL = 'overcall-advance/HAS_MAJOR_SUIT_TRUMP_FIT_WITH_PARTNERS_OVERCALL';
export const DEF_HAS_MAJOR_SUIT_TRUMP_FIT_WITH_PARTNERS_OVERCALL = DATA_DEFINITION_PREFIX+'HAS_MAJOR_SUIT_TRUMP_FIT_WITH_PARTNERS_OVERCALL';

export const GAME_FORCING_HAND_CUE_BID = 'overcall-advance/GAME_FORCING_HAND_CUE_BID';
export const DEF_GAME_FORCING_HAND_CUE_BID = DATA_DEFINITION_PREFIX+'overcall-advance/GAME_FORCING_HAND_CUE_BID';

export const NO_FIT_HAS_STOPPERS_THEIR_SUITS = 'overcall-advance/NO_FIT_HAS_STOPPERS_THEIR_SUITS';
export const DEF_NO_FIT_HAS_STOPPERS_THEIR_SUITS = DATA_DEFINITION_PREFIX+'overcall-advance/NO_FIT_HAS_STOPPERS_THEIR_SUITS';

export const NO_FIT_BID_NEW_FIVE_CARD_SUIT = 'overcall-advance/NO_FIT_BID_NEW_FIVE_CARD_SUIT';
export const DEF_NO_FIT_BID_NEW_FIVE_CARD_SUIT = DATA_DEFINITION_PREFIX+'overcall-advance/NO_FIT_BID_NEW_FIVE_CARD_SUIT';

export const MINOR_SUIT_TRUMP_FIT_WITH_PARTNER = 'overcall-advance/MINOR_SUIT_TRUMP_FIT_WITH_PARTNER';
export const DEF_MINOR_SUIT_TRUMP_FIT_WITH_PARTNER = DATA_DEFINITION_PREFIX+'overcall-advance/MINOR_SUIT_TRUMP_FIT_WITH_PARTNER';
