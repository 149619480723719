import noun_Abacus from '../../images/noun_Abacus_1838090.svg';
import noun_Close from '../../images/noun_Close_3039071.svg' 
import noun_archive from '../../images/noun_archive_1133223.svg';
import noun_auction from '../../images/noun_auction_1658111.svg';
import noun_burger from '../../images/noun_burger_menu_898763.svg';
import noun_deal from '../../images/noun_deal_1156458.svg';
import noun_Delete from '../../images/noun_Delete_1234815.svg';
import noun_export from '../../images/noun_export_2135794.svg';
import noun_filter from '../../images/noun_filter_737854.svg';
import noun_question from '../../images/noun_Question_727759.svg';
import noun_import from '../../images/noun_import_1387515.svg';
import noun_playing_cards from '../../images/noun_Playing Cards_139188.svg';
import noun_play from '../../images/noun_play_193163.svg';
import noun_Calculator from '../../images/noun_Calculator_2409505.svg';
import noun_right from '../../images/noun_right_406172.svg';
import noun_save from '../../images/noun_Save_2433479.svg';
import noun_settings from '../../images/noun_Settings_2828156.svg';
import noun_settings_menu from '../../images/noun_Settings_661334.svg';
import noun_slaying_cards from '../../images/noun_Playing Cards_2371329.svg';
import noun_test from '../../images/noun_test_2191650.svg';

const Images = {
  ABACUS_SVG: noun_Abacus,
  ARCHIVE_SVG: noun_archive,
  AUCTION_SVG: noun_auction,
  BURGER_MENU_SVG: noun_burger,
  CLOSEL_SVG: noun_Close,
  DEAL_SVG: noun_deal,
  DELETE_SVG: noun_Delete,
  EXPORT_SVG: noun_export,
  FILTER_SVG: noun_filter,
  HINT_SVG: noun_question,
  IMPORT_SVG: noun_import,
  FOUR_CARDS_SVG: noun_playing_cards,
  PLAYER_SVG: noun_play,
  POINTS_SVG: noun_Calculator,
  RIGHT_ARROW_SVG: noun_right,
  SAVE_SVG: noun_save,
  SETTINGS_SVG: noun_settings,
  SETTINGS_MENU_SVG: noun_settings_menu,
  SHOW_CARDS_SVG: noun_slaying_cards,
  TEST_SVG: noun_test,
};

export { Images };
