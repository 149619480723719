export const CLUB = '\u2663';
export const DIAMOND = '\u2666';
export const HEART = '\u2665';
export const SPADE = '\u2660';

export const NINE = '9';
export const EIGHT = '8';
export const TEN = '10';
export const JACK = 'J';
export const QUEEN = 'Q';
export const KING = 'K';
export const ACE = 'A';

export const RANDOM_DEAL = "Random Deal";
export const CardDeck = {
  suits: [CLUB, DIAMOND, HEART, SPADE],
  values: [2, 3, 4, 5, 6, 7, 8, 9, 10, JACK, QUEEN, KING, ACE],
};

export const CLUB_NAME = 'club';
export const DIAMOND_NAME = 'diamond';
export const HEART_NAME = 'heart';
export const SPADE_NAME = 'spade';
export const NO_TRUMP = 'NT';

export const SUIT_NAMES = {
  [ CLUB ]: CLUB_NAME,
  [ DIAMOND ]: DIAMOND_NAME,
  [ HEART ]: HEART_NAME,
  [ SPADE ]: SPADE_NAME,
  [ NO_TRUMP ]: NO_TRUMP,
};

export const STRAINS = {
  [ CLUB_NAME ]: CLUB,
  [ DIAMOND_NAME ]: DIAMOND,
  [ HEART_NAME ]: HEART,
  [ SPADE_NAME ]: SPADE,
  [ NO_TRUMP ]: NO_TRUMP,
};

export const DOUBLE_STRAIN = 'X';

export const SUIT_STRAINS = [CLUB, DIAMOND, HEART, SPADE];

export const POINTS_FOR_GAME = 100;

export const SUIT_VALUES_ENUM = {
  Good: 10,
  SideSuit: 2,
  ExtraLength: 3,
};

export const UNKNOWN = 'Unknown';
export const SAVED_DEAL_PREFIX = 'saved-deal-';

export const NORTH_SOUTH = 'North/South';
export const EAST_WEST = 'East/West';

export const PASS = 'pass';

export const LEVEL = 'level';
export const MAJOR = 'major';
export const MINOR = 'minor';
export const BOOK = 6;

export const TWO_CLUB = `2${ CLUB}`;
export const ONE_NT = 1 + NO_TRUMP;
export const TWO_NT = 2 + NO_TRUMP;
export const THREE_NT = 3 + NO_TRUMP;
export const DOUBLE_LABEL = 'Double';
export const PASSED_OUT = 'passed out';
export const REVERSE_BIDDING = 'reverse';
export const VOID_SUIT = 'Void';

export const STANDARD_AMERICAN = 'standard-american';
export const BRIDGE_BLVD_BEGINNER = 'bridge-blvd-beginner';

export const REBID_LABEL = 'Rebid';
export const OPEN_LABEL = 'Open';
export const RESPOND_LABEL = 'Respond';
export const OVERCALL_LABEL = 'Overcall';
export const OVERCALL_ADVANCE_LABEL = 'Advance Overcall';

export const AUTION_CONTEXT_LABEL = 'Auction Context';
export const ADD_DISTRIBUTION_POINTS_FOR_TRUMP_FIT_LABEL = 'Add the distibution points.';
export const ARTIFICIAL_LABEL = 'Artificial';
export const ASK_FOR_ACES_LABEL = 'Ask for aces';
export const ASKED_FOR_ACES_LABEL = 'Asked for aces';
export const ASK_FOR_KINGS_LABEL = 'Ask partner for king count.';
export const ASKED_FOR_KINGS_LABEL = 'Asked for kings';
export const ASTRO_REBID_LABEL = 'Astro - rebid';
export const ASTRO_TWO_CLUBS_LABEL = 'Astro - artificial, Hearts and a minor (5-4 or 4-5)';
export const ASTRO_TWO_DIAMONDS_LABEL = 'Astro - artificial, Spades and another suit (5-4 or 4-5).';
export const ASTRO_FORCING_RESPONSE_LABEL = 'Astro forcing response';
export const ASTRO_RESPONSE_PREFERS_OTHER_SUIT_LABEL = 'Astro - deny fit in major';
export const ASTRO_RESPONSE_SIX_SPADES_LABEL = 'Astro - respond with long spades';
export const ASTRO_RESPONSE_LABEL = 'Astro response -';
export const BALANCED_HAND_LABEL = 'Balanced Hand';
export const BLACKWOOD_FOR_ACES_LABEL = 'Slam attempt, Blackwood convention ask partners for ace count.';
export const CHOOSE_ONE_OF_PARTNERS_SUITS_LABEL = 'Choose one of partners bid suits.';
export const COMPETITIVE_RESPONSE_TWO_FIVE_CARD_SUITS_WITH_PLAYING_STRENGTH_LABEL = 'Two five cards suits, and playing strength';
export const CUEBID_AFTER_NT_OPEN_OVERCALLED = "Cue bid opponent's suit; stayman on, does not promise values in the bid suit.";
export const DEDUCUCT_HIGH_CARD_POINT_LABEL = 'Subtract one hcp with hand shape 4333.';
export const DENY_FOUR_CARD_MAJOR_LABEL = 'Bid denies having a four card major.';
export const DRURY_RESPONSE_LABEL = 'Drury Convention, invitational points and three card support with partner.';
export const DRURY_SUBMINIMAL_OPENER_LABEL = 'Drury, opener has subminimal hand.';
export const DRURY_MINIMAL_OPENER_LABEL = 'Drury, opener has minimal hand.';
export const DRURY_OTHER_MAJOR_LABEL = 'Drury, opener has at least four cards in the other major';
export const FOURTH_SUIT_FORCING_LABEL = 'Fourth suit forcing. Shows invitational values or better, artificial. One round Forcing.';
export const FOURTH_SUIT_FORCING_RESPONSE_LABEL = 'Response to partners fourth suit forcing bid.';
export const GAME_INVITE_LABEL = 'Game Invite';
export const GAME_FORCING_HAND_LABEL = 'Game forcing hand';
export const GERBER_FOR_ACES_ABBR_LABEL = 'Gerber - ask for aces';
export const GERBER_FOR_ACES_LABEL = 'Gerber Convention - Asks for aces.';
export const GRAND_SLAM_LABEL = 'Grand Slam';
export const HCP_LABEL = 'HCP';
export const INVITE_MINOR_GAME_LABEL = 'Invite minor game';
export const INVITE_THREE_NO_TRUMP_GAME_ABBR_LABEL = 'invite 3NT game';
export const INVITE_THREE_NO_TRUMP_GAME_LABEL = 'Invite three No Trump game. ';
export const INVITE_MAJOR_GAME_LABEL = 'Invitational hand, Invite major suit game.';
export const INVITE_SLAM = 'Invite Slam';
export const JACOBY_TRANSFER_ABBR_LABEL = 'Jacoby Transfer';
export const JACOBY_TRANSFER_LABEL = 'Jacoby Transfer';
export const JACOBY_TRANSFER_RESPONSE_LABEL = 'Jacoby Transfer Response';
export const JACOBY_TRANSFER_SUPER_ACCEPTANCE_RESPONSE_LABEL = 'Super acceptance of Jacoby Transfer';
export const JUMP_RAISE_OF_PARTNERS_SUIT_LABEL = 'Jump raise. Invitational, not forcing.';
export const STRONG_JUMP_SHIFT_LABEL = 'Strong Jump Shift, forcing.';
export const LANDY_LABEL = 'Landy - Overcall One No Trump Open. Hand is five/four in majors.';
export const LANDY_ADVANCER_PASS_STRONG_CLUBS_LABEL = 'Pass short majors/long clubs.';
export const LANDY_OVERCALLER_REBID_LABEL = 'Landy Overcaller Rebid.';
export const LANDY_OVERCALLER_REBID_BID_LONGEST_LABEL = 'Rebid longest major.';
export const LANDY_TWO_NO_TRUMP_LABEL = 'Artificial and forcing for one round. Asks partner for more info.';
export const LANDY_TWO_DIAMOND_NO_PREFERENCE_LABEL = 'Artificial, no preference, partner should bid their prefered major.';
export const LAW_OF_TOTAL_TRICKS_LABEL = 'Law of Total Tricks';
export const LIMIT_RAISE_LABEL = 'Limit raise';
export const LOSING_TRICK_COUNT_LABEL= 'Losing trick count of'
export const MAJOR_SUIT_OPEN_ABBR_LABEL = 'five card major suit';
export const MAJOR_SUIT_OPEN_LABEL = 'Open bidding with a major suit.';
export const MINOR_SUIT_OPEN_ABBR_LABEL = 'deny five card major';
export const MINOR_SUIT_OPEN_LABEL = 'Opening Minor bid, denies having a five card major suit.';
export const MINOR_SUIT_RAISE_DENIES_FOUR_CARD_MAJOR_LABEL = 'Minor suit response, denies having a four card major suit.';
export const MICHAELS_CUEBID_LABEL = "Michael's Cuebid";
export const MICHAELS_CUEBID_RESPONSE_LABEL = "Michael's Cuebid Response.";
export const MICHAELS_CUEBID_UNSPECIFIED_FIVE_CARD_MINOR_DESC = 'and an unspecified five card minor';
export const MINIMAL_HAND_LABEL = 'Minimal hand.';
export const MINIMAL_OPENER_CONFIRM_TRUMPS_LABEL = 'Minimal opening hand, raise trump suit.';
export const MINIMAL_OPENING_HAND_NO_TRUMP_RESPONSE_LABEL = 'Minimal opening hand.';
export const MINIMAL_HAND_SUIT_PREFERNCE_LABEL = 'Minimal hand. Bid suit preference.';
export const MINIMAL_HAND_PASS_EXTRA_TEXT_LABEL = 'cannot open the bidding';
export const MINIMAL_RESP_HAND_PASS_EXTRA_TEXT_LABEL = 'cannot respond to partner\'s open';
export const NEW_SUIT_RESPONSE_FORCING_LABEL = 'New Suit, forcing';
export const NEGATIVE_DOUBLE_LABEL = 'Negative Double, ';
export const NEGATIVE_DOUBLE_BOTH_MAJORS_LABEL = 'Four cards in each major.';
export const NEGATIVE_DOUBLE_BOTH_MINORS_LABEL = 'Four cards in each minor.';
export const NEGATIVE_DOUBLE_ONE_MAJOR_LABEL = 'four cards in unbid major.';
export const NEGATIVE_DOUBLE_RESPONSE_LABEL = 'Response to partners negative double.';
export const NO_TRUMP_FIT_LABEL = 'Does not have a trump fit.';
export const NO_TRUMP_OPEN_ABBR_LABEL = 'balanced hand';
export const NO_TRUMP_OPEN_LABEL = 'No Trump bid, shows a balanced hand.';
export const NO_TRUMP_RESPONSE_LABEL = 'Balanced hand, does not have fit in partners suit.';
export const ONE_LEVEL_SUIT_OVERCALL_ABBR_LABEL = 'overcall';
export const ONE_LEVEL_SUIT_OVERCALL_LABEL = 'Suit can overcall at the one level.';
export const TWO_LEVEL_SUIT_OVERCALL_LABEL = 'Suit can overcall at the two level.';
export const OPEN_BIDDING_HAND_DOES_NOT_MEET_REQUIREMENTS_LABEL = 'Cannot open';
export const OPEN_BIDDING_WITH_PREEMPT_LABEL = 'Hand is a preempt.';
export const OPEN_BIDDING_WITH_FOURTH_SEAT_PEARSON_POINTS_LABEL = 'Can open with this hand - After three passes, in the fourth seat total hcp plus spades count; if equals at least 15 open. ';
export const OPEN_BIDDING_WITH_RULE_OF_TWENTY_LABEL = 'Can open with this hand - High Card Points plus two longest suits totals at least 20.';
export const OPEN_BIDDING_WITH_QUICK_TRICKS_LABEL = 'Open light - 2.5 quick tricks and 11-12 high card points.';
export const OPEN_BIDDING_WITH_THIRD_SEAT_GOOD_SUIT_LABEL = 'Can open with this hand - In third seat with good suit and 11-12 high card points.';
export const OPEN_NO_TRUMP_WEAK_FIVE_CARD_SUIT_RESPONSE_LABEL = 'Weak hand, bidding longest suit.';
export const OPTIONAL_DOUBLE_LABEL = 'Option Double for penalty.';
export const OVER_CALL_LABEL = 'Overcall';
export const OVERCALL_ADVANCE_STRONG_FIVE_CARD_SUIT_LABEL = 'Strong five card suit.';
export const OVERCALL_ADVANCE_CUE_BID_LABEL = 'Overcall Advance - Cue bid their suit.';
export const OVERCALL_ADVANCE_MINIMAL_RAISE_LABEL = `Minimal hand, raise partner's overcall suit.`;
export const OVERCALL_ADVANCE_INVITATIONAL_RAISE_LABEL = `Invitational hand, jump raise partner's overcall suit.`;
export const OVERCALL_ADVANCE_GAME_FORCING_HAND_RAISE_LABEL = `Game forcing, raise partner's overcall suit to game!`;
export const OVERCALL_ADVANCE_PREEMPT_THREE_LEVEL_LABEL = 'Advance overcall - Natural seven card suit';
export const OVERCALLER_IS_MINIMAL_REBIDS_SUIT = 'Overcalled with minimum, rebids suit.';
export const PASS_CANNOT_RAISE_HIGHER_LABEL = 'Cannot raise higher.';
export const PASS_CANNOT_REBID_NO_TRUMP_LACKS_STOPPER_IN_ENEMY_SUIT_LABEL = 'Cannot rebid no trump, hand lacks stopper in their bid suit.';
export const PASS_CONVERT_TO_DOUBLE_BID_LABEL = 'With length and strength in their suit. Pass converts to penalty double. ';
export const PASS_DOES_NOT_HAVE_BID_LABEL = 'Does not have suitable bid.';
export const PASS_LESS_THAN_MINIMAL_POINTS_LABEL = 'Below points minimum.';
export const PASS_OVERCALL_LABEL = 'Cannot overcall.';
export const PASS_RESPONSE_AFTER_RHO_TAKEOUT_DOUBLE_LABEL = 'Pass - Hand lacks values to respond after RHO takeout double.';
export const PENALTY_DOUBLE_LABEL = 'Penalty Double.';
export const PLAY_MAJOR_GAME_POINTS_LABEL = 'Combined points equal Major Game.';
export const PLAY_MINOR_GAME_POINTS_LABEL = 'Combined points equal Minor Game.';
export const PLAY_GAME_WITH_LTC_LABEL = 'Bid game - Losing Trick Count equals game.';
export const PLAY_NO_TRUMP_OVER_SUIT_LABEL = 'Play No Trump over trump suit.';
export const PLAY_NO_TRUMP_SMALL_SLAM_LABEL = 'Play No Trump small slam.';
export const PLAY_NO_TRUMP_GRAND_SLAM_LABEL= 'Play No Trump grand slam.';
export const PLAY_THREE_NO_TRUMP_GAME_ABBR_LABEL = '3NT game - balanced hand';
export const PLAY_THREE_NO_TRUMP_GAME_LABEL = 'Play three No Trump game.';
export const PLAYING_TRICK_COUNT_LABEL = 'Playing trick count of';
export const POINTS_IN_UNBID_SUITS_LABEL = 'Has points in the unbid suits.';
export const PREEMPT_LABEL = 'Preempt';
export const PREEMPT_NEW_SUIT_LABEL = 'Bid New suit after partners open. Shows strong suit with length, and playing values.';
export const PREEMPT_RAISE_LABEL = 'Raise partners preempt with fit, and quick tricks.';
export const PREEMPT_FOUR_LEVEL_ABBR_LABEL = 'long good suit 8+/- playing tricks';
export const PREEMPT_THREE_LEVEL_ABBR_LABEL = 'long good suit - (2+ honors) - no ace/king in any other suit. no 4 card major';
export const QUANTITATIVE_FOUR_NT_ABBR_LABEL = 'Quanitative 4NT - invite slam';
export const QUANTITATIVE_FOUR_NT_DESC_LABEL = 'Quanitative Four No Trump Slam Invite.';
export const QUANTITATIVE_FOUR_NT_LABEL = `Quantitative 4${NO_TRUMP}`;
export const QUESTION_MARK_LABEL = '?';
export const RAISE_TO_MAJOR_GAME_LABEL = 'Raise to major game';
export const REBID_SIX_CARD_SUIT_LABEL = 'Rebid the six card suit.';
export const REDOUBLE_LABEL = 'Redouble, shows combined strength with partner.';
export const RESPOND_MICHAELS_CUEBID_OVERCALL_WITH_FIT_LABEL = 'Response of cue bid suit, shows limit raise of partner’s suit.';
export const RESPOND_WITH_CUEBID_SHOWING_GAME_FORCING_HAND_LABEL = 'Cuebid, shows game forcing hand.';
export const RESPONSE_TO_PARTNERS_OPENING_WHEN_OVERCALLED_LABEL = 'Response to partners opening in competition.';
export const REVERSE_BID_LABEL = 'Reverse bid, invitational or better hand.';
export const RULE_OF_FIFTEEN_THRESHOLD_LABEL = 'In 4th seat, after pass-pass-pass; need HCP + total spades in hand to total greater than 15.';
export const RULE_OF_FIFTEEN_VALUE_LABEL = `Rule of Fifteen - High card points plus spades is `;
export const SIGN_OFF_LABEL = 'Sign off';
export const SLAM_BAIL_LABEL = 'Slam retreat. Denies a slam try hand.';
export const SLAM_SIGN_OFF_LACKS_CONTROLS_REASON_LABEL = 'Sign off, partnership lacks first round controls.';
export const FIRST_ROUND_CONTROLS_COUNT_LABEL = 'First round controls';
export const SIX_CARD_MINOR_SUIT_LABEL = '6 card minor suit';
export const SMALL_SLAM_LABEL = 'Small Slam';
export const SLAM_TRY_SHOWS_CONTROL_IN_STRAIN_LABEL = 'Bid Shows control in bid suit. Asks partner to bid their next control.';
export const STAYMAN_LABEL = 'Stayman';
export const STAYMAN_BID_ABBR_LABEL = 'Stayman - 4 card major';
export const STAYMAN_DESC_LABEL= `four card major suit - Partner bids a four card major, or two diamonds to deny majors`;
export const STAYMAN_BID_LABEL = 'Stayman Convention, ask partner to bid a 4-card major if they have one.';
export const STAYMAN_DENIED_LABEL = 'Stayman Response - Denies 4 card major';
export const STAYMAN_MATCH_LABEL = 'Stayman - Partner Match suit';
export const STAYMAN_OVERCALLED_PASS_NO_FOUR_CARD_MAJOR = 'Pass after partners stayman was overcalled - No Four card major.';
export const STAYMAN_OVERCALLED_REDOUBLE_FIVE_CLUBS_NO_FOUR_CARD_MAJOR = 'Redouble - shows Five clubs, denies four card major.';
export const STAYMAN_OVERCALLED_DOUBLE_HAS_FOUR_CARD_MAJOR = 'Penalty Double - unable to bid four card major';
export const STAYMAN_RESPONSE_LABEL = 'Stayman Response';
export const STOPPER_IN_STRAIN_LABEL = 'has stoppers in the bid suit';
export const NO_STOPPER_IN_THEIR_SUIT = 'Hand lacks stopper in their suit';
export const STRONG_TWO_CLUB_NO_TRUMP_RESPONSE_LABEL = 'Opener should respond as no trump open responder.';
export const STRONG_TWO_CLUB_ABBR_LABEL = 'artificial/forcing';
export const STRONG_TWO_CLUB_LABEL = 'Strong Two Club open. ';
export const STRONG_TWO_CLUB_REBID_BALANCED_HAND_LABEL = 'Rebid No trump to show balanced hand. Treat as No trump open.';
export const STRONG_TWO_POSITIVE_SUIT_RESPONSE_LABEL = 'Strong Two Club response';
export const STRONG_TWO_CLUB_BID_LONGEST_SUIT_LABEL = 'Bid longest suit.';
export const STRONG_TWO_CLUB_WAITING_LABEL = 'Forced response, waiting for partner';
export const STRONG_TWO_OF_A_MAJOR_LABEL = "Open Bidding showing a strong hand and a five card major.";
export const STRONG_TWO_OF_A_MINOR_LABEL = "Open Bidding showing a strong hand and denies a five card major.";
export const STRONG_TWO_NEGATIVE_RESPONSE_LABEL = "Negative hand without a trump fit.";
export const SUPPORT_PARTNERS_SUIT_LABEL = 'has support for partners suit.';
export const TAKE_OUT_DOUBLE_LABEL = 'Takeout Double. Has shortness in the Bid suit, and support for the unbid suits.';
export const TAKEOUT_DOUBLE_RESPONSE_CUE_BID_LABEL = 'Response to takeout double shows game forcing values.';
export const TAKEOUT_DOUBLE_RESPONSE_INVITATIONAL_LONG_SUIT_LABEL = 'Takeout Double response - invitaional strength, jump bid';
export const TAKEOUT_DOUBLE_RESPONSE_NO_TRUMP_LABEL = 'Takeout Double response - Bid No Trump with stoppers';
export const TAKEOUT_DOUBLE_RESPONSE_LONG_SUIT_LABEL = 'Takeout Double response - Bid cheapest unbid suit.';
export const TAKEOUT_DOUBLE_RESPONSE_WEAK_HAND_BID_LONGEST_SUIT_LABEL = 'Weak Hand forced bid; bid longest suit.';
export const TAKEOUT_DOUBLE_RESPONSE_FREEBID_LONGEST_SUIT_LABEL = 'Free bid, bid longest suit.';
export const TAKEOUT_DOUBLE_RESPONSE_JUMP_TO_GAME_LABEL = 'Takeout Double response - Game values.';
export const TOTAL_POINTS_LABEL = 'total points';
export const TWO_FIVE_CARD_SUIT_HAND_LABEL = 'Hand has two five cards suits. Good playing strength.';
export const TRUMP_FIT_LABEL = 'Trump fit.';
export const ONE_NO_TRUMP_LABEL = `1${NO_TRUMP}`;
export const TWO_NO_TRUMP_LABEL = `2${NO_TRUMP}`;
export const THREE_NO_TRUMP_LABEL = `3${NO_TRUMP}`;
export const UNBALANCED_HAND_ABBR_LABEL = 'unbalanced';
export const UNBALANCED_HAND_LABEL = 'unbalanced hand';
export const UNKNOWN_SUIT_LABEL = 'Unknown';
export const UNUSUAL_TWO_NO_TRUMP_LABEL = 'Unusual Two No Trump';
export const UNUSUAL_TWO_NO_TRUMP_RESPONSE_LABEL = 'Unusual Two No Trump response - bid longer of partners five card suits';
export const WEAK_COMPETITIVE_RAISE_LABEL = 'Weak, competitive raise. Shows a fit and a few tricks.';
export const WEAK_JUMP_OVERCALL_LABEL = 'Weak Jump Overcall.';
export const WEAK_JUMP_RAISE_AFTER_OVERCALL_LABEL = 'Weak jump raise after rho takeout double.';
export const WEAK_OPEN_REBID_PLAY_GAME_LABEL = 'Play major game, distributional hand.';
export const WEAK_OPEN_REBID_RETREAT_LABEL = 'retreat to opening suit, denies outside ace or king.';
export const WEAK_OPEN_REBID_SHOWS_OUTSIDE_ACE_KING_IN_SUIT = 'Shows an outside ace or king in bid suit';
export const MINOR_SUIT_STAYMAN_LABEL = 'Minor Suit Stayman';
export const MINOR_SUIT_STAYMAN_ABBR_LABEL = 'Minor suit stayman - unbalanced hand - length in both minors';
export const MINOR_SUIT_STAYMAN_DESC_LABEL = 'Asks for a four card minor. Shows unbalanced hand with length in both minors, Denies a five card major.';
export const MINOR_SUIT_STAYMAN_RESPONSE_LABEL = 'Response to partner`s minor stayman.';
export const WEAK_TWO_NO_TRUMP_RESPONSE_LABEL = 'Artificial - forcing bid - Good hand, invitational to game.';
export const WEAK_TWO_ABBR_LABEL = 'preempt - good suit - at most one outside king/ace';
export const WEAK_TWO_LABEL = 'Make a Weak Two bid.';
export const WEAK_TWO_RESPONSE_JUMP_RAISE_LABEL = `Jump to Game in partner's suit`;

export const getOpenBiddingWithGreaterThanMinHcp = function getOpenBiddingWithGreaterThanMinHcp(minHcp) {
  return `Open with this hand - greater or equal to ${minHcp} high card points.`;
};

export const getDefListExp = function(...defs) {
  return defs.join(' - ');
};

export const rangeDefTag = function rangeDefTag(strings, rangeExp, defExp) {
  return `${rangeExp} - ${defExp}`;
};

export const lowerCaseStrainName = strain => SUIT_NAMES[ strain ].toLowerCase();

export const INVITE_GAME = 'Invite Game';
export const SLAM_BAIL = 'Bail';

export const OPENING_BID_KEY = "openingBid";
export const RESPONDING_BID_KEY ="resondingBid";
export const OVERCALL_BID_KEY = "overCallBid";
export const OVERCALL_ADVANCE_BID_KEY = "overCallAdvanceBid";

export const OPENING_BID_DESC = "Open the bidding with";
export const RESPONDING_BID_DESC ="Response to partner's bid; ";
export const OVERCALL_BID_DESC = "Overcall.";
export const OVERCALL_ADVANCE_BID_DESC = "Advance partner's overcall.";

export const VOID_SUIT_LENGTH = 0;
export const ONE_CARD_SUIT = 1;
export const TWO_CARD_SUIT = 2;
export const THREE_CARD_SUIT = 3;
export const FOUR_CARD_SUIT = 4;
export const FIVE_CARD_SUIT = 5;
export const SIX_CARD_SUIT = 6;
export const SEVEN_CARD_SUIT = 7;
export const EIGHT_CARD_SUIT = 8;
export const ONE_LEVEL = 1;
export const TWO_LEVEL = 2;
export const THREE_LEVEL = 3;
export const FOUR_LEVEL = 4;
export const FIVE_LEVEL = 5;
export const SIX_LEVEL = 6;
export const SEVEN_LEVEL = 7;
export const MIN_PTS_TO_RESPOND = 6;
export const PEARSON_POINTS = 15;
export const NO_UPPER_RANGE = -1;
export const MIN_POINTS_TO_OPEN = 12;
export const TRUMP_FIT_COUNT = 8;

export const BID_LADDER = [CLUB, DIAMOND, HEART, SPADE, NO_TRUMP];

export const CONTRACT_MIN_PTS_REQS = {
  SUIT_PART_SCORE: 18,
  NT_PART_SCORE: 20,
  MAJOR_GAME: 25,
  MINOR_GAME: 28,
  NT_GAME: 25,
  SMALL_SLAM: 33,
  GRAND_SLAM: 36,
};
