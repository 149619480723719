import React from 'react';
import { render } from 'react-dom';
import * as serviceWorker from './serviceWorker';

import App, { initColorTheme } from './js/App';

import "fontsource-poller-one";
import "fontsource-inter";

//import "fontsource-raleway";
//import "fontsource-inconsolata";
//import "fontsource-source-code-pro";

import 'normalize.css/normalize.css';
import 'skeleton-css/css/skeleton.css';
import './css/bridge.css';

initColorTheme();

render(<App />, document.getElementById('root'));

serviceWorker.register()
