
export const createBidOptions = function createBidOptions(options, getGroupType) {

  const groupBidOptionsByType = function groupBidOptionsByType() {
    return options.reduce((groupMap, option) => {
      const groupType = getGroupType
        ? getGroupType(option)
        : option;
      return groupMap.set(groupType, groupMap.has(groupType)
        ? [ ...groupMap.get(groupType), option ]
        : [ option ]
      );

    }, new Map());
  };

  return {
    options,
    groupBidOptionsByType,
  };
};
