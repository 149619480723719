import React from 'react';
import PropTypes from 'prop-types';

class DropDown extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
       isVisible: false,
    };
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.hideDropdownMenu);
  }

  showDropdownMenu = event => {
    event.preventDefault();

    this.setState({ isVisible: true }, () => {
      document.addEventListener('click', this.hideDropdownMenu);
    });
  }

  hideDropdownMenu = event => {
    event.preventDefault();

    if(event && event.target.nodeName === 'li'){
        event.target.children[ 0 ].click();
    }

    this.setState({ isVisible: false }, () => {
      document.removeEventListener('click', this.hideDropdownMenu);
    });
  }

  getIcon() {
    const { icon } = this.props;
    return this.state.isVisible
      ? icon.dropDown.closeIcon
      : icon;
  }

  render() {
    return (

      <div className="drop-down">
        {this.props.getIconButton(this.getIcon(), this.showDropdownMenu)}
        {this.state.isVisible
          && this.props.children
        }
      </div>
    );
  }
}
DropDown.propTypes = {
  icon: PropTypes.object,
  getIconButton: PropTypes.func,
  children: PropTypes.node,
};

export { DropDown };
