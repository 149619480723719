import { Coords, constants as c } from '../../../bridge';

const AUCTION_CELL_PREFIX = 'auction-cell';

export const getAuctionCellId = function getAuctionCellId({ position, rowNum }) {
  return `${AUCTION_CELL_PREFIX}-${position}-${rowNum}`;
};

export const getCellPosition = function getCellPosition(cellId) {
  return createAuctionCellProps(cellId).position;
};

export const createCellNavigationProps = ({ position = Coords.SOUTH, rowNum = 0 }) => {
  return {
    downId: getAuctionCellId({
      position,
      rowNum: rowNum + 1,
    }),
    nextId: getAuctionCellId({
      position: Coords.nextCoord(position),
      rowNum: position === Coords.SOUTH
        ? rowNum + 1
        : rowNum,
    }),
    prevId: getAuctionCellId({
      position: Coords.prevCoord(position),
      rowNum: position === Coords.WEST
        ? rowNum - 1
        : rowNum,
    }),
    upId: getAuctionCellId({
      position,
      rowNum: rowNum - 1,
    }),
  };
};

export const getBidAtCell = (bids, { position, rowNum }) => bids.filter(bid =>
  bid.position === position)[ rowNum ];

export const getIsValidBidData = bidData => bidData
  && bidData.bidValue !== '';

export const createBidValuesFrom = function createBidValuesFrom(display) {
  if (display === c.DOUBLE_STRAIN
    || display === c.PASS
  ) {
    return {
      level: null,
      strain: display,
    }
  }
  return {
    level: display.substring(0, 1),
    strain: display.substring(1),
  };
};

export const createAuctionCellProps = function createAuctionCellProps(cellId) {
  if (cellId === '') {
    return {};
  }
  const idValues = cellId.split('-');
  return {
    position: idValues[2],
    rowNum: Number(idValues[3]),
  };
};

export const getIsCellIdEqualsProps = (cellId , cellProps) => {
  return (({ position, rowNum }) => cellProps
    && position === cellProps.position
    && rowNum === cellProps.rowNum
  )(createAuctionCellProps(cellId));
};

export const getIsDisplayQuestionMark = function getIsDisplayQuestionMark(bidData) {
  return bidData.display === c.QUESTION_MARK_LABEL;
};
