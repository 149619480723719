import {
  JACOBY_TRANSFER_RESPONSE,
  LANDY_ADVANCE,
  LANDY_OVERCALLER_REBID_AFTER_TWO_NO_TRUMP_ADVANCE,
  OPEN_THE_BIDDING,
  OPENER_REBID_AFTER_NEG_DBL,
  OVERCALL_AND_TAKEOUT_DOUBLE,
  OVERCALL_NO_TRUMP_OPEN,
  OVERCALL_ADVANCE,
  PARTNER_OPENED_SUIT,
  RESPONSE_AFTER_SUIT_OVERCALL,
  RESPONSE_AFTER_STRONG_JUMP_SHIFT,
  RESPONSE_AFTER_TAKEOUT_DOUBLE,
  RESPONSE_TO_PARTNERS_TAKEOUT_DOUBLE,
  RESPONSE_AFTER_NO_TRUMP_OVERCALL,
  RESPONSE_TO_FOURTH_SUIT_FORCING,
  RESPONSE_TO_NO_TRUMP_OPEN,
  RESPONSE_TO_ONE_OF_A_SUIT_OPEN,
  RESPONSE_TO_STRONG_TWO_OPEN,
  RESPONSE_TO_WEAK_TWO_OPEN,
  STRONG_TWO_OPENER_RESPOND_TO_POSITIVE_SUIT,
} from './types';

const JACOBY_TRANSFER_RESPONSE_LABEL = 'Complete Jacoby Transfer';
const LANDY_OVERCALLER_REBID_AFTER_TWO_NO_TRUMP_ADVANCE_LABEL = 'Rebid';
const OVERCALL_ADVANCE_LABEL = 'Advance the Overcall';
const OPEN_THE_BIDDING_LABEL = 'Open the Bidding';
const OPENER_REBID_AFTER_NEG_DBL_LABEL = `Rebid after Partner's Negative Double`;
const OVERCALL_AND_TAKEOUT_DOUBLE_LABEL = `Overcalls / Takeout Double`;
const OVERCALL_NO_TRUMP_OPEN_LABEL = 'Overcall No Trump Open';
const RESPONSE_AFTER_SUIT_OVERCALL_LABEL = 'Response after suit overcall';
const RESPONSE_AFTER_STRONG_JUMP_SHIFT_LABEL = `Response after partner's Strong Jump Shift`;
const RESPONSE_AFTER_TAKEOUT_DOUBLE_LABEL = 'Response after opponents Takeout Double';
const RESPONSE_TO_PARTNERS_TAKEOUT_DOUBLE_LABEL = `Response to Partner's Takeout Double`;
const RESPONSE_AFTER_NO_TRUMP_OVERCALL_LABEL = 'Response after no trump overcall';
const RESPONSE_TO_FOURTH_SUIT_FORCING_LABEL = 'RESPONSE_TO_FOURTH_SUIT_FORCING';
const RESPONSE_TO_NO_TRUMP_OPEN_LABEL =  'Respond to One No Trump Open';
const RESPONSE_TO_ONE_OF_A_SUIT_OPEN_LABEL = 'Response to One Level Suit Open';
const RESPONSE_TO_WEAK_TWO_OPEN_LABEL = 'Response to partner\'s Weak Two Open';
const RESPONSE_TO_STRONG_TWO_OPEN_LABEL = 'Response to partner\'s Strong Two Open';
const STRONG_TWO_OPENER_RESPOND_TO_POSITIVE_SUIT_LABEL = `Strong Two Club Rebid - Respond to partner's positive suit`;

export const Labels = {
  [ JACOBY_TRANSFER_RESPONSE ]: JACOBY_TRANSFER_RESPONSE_LABEL,
  [ OPEN_THE_BIDDING ]: OPEN_THE_BIDDING_LABEL,
  [ LANDY_ADVANCE ]: OVERCALL_ADVANCE_LABEL,
  [ LANDY_OVERCALLER_REBID_AFTER_TWO_NO_TRUMP_ADVANCE ]: LANDY_OVERCALLER_REBID_AFTER_TWO_NO_TRUMP_ADVANCE_LABEL,
  [ OPENER_REBID_AFTER_NEG_DBL ]: OPENER_REBID_AFTER_NEG_DBL_LABEL,
  [ OVERCALL_AND_TAKEOUT_DOUBLE ]: OVERCALL_AND_TAKEOUT_DOUBLE_LABEL,
  [ OVERCALL_NO_TRUMP_OPEN ]: OVERCALL_NO_TRUMP_OPEN_LABEL,
  [ OVERCALL_ADVANCE ]: OVERCALL_ADVANCE_LABEL,
  [ PARTNER_OPENED_SUIT ]: 'PARTNER_OPENED_SUIT',
  [ RESPONSE_AFTER_SUIT_OVERCALL ]: RESPONSE_AFTER_SUIT_OVERCALL_LABEL,
  [ RESPONSE_AFTER_STRONG_JUMP_SHIFT ]: RESPONSE_AFTER_STRONG_JUMP_SHIFT_LABEL,
  [ RESPONSE_AFTER_TAKEOUT_DOUBLE ]: RESPONSE_AFTER_TAKEOUT_DOUBLE_LABEL,
  [ RESPONSE_TO_PARTNERS_TAKEOUT_DOUBLE ]: RESPONSE_TO_PARTNERS_TAKEOUT_DOUBLE_LABEL,
  [ RESPONSE_AFTER_NO_TRUMP_OVERCALL ]: RESPONSE_AFTER_NO_TRUMP_OVERCALL_LABEL,
  [ RESPONSE_TO_FOURTH_SUIT_FORCING ]: RESPONSE_TO_FOURTH_SUIT_FORCING_LABEL,
  [ RESPONSE_TO_NO_TRUMP_OPEN ]: RESPONSE_TO_NO_TRUMP_OPEN_LABEL,
  [ RESPONSE_TO_ONE_OF_A_SUIT_OPEN ]: RESPONSE_TO_ONE_OF_A_SUIT_OPEN_LABEL,
  [ RESPONSE_TO_WEAK_TWO_OPEN ]: RESPONSE_TO_WEAK_TWO_OPEN_LABEL,
  [ RESPONSE_TO_STRONG_TWO_OPEN ]:  RESPONSE_TO_STRONG_TWO_OPEN_LABEL,
  [ STRONG_TWO_OPENER_RESPOND_TO_POSITIVE_SUIT ]: STRONG_TWO_OPENER_RESPOND_TO_POSITIVE_SUIT_LABEL,
};
