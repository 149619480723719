import React from "react";

import { animated, useSpring } from 'react-spring';

export const FeltContainer = function FeltContainer({
  id,
  children
}) {
  const fader = useSpring({
    from: {opacity: 0},
    opacity: 1,
    config: { duration: 1000  },
  });
  return (
    <animated.section
      id={id}
      style={fader}
      className="felt__container box-shadow"
    >
      {children}
    </animated.section>
  );
}
