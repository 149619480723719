import { constants as uic, PresenterEvents } from '../constants';

const AUCTION_CONTEXT_PARAM = 'auction-context-param';

const DefaultMenuItems = [
  {id: PresenterEvents.SHOW_BID_GUIDE_PAGE, label: uic.BID_CHEAT_SHEET_LABEL, title: uic.BID_CHEAT_SHEET_LABEL, linkProps: {to: `/guide`}},
  {id: PresenterEvents.SHOW_NOW_PLAYING, label: uic.NOW_PLAYING_LABEL, title: uic.NOW_PLAYING_LABEL},
  {id: PresenterEvents.REPLAY_CURRENT_DEAL, label: uic.REPLAY_LABEL, title: uic.REPLAY_LABEL},
  {id: PresenterEvents.DEAL_ACTION, label: uic.DEAL_CARDS_LABEL, title: uic.DEAL_CARDS_LABEL},
  {id: PresenterEvents.SAVE_CURRENT_DEAL, label: uic.SAVE_LABEL, title: uic.SAVE_LABEL},
  {id: PresenterEvents.EXPORT_CURRENT_DEAL, label: uic.EXPORT_DEAL_LABEL, title: uic.EXPORT_DEAL_LABEL},
  {id: PresenterEvents.SHOW_ABOUT_PAGE, label: uic.ABOUT_LABEL, title: uic.ABOUT_LABEL, linkProps: {to: '/about'}},
];

const MobileMenuItems = [
  {id: PresenterEvents.SHOW_BID_GUIDE_PAGE, label: uic.BID_CHEAT_SHEET_LABEL, title: uic.BID_CHEAT_SHEET_LABEL, linkProps: {to: `/guide`}},
  {id: PresenterEvents.SHOW_NOW_PLAYING, label: uic.NOW_PLAYING_LABEL, title: uic.NOW_PLAYING_LABEL},
  {id: PresenterEvents.REPLAY_CURRENT_DEAL, label: uic.REPLAY_LABEL, title: uic.REPLAY_LABEL},
  {id: PresenterEvents.DEAL_ACTION, label: uic.DEAL_CARDS_LABEL, title: uic.DEAL_CARDS_LABEL},
  {id: PresenterEvents.SHOW_HINT_ACTION, label: uic.TOGGLE_HINT_LABEL, title: uic.TOGGLE_HINT_LABEL},
  {id: PresenterEvents.SHOW_AUCTION_ACTION, label: uic.SHOW_AUCTION_LABEL, title: uic.SHOW_AUCTION_LABEL},
  {id: PresenterEvents.SHOW_CARDS_ACTION, label: uic.TOGGLE_CARDS_LABEL, title: uic.TOGGLE_CARDS_LABEL},
  {id: PresenterEvents.SHOW_POINTS_ACTION, label: uic.TOGGLE_PLAYER_POINTS_LABEL, title: uic.TOGGLE_PLAYER_POINTS_LABEL},
  {id: PresenterEvents.SAVE_CURRENT_DEAL, label: uic.SAVE_LABEL, title: uic.SAVE_LABEL},
  {id: PresenterEvents.EXPORT_CURRENT_DEAL, label: uic.EXPORT_DEAL_LABEL, title: uic.EXPORT_DEAL_LABEL},
  {id: PresenterEvents.SHOW_ABOUT_PAGE, label: uic.ABOUT_LABEL, title: uic.ABOUT_LABEL, linkProps: {to: '/about'}},
];

export { DefaultMenuItems, MobileMenuItems, AUCTION_CONTEXT_PARAM };
