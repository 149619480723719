import { constants as c } from '../constants';
import { BidHelper } from '../bid-system'

export const getIsStrainHigher = function getIsStrainHigher(a, b) {
  return c.BID_LADDER.indexOf(a) > c.BID_LADDER.indexOf(b);
};

export const getLowerStrain = function getLowerStrain(strainA, strainB) {
  if (getIsStrainHigher(strainA.strain, strainA.strain)) {
    return strainB;
  }
  return strainA;
};

export const SuitSortMethods = {
  opener: (suits, hand) => {

    suits.sort((a, b) => {
      // bump up the five card majors
      if (a.getIsMajor() && b.getIsMinor()) {
        if (a.getLength() >= c.FIVE_CARD_SUIT) {
          return -1;
        }
        return 1;
      } else if (a.getIsMinor() && b.getIsMajor()) {
        if (b.getLength() >= c.FIVE_CARD_SUIT) {
          return 1;
        }
        return -1;
      }

      // bump the higher rank long suits
      if (a.getLength() >= c.FIVE_CARD_SUIT && b.getLength() >= c.FIVE_CARD_SUIT) {
        if (c.BID_LADDER.indexOf(a.strain) > c.BID_LADDER.indexOf(b.strain)) {
          return -1;
        }
        return 1;
      }

      // no change on majors less than 5 cards
      if (a.getIsMajor() && b.getIsMajor()) {
        if (a.getLength() >= c.FIVE_CARD_SUIT) {
          return -1;
        }
        return 0;
      }

      // open clubs when 3-3 in minors
      if (a.getLength() === c.THREE_CARD_SUIT && b.getLength() === c.THREE_CARD_SUIT) {
        if (c.BID_LADDER.indexOf(a.strain) < c.BID_LADDER.indexOf(b.strain)) {
          return -1;
        }
        return 1;
      }

      // open diamonds when 4-4 in minors
      if (a.getLength() === c.FOUR_CARD_SUIT && b.getLength() === c.FOUR_CARD_SUIT) {
        // balanced hands bid clubs
        if (hand.getIsBalanced()) {
          if (a.strain === c.CLUB) {
            return -1;
          }
          return 1;
        }
        //not balanced bid diamonds
        if (a.strain === c.CLUB) {
          return 1;
        }
        return -1;
      }

      // longer wins
      return b.getLength() - a.getLength();
    });

    // return the sorted suits;
    return suits;
  },

  responder: (suits, hand) => {

    const theirBid = hand.player.interferenceBid;
    const respondBid = hand.player.partnersBid;
    const beatBid = theirBid
      ? theirBid
      : respondBid;

    suits.sort((thisSuit, thatSuit) => {

      const getHigherRankSortValue = () => getIsStrainHigher(thisSuit.strain, thatSuit.strain)
        ? -1
        : 1;

      const getLowerRankSortValue = () => getIsStrainHigher(thisSuit.strain, thatSuit.strain)
        ? 1
        : -1;

      const getHasTwoFiveCardSuits = () => thisSuitLength === c.FIVE_CARD_SUIT
        && thatSuitLength === c.FIVE_CARD_SUIT;

      const thisSuitLength = thisSuit.getLength();
      const thatSuitLength = thatSuit.getLength();

      const canBidThisSuitAtOneLevel = BidHelper.getIsAtOneLevel(beatBid, thisSuit.strain);
      const canBidThatSuitAtOneLevel = BidHelper.getIsAtOneLevel(beatBid, thatSuit.strain);

      if (canBidThisSuitAtOneLevel && canBidThatSuitAtOneLevel) {

        if (getHasTwoFiveCardSuits()) {
          return getHigherRankSortValue();
        }

        if (thisSuitLength > thatSuitLength) {
          return -1;
        }
        if (thatSuitLength > thisSuitLength) {
          return 1;
        }

        if (thisSuit.getIsMajor() && thatSuit.getIsMajor()) {
          return getLowerRankSortValue();
        }

        if (hand.getIsMinimal()) {
          return getHigherRankSortValue();
        }

        return getLowerRankSortValue();

      }

      if (canBidThisSuitAtOneLevel) {
        return -1;
      }
      if (canBidThatSuitAtOneLevel) {
        return 1;
      }

      if (getHasTwoFiveCardSuits()) {
        return getHigherRankSortValue();
      }

      if (thatSuitLength - thisSuitLength === 0) {
        return getLowerRankSortValue();
      }

      return thatSuitLength - thisSuitLength;
    });

    // return the sorted suits;
    return suits;
  },

  overCaller: suits => {

      suits.sort((a, b) => {
        if (a.getIsGood() && !b.getIsGood()) {
          return - 1;
        }
        if (!a.getIsGood() && b.getIsGood()) {
          return 1;
        }
        if (!a.getIsGood() && !b.getIsGood()) {
          return 0;
        }
        // longer wins
        return b.getLength() - a.getLength();
      });

      // return the sorted suits;
      return suits;
    },

};
