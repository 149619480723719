import { has} from 'ramda';

import { constants as c } from '../constants';

import {
  createPromise,
  createRangePromise,
  getNoTrumpSuitPromises,
  createSuitLengthPromise,
} from './bid-promises';

const getRangePromiseOrEmpty = rule => (
  has('range', rule)
    ? [createRangePromise(rule.range)]
    : []
  );

const getSuitPromisesOrEmpty = rule => (
  rule.strain === c.NO_TRUMP
    ? getNoTrumpSuitPromises(c.NO_TRUMP)
    : has('suitLength', rule)
      ? [createSuitLengthPromise(
          has('actualStrain', rule)
            ? rule.actualStrain
            : rule.strain,
          rule.suitLength)
      ]
    : []
  );

const getRulePromisesOrEmpty = rule => (
  has('additionalPromises', rule)
    ? rule.additionalPromises(rule)
    : []
  );

const getTypePromises = rule => {
  return (types => types.map(type => createPromise({ type }))
    )(has('type', rule)
      ? [rule.type]
      : rule.types)
}

const getMetaDataPromise = rule => (
  has('metaData', rule)
    ? [ createPromise({
          type: 'META_DATA',
          metaData: rule.metaData,
        })
      ]
    : []
  );

const createBidPromises = function createBidPromises(rule) {
  return [
    ...getTypePromises(rule),
    ...getRangePromiseOrEmpty(rule),
    ...getSuitPromisesOrEmpty(rule),
    ...getRulePromisesOrEmpty(rule),
    ...getMetaDataPromise(rule),
  ];
};

export { createBidPromises };
