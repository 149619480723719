export const SET_AUCTION_COMPLETE_PROPS = 'game-state/set-auction-complete-props';
export const SET_AUCTION_UPDATE = 'game-state/set-auction-update';
export const SET_ON_CARD_PLAY_PROPS = 'game-state/set-end-card-play-props';
export const SET_START_CARD_PLAY_PROPS = 'game-state/set-start-card-play-props';
export const SET_CARDS_DEALT_PROPS = 'game-state/set-cards-dealt-props';
export const SET_DEAL_PROGRESS = 'game-state/set-deal-progress';
export const SET_HIGHLIGHTED_PLAYER = 'game-state/set-highlighted_player';
export const SET_NEXT_DEALER = 'game-state/set-next-dealer';
export const SET_NEXT_TRICK = 'game-state/set-next-trick';
export const SET_ORIGINAL_HANDS_VISIBLE = 'game-state/set-original-hands-visible';
export const SET_RUBBER_IN_PROGRESS = 'game-state/set-rubber-in-progress';
export const SET_DEAL_COMPLETE_PROPS = 'game-state/set-deal-complete-props';
export const SET_SELECTED_AUCTION_CELL = 'game-state/set-selected-auction-cell-props';
export const SET_START_PROPS = 'game-state/set-start-props';
export const SET_TALLY_PROPS = 'game-state/set-tally-props';
export const SET_TRICK_COMPLETE_PROPS = 'game-state/set-trick-complete-props';
export const SET_VIEW_MODE = 'game-state/set-view-mode';
export const SET_VIEW_AUCTION = 'game-state/set-view-auction';
