import { constants as c } from '../../bridge';

import React from "react";
import PropTypes from 'prop-types';

const WrappedStrain = function WrappedStrain({ strain }) {
  return <span className={c.lowerCaseStrainName(strain)}>{strain}</span>
};

const WrappedStrains = function WrappedStrains({ text }) {
  let result = [ text ];

  Object.values(c.STRAINS).forEach(strain => {
    result = result.flatMap(el => {
      if (typeof el === 'string'
        && el.includes(strain)
      ) {
        return el.split(strain).reduce((acc, str, i, a) => {
          if (str === ''
            || i === a.length - 1
          ) {
            // strain was at end/beginning or 'i' is last elem
            return [ ...acc, str ];
          }
          return [ ...acc, str, <WrappedStrain strain={strain} /> ];
        }, [])
      }
      return el;
    })
  });

  return result;
};
WrappedStrains.propTypes = {
  text: PropTypes.string,
};

export { WrappedStrains };
