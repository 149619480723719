import React from 'react';
import PropTypes from 'prop-types';

import { constants as uic } from '../../constants';

const CharIcon = function CharIcon({ id, onClick, char }) {
  return (
    <button id={id}
      onClick={onClick}
      className="char-icon"
    >
      {char}
    </button>
  );
};
CharIcon.propTypes = {
  char: PropTypes.string,
  id: PropTypes.string,
  onClick: PropTypes.func,
};

const CloseIcon = function CloseIcon(props) {
  return <CharIcon char={uic.ICON_CLOSE_CHAR} {...props} />;
};
CloseIcon.propTypes = {
  id: PropTypes.string,
  onClick: PropTypes.func,
};

const CloseCollapseIcon = function CloseCollapseIcon(props) {
  return <CharIcon char={uic.ICON_COLLAPSE_CLOSE_CHAR} {...props} />;
};
CloseCollapseIcon.propTypes = {
  id: PropTypes.string,
  onClick: PropTypes.func,
};

const OpenCollapseIcon = function OpenCollapseIcon(props) {
  return <CharIcon char={uic.ICON_COLLAPSE_OPEN_CHAR} {...props} />;
};
OpenCollapseIcon.propTypes = {
  id: PropTypes.string,
  onClick: PropTypes.func,
};

export { CharIcon, CloseIcon, CloseCollapseIcon, OpenCollapseIcon };
