import React, { Suspense, lazy } from 'react';
import { Redirect } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { compose, pipe } from 'ramda';

import { createBridgeLabels } from './bridge';

import { GamePresenter } from './presenter';

import GameView from './view/game-view';

import { FallingSuits } from './view/components/FallingSuits';

import { initTheme, getSystemPrefersDarkMode } from './view/theme';

import { BridgeLabelContext } from './view/BridgeLabelContext';
import { BidSystemConfigContext } from './view/BidSystemConfigContext';
import { PresenterContext } from './view/PresenterContext';

import { SavedDealsService } from './deal-db/saved-deals-service';

import { DisplayOptionActions } from './state/ducks/display-options';

import {
  getAppSettings,
  getGameOptionsSettings,
  getBidSystemConfig,
  getIsDarkMode,
  setThemeMode,
  getThemeMode,
  updateAppSetting,
  getDisplaySplashIntro,
} from './settings';

import { GameOptionActions } from './state/ducks/game-options';

import rootReducer from './state';

import withSplashScreen from './view/pages/SplashPage';
import FourZeroFour from './view/pages/FourZeroFour'

const AboutPage = lazy(() => import('./view/pages/AboutPage'));
const BidCheatSheetPage = lazy(() => import('./view/pages/BidCheatSheetPage'));
const BridgeGameViewer = lazy(() => import('./state/connectors/BridgeGameViewer'));
const LandingAboutPage = lazy(() => import('./view/pages/LandingAboutPage'));
const LandingCardPage = lazy(() => import('./view/pages/LandingCardPage'));
const SettingsPage = lazy(() => import('./view/pages/SettingsPage'));

export const initColorTheme = pipe(
  getSystemPrefersDarkMode,
  getThemeMode,
  initTheme
);

const getIsDarkModeWithDefault = pipe(
  getSystemPrefersDarkMode,
  getIsDarkMode
);

const setIsDarkMode = function setIsDarkMode(isDark) {
  setThemeMode(isDark);
  initColorTheme();
};

const createBidSystem = function createBidSystem() {
  return {
    bidSystemConfig: getBidSystemConfig(),
  };
};

/**
* @class App - React App -
*/
class App extends React.Component {
  constructor(props) {
    super(props);

    const { bidSystemConfig } = createBidSystem();
    this.state = {
      bidSystemConfig,
      bridgeLabels: createBridgeLabels(bidSystemConfig)
    };

    this.store = createStore(rootReducer);

    this.savedDealsService = new SavedDealsService();

    this.gameView = new GameView(this);

    this.gamePresenter = new GamePresenter(
      this.store,
      this.gameView,
      this.savedDealsService,
      this.refreshPlayers,
    );
  }

  refreshPlayers = async () => {
    const players = await this.getPlayers();
    players.forEach(player =>
      player.bidSystemConfig = this.getBidSystemConfig()
    );
    this.gamePresenter.onNewSession(players);
  }

  prepareNewSession = gameOptions => {
    this.setGameOptions(gameOptions);
    // createBidSystem -> broadcast to players in setState callback
    const { bidSystemConfig } = createBidSystem();
    this.setState({
        bidSystemConfig,
        bridgeLabels: createBridgeLabels(bidSystemConfig)
      },
      this.refreshPlayers
    );
  }

  getBidSystemConfig = () => {
    return this.state.bidSystemConfig;
  }

  getBridgeLabels = () => {
    return this.state.bridgeLabels;
  }

  getPlayers = async () => {
    const playersPromise = (async () => {
      if (!this.players) {
        const { createBridgePlayers } = await import('./bridge');
        this.players = createBridgePlayers(['1', '2', '3', '4'], this.getBidSystemConfig());
        return this.players;
      }
      return this.players;
    })();

    return await playersPromise.then(
      players => players
    );
  }

  setGameOptions(gameOptions) {
    this.store.dispatch(GameOptionActions.setGameOptions(gameOptions));
  }

  getGamePlayProperties() {
    return this.gamePresenter.getDefaultProperties();
  }

  updateAppSetting = update => {
    compose(
      this.store.dispatch,
      DisplayOptionActions.appSettingUpdate,
      updateAppSetting,
    )(update);
  }

  render() {
    return (
      <BridgeLabelContext.Provider value={this.getBridgeLabels()} >
      <BidSystemConfigContext.Provider value={this.getBidSystemConfig} >
      <Provider store={this.store}>
        <Router>
          <Switch>
          <Route path="/" exact strict render={() =>
            (getDisplaySplashIntro()
              ? <Redirect to='/about/landing' />
              : <Redirect to='/options' />
            )
          }/>
          <Route path="/options" exact render={props =>
            <Suspense fallback={<FallingSuits isDisplayTitle={true}/>}>
              <LandingCardPage
                 {...props}
                 prepareNewSession={this.prepareNewSession}
                 gameOptions={getGameOptionsSettings()}
               />
             </Suspense>
          }/>
          <Route path="/about/landing" exact render={props =>
            <Suspense fallback={<FallingSuits isDisplayTitle={true}/>}>
              <LandingAboutPage {...props} />
            </Suspense>
          }/>
          <Route path="/about" exact render={props =>
            <Suspense fallback={<FallingSuits />}>
              <AboutPage {...props} />
            </Suspense>
          }/>
          <Route path="/guide" exact render={props =>
            <Suspense fallback={<FallingSuits />}>
              <BidCheatSheetPage {...props} bidSystemConfig={getBidSystemConfig()} />
            </Suspense>
          }/>
          <Route path="/settings" exact render={props =>
            <Suspense fallback={<FallingSuits isDisplayTitle={true}/>}>
              <SettingsPage
                {...props}
                getIsDarkMode={getIsDarkModeWithDefault}
                setIsDarkMode={setIsDarkMode}
                getAppSettings={getAppSettings}
                updateAppSetting={this.updateAppSetting}
                getBidSystemConfig={getBidSystemConfig}
              />
            </Suspense>
          }/>
          <Route path="/:playType/:bidType/:bidSystem/:dealType" exact render={props => (
            <Suspense fallback={<FallingSuits isDisplayTitle={true}/>}>
              <PresenterContext.Provider value={this.getGamePlayProperties()}>
                <BridgeGameViewer
                  handleNewGameOptions={this.prepareNewSession}
                  {...props}
                />
              </PresenterContext.Provider>
            </Suspense>
          )
          }/>

          <Route component={FourZeroFour} />
          </Switch>
        </Router>
      </Provider>
      </BidSystemConfigContext.Provider>
      </BridgeLabelContext.Provider>
    );
  }
}

export default withSplashScreen(App);
