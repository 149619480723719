import React from "react";
import PropTypes from 'prop-types';

import { DialogTitleBar } from '../components';

const ExportDealModal = props => {
  return (
    <div id="export-deal-modal" className="main-component__container">
      <DialogTitleBar title="Export Deal" onClose={props.onClose}/>
      <div id="export-deal-content">
        <pre><code contentEditable="true" tabIndex="0" spellCheck="false" className="modal-dialog__content">
          {props.content}
        </code></pre>
      </div>
    </div>
  );
};

ExportDealModal.propTypes = {
  content: PropTypes.string,
  onClose: PropTypes.func,
};

export default ExportDealModal;
