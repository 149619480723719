import {
  createTotalPointsLabel,
  createRangeLabel,
} from '../create-range-label';

import {
  RAISE,
  SIMPLE_RAISE,
  JUMP_RAISE,
  PLAY_THREE_NT,
  NEW_SUIT,
  TWO_NO_TRUMP,
  STRONG_GAME_RAISE,
  WEAK_GAME_RAISE,
  DEF_RAISE,
  DEF_SIMPLE_RAISE,
  DEF_JUMP_RAISE,
  DEF_PLAY_THREE_NT,
  DEF_NEW_SUIT,
  DEF_TWO_NO_TRUMP,
  DEF_STRONG_GAME_RAISE,
  DEF_WEAK_GAME_RAISE,
} from './types';

import { WEAK_TWO_RESPONSE_RANGES } from './ranges';

export const createLabels = function createLabels(bidSystemConfig) {
  const ranges = bidSystemConfig[ WEAK_TWO_RESPONSE_RANGES ];

  const RAISE_LABEL = `Raise Partner's Suit`;
  const DEF_RAISE_LABEL = `fit with partner`;

  const SIMPLE_RAISE_LABEL = `Simple Raise`;
  const DEF_SIMPLE_RAISE_LABEL = `3+ cards in partner's suit - ${createTotalPointsLabel(ranges[ SIMPLE_RAISE ])}`;

  const JUMP_RAISE_LABEL = 'Jump Raise';
  const DEF_JUMP_RAISE_LABEL = `Play game in partners suit`;
  const STRONG_GAME_RAISE_LABEL = 'Strong';
  const DEF_STRONG_GAME_RAISE_LABEL = `Correct Contract, otherwise bid 2NT or new suit - 4+ card fit - ${createRangeLabel(ranges[ STRONG_GAME_RAISE ])}`;
  const WEAK_GAME_RAISE_LABEL = 'Preemptive';
  const DEF_WEAK_GAME_RAISE_LABEL = ` weak - 4+ card fit - ${createRangeLabel(ranges[ WEAK_GAME_RAISE ])}`;

  const PLAY_THREE_NT_LABEL = 'Jump to Game No Trumps';
  const DEF_PLAY_THREE_NT_LABEL = 'Play three no trump game';

  const NEW_SUIT_LABEL = `New Suit Forcing`;
  const DEF_NEW_SUIT_LABEL = `Strong hand ${createRangeLabel(ranges[ NEW_SUIT ])}, and a good 5 card suit. Denies a fit`;

  const TWO_NO_TRUMP_LABEL = 'Two No Trump';
  const DEF_TWO_NO_TRUMP_LABEL = 'Forcing, (likely trump fit) asking partner to bid suit containing an outside ace or king.';

  return {
    [ RAISE ]: RAISE_LABEL,
    [ DEF_RAISE ]: DEF_RAISE_LABEL,
    [ SIMPLE_RAISE ]: SIMPLE_RAISE_LABEL,
    [ DEF_SIMPLE_RAISE ]: DEF_SIMPLE_RAISE_LABEL,
    [ JUMP_RAISE ]: JUMP_RAISE_LABEL,
    [ DEF_JUMP_RAISE ]: DEF_JUMP_RAISE_LABEL,
    [ STRONG_GAME_RAISE ]: STRONG_GAME_RAISE_LABEL,
    [ DEF_STRONG_GAME_RAISE ]: DEF_STRONG_GAME_RAISE_LABEL,
    [ WEAK_GAME_RAISE ]: WEAK_GAME_RAISE_LABEL,
    [ DEF_WEAK_GAME_RAISE ]: DEF_WEAK_GAME_RAISE_LABEL,
    [ PLAY_THREE_NT ]: PLAY_THREE_NT_LABEL,
    [ DEF_PLAY_THREE_NT ]: DEF_PLAY_THREE_NT_LABEL,
    [ NEW_SUIT ]: NEW_SUIT_LABEL,
    [ DEF_NEW_SUIT ]: DEF_NEW_SUIT_LABEL,
    [ TWO_NO_TRUMP ]: TWO_NO_TRUMP_LABEL,
    [ DEF_TWO_NO_TRUMP ]: DEF_TWO_NO_TRUMP_LABEL,
  };
};
