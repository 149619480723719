import { isNil } from 'ramda';

import { constants as c } from '../../constants';

import {
  OPEN_THE_BIDDING,
  RESPONSE_TO_ONE_OF_A_SUIT_OPEN,
  RESPONSE_TO_NO_TRUMP_OPEN,
  RESPONSE_AFTER_SUIT_OVERCALL,
  OVERCALL_AND_TAKEOUT_DOUBLE,
} from '../bid-context';

import {
  DirectOvercallOptions,
  JacobyAcceptOptions,
  JacobyTransferRebidOptions,
  OpeningBidOptions,
  OvercallAdvanceOptions,
  ResponseAfterOppSuitOvercall,
  ResponseToSuitOpenOptions,
  ResponseNoTrumpOpenOptions,
  StrongJumpShiftResponse,
  StrongTwoOpenResponseOptions,
  StrongTwoOpenerRebidPositiveSuit,
  TakeoutDoubleResponseOptions,
  WeakTwoResponseOptions,
} from '../bid-options';

import {
  CONTROL_BID_TO_ESTABLISH_SLAM,
  CUE_BID,
  DRURY,
  FOURTH_SUIT_FORCING,
  FOURTH_SUIT_FORCING_INVITE,
  JACOBY_TRANSFER,
  JUMP_SHIFT_FLOOR,
  LIMIT_RAISE_WITH_THREE_CARD_FIT,
  MICHAELS_CUE_BID,
  NEGATIVE_DOUBLE,
  NO_TRUMP_OVERCALL_DEFENCE_TWO_FIVE_CARD_SUITS,
  OPEN_LIGHT_QUICK_TRICK_COUNT,
  OPEN_POINTS_MINIMUM,
  OPEN_PREEMPT_MINIMUM_POINTS,
  OPEN_RULE_OF_TWENTY,
  OPEN_THIRD_SEAT_LIGHT_GOOD_SUIT,
  OVERCALL_MINIMUM_POINTS,
  OVERCALL_NO_TRUMP_OPEN_CONVENTION,
  OVERCALL_NO_TRUMP_OPEN_ASTRO,
  OVERCALL_NO_TRUMP_OPEN_CAPPELLETTI,
  OVERCALL_NO_TRUMP_OPEN_LANDY,
  TWO_LEVEL_GOOD_SUIT_OVERCALL_MIMIMUM,
  TAKE_OUT_DOUBLE_POINT_RANGE,
  RESPOND_POINTS_MINIMUM,
  WEAK_TWOS,
} from './types'

export const DefendNoTrumpOpenConventions = [
  OVERCALL_NO_TRUMP_OPEN_ASTRO,
  OVERCALL_NO_TRUMP_OPEN_CAPPELLETTI,
  OVERCALL_NO_TRUMP_OPEN_LANDY,
];

export const GroupedOptions = {
  [ OPEN_THE_BIDDING ]: [
    OPEN_POINTS_MINIMUM,
    OPEN_PREEMPT_MINIMUM_POINTS,
    OpeningBidOptions.OPENING_BID_RANGES,
    OPEN_LIGHT_QUICK_TRICK_COUNT,
    OPEN_THIRD_SEAT_LIGHT_GOOD_SUIT,
    OPEN_RULE_OF_TWENTY,
    WEAK_TWOS,
  ],
  [ RESPONSE_TO_ONE_OF_A_SUIT_OPEN ]: [
    RESPOND_POINTS_MINIMUM,
    LIMIT_RAISE_WITH_THREE_CARD_FIT,
    JUMP_SHIFT_FLOOR,
    DRURY,
  ],
  [ RESPONSE_TO_NO_TRUMP_OPEN ]: [
    JACOBY_TRANSFER,
  ],
  [ RESPONSE_AFTER_SUIT_OVERCALL ]: [
    NEGATIVE_DOUBLE,
  ],
  [ OVERCALL_AND_TAKEOUT_DOUBLE ]: [
    TWO_LEVEL_GOOD_SUIT_OVERCALL_MIMIMUM,
    TAKE_OUT_DOUBLE_POINT_RANGE,
    MICHAELS_CUE_BID,
  ],
};

const DefaultBidSystemConfig = Object.freeze({
  [ CONTROL_BID_TO_ESTABLISH_SLAM ]: true,
  [ CUE_BID ]: true,
  [ DRURY ]: true,
  [ FOURTH_SUIT_FORCING ]: true,
  [ FOURTH_SUIT_FORCING_INVITE ]: true,
  [ JACOBY_TRANSFER ]: true,
  [ JUMP_SHIFT_FLOOR ]: 17,
  [ LIMIT_RAISE_WITH_THREE_CARD_FIT ]: false,
  [ OPEN_LIGHT_QUICK_TRICK_COUNT ]: true,
  [ OPEN_POINTS_MINIMUM ]: 13,
  [ OPEN_PREEMPT_MINIMUM_POINTS ]: 5,
  [ OPEN_RULE_OF_TWENTY ]: false,
  [ OPEN_THIRD_SEAT_LIGHT_GOOD_SUIT ]: true,
  [ OVERCALL_MINIMUM_POINTS ]: 5,
  [ OVERCALL_NO_TRUMP_OPEN_CONVENTION ]: OVERCALL_NO_TRUMP_OPEN_LANDY,
  [ MICHAELS_CUE_BID ]: true,
  [ NEGATIVE_DOUBLE ]: true,
  [ NO_TRUMP_OVERCALL_DEFENCE_TWO_FIVE_CARD_SUITS ]: true,
  [ RESPOND_POINTS_MINIMUM]: 6,
  [ TWO_LEVEL_GOOD_SUIT_OVERCALL_MIMIMUM ]: 12,
  [ WEAK_TWOS ]: true,
  [ TAKE_OUT_DOUBLE_POINT_RANGE ]: {
    lower: 12,
    noTrumpLower: 18,
    threeLevelLower: 15,
    sixCardSuitLower: 17,
    strongHandLower: 19,
  },
  [ DirectOvercallOptions.DIRECT_OVERCALL_RANGES ]: DirectOvercallOptions.createRanges(),
  [ OpeningBidOptions.OPENING_BID_RANGES ]: OpeningBidOptions.createRanges({ isWeakTwo: true }),
  [ OvercallAdvanceOptions.OVERCALL_ADVANCE_RANGES ]: OvercallAdvanceOptions.createRanges(),
  [ ResponseAfterOppSuitOvercall.RESPONSE_AFTER_OPP_SUIT_OVERCALL_RANGES ]: ResponseAfterOppSuitOvercall.createRanges(),
  [ ResponseToSuitOpenOptions.RESPONSE_ONE_LEVEL_SUIT_OPEN_RANGES ]: ResponseToSuitOpenOptions.createRanges(),
  [ ResponseNoTrumpOpenOptions.RESPONSE_OPEN_NO_TRUMP_RANGES ]: ResponseNoTrumpOpenOptions.createRanges(),
  [ StrongJumpShiftResponse.STRONG_JUMP_SHIFT_RANGES ]: StrongJumpShiftResponse.createRanges(),
  [ StrongTwoOpenResponseOptions.STRONG_TWO_RESPONSE_RANGES ]: StrongTwoOpenResponseOptions.createRanges(),
  [ TakeoutDoubleResponseOptions.TAKE_OUT_DOUBLE_RESPONSE_RANGES ]: TakeoutDoubleResponseOptions.createRanges(),
  [ WeakTwoResponseOptions.WEAK_TWO_RESPONSE_RANGES ]: WeakTwoResponseOptions.createRanges(),
  [ JacobyAcceptOptions.JACOBY_ACCEPT_RANGES ]: JacobyAcceptOptions.createRanges(),
  [ JacobyTransferRebidOptions.JACOBY_TRANSFER_REBID_RANGES ]: JacobyTransferRebidOptions.createRanges(),
  [ StrongTwoOpenerRebidPositiveSuit.STRONG_TWO_OPENER_RESPONSE_TO_POSITIVE_SUIT_RANGES ]: StrongTwoOpenerRebidPositiveSuit.createRanges(),
});

const BridgeBlvdBeginnerSystemConfig = Object.freeze({
  [ CONTROL_BID_TO_ESTABLISH_SLAM ]: false,
  [ CUE_BID ]: false,
  [ DRURY ]: false,
  [ FOURTH_SUIT_FORCING ]: false,
  [ FOURTH_SUIT_FORCING_INVITE ]: false,
  [ JACOBY_TRANSFER ]: false,
  [ JUMP_SHIFT_FLOOR ]: 17,
  [ LIMIT_RAISE_WITH_THREE_CARD_FIT ]: false,
  [ OPEN_LIGHT_QUICK_TRICK_COUNT ]: false,
  [ OPEN_POINTS_MINIMUM ]: 13,
  [ OPEN_PREEMPT_MINIMUM_POINTS ]: 5,
  [ OPEN_RULE_OF_TWENTY ]: false,
  [ OPEN_THIRD_SEAT_LIGHT_GOOD_SUIT ]: false,
  [ OVERCALL_MINIMUM_POINTS ]: 5,
  [ OVERCALL_NO_TRUMP_OPEN_CONVENTION ]: '',
  [ TWO_LEVEL_GOOD_SUIT_OVERCALL_MIMIMUM ]: 12,
  [ MICHAELS_CUE_BID ]: false,
  [ NO_TRUMP_OVERCALL_DEFENCE_TWO_FIVE_CARD_SUITS ]: false,
  [ NEGATIVE_DOUBLE ]: false,
  [ WEAK_TWOS ]: false,
  [ TAKE_OUT_DOUBLE_POINT_RANGE ]: {
    lower: 12,
    noTrumpLower: 18,
    threeLevelLower: 15,
    sixCardSuitLower: 17,
    strongHandLower: 19,
  },
  [ DirectOvercallOptions.DIRECT_OVERCALL_RANGES ]: DirectOvercallOptions.createRanges(),
  [ OpeningBidOptions.OPENING_BID_RANGES ]: OpeningBidOptions.createRanges({ isWeakTwo: false }),
  [ OvercallAdvanceOptions.OVERCALL_ADVANCE_RANGES ]: OvercallAdvanceOptions.createRanges(),
  [ ResponseAfterOppSuitOvercall.RESPONSE_AFTER_OPP_SUIT_OVERCALL_RANGES ]: ResponseAfterOppSuitOvercall.createRanges(),
  [ ResponseToSuitOpenOptions.RESPONSE_ONE_LEVEL_SUIT_OPEN_RANGES ]: ResponseToSuitOpenOptions.createRanges(),
  [ ResponseNoTrumpOpenOptions.RESPONSE_OPEN_NO_TRUMP_RANGES ]: ResponseNoTrumpOpenOptions.createRanges(),
  [ StrongJumpShiftResponse.STRONG_JUMP_SHIFT_RANGES ]: StrongJumpShiftResponse.createRanges(),
  [ StrongTwoOpenResponseOptions.STRONG_TWO_RESPONSE_RANGES ]: StrongTwoOpenResponseOptions.createRanges(),
  [ TakeoutDoubleResponseOptions.TAKE_OUT_DOUBLE_RESPONSE_RANGES ]: TakeoutDoubleResponseOptions.createRanges(),
  [ WeakTwoResponseOptions.WEAK_TWO_RESPONSE_RANGES ]: WeakTwoResponseOptions.createRanges(),
  [ JacobyAcceptOptions.JACOBY_ACCEPT_RANGES ]: JacobyAcceptOptions.createRanges(),
  [ JacobyTransferRebidOptions.JACOBY_TRANSFER_REBID_RANGES ]: JacobyTransferRebidOptions.createRanges(),
  [ StrongTwoOpenerRebidPositiveSuit.STRONG_TWO_OPENER_RESPONSE_TO_POSITIVE_SUIT_RANGES ]: StrongTwoOpenerRebidPositiveSuit.createRanges(),

});

export const DEFAULT_BID_SYSTEM_NAME = c.STANDARD_AMERICAN;

export const getDefaultSystemConfig = function getDefaultSystemConfig(
  bidSystemName = DEFAULT_BID_SYSTEM_NAME
) {
  return bidSystemName === c.STANDARD_AMERICAN
    ? DefaultBidSystemConfig
    : BridgeBlvdBeginnerSystemConfig;
};

export const getTakeoutDoublePointRange = ({ bidSystemConfig }) => bidSystemConfig[ TAKE_OUT_DOUBLE_POINT_RANGE ];

export const getIsMichaelsCueBid = ({ bidSystemConfig }) => bidSystemConfig[ MICHAELS_CUE_BID ];

export const getIsJacobyTransfer = ({ bidSystemConfig }) => bidSystemConfig[ JACOBY_TRANSFER ];

export const getIsOverCallAdvanceCueBid = ({ bidSystemConfig }) => bidSystemConfig.cueBid;

export const getIsCueBidAfterNoTrumpOpenOvercall = ({ bidSystemConfig }) => bidSystemConfig.cueBid;

export const getIsTheyCueBidOvercall = ({ bidSystemConfig }) => bidSystemConfig.cueBid;

export const getIsTakeOutDoubleRespondCueBid = ({ bidSystemConfig }) => bidSystemConfig.cueBid;

export const getOvercallNoTrumpOpenConvention = ({ bidSystemConfig}) => bidSystemConfig.overcallNoTrumpOpenConvention

export const getIsOvercallNoTrumpOpenConvention = ({ bidSystemConfig }) => !isNil(bidSystemConfig.overcallNoTrumpOpenConvention);

export const getTwoLevelGoodSuitOvercallMin = ({ bidSystemConfig }) => bidSystemConfig[ TWO_LEVEL_GOOD_SUIT_OVERCALL_MIMIMUM ];

export const getIsNoTrumpOvercallDefenceTwoFiveCardSuits = ({ bidSystemConfig }) => bidSystemConfig.noTrumpOvercallDefenceTwoFiveCardSuits;

export const getIsNegativeDouble = ({ bidSystemConfig }) => bidSystemConfig[ NEGATIVE_DOUBLE ];

export const getJumpShiftFloor = ({ bidSystemConfig }) => bidSystemConfig[ JUMP_SHIFT_FLOOR ];

export const getIsControlBidToEstablishSlam = ({ bidSystemConfig }) => bidSystemConfig.controlBidToEstablishSlam;

export const getLimitRaiseWithOnlyThreeCardFit = ({ bidSystemConfig }) => bidSystemConfig[ LIMIT_RAISE_WITH_THREE_CARD_FIT ];

export const getMinimumResponsePoints = ({ bidSystemConfig }) => bidSystemConfig.respondPointsMin;

export const getMinimumOpenPoints = ({ bidSystemConfig }) => bidSystemConfig.openPointsMinimum;

export const getIsPlayWeakTwos = ({ bidSystemConfig }) => bidSystemConfig[ WEAK_TWOS ];

export const getIsDrury = ({ bidSystemConfig }) => bidSystemConfig.drury;

export const getNegativeDoubleRange = ({ bidSystemConfig }) =>
  bidSystemConfig[ ResponseAfterOppSuitOvercall.RESPONSE_AFTER_OPP_SUIT_OVERCALL_RANGES ][ ResponseAfterOppSuitOvercall.NEGATIVE_DOUBLE ];

export const getNegativeDoubleThreeLevelRange = ({ bidSystemConfig }) =>
  bidSystemConfig[ ResponseAfterOppSuitOvercall.RESPONSE_AFTER_OPP_SUIT_OVERCALL_RANGES ][ ResponseAfterOppSuitOvercall.NEGATIVE_DOUBLE_THREE_LEVEL ];

export const getRaiseToMajorGameInCompRange = ({ bidSystemConfig }) =>
  bidSystemConfig[ ResponseAfterOppSuitOvercall.RESPONSE_AFTER_OPP_SUIT_OVERCALL_RANGES ][ ResponseAfterOppSuitOvercall.RAISE_TO_MAJOR_GAME ];


export const getOpenNoTrumpResponseRanges = ({ bidSystemConfig }) =>
  bidSystemConfig[ ResponseNoTrumpOpenOptions.RESPONSE_OPEN_NO_TRUMP_RANGES ];

export const getOverCallAdvanceRanges = ({ bidSystemConfig }) =>
  bidSystemConfig[ OvercallAdvanceOptions.OVERCALL_ADVANCE_RANGES ]

export const getStrongTwoPositiveSuitRanges = ({ bidSystemConfig }) =>
  bidSystemConfig[ StrongTwoOpenerRebidPositiveSuit.STRONG_TWO_OPENER_RESPONSE_TO_POSITIVE_SUIT_RANGES ]

export const getStrongTwoOpenResponseGameForceRange = ({ bidSystemConfig }) =>
  bidSystemConfig[ StrongTwoOpenResponseOptions.STRONG_TWO_RESPONSE_RANGES ][ StrongTwoOpenResponseOptions.GAME_FORCE_RANGE ];

export const getStrongTwoOpenResponseGameWaitingRange = ({ bidSystemConfig }) =>
  bidSystemConfig[ StrongTwoOpenResponseOptions.STRONG_TWO_RESPONSE_RANGES ][ StrongTwoOpenResponseOptions.TWO_D_WAITING ];

export const getTakeoutDoubleResponseFreeBidRange = ({ bidSystemConfig }) =>
  bidSystemConfig[ TakeoutDoubleResponseOptions.TAKE_OUT_DOUBLE_RESPONSE_RANGES ][ TakeoutDoubleResponseOptions.FREE_BID_RANGE ]

export const getTakeoutDoubleResponseNoTrumpResponseRange = ({ bidSystemConfig }) =>
  bidSystemConfig[ TakeoutDoubleResponseOptions.TAKE_OUT_DOUBLE_RESPONSE_RANGES ][ TakeoutDoubleResponseOptions.NO_TRUMP_RESPONSE_RANGE ];

export const getTakeoutDoubleResponseSuitResponseRange = ({ bidSystemConfig }) =>
  bidSystemConfig[ TakeoutDoubleResponseOptions.TAKE_OUT_DOUBLE_RESPONSE_RANGES ][ TakeoutDoubleResponseOptions.SUIT_RESPONSE_RANGE ];

export const getWeakTwoResponseStrongGameRange = ({ bidSystemConfig }) =>
  bidSystemConfig[ WeakTwoResponseOptions.WEAK_TWO_RESPONSE_RANGES ][ WeakTwoResponseOptions.STRONG_GAME_RAISE ];

// Open Ranges
export const getOpeningBidRanges = ({ bidSystemConfig }) =>
  bidSystemConfig[ OpeningBidOptions.OPENING_BID_RANGES ]

export const getOldStrongTwoRange = ({ bidSystemConfig }) =>
  bidSystemConfig[ OpeningBidOptions.OPENING_BID_RANGES ][ OpeningBidOptions.OPEN_TWO_LEVEL_STRONG_OLD ];

export const getOpenSuitOneLevelRange = ({ bidSystemConfig }) =>
  bidSystemConfig[ OpeningBidOptions.OPENING_BID_RANGES ][ OpeningBidOptions.OPEN_SUIT_ONE_LEVEL ];

export const getOpenOneNoTrumpRange = ({ bidSystemConfig }) =>
  bidSystemConfig[ OpeningBidOptions.OPENING_BID_RANGES ][ OpeningBidOptions.OPEN_ONE_NO_TRUMP ];

export const getOpenTwoNoTrumpRange = ({ bidSystemConfig }) =>
  bidSystemConfig[ OpeningBidOptions.OPENING_BID_RANGES ][ OpeningBidOptions.OPEN_TWO_NO_TRUMP ];

export const getOpenThreeNoTrumpRange = ({ bidSystemConfig }) =>
  bidSystemConfig[ OpeningBidOptions.OPENING_BID_RANGES ][ OpeningBidOptions.OPEN_THREE_NO_TRUMP ];

export const getTwoLevelStrong = ({ bidSystemConfig }) =>
  bidSystemConfig[ OpeningBidOptions.OPENING_BID_RANGES ][ OpeningBidOptions.OPEN_TWO_LEVEL_STRONG ];

export const getTwoLevelStrongBalancedRange = ({ bidSystemConfig }) =>
  bidSystemConfig[ OpeningBidOptions.OPENING_BID_RANGES ][ OpeningBidOptions.OPEN_TWO_LEVEL_STRONG_BALANCED ];

export const getTwoLevelStrongNotBalancedRange = ({ bidSystemConfig }) =>
  bidSystemConfig[ OpeningBidOptions.OPENING_BID_RANGES ][ OpeningBidOptions.OPEN_TWO_LEVEL_STRONG_UNBALANCED ];

export const getWeakTwoOpenRange = ({ bidSystemConfig }) =>
  bidSystemConfig[ OpeningBidOptions.OPENING_BID_RANGES ][ OpeningBidOptions.OPEN_TWO_LEVEL_WEAK_PREEMPT ];

export const getOpenThreeLevelRange = ({ bidSystemConfig }) =>
  bidSystemConfig[ OpeningBidOptions.OPENING_BID_RANGES ][ OpeningBidOptions.OPEN_THREE_LEVEL_PREEMPT ];

export { Labels } from './labels';

export {
  CONTROL_BID_TO_ESTABLISH_SLAM,
  CUE_BID,
  DRURY,
  FOURTH_SUIT_FORCING,
  FOURTH_SUIT_FORCING_INVITE,
  JACOBY_TRANSFER,
  JUMP_SHIFT_FLOOR,
  LIMIT_RAISE_WITH_THREE_CARD_FIT,
  MICHAELS_CUE_BID,
  NEGATIVE_DOUBLE,
  NO_TRUMP_OVERCALL_DEFENCE_TWO_FIVE_CARD_SUITS,
  OPEN_LIGHT_QUICK_TRICK_COUNT,
  OPEN_POINTS_MINIMUM,
  OPEN_PREEMPT_MINIMUM_POINTS,
  OPEN_RULE_OF_TWENTY,
  OPEN_THIRD_SEAT_LIGHT_GOOD_SUIT,
  OVERCALL_MINIMUM_POINTS,
  OVERCALL_NO_TRUMP_OPEN_CONVENTION,
  OVERCALL_NO_TRUMP_OPEN_ASTRO,
  OVERCALL_NO_TRUMP_OPEN_CAPPELLETTI,
  OVERCALL_NO_TRUMP_OPEN_LANDY,
  TWO_LEVEL_GOOD_SUIT_OVERCALL_MIMIMUM,
  TAKE_OUT_DOUBLE_POINT_RANGE,
  RESPOND_POINTS_MINIMUM,
  WEAK_TWOS,
};
