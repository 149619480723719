const generateNextBid = async function generateNextBid({
  player,
  currentBid,
  passCnt,
}) {
  const generatorPromise =  (async () => {
    if (player.getOurBids().length === 0 && player.getTheirBids().length === 0) {
      const { OpenBidGenerator } = await import('./open-bid-generator')
      return new OpenBidGenerator(player, passCnt);
    }
    if (player.getOurBids().length === 0) {
      const { OvercallBidGenerator } = await import('./overcall-bid-generator');
      return new OvercallBidGenerator(player, currentBid);
    }
    const { ResponseBidGenerator } = await import('./response-bid-generator');
    return new ResponseBidGenerator(player, currentBid);
  })();

  return await generatorPromise.then(
    g => g.getBid()
  );
};

export { generateNextBid };
