import { createRangeLabel, createTotalPointsLabel } from '../create-range-label';
//import { DATA_DEF_SEP } from '../../constants';

import {
  MAJOR_SUIT_FIT,
  MAJOR_SUIT_SIMPLE_RAISE,
  MAJOR_SUIT_LIMIT_RAISE,
  MAJOR_SUIT_WEAK_GAME_RAISE,
  NEW_SUIT_RESPONSE,
  NEW_SUIT_ONE_LEVEL,
  NEW_SUIT_TWO_LEVEL,
  NEW_SUIT_JUMP_SHIFT,
  MINOR_SUIT_RAISE,
  MINOR_SUIT_SIMPLE_RAISE,
  MINOR_SUIT_LIMIT_RAISE,
  NO_TRUMP_RESPONSE,
  ONE_LEVEL_NO_TRUMP,
  TWO_LEVEL_NO_TRUMP,
  THREE_LEVEL_NO_TRUMP,
  DEF_ONE_LEVEL_NO_TRUMP,
  DEF_TWO_LEVEL_NO_TRUMP,
  DEF_THREE_LEVEL_NO_TRUMP,
  DRURY_RESPONSE,
  DEF_MAJOR_SUIT_FIT,
  DEF_MAJOR_SUIT_SIMPLE_RAISE,
  DEF_MAJOR_SUIT_LIMIT_RAISE,
  DEF_MAJOR_SUIT_WEAK_GAME_RAISE,
  DEF_NEW_SUIT_RESPONSE,
  DEF_NEW_SUIT_ONE_LEVEL,
  DEF_NEW_SUIT_TWO_LEVEL,
  DEF_NEW_SUIT_JUMP_SHIFT,
  DEF_MINOR_SUIT_RAISE,
  DEF_MINOR_SUIT_SIMPLE_RAISE,
  DEF_MINOR_SUIT_LIMIT_RAISE,
  DEF_NO_TRUMP_RESPONSE,
  DEF_DRURY_RESPONSE,
} from './types';

import { RESPONSE_ONE_LEVEL_SUIT_OPEN_RANGES } from './ranges';

export const createLabels = function createLabels(bidSystemConfig) {
  const Ranges = bidSystemConfig[ RESPONSE_ONE_LEVEL_SUIT_OPEN_RANGES ];

  const ONE_LEVEL_LABEL = 'One Level';
  const TWO_LEVEL_LABEL = 'Two Level';
  const THREE_LEVEL_LABEL = 'Three Level';
  const SIMPLE_RAISE_LABEL = 'Simple Raise';
  const LIMIT_RAISE_LABEL = 'Limit Raise';

  const MAJOR_SUIT_FIT_LABEL = 'Major Suit Fit';
  const DEF_MAJOR_SUIT_FIT_LABEL = '3+ cards in suit - add distrution points to point total - raise level to inform partner of your hands strength';
  const MAJOR_SUIT_SIMPLE_RAISE_LABEL = SIMPLE_RAISE_LABEL;
  const MAJOR_SUIT_LIMIT_RAISE_LABEL  = LIMIT_RAISE_LABEL;
  const MAJOR_SUIT_WEAK_GAME_RAISE_LABEL = 'Preempt Game Raise';
  const DEF_MAJOR_SUIT_SIMPLE_RAISE_LABEL = `${createTotalPointsLabel(Ranges[ MAJOR_SUIT_SIMPLE_RAISE ])}`;
  const DEF_MAJOR_SUIT_LIMIT_RAISE_LABEL  = `${createTotalPointsLabel(Ranges[ MAJOR_SUIT_LIMIT_RAISE ])} - 4+ trumps`;
  const DEF_MAJOR_SUIT_WEAK_GAME_RAISE_LABEL = `${createTotalPointsLabel(Ranges[ MAJOR_SUIT_WEAK_GAME_RAISE ])} - 5+ trumps`;

  const NEW_SUIT_RESPONSE_LABEL = 'New Suit';
  const DEF_NEW_SUIT_RESPONSE_LABEL = '4+ cards in unbid suit';
  const NEW_SUIT_ONE_LEVEL_LABEL = ONE_LEVEL_LABEL;
  const DEF_NEW_SUIT_ONE_LEVEL_LABEL = `${createRangeLabel(Ranges[ NEW_SUIT_ONE_LEVEL ])}`;
  const NEW_SUIT_TWO_LEVEL_LABEL = TWO_LEVEL_LABEL;
  const DEF_NEW_SUIT_TWO_LEVEL_LABEL = `${createRangeLabel(Ranges[ NEW_SUIT_TWO_LEVEL ])}`;
  const NEW_SUIT_JUMP_SHIFT_LABEL = 'Jump Shift';
  const DEF_NEW_SUIT_JUMP_SHIFT_LABEL = `Jump a level in your strain - ${createRangeLabel(Ranges[ NEW_SUIT_JUMP_SHIFT ])} - 5+ cards in suit`;

  const MINOR_SUIT_RAISE_LABEL = 'Minor Suit Fit';
  const DEF_MINOR_SUIT_RAISE_LABEL = '4+ cards';
  const MINOR_SUIT_SIMPLE_RAISE_LABEL = SIMPLE_RAISE_LABEL;
  const MINOR_SUIT_LIMIT_RAISE_LABEL = LIMIT_RAISE_LABEL;
  const DEF_MINOR_SUIT_SIMPLE_RAISE_LABEL = `${createTotalPointsLabel(Ranges[ MAJOR_SUIT_SIMPLE_RAISE ])}`;
  const DEF_MINOR_SUIT_LIMIT_RAISE_LABEL = `${createTotalPointsLabel(Ranges[ MAJOR_SUIT_LIMIT_RAISE ])} - 5+ trumps`;;

  const NO_TRUMP_RESPONSE_LABEL = 'No Trump.';
  const DEF_NO_TRUMP_RESPONSE_LABEL = 'Balanced Hand.';
  const ONE_LEVEL_NO_TRUMP_LABEL = ONE_LEVEL_LABEL;
  const TWO_LEVEL_NO_TRUMP_LABEL = TWO_LEVEL_LABEL;
  const THREE_LEVEL_NO_TRUMP_LABEL = THREE_LEVEL_LABEL;
  const DEF_ONE_LEVEL_NO_TRUMP_LABEL = `${createRangeLabel(Ranges[ ONE_LEVEL_NO_TRUMP ])}`;
  const DEF_TWO_LEVEL_NO_TRUMP_LABEL  =  `${createRangeLabel(Ranges[ TWO_LEVEL_NO_TRUMP ])}`;
  const DEF_THREE_LEVEL_NO_TRUMP_LABEL  = `${createRangeLabel(Ranges[ THREE_LEVEL_NO_TRUMP ])}`;

  const DRURY_RESPONSE_LABEL = 'Drury';
  const DEF_DRURY_RESPONSE_LABEL = 'Passed hand - partner opened 1H or 1S - 10 to 12 total points - 3+ card fit';

  return {
    [ MAJOR_SUIT_FIT ]: MAJOR_SUIT_FIT_LABEL,
    [ MAJOR_SUIT_SIMPLE_RAISE ]: MAJOR_SUIT_SIMPLE_RAISE_LABEL,
    [ MAJOR_SUIT_LIMIT_RAISE ]: MAJOR_SUIT_LIMIT_RAISE_LABEL,
    [ MAJOR_SUIT_WEAK_GAME_RAISE ]: MAJOR_SUIT_WEAK_GAME_RAISE_LABEL,
    [ NEW_SUIT_RESPONSE ]: NEW_SUIT_RESPONSE_LABEL,
    [ NEW_SUIT_ONE_LEVEL ]: NEW_SUIT_ONE_LEVEL_LABEL,
    [ NEW_SUIT_TWO_LEVEL ]: NEW_SUIT_TWO_LEVEL_LABEL,
    [ NEW_SUIT_JUMP_SHIFT ]: NEW_SUIT_JUMP_SHIFT_LABEL,
    [ MINOR_SUIT_RAISE ]: MINOR_SUIT_RAISE_LABEL,
    [ MINOR_SUIT_SIMPLE_RAISE ]: MINOR_SUIT_SIMPLE_RAISE_LABEL,
    [ MINOR_SUIT_LIMIT_RAISE ]: MINOR_SUIT_LIMIT_RAISE_LABEL,
    [ NO_TRUMP_RESPONSE ]: NO_TRUMP_RESPONSE_LABEL,
    [ ONE_LEVEL_NO_TRUMP ]: ONE_LEVEL_NO_TRUMP_LABEL,
    [ TWO_LEVEL_NO_TRUMP ]: TWO_LEVEL_NO_TRUMP_LABEL,
    [ THREE_LEVEL_NO_TRUMP ]: THREE_LEVEL_NO_TRUMP_LABEL,

    [ DRURY_RESPONSE ]: DRURY_RESPONSE_LABEL,
    [ DEF_MAJOR_SUIT_FIT ]: DEF_MAJOR_SUIT_FIT_LABEL,
    [ DEF_MAJOR_SUIT_SIMPLE_RAISE ]: DEF_MAJOR_SUIT_SIMPLE_RAISE_LABEL,
    [ DEF_MAJOR_SUIT_LIMIT_RAISE ]: DEF_MAJOR_SUIT_LIMIT_RAISE_LABEL,
    [ DEF_MAJOR_SUIT_WEAK_GAME_RAISE ]: DEF_MAJOR_SUIT_WEAK_GAME_RAISE_LABEL,
    [ DEF_NEW_SUIT_RESPONSE ]: DEF_NEW_SUIT_RESPONSE_LABEL,
    [ DEF_NEW_SUIT_ONE_LEVEL ]: DEF_NEW_SUIT_ONE_LEVEL_LABEL,
    [ DEF_NEW_SUIT_TWO_LEVEL ]: DEF_NEW_SUIT_TWO_LEVEL_LABEL,
    [ DEF_NEW_SUIT_JUMP_SHIFT ]: DEF_NEW_SUIT_JUMP_SHIFT_LABEL,
    [ DEF_MINOR_SUIT_RAISE ]: DEF_MINOR_SUIT_RAISE_LABEL,
    [ DEF_MINOR_SUIT_SIMPLE_RAISE ]: DEF_MINOR_SUIT_SIMPLE_RAISE_LABEL,
    [ DEF_MINOR_SUIT_LIMIT_RAISE ]: DEF_MINOR_SUIT_LIMIT_RAISE_LABEL,
    [ DEF_NO_TRUMP_RESPONSE ]: DEF_NO_TRUMP_RESPONSE_LABEL,
    [ DEF_ONE_LEVEL_NO_TRUMP ]: DEF_ONE_LEVEL_NO_TRUMP_LABEL,
    [ DEF_TWO_LEVEL_NO_TRUMP ]: DEF_TWO_LEVEL_NO_TRUMP_LABEL,
    [ DEF_THREE_LEVEL_NO_TRUMP ]: DEF_THREE_LEVEL_NO_TRUMP_LABEL,
    [ DEF_DRURY_RESPONSE ]: DEF_DRURY_RESPONSE_LABEL,
  };
};
