import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {isNil, F, T } from 'ramda';

import { constants as uic } from '../../constants';

import { DialogTitleBar, TabsSection } from '../components';
import Pagination from '../components/Pagination';

import { useFocus,  useKeyPress } from '../hooks';

/**
* @function FolderContentItem
*/
const FolderContentItem = function FolderContentItem({ deal, onClick, isFocus }) {
  const inputRef = useFocus(isFocus);
  const classList = `folder-content__button${deal.isSelected ? ' active': ''}`;

  return (
    <button
      ref={inputRef}
      id={deal.id}
      className={classList}
      onClick={onClick}
      tabIndex={isFocus ? '0': '-1'}
    >
      {deal.label}
    </button>
  );
};

/**
* displays the openFolder content
*/
const OpenFolderContent = function OpenFolderContent({
  folderItems,
  isShowPaging,
  onSelectFolderItem
}) {
  // FIXME: calc
  const pageSize = 14;

  const [ pageContent, setPageContent ] = useState([]);
  const [ focusItemId, setFocusItemId ]  = useState();

  const refs = pageContent
    && pageContent.reduce((acc, deal) => {
      acc[ deal.id ] = React.createRef();
        return acc;
      }, {});

  const initialPage = (index => {
    return index >  0
    ? Math.ceil(index / pageSize)
    : 1
  })(folderItems.findIndex(item => item.isSelected) + 1);

  const getHasPageContent = () => pageContent.length > 0;

  const onChangePage = pageContent => {
    setTimeout(() => {
      setPageContent(pageContent);
      //const index = getFocusItemIndex();
      //setFocusItemId(pageContent[ index < 0 ? 0 : index ].id);
    }, 500);
  };

  const getIsFocusItem = id => folderItems.find(item => item.id === id && item.isSelected)
    || id === pageContent[ 0 ].id;

  const getFocusItemId = () => pageContent.find(item => getIsFocusItem(item.id))?.id;
  const getFocusItemIndex = () => pageContent.findIndex(item => item.id === focusItemId);

  const nav = dir => {
    // if in tabs list arrow keys nav tabs
    if (refs[ getFocusItemId() ]?.current.parentNode === document.activeElement.parentNode.parentNode) {
      const index = ((check, max) => check > max
        ? 0
        : check < 0
          ? max
          : check
      )(getFocusItemIndex() + dir, pageContent.length -1);

      refs[ pageContent[ index ].id ].current.firstChild.focus();
      setFocusItemId(pageContent[ index ].id);
    }
  };

  useKeyPress('ArrowUp', nav, [ -1 ]);
  useKeyPress('ArrowLeft', nav, [ -1 ]);
  useKeyPress('ArrowDown', nav, [ 1 ]);
  useKeyPress('ArrowRight', nav, [ 1 ]);

  return (
    <div id="deals-menu__folder-content" tabIndex="0">
      {isShowPaging &&
        <Pagination
          initialPage={initialPage}
          items={folderItems}
          onChangePage={onChangePage}
          pageSize={pageSize}
        />
      }

      {getHasPageContent() &&
        <ul>
        {pageContent.map(deal =>
          <li
            key={deal.id}
            ref={refs[ deal.id ]}
          >
          <FolderContentItem
            key={deal.timeStamp}
            deal={deal}
            onClick={onSelectFolderItem}
            isFocus={getIsFocusItem(deal.id)}
          />
          </li>)
        }
        </ul>
      }
    </div>
  );
};

const createOpenFolderProps = ({
  openFolder,
  folderItems,
  getisFolderOpen,
}) => ({
  openFolder,
  folderItems,
  getisFolderOpen,
});

const DealSelectorModal =  function DealSelectorModal({
  folderValues,
  onClose,
  onSubmit,
  title,
}) {
  const getOpenFolder = id => {
    return (({ folderNames }) => (folderNames.find(folder => (isNil(id) && folder.isDefaultOpen)
        || folder.id === id
      ) || folderNames[ 0 ]
    ))(folderValues);
  };

  const getOpenFolderItems = folder => folderValues.dealNamesMap.get(folder.id);

  const getIsFullScreenLayout = () => document.body.clientWidth >= 420;

  const [ openFolderPops, setOpenFolderProps ] = useState((openFolder => createOpenFolderProps({
    openFolder,
    folderItems: getOpenFolderItems(openFolder),
    // initial function -
    getisFolderOpen: () => openFolder.isDefaultOpen && getIsFullScreenLayout(),
  }))(getOpenFolder()));

  const getIsShowFolderTabs = () => getIsFullScreenLayout()
    || !openFolderPops.getisFolderOpen();

  const getIsShowFolderContent = () => getIsFullScreenLayout()
    || openFolderPops.getisFolderOpen();

  const getIsShowPaging = getIsShowFolderContent;

  const getIsShowBackIcon = () => !getIsFullScreenLayout()
    && openFolderPops.getisFolderOpen();

  const getIsOpenFolder = folder => openFolderPops.openFolder === folder
    && openFolderPops.getisFolderOpen();

  const setOpenFolder = folderId => {
    const { dealNamesMap } = folderValues;
    const openFolder = getOpenFolder(folderId);
    setOpenFolderProps(createOpenFolderProps({
      openFolder,
      folderItems: dealNamesMap.get(openFolder.id),
      getisFolderOpen: T,
    }));
  };

  const onFolderTabClick = id => setOpenFolder(id);

  const onDealClick = event => {
    event.preventDefault();
    onSubmit(event, {selectedDealId: event.currentTarget.id});
  };

  const onBackToFoldersClick = event => {
    event.preventDefault();
    setOpenFolderProps(createOpenFolderProps({
      ...openFolderPops,
      getisFolderOpen: F,
    }));
  };

  return (
      <div id="deals-menu__container" className="main-component__container">
        <DialogTitleBar
          title={title}
          onBackIconClick={getIsShowBackIcon()
            ? onBackToFoldersClick
            : null
          }
          onClose={onClose}
        />

        <TabsSection
          id="deals-menu__folder-container"
          tabContainerId="deals-menu__folder-tabs"
          tablistAriaLabel={uic.FOLDER_DEALS_LABEL}
          isShowTabs={getIsShowFolderTabs()}
          tabNames={folderValues.folderNames}
          onTabOpen={onFolderTabClick}
          getIsTabOpen={getIsOpenFolder}
        >
          {getIsShowFolderContent()
            && <OpenFolderContent
                 onSelectFolderItem={onDealClick}
                 folderItems={openFolderPops.folderItems}
                 isShowPaging={getIsShowPaging()}
                />
          }
        </TabsSection>
      </div>

  );
};
DealSelectorModal.propTypes = {
  folderValues: PropTypes.object,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  title: PropTypes.string,
};

export default DealSelectorModal;
