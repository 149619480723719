import { DATA_DEFINITION_PREFIX } from '../../constants';

export const RESPOND_WITH_REDOUBLE = 'respond-after-takeout-double/RESPOND_WITH_REDOUBLE';
export const DEF_RESPOND_WITH_REDOUBLE = DATA_DEFINITION_PREFIX+'respond-after-takeout-double/RESPOND_WITH_REDOUBLE';

export const RESPOND_ONE_NO_TRUMP = 'respond-after-takeout-double/RESPOND_ONE_NO_TRUMP';
export const DEF_RESPOND_ONE_NO_TRUMP = DATA_DEFINITION_PREFIX+'respond-after-takeout-double/RESPOND_ONE_NO_TRUMP';

export const SIMPLE_RAISE_TRUMP_SUPPORT = 'respond-after-takeout-double/SIMPLE_RAISE_TRUMP_SUPPORT';
export const DEF_SIMPLE_RAISE_TRUMP_SUPPORT = DATA_DEFINITION_PREFIX+'respond-after-takeout-double/SIMPLE_RAISE_TRUMP_SUPPORT';

export const JUMP_RAISE_TRUMP_SUPPORT = 'respond-after-takeout-double/JUMP_RAISE_TRUMP_SUPPORT';
export const DEF_JUMP_RAISE_TRUMP_SUPPORT = DATA_DEFINITION_PREFIX+'respond-after-takeout-double/JUMP_RAISE_TRUMP_SUPPORT';

export const RESPOND_AT_ONE_LEVEL_NEW_SUIT = 'respond-after-takeout-double/RESPOND_AT_ONE_LEVEL_NEW_SUIT';
export const DEF_RESPOND_AT_ONE_LEVEL_NEW_SUIT = DATA_DEFINITION_PREFIX+'respond-after-takeout-double/RESPOND_AT_ONE_LEVEL_NEW_SUIT';

export const RESPOND_AT_TWO_LEVEL_NEW_SUIT = 'respond-after-takeout-double/RESPOND_AT_TWO_LEVEL_NEW_SUIT';
export const DEF_RESPOND_AT_TWO_LEVEL_NEW_SUIT = DATA_DEFINITION_PREFIX+'respond-after-takeout-double/RESPOND_AT_TWO_LEVEL_NEW_SUIT';
