import { constants as c } from '../../../constants';
import Suit from '../../../suit';
import { getHasFourCardMajor } from '../../../hand';

import BidHelper from '../../bid-helper';
import * as BidTypes from '../../bid-types';
import { getInRange } from '../../point-range';
import * as prom from '../../bid-promises';

import {
  getOpeningBidRanges,
  getIsPlayWeakTwos,
  getOldStrongTwoRange,
  getOpenSuitOneLevelRange,
  getOpenOneNoTrumpRange,
  getOpenTwoNoTrumpRange,
  getOpenThreeNoTrumpRange,
  getTwoLevelStrong,
  getTwoLevelStrongBalancedRange,
  getTwoLevelStrongNotBalancedRange,
  getOpenThreeLevelRange,
  getWeakTwoOpenRange,
} from '../../bid-system-config';

import { PASS_OPTION } from '../pass-option';
import { createBidOptions } from '../create-bid-options';

import {
  OPEN_ONE_NO_TRUMP,
  OPEN_TWO_NO_TRUMP,
  OPEN_THREE_NO_TRUMP,
  OPEN_TWO_LEVEL_STRONG,
  OPEN_TWO_LEVEL_STRONG_BALANCED,
  OPEN_TWO_LEVEL_STRONG_UNBALANCED,
  OPEN_TWO_LEVEL_STRONG_OLD,
  OPEN_MAJOR_SUIT_AT_ONE_LEVEL,
  OPEN_MINOR_SUIT_AT_ONE_LEVEL,
  OPEN_TWO_LEVEL_WEAK_PREEMPT,
  OPEN_THREE_LEVEL_PREEMPT,
  OPEN_FOUR_LEVEL_PREEMPT,
  OPEN_NO_TRUMP,
  OPEN_SUIT_ONE_LEVEL,
  OPEN_PREEMPT,
} from './types';

import {
  OPENING_BID_RANGES,
  createRanges,
} from './ranges';

import { createLabels } from './labels';

import * as R from 'ramda';

const getGroupType = function getGroupType(type) {
  switch (type) {
    case OPEN_ONE_NO_TRUMP:
    case OPEN_TWO_NO_TRUMP:
    case OPEN_THREE_NO_TRUMP:
      return OPEN_NO_TRUMP;
    case OPEN_TWO_LEVEL_STRONG_BALANCED:
    case OPEN_TWO_LEVEL_STRONG_UNBALANCED:
      return OPEN_TWO_LEVEL_STRONG;
    case OPEN_MAJOR_SUIT_AT_ONE_LEVEL:
    case OPEN_MINOR_SUIT_AT_ONE_LEVEL:
      return OPEN_SUIT_ONE_LEVEL;
    case OPEN_TWO_LEVEL_WEAK_PREEMPT:
    case OPEN_THREE_LEVEL_PREEMPT:
    case OPEN_FOUR_LEVEL_PREEMPT:
      return OPEN_PREEMPT;
    default:
      return type;
  }
};

const getOptions = function getOptions(player) {
  const optionTypes = getIsPlayWeakTwos(player)
    ? [
        OPEN_ONE_NO_TRUMP,
        OPEN_TWO_NO_TRUMP,
        OPEN_THREE_NO_TRUMP,
        OPEN_TWO_LEVEL_STRONG_BALANCED,
        OPEN_TWO_LEVEL_STRONG_UNBALANCED,
        OPEN_MAJOR_SUIT_AT_ONE_LEVEL,
        OPEN_MINOR_SUIT_AT_ONE_LEVEL,
        OPEN_TWO_LEVEL_WEAK_PREEMPT,
        OPEN_THREE_LEVEL_PREEMPT,
        OPEN_FOUR_LEVEL_PREEMPT,
        PASS_OPTION,
      ]
    : [
        OPEN_ONE_NO_TRUMP,
        OPEN_TWO_NO_TRUMP,
        OPEN_THREE_NO_TRUMP,
        OPEN_TWO_LEVEL_STRONG_OLD,
        OPEN_MAJOR_SUIT_AT_ONE_LEVEL,
        OPEN_MINOR_SUIT_AT_ONE_LEVEL,
        OPEN_THREE_LEVEL_PREEMPT,
        OPEN_FOUR_LEVEL_PREEMPT,
        PASS_OPTION
      ];

  return createBidOptions(optionTypes, getGroupType);
};

const getHandHasFourCardMajor = function getHandHasFourCardMajor(hand, strain) {
  if (Suit.getIsMinor(strain)) {
    return getHasFourCardMajor(hand);
  }
  return hand.getSuit(BidHelper.getOtherMajor(strain)).getLength() >= c.FOUR_CARD_SUIT;
};

const getIsAllConditions = function getIsAllConditions(rule, hand, suit) {
  return getInRange(hand.getHighCardPoints(), rule.range)
    && (R.has('isMajor', rule) ? suit.getIsMajor() && rule.isMajor : true)
    && (R.has('isMinor', rule) ? suit.getIsMinor() && rule.isMinor : true)
    && (R.has('suitLength', rule) ? suit.getLength() >= rule.suitLength : true)
    && (R.has('getIsSuitGood', rule) ? rule.getIsSuitGood(suit) : true)
    && (R.has('acceptsStrain', rule) ? rule.acceptsStrain(suit.strain) : true);
};

const getCanOpenOneLevelOverPreempt = function getCanOpenOneLevelOverPreempt(hand, suit) {
  return getHasOneLevelOpeningPoints(hand)
    && hand.getOutSideAceSuit(suit.strain)
    && hand.getHasTwoLongSuits();
};

const getHasOneLevelOpeningPoints = function getHasOneLevelOpeningPoints(hand) {
  const hcp = hand.getHighCardPoints();
  const { lower } = getOpenSuitOneLevelRange(hand.player);
  if (hcp >= lower) {
    if (hcp === lower) {
      return (hand.getHighCardPoints() - hand.getBadPoints()) >= lower
    }
    return true;
  }
  return false;
}

const getShouldOneLevelSuitOpen = function getShouldOneLevelSuitOpen(rule, hand, suit) {
  return getHasOneLevelOpeningPoints(hand)
    && getIsAllConditions(rule, hand, suit);
};

const getOldStrongTwoOpenRule = ({ bidSystemConfig }) => [
  {
    bidOption: OPEN_TWO_LEVEL_STRONG,
    type: BidTypes.STRONG_TWO_OF_A_MAJOR,
    level: c.TWO_LEVEL,
    range: getOldStrongTwoRange({ bidSystemConfig }),
    isMajor: true,
    suitLength: c.FIVE_CARD_SUIT,
  },
  {
    bidOption: OPEN_TWO_LEVEL_STRONG,
    type: BidTypes.STRONG_TWO_OF_A_MINOR,
    level: c.TWO_LEVEL,
    range: getOldStrongTwoRange({ bidSystemConfig }),
    suitLength: c.FIVE_CARD_SUIT,
  },
];

const getOneLevelSuitOpenRule = ({ bidSystemConfig }) => [{
    bidOption: OPEN_MAJOR_SUIT_AT_ONE_LEVEL,
    type: BidTypes.MAJOR_SUIT_OPEN,
    level: c.ONE_LEVEL,
    range: getOpenSuitOneLevelRange({ bidSystemConfig }),
    isMajor: true,
    acceptsStrain: strain => Suit.getIsMajor(strain),
    suitLength: c.FIVE_CARD_SUIT,
    getIsMeetsRequirements(rule, hand, suit) {
      return getShouldOneLevelSuitOpen(rule, hand, suit);
    },
  },
  {
    bidOption: OPEN_MINOR_SUIT_AT_ONE_LEVEL,
    type: BidTypes.MINOR_SUIT_OPEN,
    level: c.ONE_LEVEL,
    range: getOpenSuitOneLevelRange({ bidSystemConfig }),
    isMinor: true,
    acceptsStrain: strain => Suit.getIsMinor(strain),
    suitLength: c.THREE_CARD_SUIT,
    getIsMeetsRequirements(rule, hand, suit) {
      return getShouldOneLevelSuitOpen(rule, hand, suit);
    },
  }
];

const getNoTrumpOpenRules = ({ bidSystemConfig }) => [
  {
    bidOption: OPEN_ONE_NO_TRUMP,
    type: BidTypes.OPEN_NO_TRUMP,
    strain: c.NO_TRUMP,
    level: c.ONE_LEVEL,
    range: getOpenOneNoTrumpRange({ bidSystemConfig }),
  },
  {
    bidOption: OPEN_TWO_NO_TRUMP,
    type: BidTypes.OPEN_NO_TRUMP,
    strain: c.NO_TRUMP,
    level: c.TWO_LEVEL,
    range: getOpenTwoNoTrumpRange({ bidSystemConfig }),
  },
  {
    bidOption: OPEN_THREE_NO_TRUMP,
    type: BidTypes.OPEN_NO_TRUMP,
    strain: c.NO_TRUMP,
    level: c.THREE_LEVEL,
    range: getOpenThreeNoTrumpRange({ bidSystemConfig }),
  },
];

const TwoNoTrumpOpenRules = [{
  bidOption: OPEN_TWO_NO_TRUMP,
  level: c.TWO_LEVEL,
  range: {
    lower: 21,
    upper: 40,
  },
}];

const getWeakTwoOpenRules = ({ bidSystemConfig }) => [{
    bidOption: OPEN_TWO_LEVEL_STRONG_BALANCED,
    type: BidTypes.STRONG_TWO_CLUB,
    strain: c.CLUB,
    level: c.TWO_LEVEL,
    range: getTwoLevelStrongBalancedRange({ bidSystemConfig }),
    getIsMeetsRequirements(rule, hand, /*suit*/) {
      return hand.getIsBalanced()
        && getInRange(hand.getHighCardPoints(), getTwoLevelStrongBalancedRange({ bidSystemConfig }));
    },
  },
  {
    bidOption: OPEN_TWO_LEVEL_STRONG_UNBALANCED,
    type: BidTypes.STRONG_TWO_CLUB,
    strain: c.CLUB,
    level: c.TWO_LEVEL,
    range: getTwoLevelStrongNotBalancedRange({ bidSystemConfig }),
    additionalPromises: () => [
      prom.createPromise({
        type: BidTypes.UNBALANCED_HAND,
      }),
      prom.createTrickPromise({
        type: BidTypes.PLAYING_TRICK_COUNT,
        count: 9,
      })
    ],
    getIsMeetsRequirements(rule, hand, suit) {
      return !hand.getIsBalanced()
        && suit.getLength() >= c.FIVE_CARD_SUIT
        && ((getInRange(hand.getHighCardPoints(suit.strain), getTwoLevelStrongNotBalancedRange({ bidSystemConfig }))
               && suit.getIsGood()
               && hand.getPlayingTrickCount(suit.strain) >= 9)
            || getInRange(hand.getHighCardPoints(suit.strain), getTwoLevelStrong({ bidSystemConfig }))
        );
    },
  },
  {
    bidOption: OPEN_TWO_LEVEL_WEAK_PREEMPT,
    type: BidTypes.PREEMPT_WEAK_TWO,
    level: c.TWO_LEVEL,
    range: getWeakTwoOpenRange({ bidSystemConfig }),
    acceptsStrain: strain =>
      [ c.DIAMOND, c.HEART, c.SPADE ].some(s => s === strain),
    suitLength: c.SIX_CARD_SUIT,
    getIsSuitGood: suit => suit.getIsGood(),
    // TODO: look up Rule of 2,3,4
    getIsMeetsRequirements(rule, hand, suit) {
      return getIsAllConditions(rule, hand, suit)
        && !getCanOpenOneLevelOverPreempt(hand, suit)
        // has another 4 card suiter
        && !((suit.getIsMajor() && hand.getSuit(BidHelper.getOtherMajor(suit.strain)).getLength() >= c.FOUR_CARD_SUIT)
          || (suit.getIsMinor() && getHasFourCardMajor(hand)))
        // check if your points are in onother suits
        && (hand.getHighCardPoints() - suit.getHighCardPoints() <= 5);
    },
  }
];

const getThreeLevelPreemptOpenRule = ({ bidSystemConfig }) => [{
  bidOption: OPEN_THREE_LEVEL_PREEMPT,
  type: BidTypes.PREEMPT_THREE_LEVEL,
  level: c.THREE_LEVEL,
  range: getOpenThreeLevelRange({ bidSystemConfig }),
  acceptsStrain: strain =>
    [ c.CLUB, c.DIAMOND, c.HEART, c.SPADE ].some(s => s === strain),
  getIsSuitGood: suit => suit.getHonorCount() >= 2 && suit.getHighCardPoints() >= 4,
  suitLength: c.SEVEN_CARD_SUIT,
  getIsMeetsRequirements(rule, hand, suit) {
    return getIsAllConditions(rule, hand, suit)
      && !hand.getOutSideKingSuit(suit.strain)
      && !hand.getOutSideAceSuit(suit.strain)
      //&& BidHelper.getIsPassedHand(hand.player.getPartner())
      && !getHandHasFourCardMajor(hand, suit.strain);
  },
}];

const getFourLevelPreemptOpenRule = function getFourLevelPreemptOpenRule(ranges) {
  return {
    bidOption: OPEN_FOUR_LEVEL_PREEMPT,
    type: BidTypes.PREEMPT_FOUR_LEVEL,
    level: c.FOUR_LEVEL,
    range: ranges[ OPEN_FOUR_LEVEL_PREEMPT ],
    acceptsStrain: strain =>
      [ c.HEART, c.SPADE ].some(s => s === strain),
    getIsSuitGood: suit => suit.getIsGood(),
    suitLength: c.EIGHT_CARD_SUIT,
    promiseType: BidTypes.SIGN_OFF,
    getIsMeetsRequirements(rule, hand, suit) {
      return getIsAllConditions(rule, hand, suit);
    },
  };
};

const getOpenSuitRules = function getOpenSuitRules({ bidSystemConfig }) {

  const ranges = getOpeningBidRanges({ bidSystemConfig });

  return [getFourLevelPreemptOpenRule(ranges),
    ...getThreeLevelPreemptOpenRule({ bidSystemConfig }),
    ...(getIsPlayWeakTwos({ bidSystemConfig })
      ? getWeakTwoOpenRules({ bidSystemConfig })
      : []
      ),
    ...getOneLevelSuitOpenRule({ bidSystemConfig })
  ];
};

export {
  getOptions,
  OPEN_ONE_NO_TRUMP,
  OPEN_TWO_NO_TRUMP,
  OPEN_THREE_NO_TRUMP,
  OPEN_TWO_LEVEL_STRONG,
  OPEN_TWO_LEVEL_STRONG_BALANCED,
  OPEN_TWO_LEVEL_STRONG_UNBALANCED,
  OPEN_TWO_LEVEL_STRONG_OLD,
  OPEN_MAJOR_SUIT_AT_ONE_LEVEL,
  OPEN_MINOR_SUIT_AT_ONE_LEVEL,
  OPEN_TWO_LEVEL_WEAK_PREEMPT,
  OPEN_THREE_LEVEL_PREEMPT,
  OPEN_FOUR_LEVEL_PREEMPT,
  OPEN_NO_TRUMP,
  OPEN_SUIT_ONE_LEVEL,
  OPEN_PREEMPT,OPENING_BID_RANGES,
  createLabels,
  getOpenSuitRules,
  createRanges,
  getNoTrumpOpenRules,
  getOneLevelSuitOpenRule,
  getOldStrongTwoOpenRule,
  TwoNoTrumpOpenRules,
};
