import { constants as c } from '../../../constants';

import { getInRange } from '../../point-range';

import { createBidOptions } from '../create-bid-options';
import { PASS_OPTION } from '../pass-option';

import {
  BID_NEW_SUIT_ON_LEVEL_ONE,
  BID_ONE_NO_TRUMP_STOPPER_IN_ENEMY_SUIT_MINIMAL,
  DEFEND_MICHAELS_CUEBID_RESPONSE,
  RAISE_PARTNERS_SUIT_TO_TWO_LEVEL_MINIMAL,
  NEGATIVE_DOUBLE,
  NEGATIVE_DOUBLE_THREE_LEVEL,
  TWO_OVER_ONE_RESPONSE,
  BID_TWO_NO_TRUMP_STOPPER_IN_ENEMY_SUIT_INVITATIONAL,
  JUMP_RAISE_OF_PARTNERS_SUIT,
  NEW_SUIT_FORCING,
  BID_THREE_NO_TRUMP_STOPPER_IN_ENEMY_SUIT_FORCING,
  CUEBID_RESPONSE,
  RAISE_TO_MAJOR_GAME,
} from './types';

import {
  RESPONSE_AFTER_OPP_SUIT_OVERCALL_RANGES,
  createRanges,
} from './ranges';

import { createLabels } from './labels';

const getOptions = function getOptions() {
  return createBidOptions([
    BID_NEW_SUIT_ON_LEVEL_ONE,
    BID_ONE_NO_TRUMP_STOPPER_IN_ENEMY_SUIT_MINIMAL,
    DEFEND_MICHAELS_CUEBID_RESPONSE,
    RAISE_PARTNERS_SUIT_TO_TWO_LEVEL_MINIMAL,
    NEGATIVE_DOUBLE,
    TWO_OVER_ONE_RESPONSE,
    BID_TWO_NO_TRUMP_STOPPER_IN_ENEMY_SUIT_INVITATIONAL,
    JUMP_RAISE_OF_PARTNERS_SUIT,
    RAISE_TO_MAJOR_GAME,
    NEW_SUIT_FORCING,
    BID_THREE_NO_TRUMP_STOPPER_IN_ENEMY_SUIT_FORCING,
    CUEBID_RESPONSE,
    PASS_OPTION,
  ]);
};

const getRespondNoTrumpRules = ({ bidSystemConfig }) => {

  const getIsMeetsRequirements = (rule, hand, beatBid) => hand.getSuit(beatBid.strain).getHasStoppers()
    && getInRange(hand.getHighCardPoints(), rule.range);

  const ranges = bidSystemConfig[ RESPONSE_AFTER_OPP_SUIT_OVERCALL_RANGES ];

  return [{
    bidOption: BID_ONE_NO_TRUMP_STOPPER_IN_ENEMY_SUIT_MINIMAL,
    strain: c.NO_TRUMP,
    level: c.ONE_LEVEL,
    range: ranges[ BID_ONE_NO_TRUMP_STOPPER_IN_ENEMY_SUIT_MINIMAL ],
    getIsMeetsRequirements,
  },
  {
    bidOption: BID_TWO_NO_TRUMP_STOPPER_IN_ENEMY_SUIT_INVITATIONAL,
    strain: c.NO_TRUMP,
    level: c.TWO_LEVEL,
    range: ranges[ BID_TWO_NO_TRUMP_STOPPER_IN_ENEMY_SUIT_INVITATIONAL ],
    getIsMeetsRequirements,
  },
  {
    level: c.THREE_LEVEL,
    strain: c.NO_TRUMP,
    range: ranges[ BID_THREE_NO_TRUMP_STOPPER_IN_ENEMY_SUIT_FORCING ],
    selectedBidOption: BID_THREE_NO_TRUMP_STOPPER_IN_ENEMY_SUIT_FORCING,
    getIsMeetsRequirements,
   }];
};

export {
  BID_NEW_SUIT_ON_LEVEL_ONE,
  BID_ONE_NO_TRUMP_STOPPER_IN_ENEMY_SUIT_MINIMAL,
  DEFEND_MICHAELS_CUEBID_RESPONSE,
  RAISE_PARTNERS_SUIT_TO_TWO_LEVEL_MINIMAL,
  NEGATIVE_DOUBLE,
  NEGATIVE_DOUBLE_THREE_LEVEL,
  TWO_OVER_ONE_RESPONSE,
  BID_TWO_NO_TRUMP_STOPPER_IN_ENEMY_SUIT_INVITATIONAL,
  JUMP_RAISE_OF_PARTNERS_SUIT,
  NEW_SUIT_FORCING,
  BID_THREE_NO_TRUMP_STOPPER_IN_ENEMY_SUIT_FORCING,
  CUEBID_RESPONSE,
  RESPONSE_AFTER_OPP_SUIT_OVERCALL_RANGES,
  RAISE_TO_MAJOR_GAME,
  createRanges,
  createLabels,
  getRespondNoTrumpRules,
  getOptions,
};
