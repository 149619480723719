import { getGameOptionsSettings } from '../../../settings';
import { SET_GAME_OPTIONS, SET_DEAL_QUEUE, SET_CURRENT_QUEUE_DEAL_ID } from './types';

const DEAL_QUEUE = 'dealQueue';
const CURRENT_QUEUE_DEAL_ID = 'currentQueueDealId';

//const getGameOptions = () => getAppSettings().gameOptions;

const getDefaultOptions = () => Object.assign(
  {},
  getGameOptionsSettings(),
  { [ CURRENT_QUEUE_DEAL_ID ]: null},
  { [ DEAL_QUEUE ]: [] },
);


const reducer = function gameOptions(state = getDefaultOptions(), action) {
  if (action.type === SET_GAME_OPTIONS) {
    return Object.assign({}, state, action.gameOptions);
  }
  if (action.type === SET_CURRENT_QUEUE_DEAL_ID) {
    return Object.assign(
      {},
      state,
      createCurrentQueueDealIdProps(action.payload)
    );
  }
  if (action.type === SET_DEAL_QUEUE) {
    return Object.assign(
      {},
      state,
      createCurrentQueueDealIdProps(null),
      { [ DEAL_QUEUE ]: action.payload }
    );
  }

  return state;
};

const createCurrentQueueDealIdProps = update => ({ [ CURRENT_QUEUE_DEAL_ID ]: update });

export default reducer;
