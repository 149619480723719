import ModalViewer from './ModalViewer';
import ExceptionModal from './ExceptionModal';

import React from 'react';

class ErrorViewer {

  static displayError(error) {
    debugger;
    const getModalContainerTag = events => <ExceptionModal error={error} onClose={events.onClose} />;
    ModalViewer.showModal({
      getModalContainerTag,
    });
  }
}

export default ErrorViewer;
