import { assoc, pipe } from 'ramda';

import {
  constants as c,
  Coords,
  getTeamFor,
} from '../bridge';

import {
  constants as uic,
  Icons,
  DealOptions,
  GameEvents,
  PresenterEvents,
} from '../constants';

import {
  DisplayOptionActions,
  TOGGLE_POINTS_VISIBLE,
  TOGGLE_HANDS_VISIBLE,
  TOGGLE_BID_HINTS_VISIBLE,
  TOGGLE_AUCTION_VISIBLE,
} from '../state/ducks/display-options';

import {
  selectDealProps,
  selectGuideBid,
  selectHands,
  selectectNextQueueDeal,
  selectPlayingTrick,
} from '../state/selectors';

import { GameStateActions } from '../state/ducks/game-state';
import { GameOptionActions } from '../state/ducks/game-options';

import { createGameModel } from '../game';
import GameView from '../view/game-view';
import UIHelper from '../view/UIHelper';

import { DefaultMenuItems, MobileMenuItems } from './menu-items';
import { DealProgress } from './deal-progress';
import { GamePause } from './game-pause';
import { createActionLookup } from './action-lookup'

const createDealCompleteProps = ({
  dealProgress = DealProgress.queryNextDeal,
  rubberInPlay = false,
  vulnerability = null,
}) => ({
  nextCardToPlay: null,
  dealProgress,
  rubberInPlay,
  vulnerability,
});

/**
* @class GamePresenter
*/
class GamePresenter {
  constructor(
    store,
    view,
    savedDealsService,
    handleRefresh
  ) {
    this.store = store;
    this.view = view;
    this.savedDealsService = savedDealsService;
    this.handleRefresh = handleRefresh;
    this.gameStateChangeListeners = [];

    this.actions = createActionLookup(this);

    this.dealCards = this.dealCards.bind(this);
    this.getDealMenuProps = this.getDealMenuProps.bind(this);
    this.getGameOptions = this.getGameOptions.bind(this);
  }

  /**
  * @method
  */
  onNewSession(players) {
    if (!this.gameModel) {
      this.gameModel = createGameModel({
        presenter: this,
        savedDealsService: this.savedDealsService,
        players,
      });
    }
    this.gameModel.resetDefaultDealState();
  }

  /**
  * @method
  */
  getDealProgress() {
    return this.store.getState().gameState.dealProgress;
  }

  /**
  * @method setGameState
  * takes the gameModel updates and stores/broadcasts to view
  */
  setGameState({ event, payload }) {

    const DispatchActions = {
      [ GameEvents.ON_AUCTION_UPDATE ]: value => this.store.dispatch(GameStateActions.setAuctionUpdate(value)),
      [ GameEvents.ON_DEAL_PROGRESS ]: value => this.store.dispatch(GameStateActions.setDealProgress(value)),
      [ GameEvents.ON_GO_HOME ]: value => {
        this.gameModel.resetDefaultDealState();
        this.store.dispatch(GameStateActions.setDealCompleteProps(createDealCompleteProps({
          dealProgress: DealProgress.gameInit
        })));
        this.broadcastGameStateChange(value);
      },
      [ GameEvents.ON_DEAL_CARDS ]: value => this.store.dispatch(GameStateActions.setCardsDealtProps(value)),
      [ GameEvents.ON_NEW_DEAL_START ]: value => this.store.dispatch(GameStateActions.setCardsDealtProps(value)),
      [ GameEvents.ON_DEAL_COMPLETE ]: value => this.store.dispatch(GameStateActions.setDealCompleteProps(value)),
      [ GameEvents.ON_AUCTION_COMPLETED ]: value => {
        // bump the progress if default is to view
        const dealProgress = this.getDisplayOptions().isDisplayAuctionViewer
          ? DealProgress.auctionView
          : value.dealProgress;

        this.store.dispatch(GameStateActions.setAuctionCompleteProps({
          ...value,
          dealProgress
        }));

        this.saveCurrentDealToHistory();
      },
      [ GameEvents.ON_HAND_PLAYED_OUT ]: value => {
        this.store.dispatch(GameStateActions.setTallyProps(value));
        setTimeout(() => this.broadcastGameStateChange({
          showTallyViewer: {
            onClose: value.gameReturnFunc
          },
        }), GamePause.AUTO_TRICK_VIEW_WAIT);
      },
      [ GameEvents.ON_GAME_MODEL_INIT ]: value => this.store.dispatch(GameStateActions.setGameStateStartProps(value)),
      [ GameEvents.ON_PLAY_CARD ]: value => this.onPlayCard(value),
      [ GameEvents.ON_TRICK_COMPLETE ]: value => {
        this.store.dispatch(GameStateActions.setTrickCompleteProps(value));
        setTimeout(() => this.broadcastGameStateChange({
          showTrickViewer: {
            onClose: value.gameReturnFunc
          },
        }), GamePause.AUTO_TRICK_VIEW_WAIT);
      },
    };

    return DispatchActions[ event ](payload);
  }

  /**
  * @method onPlayCard
  */
  onPlayCard(value) {
    const { cardProps, gameReturnFunc } =  value;

    const checkLeadCardPlayed = () => !this.getDealHasProgressedTo(DealProgress.leadCardPlayed)
      ? DealProgress.leadCardPlayed
      : this.getDealProgress();

    const getIsContractNorthSouth = () => {
      const { declarer } = selectDealProps(this.store.getState()).contract;
      return getTeamFor(declarer) === c.NORTH_SOUTH;
    };

    const playNextCard = cardProps => {
      const { position, card } = cardProps;
      // removes the played card from players playing hand
      const getPlayingHandsUpdate = () => {
        return selectHands(this.store.getState()).map(playingHand => {
          if (playingHand.position === position) {
            playingHand.hand.suits = playingHand.hand.suits.map(suit => {
              if (suit.strain === card.strain) {
                const cards = suit.cards.split(',').filter(cardValue => cardValue !== card.value);
                suit.cards = cards.join(',');
              }
              return suit;
            });
          }
          return playingHand;
        });
      };

      this.store.dispatch(GameStateActions.setOnCardPlayProps({
        playingHands: getPlayingHandsUpdate(),
        playingTrick: [...selectPlayingTrick(this.store.getState()), cardProps],
        nextCardToPlay: null,
        dealProgress: checkLeadCardPlayed(),
      }));

      setTimeout(() => {
        if (!this.getDealHasProgressedTo(DealProgress.dummyCardsDown)) {
          this.setGameState({
            event: GameEvents.ON_DEAL_PROGRESS,
            payload: {
              dealProgress: DealProgress.dummyCardsDown
            }});
        }
        gameReturnFunc();
      }, GamePause.AFTER_CARD_WAIT);

    };

    const hasPlayButton = cardProps.position === Coords.SOUTH
      || (cardProps.position === Coords.NORTH
        && getIsContractNorthSouth());

      // set the new card that will be played at the position
      this.store.dispatch(GameStateActions.setStartCardPlayProps({
        playNextCard,
        cardProps,
        dealProgress: checkLeadCardPlayed(),
        hasPlayButton,
      }));

      if (!hasPlayButton) {
        setTimeout(() => {
          // no play button === let the computer auto play the card
          playNextCard(cardProps);
        }, GamePause.BEFORE_CARD_WAIT);
      }
  }

  /**
  * @return {Object} props - game properties
  */
  getDefaultProperties() {
    return {
      isInteractiveAuctionRun: this.getIsInterActiveAuction(),
      iconProps: {
        getIsActionToggled: this.getIsActionToggled.bind(this),
        getIsIconActive: this.getIsIconActive.bind(this),
        getIcons: this.getIcons,
        onIconClick: this.actionLookup,
      },
      actionLookup: this.actionLookup,
      onBridgeGameViewLoaded: this.onBridgeGameViewLoaded.bind(this),
      setOnBridgeGameUpdate: this.setOnBridgeGameUpdate.bind(this),
    };
  }

  /**
  * @method
  */
  onBridgeGameViewLoaded = async () => {
    if (!this.gameModel) {
      // Browser Refresh - skips lazy init - make sure init
      await this.handleRefresh();
    }
    if (DealProgress.getIsGameInit(this.getDealProgress())) {
      this.setGameState({
        event: GameEvents.ON_DEAL_PROGRESS,
        payload: {
          dealProgress: DealProgress.newDealStart,
        }
      });
      this.procedeToNextDeal();
    }
  }

  /**
  * @method
  */
  getIsDisplayIcons() {
    return window.matchMedia("(min-width: 992px)").matches;
  }

  /**
  * @method
  */
  getMenuItems() {
    return this.getIsDisplayIcons()
      ? process.env.NODE_ENV === 'development'
        ? [ ...DefaultMenuItems, {id: PresenterEvents.RUN_TESTS, label: uic.TEST_LABEL, title: uic.TEST_LABEL} ]
        : DefaultMenuItems
      : MobileMenuItems;
  }

  /**
  * @method getDropDown
  */
  getDropDown = (icon, getIconButton, isTop) => {
    return this.view.getDropDown({
      icon,
      getIconButton,
      menuClickAction: this.actionLookup,
      menuItems: this.getMenuItems(),
      isTop,
    });
  }

  /**
  * @method getIcons
  */
  getIcons = (isTop = true) => {
    const getBurgerIcon = () => assoc(
      'dropDown',
      {
        getDropDown: this.getDropDown,
        closeIcon: Icons.CLOSE,
      },
      Icons.BURGER
    );

    const getDealIcon = () => this.getIsRandomDeal()
      ? Icons.DEAL
      : Icons.DEAL_DRAWER;

    const getUpDownIcon = () => UIHelper.getIsPortrait()
      ? [ isTop ? Icons.DOWN_ARROW : Icons.UP_ARROW ]
      : [];

    // small mobile screen icon list
    if (!this.getIsDisplayIcons()) {
      return [
        ...getUpDownIcon(),
        getDealIcon(),
        getBurgerIcon()
      ];
    }

    // build large screen icon list
    const toggleActions = [
      Icons.SHOW_CARDS,
      Icons.SHOW_POINTS,
      Icons.SHOW_AUCTION,
    ];

    const PlayIcons = [
      ...toggleActions,
      Icons.TRICK_VIEW,
      ...(this.getIsRubberMatch()
        ? [ Icons.TALLY_VIEW ]
        : []),
    ];

    const SavedDealIcons = [
      Icons.DEAL_DRAWER,
      Icons.SAVE,
      ...(this.getIsSavedDeals()
        ? [ Icons.DELETE ]
        : [ Icons.DELETE ]),
    ];

    const bidPracticeIcons = [
      ...(this.getIsRandomDeal()
        ? [ Icons.DEAL ]
        : SavedDealIcons),
      ...toggleActions,
    ];

    return [
      ...(this.getIsBidPractice()
        ? bidPracticeIcons
        : PlayIcons
      ),
      Icons.SHOW_HINT,
      getBurgerIcon(),
    ];
  }

  /**
  * @method
  */
  getNextDealerPosition() {
    this.store.dispatch(GameStateActions.setNextDealer());
    return this.store.getState().gameState.nextDealerPosition;
  }

  /**
  * @method
  */
  setDealQueue(dealQueue) {
    this.store.dispatch(GameOptionActions.setDealQueue(dealQueue));
  }

  /**
  * @method
  */
  getDealHasProgressedTo(dealProgress) {
    return DealProgress.getIsGTE(this.getDealProgress(), dealProgress);
  }

  /**
  * @method
  */
  getGameOptions(dealType) {
    return !dealType
      ? this.store.getState().gameOptions
      : {
        ...this.store.getState().gameOptions,
        dealType,
      };
  }

  /**
  * @method setGoHome
  */
  setGoHome() {
    this.setGameState({
      event: GameEvents.ON_GO_HOME,
      payload: {
        isGotoHome: true,
      },
    });
  }

  /**
  * @method procedeToNextDeal
  */
  procedeToNextDeal(dealProps = {
    dealType: this.getDealType()
  }) {
    if (this.getIsPredeterminedDeal()
      && dealProps.dealType !== DealOptions.REDEAL
    ) {
      this.openDealsMenu(dealProps);

    } else {

      const getNewDealProps = () => dealProps.dealType === DealOptions.REDEAL
        ? dealProps
        : {
          ...dealProps,
          name: c.RANDOM_DEAL,
        };

      return pipe(
        this.gameModel.resetDefaultDealState,
        getNewDealProps,
        this.dealCards,
      )();
    }
  }

  /**
  * @method dealCards
  */
  dealCards(dealProps) {

    /**
    * @function completeDeal
    */
    const completeDeal = (dealProps, waitTime = 0) => {

      setTimeout(() => {

        const rubberMatch = this.gameModel.getRubberMatch();
        const rubberInPlay = this.getIsRubberMatch() && !rubberMatch.winner;

        if (rubberMatch.winner
          || !this.getIsRubberMatch()
        ) {
          this.gameModel.resetRubberScoreKeeper();
        }

        this.setGameState({
          event: GameEvents.ON_DEAL_COMPLETE,
          payload: createDealCompleteProps({
            rubberInPlay,
            vulnerability: this.gameModel.getVulnerability(),
          })
        });

        if (!this.getIsBidPractice()) {
          this.broadcastGameStateChange({
            nextDealQuery: {
                rubberMatch,
                gameReturnFunc: this.procedeToNextDeal.bind(this),
              },
            });
        }

        if (this.getIsBidPractice()) {
          this.procedeToNextDeal(dealProps);
        }

      }, waitTime);
    };

    /**
    * @function runDeal
    */
    const runDeal = async dealProps => {

      const cardDealPromise = new Promise(resolve => {
        const cardDealer = this.gameModel.getCardDealer(dealProps);
        cardDealer(this.getIsBidPractice(), resolve);
      });

      await cardDealPromise;

      completeDeal({
          ...dealProps,
          ...{dealType: this.getDealType()}
        },
        GamePause.HAND_COMPLETE_WAIT
      );
    };

    // the user forces to  next deal
    // save - new props
    // delete - clear
    // replay - old props
    // history - dealt
    this.handleForceNewDeal = (deal => completeDeal(deal));

    runDeal(dealProps);
  }

  //this.savedDealsService.deleteSavedDeal("XXXX", this.displayError);
  //this.savedDealsService.deleteFolderDeals('aaaaaaa', this.displayError);
  /**
  * @method
  */
  getDealMenuProps({ dealType }) {
    const {
      folderName: openFolderName,
      name: selectedDealName,
    } = DealProgress.getIsGameInit(this.getDealProgress())
      ? {folderName: '', name: ''}
      : this.gameModel.getCurrentDealProps();

    const stringSort = (a, b) => a[ 'label' ].localeCompare(b[ 'label' ]);

    const dateSort = (a, b) =>
      new Date(a[ 'label' ]) > new Date(b[ 'label' ])
        ? -1
        : 1;

    const createMenuProps = ({ data, title, sortMethod = stringSort }) =>
      ({ data, title, openFolderName, selectedDealName, sortMethod });

    switch (dealType) {
      case DealOptions.DEAL_PRELOADED_HANDS:
        return createMenuProps({
          data: this.gameModel.getPreLoadedDeals(),
          title: uic.CANNED_DEALS_LABEL,
        });
      case DealOptions.DEAL_HISTORY_HANDS:
        return createMenuProps({
          data: this.savedDealsService.selectDealHistory(),
          title: uic.HISTORY_LABEL,
          sortMethod: dateSort,
        });
      default:
        return createMenuProps({
          data: this.savedDealsService.selectFolderDeals(),
          title: uic.SAVED_DEALS_LABEL,
        });
    }
  }

  /**
  * @method
  */
  showTrickViewer() {
    if (this.getDealHasProgressedTo(DealProgress.trickPlayed)) {
      this.broadcastGameStateChange({
        showTrickViewer: true,
      });
    }
  }

  /**
  * @method
  */
  showTallyViewer() {
    if (this.getIsRubberMatch()
      && this.store.getState().gameState.rubberInPlay
    ) {
      this.broadcastGameStateChange({
        showTallyViewer: true,
      });
    }
  }

  /**
  * @method showBidOptionsViewer
  */
  showBidOptionsViewer() {
    const guideBid = selectGuideBid(this.store.getState());
    this.view.showBidOptionsModal(guideBid);
  }

  /**
  * @method openDealsMenu opens the select deals menu
  * @return id the a deal Id in the menuData
  */
  openDealsMenu({ dealType }) {

    const menuProps = pipe(
      this.getGameOptions,
      this.getDealMenuProps
    )(dealType);

    if (menuProps.data.length === 0) {
      // No Deals!
      this.showInfoSlides({
        title: uic.INFO_LABEL,
        content: [ uic.NO_SAVED_DEALS_LABEL ],
      });

      return;
    }

    /**
    * @function onSelectDeal
    */
    const onSelectDeal = (({ selectedDealId }) => {
      const selectedDeal = menuProps.data.find(deal => deal.id === selectedDealId);
      //TODO: build a find in deal selector/search
      //const selectedDeal = this.savedDealsService.selectSavedDealByName("east should rebid 1nt");
      this.dealCards(selectedDeal);
    });

    this.view.showDealsMenu(menuProps, onSelectDeal);
  }

  /**
  * @method playCards
  */
  playCards() {
    this.gameModel.playCards();
  }

  /**
  * @method
  */
  displayError(error, dealName) {
    GameView.displayError(error, dealName);
  }

  /**
  * @method
  */
  exportDeal() {
    try {
      this.view.showGameExport(this.gameModel.getExternalExportJSON());
    } catch (error) {
      this.displayError(error);
    }
  }

  /**
  * @method
  */
  importDeal() {
    const onImportDeal = importData => {
      try {
         //const importDeal = JSON.parse(importData);
         // FIXME: redo!!!!!!!!!!!!!
         //this.savedDealsService.insertDeal(importDeal);
       } catch (error) {
         this.displayError(error);
       }
    };
    this.view.showGameImport(onImportDeal);
  }

  /**
  * @method
  */
  showInfoSlides(props) {
    this.view.showInfoSlides(props);
  }

  /**
  * @method
  */
  runTest() {
    this.gameModel.runTest();
  }

  /**
  * @method
  */
  saveCurrentDealToHistory() {
    if (!DealProgress.getIsAuctionComplete(this.getDealProgress())) {
      return;
    }

    const insertDealHistory = deal =>
      this.savedDealsService.insertDealHistory(deal, this.displayError);

    const updatePlayProps = deal =>
      this.savedDealsService.incrementPlayProps(deal);

    return pipe(
      this.gameModel.getCurrentDealAsExternalExport,
      updatePlayProps,
      insertDealHistory
    )();
  }

  /**
  * @method dealNextInQueue
  */
  dealNextInQueue() {
    const deal = selectectNextQueueDeal(this.store.getState());
    this.store.dispatch(GameOptionActions.setCurrentQueueDealId(deal.id));
    this.dealCards(deal);
  }

  /**
  * @method replayCurrentDeal
  */
  replayCurrentDeal() {
    if (this.handleForceNewDeal) {
      this.handleForceNewDeal({
        ...this.gameModel.getCurrentDealAsExternalExport(),
        dealType: DealOptions.REDEAL,
      });
      return;
    }
    // No Deal!
    this.showInfoSlides({
      title: uic.INFO_LABEL,
      content: ['No Deal!'],
    });

  }

  /**
  * @method
  */
  saveCurrentDeal() {
    // onForm submit receive the formData
    const onSubmitProperties = formData => {
      try {
        const deal = this.gameModel.getDealAsExternalExport({
          id: formData.id,
          name: formData.dealName,
          folderName: formData.folderName,
        });

        let newDeal = (deal => {
          if (this.getIsSavedDeals()) {
            // update the prev saved deal
            return this.savedDealsService.updateFolderDeal(deal.folderName, deal);
          } else {
            // otherwise deals are random - insert to Folder Deals
            return this.savedDealsService.insertFolderDeal(deal.folderName, deal);
          }}
        ) (deal);

        this.handleForceNewDeal(Object.assign({}, newDeal, {dealType: DealOptions.REDEAL}));

      } catch (error) {
        this.displayError(error);
      }
    };

    const { id, name, folderName } = this.gameModel.getCurrentDealProps();

    this.view.showSaveDealForm({
      id,
      name,
      folderName,
      onSubmit: onSubmitProperties,
    });
  }

  /**
  * @method
  */
  deleteCurrentDeal() {
    this.gameModel.deleteCurrentDeal();
    if (this.handleForceNewDeal) {
      this.handleForceNewDeal();
    }
 }

 /**
 * @method
 */
  handleDealAction(dealType) {
    const getIsAllowDeal = progress => (this.getIsInterActiveAuction()
        &&  DealProgress.getIsAuctionInProgress(progress))
      || (DealProgress.getIsAuctionComplete(progress)
        || DealProgress.getIsNewDealStart(progress));

    if (getIsAllowDeal(this.getDealProgress())) {
      this.handleForceNewDeal
        ? this.handleForceNewDeal({dealType})
        : this.procedeToNextDeal({dealType});
    }
  }

  /**
  * @method actionLookup
  * @param actionId
  */
  actionLookup = actionId => {
    this.actions[ actionId ]();
    return false;
  }

  broadcastGameStateChange(dealProps) {
    this.gameStateChangeListeners.forEach(fn => fn(dealProps));
  }

  setOnBridgeGameUpdate(onBridgeGameUpdateListener, cancel) {
    if (cancel) {
      this.gameStateChangeListeners = this.gameStateChangeListeners.filter((fn) => {
        return onBridgeGameUpdateListener !== fn;
      });
    } else {
      this.gameStateChangeListeners = [...this.gameStateChangeListeners, onBridgeGameUpdateListener];
    }
  }

  getIsInterActiveAuction() {
    return this.getGameOptions().bidType === uic.BID_OPTIONS.interactiveBid;
  }

  getIsRandomDeal() {
    return this.getDealType() === DealOptions.DEAL_RANDOM_HANDS;
  }

  getIsSavedDeals() {
    return this.getDealType() === DealOptions.DEAL_SAVED_HANDS;
  }

  getIsRubberMatch() {
    return this.getPlayType() === uic.PLAY_OPTIONS.playRubber;
  }

  getPlayType() {
    return this.getGameOptions().playType;
  }

  getDealType() {
    return this.getGameOptions().dealType;
  }

  getIsBidPractice() {
    return uic.getIsPlayTypeBidPractice(this.getPlayType());
  }

  getIsPredeterminedDeal() {
    return !this.getIsRandomDeal();
  }

  toggleCardsVisible() {
    this.toggleDisplayOption(TOGGLE_HANDS_VISIBLE);
  }

  toggleDisplayOption(action) {
    return this.store.dispatch(DisplayOptionActions.toggleDisplay(action));
  }

  togglePoints() {
    this.toggleDisplayOption(TOGGLE_POINTS_VISIBLE);
  }

  toggleAuctionViewer() {
    // progress to aution view
    if (!this.getDisplayOptions().isDisplayAuctionViewer
      && this.getDealProgress() === DealProgress.auctionCompleted
    ) {
      this.store.dispatch(GameStateActions.setViewAuction());
    }
    // regress
    if (this.getDisplayOptions().isDisplayAuctionViewer
      && this.getDealProgress() === DealProgress.auctionView
    ) {
      this.store.dispatch(GameStateActions.setDealProgress({dealProgress: DealProgress.auctionCompleted}))
    }

    this.toggleDisplayOption(TOGGLE_AUCTION_VISIBLE);
  }

  /**
  *
  */
  showHint() {
    if (DealProgress.getIsCardsDealt(this.getDealProgress())) {
      this.view.showHintModal('Maybe Deal Cards?');
    } //else if (DealProgress.getIsAuctionInProgress(this.getDealProgress())) {
      this.toggleDisplayOption(TOGGLE_BID_HINTS_VISIBLE);
    //}
  }

  /**
  *
  */
  getIsIconActive(iconId) {
    return (iconId === PresenterEvents.DEAL_ACTION || iconId === PresenterEvents.SHOW_HINT_ACTION)
      || this.getDealHasProgressedTo(DealProgress.cardsDealt);
  }

  /**
  *
  */
  getDisplayOptions() {
    return this.store.getState().displayOptions;
  }

  /**
  *
  */
  getIsActionToggled(actionId) {
    const actions = {
      [ PresenterEvents.SHOW_AUCTION_ACTION ]: this.getDisplayOptions().isDisplayAuctionViewer,
      [ PresenterEvents.SHOW_CARDS_ACTION ]: this.getDisplayOptions().isDisplayHands,
      [ PresenterEvents.SHOW_HINT_ACTION ]: this.getDisplayOptions().isDisplayBidHints,
      [ PresenterEvents.SHOW_POINTS_ACTION ]: this.getDisplayOptions().isDisplayHcp,
    };
    return actions[ actionId ];
  }
}

export { GamePresenter };
