import React from "react";
import PropTypes from 'prop-types';

import { has } from 'ramda';
import { constants as uic } from '../../constants';

import { IconButton } from './IconButton';

class IconContainer extends React.Component {

  componentDidMount() {
    this.props.setOnStateChangeListener(this.onParentStateChangeHandler);
  }

  componentWillUnmount() {
    this.props.setOnStateChangeListener(this.onParentStateChangeHandler, true);
  }

  onParentStateChangeHandler = changeData => {
    if (has(uic.STATUS_CHANGE, changeData)) {
      this.setState({ state: this.state });
    }
  }

  onIconClick = e => {
    e.preventDefault();
    return this.props.onIconClick(e.currentTarget.id);
  }

  getIsActionToggled = actionId => {
    return this.props.getIsActionToggled(actionId);
  }

  getIsIconActive = iconId => {
    return this.props.getIsIconActive(iconId);
  }

  getIconButton(icon, onClick) {
    return (
      <IconButton
        key = {icon.id}
        src = {icon.imgSrc}
        rotate = {icon.rotate}
        id = {icon.id}
        title = {icon.label}
        getIsActive ={this.getIsIconActive}
        getIsToggled = {this.getIsActionToggled}
        onClick = {onClick} />
    );
  }

  getIcons() {
    return this.props.icons.map(icon => {
      if (icon.dropDown) {
        return icon.dropDown.getDropDown(icon, this.getIconButton, this.props.isTop);
      }
      return this.getIconButton(icon, this.onIconClick);
    });
  }

  render() {
    return (
      <div id="nav-bar__icon-container">
         {this.getIcons()}
      </div>
    );
  }
}

IconContainer.propTypes = {
  icons: PropTypes.array,
  isTop: PropTypes.bool,
  onIconClick: PropTypes.func,
  getIsActionToggled: PropTypes.func,
  getIsIconActive: PropTypes.func,
  setOnStateChangeListener: PropTypes.func,
};
IconContainer.defaultProps = {
  getIsIconActive: () => true,
  getIsActionToggled: () => false,
  isTop: true,
};

export { IconContainer };
