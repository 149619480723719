import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';

import { BridgeBlvdTitle, FallingSuits } from '../components';

const FourZeroFour = function FourZeroFour({ location }) {

  const [ isGotoHome, setIsGotoHome] = useState(false);

  const onHomeClick = e => {
    e.preventDefault();
    setIsGotoHome(true);
  };

  if (isGotoHome) {
    return <Redirect to='/' />;
  }
  return (
    <FallingSuits >
    <div className="landing-page-layout">
      <div className="four-zero-four">
        <h1>
          <BridgeBlvdTitle/>
        </h1>
        <h2>ummmm? <span>{location.pathname}</span></h2>
        <div className="button-center_div">
          <button
            autoFocus
            onClick={onHomeClick}
            className="bridge-button button-center"
            >Home<span>&rarr;</span></button>
        </div>
      </div>

    </div>
    </FallingSuits >
  );
}


export default FourZeroFour;
