import { constants as c } from '../../../constants';
import { CHEAP, JUMP, GAME } from '../constants';

import { createRangeLabel } from '../create-range-label';

import {
  TAKE_OUT_DOUBLE_RESPONSE_RANGES,
  FREE_BID_RANGE,
  SUIT_RESPONSE_RANGE,
  NO_TRUMP_RESPONSE_RANGE,
} from './ranges';

import {
  MINIMAL_FORCE_BID,
  FREE_BID,
  INVITATIONAL_STRENGTH,
  MINIMAL_NO_TRUMP,
  INVITATIONAL_NO_TRUMP,
  GAME_FORCE_CUEBID,
  GAME_FORCE_SUIT,
  GAME_FORCE_NO_TRUMP,
  DEF_MINIMAL_FORCE_BID,
  DEF_FREE_BID,
  DEF_INVITATIONAL_STRENGTH,
  DEF_MINIMAL_NO_TRUMP,
  DEF_INVITATIONAL_NO_TRUMP,
  DEF_GAME_FORCE_CUEBID,
  DEF_GAME_FORCE_SUIT,
  DEF_GAME_FORCE_NO_TRUMP,
} from './types';

export const createLabels = function createLabels(bidSystemConfig) {
  const Ranges = bidSystemConfig[ TAKE_OUT_DOUBLE_RESPONSE_RANGES ];

  const MINIMAL_FORCE_BID_LABEL = `Forced Bid`;
  const DEF_MINIMAL_FORCE_BID_LABEL = `Minimal hand ${createRangeLabel(Ranges[ SUIT_RESPONSE_RANGE][ CHEAP ] )} - Bid longest unbid suit at the lowest level.`;

  const FREE_BID_LABEL = `Free Bid`;
  const DEF_FREE_BID_LABEL = `RHO bid over partner - Minimal hand ${createRangeLabel(Ranges[ FREE_BID_RANGE ])} - Free Bid longest suit at the lowest level.`;

  const INVITATIONAL_STRENGTH_LABEL = `Invitational Hand`;
  const DEF_INVITATIONAL_STRENGTH_LABEL = `${createRangeLabel(Ranges[ SUIT_RESPONSE_RANGE ][ JUMP  ])} - Jump a level shows strength`;

  const MINIMAL_NO_TRUMP_LABEL = `No Trump Minimal`;
  const DEF_MINIMAL_NO_TRUMP_LABEL = `Balanced Hand ${createRangeLabel(Ranges[ NO_TRUMP_RESPONSE_RANGE ][ c.ONE_LEVEL ])} with stoppers in enemy suit.`;

  const INVITATIONAL_NO_TRUMP_LABEL = `No Trump Invitational`;
  const DEF_INVITATIONAL_NO_TRUMP_LABEL = `Balanced hand ${createRangeLabel(Ranges[ NO_TRUMP_RESPONSE_RANGE ][ c.TWO_LEVEL ])} with stoppers in enemy suit - Jump a level in no trump without a major suit biut`;

  const GAME_FORCE_CUEBID_LABEL = `Cue Bid Their Suit`;
  const DEF_GAME_FORCE_CUEBID_LABEL = `Game values ${createRangeLabel(Ranges[ SUIT_RESPONSE_RANGE ][ GAME ])}. Cue bid, show points get another bid from partner`;

  const GAME_FORCE_SUIT_LABEL = `Bid Game - Suit`;
  const DEF_GAME_FORCE_SUIT_LABEL = `Game values ${createRangeLabel(Ranges[ SUIT_RESPONSE_RANGE ][ GAME ])}`;

  const GAME_FORCE_NO_TRUMP_LABEL = `Bid Game - No Trumps`;
  const DEF_GAME_FORCE_NO_TRUMP_LABEL = `Game values ${createRangeLabel(Ranges[ NO_TRUMP_RESPONSE_RANGE ][ c.THREE_LEVEL ])} and stoppers in enemy suit`;

  return {
    [ MINIMAL_FORCE_BID ]: MINIMAL_FORCE_BID_LABEL,
    [ FREE_BID ]: FREE_BID_LABEL,
    [ INVITATIONAL_STRENGTH ]: INVITATIONAL_STRENGTH_LABEL,
    [ MINIMAL_NO_TRUMP ]: MINIMAL_NO_TRUMP_LABEL,
    [ INVITATIONAL_NO_TRUMP ]: INVITATIONAL_NO_TRUMP_LABEL,
    [ GAME_FORCE_CUEBID ]: GAME_FORCE_CUEBID_LABEL,
    [ GAME_FORCE_SUIT ]: GAME_FORCE_SUIT_LABEL,
    [ GAME_FORCE_NO_TRUMP ]: GAME_FORCE_NO_TRUMP_LABEL,
    [ DEF_MINIMAL_FORCE_BID ]: DEF_MINIMAL_FORCE_BID_LABEL,
    [ DEF_FREE_BID ]: DEF_FREE_BID_LABEL,
    [ DEF_INVITATIONAL_STRENGTH ]: DEF_INVITATIONAL_STRENGTH_LABEL,
    [ DEF_MINIMAL_NO_TRUMP ]: DEF_MINIMAL_NO_TRUMP_LABEL,
    [ DEF_INVITATIONAL_NO_TRUMP ]: DEF_INVITATIONAL_NO_TRUMP_LABEL,
    [ DEF_GAME_FORCE_CUEBID ]: DEF_GAME_FORCE_CUEBID_LABEL,
    [ DEF_GAME_FORCE_SUIT ]: DEF_GAME_FORCE_SUIT_LABEL,
    [ DEF_GAME_FORCE_NO_TRUMP ]: DEF_GAME_FORCE_NO_TRUMP_LABEL,
  };

};
