import { DATA_DEFINITION_PREFIX } from '../../constants';

export const TWO_SUITED_OVERCALLS = 'direct-overcall/TWO_SUITED_OVERCALLS';
export const DEF_TWO_SUITED_OVERCALLS = DATA_DEFINITION_PREFIX+'direct-overcall/TWO_SUITED_OVERCALLS';
export const MICHAELS_CUEBID = 'direct-overcall/MICHAELS_CUEBID';
export const DEF_MICHAELS_CUEBID = DATA_DEFINITION_PREFIX+'direct-overcall/MICHAELS_CUEBID';
export const UNUSUAL_TWO_NO_TRUMP = 'direct-overcall/UNUSUAL_TWO_NO_TRUMP';
export const DEF_UNUSUAL_TWO_NO_TRUMP = DATA_DEFINITION_PREFIX+'direct-overcall/UNUSUAL_TWO_NO_TRUMP';

export const ONE_LEVEL_NO_TRUMP = 'direct-overcall/ONE_LEVEL_NO_TRUMP';
export const DEF_ONE_LEVEL_NO_TRUMP = DATA_DEFINITION_PREFIX+'direct-overcall/ONE_LEVEL_NO_TRUMP';

export const ONE_LEVEL_FIVE_CARD_SUIT = 'direct-overcall/ONE_LEVEL_FIVE_CARD_SUIT';
export const DEF_ONE_LEVEL_FIVE_CARD_SUIT = DATA_DEFINITION_PREFIX+'direct-overcall/ONE_LEVEL_FIVE_CARD_SUIT';

export const TWO_LEVEL_FIVE_CARD_SUIT = 'direct-overcall/TWO_LEVEL_FIVE_CARD_SUIT';
export const DEF_TWO_LEVEL_FIVE_CARD_SUIT = DATA_DEFINITION_PREFIX+'direct-overcall/TWO_LEVEL_FIVE_CARD_SUIT';

export const WEAK_JUMP_OVERCALL = 'direct-overcall/WEAK_JUMP_OVERCALL';
export const DEF_WEAK_JUMP_OVERCALL = DATA_DEFINITION_PREFIX+'direct-overcall/WEAK_JUMP_OVERCALL';


export const TAKE_OUT_DOUBLE = 'direct-overcall/TAKE_OUT_DOUBLE';
export const DEF_TAKE_OUT_DOUBLE = DATA_DEFINITION_PREFIX+'direct-overcall/TAKE_OUT_DOUBLE';
export const TAKE_OUT_DOUBLE_SHORT_IN_ENEMY_SUIT = 'direct-overcall/TAKE_OUT_DOUBLE_SHORT_IN_ENEMY_SUIT';
export const TAKE_OUT_DOUBLE_STRONG_HAND = 'direct-overcall/TAKE_OUT_DOUBLE_STRONG_HAND';
