import {
  RESPOND_WITH_REDOUBLE,
  RESPOND_ONE_NO_TRUMP,
  SIMPLE_RAISE_TRUMP_SUPPORT,
  JUMP_RAISE_TRUMP_SUPPORT,
  RESPOND_AT_ONE_LEVEL_NEW_SUIT,
  RESPOND_AT_TWO_LEVEL_NEW_SUIT,
  DEF_RESPOND_WITH_REDOUBLE,
  DEF_RESPOND_ONE_NO_TRUMP,
  DEF_SIMPLE_RAISE_TRUMP_SUPPORT,
  DEF_JUMP_RAISE_TRUMP_SUPPORT,
  DEF_RESPOND_AT_ONE_LEVEL_NEW_SUIT,
  DEF_RESPOND_AT_TWO_LEVEL_NEW_SUIT,
} from './types';

const RESPOND_WITH_REDOUBLE_LABEL = 'Redouble';
const DEF_RESPOND_WITH_REDOUBLE_LABEL =  '10+ HCP - points in the unbid suits - Double is for penalty.';

const RESPOND_ONE_NO_TRUMP_LABEL = 'No Trump';
const DEF_RESPOND_ONE_NO_TRUMP_LABEL = '8 to 10 HCP - strength in the unbid suits.';

const SIMPLE_RAISE_TRUMP_SUPPORT_LABEL = 'Raise Partners Suit';
const DEF_SIMPLE_RAISE_TRUMP_SUPPORT_LABEL = '5 to 10 HCP and 3 cards for major suit, 4 if minor';

const JUMP_RAISE_TRUMP_SUPPORT_LABEL = 'Jump Raise Partner\'s Suit';
const DEF_JUMP_RAISE_TRUMP_SUPPORT_LABEL = '4 to 7 HCP and 4 card support for partner\'s suit. Weak bid, Double first if Stronger.';

const RESPOND_AT_ONE_LEVEL_NEW_SUIT_LABEL = 'Bid New Suit';
const DEF_RESPOND_AT_ONE_LEVEL_NEW_SUIT_LABEL = '6+ HCP - 4+ cards in suit. if only six HCP the suit is also good.';

const RESPOND_AT_TWO_LEVEL_NEW_SUIT_LABEL = 'New suit 2 level';
const DEF_RESPOND_AT_TWO_LEVEL_NEW_SUIT_LABEL = '4 to - 6+ cards in suit. Weak bid; with 10 HCP double first.';

export const Labels = {
  [ RESPOND_WITH_REDOUBLE ]: RESPOND_WITH_REDOUBLE_LABEL,
  [ RESPOND_ONE_NO_TRUMP ]: RESPOND_ONE_NO_TRUMP_LABEL,
  [ SIMPLE_RAISE_TRUMP_SUPPORT ]: SIMPLE_RAISE_TRUMP_SUPPORT_LABEL,
  [ JUMP_RAISE_TRUMP_SUPPORT ]: JUMP_RAISE_TRUMP_SUPPORT_LABEL,
  [ RESPOND_AT_ONE_LEVEL_NEW_SUIT ]: RESPOND_AT_ONE_LEVEL_NEW_SUIT_LABEL,
  [ RESPOND_AT_TWO_LEVEL_NEW_SUIT ]: RESPOND_AT_TWO_LEVEL_NEW_SUIT_LABEL,
  [ DEF_RESPOND_WITH_REDOUBLE ]: DEF_RESPOND_WITH_REDOUBLE_LABEL,
  [ DEF_RESPOND_ONE_NO_TRUMP ]: DEF_RESPOND_ONE_NO_TRUMP_LABEL,
  [ DEF_SIMPLE_RAISE_TRUMP_SUPPORT ]: DEF_SIMPLE_RAISE_TRUMP_SUPPORT_LABEL,
  [ DEF_JUMP_RAISE_TRUMP_SUPPORT ]: DEF_JUMP_RAISE_TRUMP_SUPPORT_LABEL,
  [ DEF_RESPOND_AT_ONE_LEVEL_NEW_SUIT ]: DEF_RESPOND_AT_ONE_LEVEL_NEW_SUIT_LABEL,
  [ DEF_RESPOND_AT_TWO_LEVEL_NEW_SUIT ]: DEF_RESPOND_AT_TWO_LEVEL_NEW_SUIT_LABEL,
};
