/* eslint no-console: 0 */
import { constants as c, Coords } from '../constants';
import { Card } from '../deck';
import { getIsOnNorthSouth } from '../player';
import Suit from '../suit';
import { sortPlayersForCardPlay } from '../player-sort';

const TRUMP_SUIT = 'TrumpSuit';
const LONG_STRONG_SUIT = 'LongStrongSuit';
const LONGEST_SUIT = 'LongestSuit';
const SIDE_SUIT = 'SideSuit';
const PARTNERS_SUITS = 'PartnersSuits';
const THEIR_SUITS = 'TheirSuits';
const VOIDS = 'VOIDS';
const SINGLETONS = 'Singletons';

class Strategy {
  constructor(cardSelector) {
    this.cardSelector = cardSelector;
  }

  getOpeningNoTrumpLeadSuit({hand, suitMap}) {
    // 1. suit partner bid
    const bidSuits = suitMap.get(PARTNERS_SUITS);
    if (bidSuits.length > 0) {
      return hand.getSuit(bidSuits[0].strain);
    }
    // 2. longsuit or longest unbid suit.
    const theirBidSuits = suitMap.get(THEIR_SUITS);
    const longStrongSuit = hand.getLongStrongSuit();
    if (!longStrongSuit.getIsVoid()) {
      if (!theirBidSuits.some(suit => suit.strain === longStrongSuit.strain)) {
        return longStrongSuit;
      }
    }
  }

  getDiscardSuit() {
    const suits = this.cardSelector.hand.suits.sort((a, b) => {
      if (a.getIsVoid()
        || this.cardSelector.getIsTrumpStrain(a.strain)) {
        return 1;
      }
      if (b.getIsVoid()
        || this.cardSelector.getIsTrumpStrain(b.strain)) {
        return -1;
      }
      const result = a.compare(b) * -1;
      return result;
    });

    return [...suits][0];
  }

  /**
  * @return the suit in play
  */
  getSuit(trickStrain, isLead, lastTrick) {
    const hasSuit = (suit) => {
      if (!suit) {
        return false;
      }
      if (typeof suit === 'object') {
        return suit.getLength() > 0;
      }
    };
    let suit = isLead
      ? this.getTrickLeadSuit(lastTrick)
      : this.getFollowSuit(trickStrain);

    if (!hasSuit(suit)) {
      if (this.cardSelector.mustCover && !this.cardSelector.getIsPlayingNoTrump()) {
        suit = this.cardSelector.suitMap.get(TRUMP_SUIT);
      }
      if (!hasSuit(suit)) {
        suit = this.getDiscardSuit();
      }
    }
    return suit;
  }

  /**
  * @return the card in play
  */
  getCard(trickStrain, currentTrick, lastTrick) {
    const isLead = !trickStrain;
    const suit = this.getSuit(trickStrain, isLead, lastTrick);

    let index;

    if (isLead) {
      if (this.cardSelector.getIsPlayingNoTrump()) {
        // fourth from longest and strongest
        index = (suit.getLength() >= c.FOUR_CARD_SUIT) ? 3 : suit.getLength() - 1;
      } else {
        const leadHigh = ((suit.getLength() >= c.THREE_CARD_SUIT) ||
          suit.getHasTouchingHonors());

        index = leadHigh ? 0 : suit.getLength() - 1;
      }
    } else if (!this.cardSelector.mustCover) {
      index = suit.getLength() - 1;
    } else {
      const trickWinner = currentTrick.getWinner();
      index = suit.cards.findIndex(card => card.compare(trickWinner.card) < 0);

      if (index < 0) {
        const leadHigh = false;
        index = leadHigh ? 0 : suit.getLength() - 1;
      }
    }
    const card = suit.cards[index];
    suit.cards.splice(index, 1);
    return card;
  }
}

class DefenderNoTrumpStrategy extends Strategy {
  getTrickLeadSuit(lastTrick) {
    if (!lastTrick) {
      return this.getOpeningNoTrumpLeadSuit(this.cardSelector);
    }
    return this.cardSelector.hand.getSuit(lastTrick.getWinner().strain);
  }

  getFollowSuit(trickStrain) {
    return this.cardSelector.hand.getSuit(trickStrain);
  }

  createPlan(dummyHand, card) {
  }
}

class DefenderSuitStrategy extends Strategy {
  getTrickLeadSuit(lastTrick) {
    return this.cardSelector.getOpeningSuitContractLead();
  }

  getFollowSuit(trickStrain) {
    return this.cardSelector.hand.getSuit(trickStrain);
  }

  createPlan(dummyHand, card) {
  }
}

class DeclarerNoTrumpStrategy extends Strategy {
  getTrickLeadSuit(lastTrick) {
    return this.cardSelector.getOpeningSuitContractLead();
  }

  getFollowSuit(trickStrain) {
    return this.cardSelector.hand.getSuit(trickStrain);
  }

  createPlan(dummyHand, card) {

    const { hand } = this.cardSelector;

    const clubs = Suit.createSuit(c.CLUB,
      [...hand.clubs.cards, ...dummyHand.clubs.cards]);

    const diamonds = Suit.createSuit(c.DIAMOND,
      [...hand.diamonds.cards, ...dummyHand.diamonds.cards]);

    const hearts = Suit.createSuit(c.HEART,
      [...hand.hearts.cards, ...dummyHand.hearts.cards]);

    const spades = Suit.createSuit(c.SPADE,
      [...hand.spades.cards, ...dummyHand.spades.cards]);

    const combo = [clubs, diamonds, hearts, spades];

    const instantWinnerCount = combo.reduce((cnt, suit) => cnt + suit.getInstantWinnerCount(), 0);

    const makeUp = this.cardSelector.getCardPlay().getRequiredTrickCount() - instantWinnerCount;

    combo.sort((a, b) => {
      let result = 0;

      const compare = (val1, val2) => {
        if (val1 && !val2) {
          return -1;
        }
        if (!val1 && val2) {
          return 1;
        }
        return 0;
      };

      const test = {
        short: 0,
        tenace: 0,
        hcp: 0,
        touchingHonors: 0,
        missingAce: 0,
        highSpots: 0,
        AceOnly: 0,
        length: 0,
        getResult() {
          if (this.short > 0) {
            if (this.hcp < 0) {
              return -1;
            }
            return 1;
          }
          if ((this.length < 0)
            && ((this.touchingHonors > 0 && this.missingAce)
            || (this.tenace > 0))) {
            return -1;
          }
          if (this.length < 0) {
            return -1;
          }
          return this.hcp;
        },
      };

      if (a.length <= 6 || b.length <= 6) {
        const aIsOk = (hand.getSuit(a.strain).length > c.THREE_CARD_SUIT
        || dummyHand.getSuit(a.strain).length > c.THREE_CARD_SUIT);

        const bIsOk = (hand.getSuit(b.strain).length > c.THREE_CARD_SUIT
        || dummyHand.getSuit(b.strain).length > c.THREE_CARD_SUIT);

        test.short = compare(aIsOk, bIsOk);
      }

      if (a.length >= 7 || b.length >= 7) {
        const aIsOk = (hand.getSuit(a.strain).length >= c.FIVE_CARD_SUIT
        || dummyHand.getSuit(a.strain).length >= c.FIVE_CARD_SUIT);

        const bIsOk = (hand.getSuit(b.strain).length >= c.FIVE_CARD_SUIT
        || dummyHand.getSuit(b.strain).length >= c.FIVE_CARD_SUIT);

        test.length = compare(aIsOk, bIsOk);
      }

      test.tenace = compare(a.isTenace, b.isTenace);

      test.hcp = compare(a.highCardPoints > b.highCardPoints, b.highCardPoints > a.highCardPoints);

      test.touchingHonors = compare(a.hasTouchingHonors, b.touchingHonors);

      test.missingAce = compare(!a.getHasCard(c.ACE), !b.getHasCard(c.ACE));

      test.highSpots = compare(a.hasHighSpotCards, b.hasHighSpotCards);

      test.aceOnly = compare(a.getHasCard(c.ACE) && a.highCardPoints === 4,
        b.getHasCard(c.ACE) && b.highCardPoints === 4);

      result = test.getResult();

      return result;
    });

    this.plan = {
      card,
      makeUp,
    };
  }
}

class DeclarerSuitStrategy extends Strategy {
  getTrickLeadSuit(/*lastTrick*/) {
    //if (!this.cardSelector.getIsOpposingTrumpsCleared()) {
      return this.cardSelector.suitMap.get(TRUMP_SUIT);
    //}
  }

  getFollowSuit(trickStrain) {
    return this.cardSelector.hand.getSuit(trickStrain);
  }

  createPlan(dummyHand, card) {
  }
}


class CardSelector {
  constructor(player, contract) {
    this.player = player;
    this.hand = player.getCloneHand();
    this.contract = contract;

    this.mustCover = false;

    this.initSuitMap();

    this.strategy = this.getStrategy(player);
  }

  getStrategy(player) {
    if (player.getIsDefending()) {
      if (this.getIsPlayingNoTrump()) {
        return new DefenderNoTrumpStrategy(this);
      }
      return new DefenderSuitStrategy(this);
    }
    if (this.getIsPlayingNoTrump()) {
      return new DeclarerNoTrumpStrategy(this);
    }
    return new DeclarerSuitStrategy(this);
  }

  initSuitMap() {
    const longStrongSuit = this.hand.getLongStrongSuit(c.THREE_CARD_SUIT);
    const trumpSuit = !this.getIsPlayingNoTrump()
      ? this.hand.getSuit(this.contract.strain) : undefined;
    const sideSuit = !longStrongSuit.getIsVoid()
      ? this.hand.getSideSuit(longStrongSuit.strain) : undefined;

    this.suitMap = new Map();

    this.suitMap.set(LONG_STRONG_SUIT, longStrongSuit)
      .set(LONGEST_SUIT, this.hand.getLongestSuit(c.THREE_CARD_SUIT))
      .set(TRUMP_SUIT, trumpSuit)
      .set(SIDE_SUIT, sideSuit)
      .set(PARTNERS_SUITS, this.player.getSuitsPartnerBid())
      .set(THEIR_SUITS, this.player.getSuitsTheyBid())
      .set(VOIDS, this.hand.getVoidSuits())
      .set(SINGLETONS, this.hand.getSingletonSuits());
  }

  getIsTrumpStrain(strain) {
    return this.contract.strain === strain;
  }

  getHand() {
    return this.hand;
  }

  getMustCover() {
    return this.mustCover;
  }

  setMustCover(flag) {
    this.mustCover = flag;
  }

  getIsPlayingNoTrump() {
    return this.contract.strain === c.NO_TRUMP;
  }

  getSuitMap() {
    return this.suitMap;
  }

  getCardPlay() {
    return this.player.cardPlayService;
  }

  createPlan(dummyHand, card) {
    this.strategy.createPlan(dummyHand, card);
  }

  getStrainIsTrump(strain) {
    return !this.getIsPlayingNoTrump() && this.contract.strain === strain;
  }

  getOpeningSuitContractLead() {
    this.sortSuitsForOpeningSuitLead();
    return this.hand.suits[0];
  }

  sortSuitsForOpeningSuitLead() {
    this.hand.suits.sort((a, b) => {
      let result = 0;

      // hasAceIsOnlyHonor
      result = this.compareSuitsForSingleAce(a, b);

      // 1. priority singleton
      if (result === 0) {
        if (a.isSingleton && !b.isSingleton) {
          result = -1;
        } else if (!a.isSingleton && b.isSingleton) {
          result = 1;
        }
      }

      // 2. priority partners suit
      if (result === 0) {
        result = this.compareSuitsToPartners(a, b);
      }

      // 3. priority attacking suit
      if (result === 0) {
        if (a.hasTouchingHonors && !b.hasTouchingHonors) {
          result = -1;
        } else if (!a.hasTouchingHonors && b.hasTouchingHonors) {
          result = 1;
        }
      }

      // 4. Long Suit
      if (result === 0) {
        const longStrong = this.suitMap.get(LONG_STRONG_SUIT);
        if (longStrong !== undefined) {
          const aIsLongStrong = a.strain === longStrong.strain;
          const bIsLongStrong = b.strain === longStrong.strain;
          if (aIsLongStrong && !bIsLongStrong) {
            result = -1;
          } else if (!aIsLongStrong && bIsLongStrong) {
            result = 1;
          }
        } else {
          const longSuit = this.suitMap.get(LONGEST_SUIT);
          if (longSuit !== undefined) {
            const aIsLong = a.strain === longSuit.strain;
            const bIsLong = b.strain === longSuit.strain;
            if (aIsLong && !bIsLong) {
              result = -1;
            } else if (!aIsLong && bIsLong) {
              result = 1;
            }
          }
        }
      }

      // 5. unbid suit of opponents
      if (result === 0) {
        result = this.compareSuitsToTheirs(a, b);
      }

      // lower the trumps
      if (result === -1) {
        if (this.getStrainIsTrump(a.strain)) {
          result = 1;
        }
      } else if (result === 1) {
        if (this.getStrainIsTrump(b.strain)) {
          result = -1;
        }
      }

      return result;
    });
  }

  compareSuitsForSingleAce(a, b) {
    let result = 0;
    if (a.hasAceIsOnlyHonor && !b.hasAceIsOnlyHonor) {
      result = 1;
    } else if (!a.hasAceIsOnlyHonor && b.hasAceIsOnlyHonor) {
      result = -1;
    }
    return result;
  }

  compareSuitsToPartners(a, b) {
    let result = 0;
    const partnerSuits = this.suitMap.get(PARTNERS_SUITS);

    if (partnerSuits) {
      const aInP = partnerSuits.some(suit => suit.strain === a.strain);
      const bInP = partnerSuits.some(suit => suit.strain === b.strain);
      if (aInP && !bInP) {
        result = -1;
      } else if (!aInP && bInP) {
        result = 1;
      }
    }

    return result;
  }

  compareSuitsToTheirs(a, b) {
    let result = 0;
    const theirSuits = this.suitMap.get(THEIR_SUITS);
    if (theirSuits) {
      const aInT = theirSuits.some(suit => suit.strain === a.strain);
      const bInT = theirSuits.some(suit => suit.strain === b.strain);
      if (aInT && !bInT) {
        result = 1;
      } else if (!aInT && bInT) {
        result = -1;
      }
    }
    return result;
  }

  sortSuitsForOpeningNoTrumpLead() {
    this.hand.suits.sort((a, b) => {
      let result = 0;

      // hasAceIsOnlyHonor
      result = this.compareSuitsForSingleAce(a, b);

      if (result === 0) {
        result = this.compareSuitsToPartners(a, b);
      }

      if (result === 0) {
        result = this.compareSuitsToTheirs(a, b);
      }

      if (result === 0) {
        result = b.length - a.length;
      }
      return result;
    });
  }

  getCard(trickStrain, currentTrick, lastTrick) {
    return this.strategy.getCard(trickStrain, currentTrick, lastTrick);
  }
}

class Trick {
  constructor(trumpStrain) {
    this.trumpStrain = trumpStrain;
    this.cards = new Map();
  }

  getLastPlayedCard() {
    return this.lastPlayedCard;
  }

  getWinner() {
    const getWinnerValues = (player, card) => {
      return {
        player: player,
        card: card,
        strain: card.strain,
      };
    };

    let winner;
    for (let [player, card] of this.cards) {
      if (!card) {
        debugger;
      }
      if (!winner) {
        winner = getWinnerValues(player, card);
      } else {
        const strainWinner = card.strain === this.trumpStrain?
          this.trumpStrain : winner.strain;

        if (winner.card.compare(card, strainWinner) > 0) {
          winner = getWinnerValues(player, card);
        }
      }
    }
    return winner;
  }

  addCard(player, card) {
    this.lastPlayedCard = card;
    this.cards.set(player, card);
  }

  getCardsAsArray() {
    const result = [];
    const it = this.cards.values();
    let card = it.next();
    while (!card.done) {
      result.push(card.value);
      card = it.next();
    }
    return result;
  }

  toString() {
    const winner = this.getWinner();
    const cardsPlayed = this.getCardsAsArray().join();


    return `player ${winner.player.position} card ${winner.card} cards played ${cardsPlayed}`;
  }
}

class CardPlayService {
  constructor(gameService) {
    //this.game = gameService;
    this.players = gameService.players;
    this.contract = gameService.getContract();

    this.tricks = [];
    this.northSouthTricks = [];
    this.eastWestTricks = [];

    this.players.forEach(player => player.setCardPlay(this));

    this.initUnplayedCardMap();
  }

  static getCardPlayedExport(player, card)  {
    return {
      position: player.position,
      card: {
        value: card.value.toString(),
        strain: card.strain,
        suitName: c.SUIT_NAMES[card.strain],
      },
    };
  }

  /**
   */
  initUnplayedCardMap() {
    this.unplayedCardMap = new Map();
    c.CardDeck.suits.forEach(strain => {
      const cards = c.CardDeck.values.map(value => new Card(strain, value));
      this.unplayedCardMap.set(strain, cards);
    });
  }

  getIsOpposingTrumpsCleared() {
    return false;
  }

  getTrumpsPlayedCount() {
    return this.getCardsInStrainPlayedCount(this.trumpStrain);
  }

  getCardsInStrainPlayedCount(strain) {
    return this.unplayedCardMap.get(strain).length;
  }

  /**
  * models the dummy hand laying down cards
  * @param {Hand} dummyHand
  * @param {Trick} currentTrick
  */
  setDummyHand(dummyHand, currentTrick) {
    this.dummyHand = dummyHand;

    this.players.forEach((player) => {
      if (player !== dummyHand.player) {
        player.getCardSelectorInstance().createPlan(dummyHand, currentTrick.lastPlayedCard);
      }
    });
  }

  /**
  * return the number of tricks to make the contract
   */
  getRequiredTrickCount() {
    return c.BOOK + this.contract.level;
  }

  /**
  * appends a trick to array
  * @param {Array} tricks - array of tricks
  * @param {Trick} trick - the trick
  */
  addTrick(tricks, trick) {
    return [...tricks, trick];
  }

  /**
  * @param {Trick} trick - the trick
  * @param {function} onTrickCompleteEvent - return the played trick values
  * @param {function} resolve - promise resolve
  */
  trickCompleteHandler(trick, onTrickCompleteEvent, resolve) {

    const gameReturnFunc = () => {
      // there will be a card retuned from i/0
      resolve('done!');
    };

    const winner = trick.getWinner();

    if (winner.player.getIsNorthSouth()) {
      this.northSouthTricks = this.addTrick(this.northSouthTricks, trick);
    } else {
      this.eastWestTricks = this.addTrick(this.eastWestTricks, trick);
    }
    winner.player.setIsLeadCard(true);

    this.tricks = this.addTrick(this.tricks, trick);

    onTrickCompleteEvent({
      winner: CardPlayService.getCardPlayedExport(winner.player, winner.card),
      cardsPlayed: Array.from(trick.cards).map(playerCardArray => CardPlayService.getCardPlayedExport(playerCardArray[0], playerCardArray[1])),
      playOrder: Array.from(trick.cards.keys()).map(player => player.position),
      northSouthCount: this.northSouthTricks.length,
      eastWestCount: this.eastWestTricks.length,
      gameReturnFunc,
    });
  }

  /**
  * @param {Player} cardPlayer - the player
  * @param {Card} card - the card
  * @param {Trick} trick - the trick
  */
  playCard(cardPlayer, card, trick) {
    // add the card to the trick
    trick.addCard(cardPlayer, card);
    // signal each player the update
    this.players.forEach((player) => {
      if (player !== cardPlayer) {
        player.signalCard(trick);
      }
    });
    // update the unplayed cards
    const suit = this.unplayedCardMap.get(card.strain);
    const index = suit.findIndex(c => c.value === card.value);
    suit.splice(index, 1);
    //console.log(`player ${cardPlayer.position} card ${card}`);
  }

  /**
  * returns the bridge tally values for completed play
  */
  getTallyData() {
    const {declarer: contractDeclarer, value: contractValue} = this.contract;
    const tallyData = {
      contractDeclarer,
      contractValue,
      contractResult: 0,
      contractPoints: 0,
      overTrickPoints: 0,
      slamBonus: 0,
      doubleBonus: 0,
      honorBonus: 0,
      underTrickPenalty: 0,
    };
    const tricksMade = getIsOnNorthSouth(contractDeclarer)
      ? this.northSouthTricks.length
      : this.eastWestTricks.length;

    tallyData.contractResult = tricksMade - this.getRequiredTrickCount();

    const noTrumpFirstTrick = 40;
    const pointsPerTrick = Suit.getIsMinor(this.contract.strain) ? 20 : 30;
    if (tallyData.contractResult >= 0) {
      const SLAM_BONUS = {
        smallSlam: 500,
        vulnerableSmallSlam: 750,
        grandSlam: 1000,
        vulnerableGrandSlam: 1500,
      };
      if (this.contract.level === c.SEVEN_LEVEL) {
        tallyData.slamBonus = SLAM_BONUS.grandSlam;
      } else if (this.contract.level === c.SIX_LEVEL) {
        tallyData.slamBonus = SLAM_BONUS.smallSlam;
      }
      tallyData.overTrickPoints = tallyData.contractResult * pointsPerTrick;
      const contractPoints = this.contract.level * pointsPerTrick;
      tallyData.contractPoints = this.contract.isNoTrump
        ? contractPoints + (noTrumpFirstTrick - pointsPerTrick)
          : contractPoints;
    } else {
      const penalty = 50;
      //const vulnerable = 100;
      const doubledVals = [100, 200, 200, 300];
      //const doubledVulnerableVals = [100, 200, 300, 300];
      const underCnt = Math.abs(tallyData.contractResult);

      if (!this.contract.isDoubled) {
        tallyData.underTrickPenalty = penalty * underCnt;
      } else {
        const first = 0;
        let underPenalty = doubledVals[first];
        if (underCnt >= 2) {
          underPenalty += doubledVals[first + 1];
          if (underCnt >= 3) {
            underPenalty += doubledVals[first + 2];
            if (underCnt >= 4) {
               for (let i = 3; i < (first + underCnt); i++) {
                 underPenalty += doubledVals[first + 3];
               }
            }
          }
        }
        tallyData.underTrickPenalty = underPenalty;
      }
    }
    return tallyData;
  }

  /*
  * returns the last trick completed
  */
  getLastTrick() {
    return this.tricks.length > 0
      ? this.tricks[this.tricks.length -1] : null;
  }

  /**
  * run through the card play
  * @param {function} onCardPlayedEvent
  * @param {function} onTrickCompleteEvent
  * @param {function} onPlayCompleteEvent
  */
  run(onCardPlayedEvent, onTrickCompleteEvent, onPlayCompleteEvent) {

    // broadcast trick complete event - wait for resolve
    const completeTrick = async (trick) => {
      const promise = new Promise((resolve) => {
        this.trickCompleteHandler(trick, onTrickCompleteEvent, resolve);
      });
      await promise;
      playCards();
    };

    // returns promise cardplay listener promise
    const getPlayCardPromise = (player, card, trick) => {
      return new Promise(resolve => {
        const gameReturnFunc = () => {
          this.playCard(player, card, trick);
          resolve("done!");
        };

        onCardPlayedEvent({
          cardProps: CardPlayService.getCardPlayedExport(player, card),
          gameReturnFunc,
        });
      });
    };

    // async loop waits for each card played promise
    const playTrickLoop = async (players) => {
      const currentTrick = new Trick(this.contract.strain);
      const lastTrick = this.getLastTrick();
      let trickStrain;

      for (let player of players) {
        const card = player.getCard(trickStrain, currentTrick, lastTrick);
        if (!trickStrain) {
          trickStrain = card.strain;
        }
        await getPlayCardPromise(player, card, currentTrick);
      }
      completeTrick(currentTrick);
    };

    // plays the next trick - runs the cardplay loop (each player plays a card)
    const playNextTrick = () => {
      // resort to card leader for each trick
      const getCardLeadPosition = () => {
        if (this.tricks.length === 0) {
          return Coords.nextCoord(this.contract.declarer);
        }
        return this.tricks[this.tricks.length -1].getWinner().player.position;
      };
      const players = sortPlayersForCardPlay(this.players, getCardLeadPosition());

      playTrickLoop(players);
    };

    //play until
    const playCards = () => {
      if (this.tricks.length < 13) {
        playNextTrick();
      } else {
        onPlayCompleteEvent(this.getTallyData());
      }
    };

    playCards();
  }
}

export { CardPlayService, CardSelector};
