import { createRandomDealService, createDeal } from './bridge-deal-service';
import { assignSeats, sortPlayersForBid } from '../player-sort';
import { constants as c } from '../constants';
//import { BidTypes } from '../bid-system';

/**
* @class BidgeGameService - dealCards, holdAuction, playCards
*/
class BridgeGameService {
  constructor(players) {
    this.randomDealService = createRandomDealService(c.RANDOM_DEAL);

    // default to random deals
    this.dealService = this.randomDealService;

    this.players = players;
    assignSeats(this.players);
    players.forEach(player => player.setGameService(this));
  }

  getPlayers() {
    return this.players;
  }

  dealCards(dealService = this.randomDealService) {
    this.dealService = dealService;
    dealService.dealCards(this.players);
    return dealService;
  }

  /**
  * @method holdAuction
  * @param {function} onFinish
  * @param {function} onError
  * @param {function} onAddBid
  */
  holdAuction({ onFinish, onError, onAddBid }) {
    import('./auction-service')
      .then(({ createAuctionService }) => {
        const onRunFinish = auctionExport => {

          if (auctionExport) {

            if (auctionExport.cancelled) {
              return onFinish(auctionExport);
            }

            const isPlayable = auctionExport.contract.value !== c.PASSED_OUT;

            // return the result
            this.auctionData = {
              isPlayable,
              auction: auctionExport,
              bidSequence: auctionService.getBidSequence(),
              firstBidForEachBidderType: isPlayable
                ? auctionService.getFirstBidForEachBidderType()
                : {},
            };

            const savedBidSequence = this.dealService.bidSequence;
            const newSequence = this.auctionData.bidSequence;

            if (savedBidSequence) {
              this.auctionData.bidSequenceChanged = savedBidSequence !== newSequence;
            }
            /**
            const bidSequence = this.auctionData.bidSequence;
            const bids = auctionService.bids;

            if (bids.some(bid =>
              bid.getHasPromiseType(BidTypes.DRURY_SUBMINIMAL_OPENER)
              || bid.getHasPromiseType(BidTypes.DRURY_OTHER_MAJOR)
            )) {
              this.auctionData.saveTestDeal = {folderName: 'drury convention', bidSequence};
            }*/

            onFinish(this.auctionData);

          }
          //onFinish();
        };

        const auctionService = createAuctionService({
          dealerPosition: this.getDealerPosition(),
          players: sortPlayersForBid(this.players),
          onAddBid
        })
        auctionService.run(onRunFinish, onError);
      }).catch(err => {
        onError(err);
      });
  }

  playCards(onCardPlayed, onTrickComplete, onPlayCompleteEvent, onError) {
    import('./card-play-service')
      .then(({ CardPlayService }) => {
        const handlePlayComplete = result => {
          this.cardPlayValues = {
            tricks: result,
          };
          onPlayCompleteEvent(result);
        };
        const cardPlayService = new CardPlayService(this);
        cardPlayService.run(onCardPlayed, onTrickComplete, handlePlayComplete);
      }).catch(err => {
        onError(err);
      });
  }

  static clearHands(players) {
    players.forEach(player => player.clearHand());
  }

  reset() {
    this.dealService = this.randomDealService;
    this.auctionData = null;
    BridgeGameService.clearHands(this.players);
  }

  getDealer() {
    return this.dealService.dealer;
  }

  getDealerPosition() {
    return this.getDealer().position;
  }

  getHasPlayableAuction() {
    return this.auctionData
      ? this.auctionData.isPlayable
      : false;
  }

  getContract() {
    return this.auctionData
      ? this.auctionData.auction.contract
      : null;
  }

  getBidSequence() {
    return this.auctionData.bidSequence;
  }

  getProperties() {
    const { id, source, dealName, dealer } = this.dealService;

    return {
      id,
      source,
      dealName,
      dealerPosition: dealer
        ? dealer.position
        : null,
      contract: this.getContract(),
    };
  }

  getExternalExport({ id, name, folderName, source }) {
    const { bidSequence, tags} = this.auctionData
      ? this.auctionData
      : {
        bidSequence: '',
        tags: '',
      };

    return !this.dealService.dealer
      ? {}
      : createDeal({
          ...this.getDealExport(),
          id: id || '',
          name: name,
          folderName: folderName || '',
          source,
          bidSequence,
          tags,
        });
  }

  getExternalExportJSON() {
    const {id, dealName: name, folderName} = this.dealService;
    let result = JSON.stringify(this.getExternalExport(
        {id, name, folderName}
      ), null, 2);
    result = result.replace(/[",]/g,'');
    return result;
  }

  getDealExport() {
    return this.dealService.getExport(this.players);
  }

  runTest(onFinish, onError) {
    this.continueTesting = !this.continueTesting;
    let i = 0;
    const run = () => {
      try {
        if (this.continueTesting) {
          this.reset();
          this.dealCards();
          this.holdAuction({ onFinish, onError });
          i++;
          console.log("test cnt = " + i);
        }
      } catch (e) {
        console.error(e, e.stacktrace);
      }
      if (this.continueTesting) {
        setTimeout(run, 500);
      }
    };

    if (this.continueTesting) {
      setTimeout(run, 500);
    }
  }
}

export default BridgeGameService;
