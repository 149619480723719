import { getDisplayOptionsSettings } from '../../../settings';
import { constants as uic } from '../../../constants';
import { APP_SETTINGS_UPDATE } from './types';

import * as R from 'ramda';

const getStartOptions = () => Object.assign(
  {},
  getDisplayOptionsSettings(),
  { [ uic.IS_DISPLAY_AUCTION_VIEWER ]: false },
);

const reducer = function getDisplayOptions(state = getStartOptions(), action) {
  if (action.type === APP_SETTINGS_UPDATE) {
    return Object.assign(
      {},
      state,
      getDisplayOptionsSettings(),
    );
  }

  if (!R.isNil(state[action.key])) {
    return Object.assign(
      {},
      state,
      {[action.key]: !state[action.key]}
    );
  }

  return state;
};

export default reducer;
