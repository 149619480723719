import React from 'react';
import PropTypes from 'prop-types';

export const SelectLayout = function SelectLayout({
  containerClass,
  displayLabels,
  labelFor,
  label,
  canEdit,
  getSelect,
  getEditLabel
}) {
  const getContent = () => (
      <>
      {displayLabels &&
        <label htmlFor={labelFor}>{label}</label>
      }
      {canEdit
        ? getSelect()
        : getEditLabel()
      }
      </>
  );
  return (
    canEdit
      ? <div className={containerClass}>
          {getContent()}
        </div>
      : getContent()
  );
};
SelectLayout.propTypes = {
  containerClass: PropTypes.string,
  displayLabels: PropTypes.bool,
  labelFor: PropTypes.string,
  label: PropTypes.string,
  canEdit: PropTypes.bool,
  getSelect: PropTypes.func,
  getEditLabel: PropTypes.func,
};
