export const ON_AUCTION_UPDATE = 'game-event-types/onAuctionUpdate';

export const ON_DEAL_PROGRESS = 'game-event-types/onDealProgress';

export const ON_GO_HOME = 'game-event-types/onGoHome';

export const ON_DEAL_CARDS = 'game-event-types/onDealCards';

export const ON_NEW_DEAL_START = 'game-event-types/onNewDealStart';

export const ON_DEAL_COMPLETE = 'game-event-types/onDealComplete';

export const ON_AUCTION_COMPLETED = 'game-event-types/onAuctionCompleted';

export const ON_HAND_PLAYED_OUT = 'game-event-types/onHandPlayedOut';

export const ON_GAME_MODEL_INIT = 'game-event-types/onGameModelInit';

export const ON_PLAY_CARD = 'game-event-types/onPlayCard';

export const ON_TRICK_COMPLETE = 'game-event-types/onTrickComplete';
