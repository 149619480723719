import { TWO_D_WAITING } from './types';

export const STRONG_TWO_RESPONSE_RANGES = 'bid-options/strong-two/STRONG_TWO_RESPONSE_RANGES';

export const GAME_FORCE_RANGE = 'bid-options/strong-two/GAME_FORCE_RANGE';

export const createRanges = () => ({
  [ TWO_D_WAITING ]: {
    lower: 0,
    upper: 7,
  },
  [ GAME_FORCE_RANGE ]: {
    lower: 8,
  },
});
