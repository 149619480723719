import { useSelector } from 'react-redux';

import {
  selectDealProgress,
  selectIsBidPractice,
  selectIsDisplayHands,
  selectIsAuctionViewerVisible,
} from '../../state/selectors';

import { DealProgress, AuctionViewStates } from '../../presenter';

export default function useAuctionViewState() {
  const dealProgress = useSelector(state => selectDealProgress(state));
  const isBidPractice = useSelector(state => selectIsBidPractice(state));
  const isDisplayHands = useSelector(state => selectIsDisplayHands(state));
  const isAuctionViewerVisible = useSelector(state => selectIsAuctionViewerVisible(state));

  const isViewerVisible = isAuctionViewerVisible
    || (isAuctionViewerVisible
      && DealProgress.getIsPlayInProgress(dealProgress))
    || (!DealProgress.getIsPlayInProgress(dealProgress)
      && (
        // complete but not view
        (DealProgress.getIsAuctionComplete(dealProgress)
          && !DealProgress.getIsAuctionView(dealProgress)
          )
        // viewing
        || DealProgress.getIsAuctionView(dealProgress)
      ));

  const nextAuctionViewState = (() => {
    if (dealProgress === DealProgress.auctionCompleted) {
      return AuctionViewStates.AUCTION_SHOW_AUCTION;
    }

    if (dealProgress === DealProgress.auctionView) {
      if (isBidPractice
       && !isDisplayHands
      ) {
        return AuctionViewStates.AUCTION_SHOW_CARDS;
      }
      return AuctionViewStates.AUCTION_CLOSE;
    }

    if (isViewerVisible
      && DealProgress.getIsAuctionViewed(dealProgress)) {
      return AuctionViewStates.AUCTION_NO_OP;
    }
  })();

  return {
    isViewerVisible,
    nextAuctionViewState,
  }
};
