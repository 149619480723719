import { Coords } from './constants';

import uuid from 'uuid';

const UnknownStrain = {
  id: '187f0692-97f9-4948-817c-8c8584b4de3a',
  name: 'unknown promise strain!',
  folderName: 'Error Deals',
  timeStamp: 1602441272971,
  dealer: 'West',
  hands: {
    west: '10♣ 6♣ 4♣ A♦ 8♦ 4♦ J♥ 9♥ 5♥ J♠ 9♠ 5♠ 3♠',
    north: 'A♣ 9♣ 7♣ 5♣ 2♦ 8♥ 2♥ A♠ Q♠ 7♠ 6♠ 4♠ 2♠',
    east: 'J♣ 3♣ 2♣ K♦ Q♦ 10♦ 6♦ 3♦ Q♥ 10♥ 4♥ 10♠ 8♠',
    south: 'K♣ Q♣ 8♣ J♦ 9♦ 7♦ 5♦ A♥ K♥ 7♥ 6♥ 3♥ K♠',
  },
};

const SouthResponseWithEightSpades = {
  id: uuid.v4(),
  name: 'South response with eight spades',
  folderName: 'Opening Responses',
  dealer: Coords.WEST,
  hands: {
    west: '9♣ 8♣ 5♣ K♦ J♦ 10♦ 9♦ 8♦ 2♦ 10♥ 2♥ 8♠ 6♠',
    north: 'A♣ K♣ Q♣ 7♣ 2♣ 6♦ 4♦ 3♦ 8♥ 7♥ 3♥ A♠ 2♠',
    east: 'J♣ 10♣ 6♣ 4♣ 3♣ A♦ 7♦ A♥ K♥ Q♥ J♥ 5♥ J♠',
    south: 'Q♦ 5♦ 9♥ 6♥ 4♥ K♠ Q♠ 10♠ 9♠ 7♠ 5♠ 4♠ 3♠',
  },
  auction: 'W-P N-1♣ E-1♥ S-X W-P N-P E-P',
};

const NorthPreemptsThreeClubsOvercall = {
  id: uuid.v4(),
  name: 'North Overcalls Three Clubs Preempt',
  folderName: 'Overcalls',
  dealerPosition: Coords.SOUTH,
  hands: {
    west: '6♣ A♦ Q♦ 6♦ A♥ 9♥ 6♥ 2♥ J♠ 5♠ 4♠ 3♠ 2♠',
    north: 'K♣ Q♣ 10♣ 9♣ 7♣ 4♣ 3♣ J♦ 8♦ 2♦ K♥ 10♥ 3♥',
    east: 'A♣ 2♣ 10♦ 9♦ 4♦ Q♥ 7♥ 5♥ 4♥ A♠ 10♠ 8♠ 6♠',
    south: 'J♣ 8♣ 5♣ K♦ 7♦ 5♦ 3♦ J♥ 8♥ K♠ Q♠ 9♠ 7♠',
  },
  auction: 'S-P W-1♠ N-3♣ E-3♠ S-P W-4♠ N-P E-P S-P',
};

const EastPassesSlamTry = {
  id: uuid.v4(),
  name: 'East Passes Slam Try',
  folderName: 'Slams',
  dealerPosition: Coords.WEST,
  hands: {
    west: '5♣ 4♣ 3♣ A♦ K♦ 10♦ 9♦ 2♥ A♠ K♠ 8♠ 6♠ 5♠',
    north: '10♣ 7♣ 6♣ J♦ 5♦ 3♦ A♥ 5♥ 4♥ 3♥ 10♠ 7♠ 4♠',
    east: 'A♣ 9♣ 8♣ Q♦ 4♦ 2♦ K♥ J♥ 8♥ Q♠ J♠ 9♠ 3♠',
    south: 'K♣ Q♣ J♣ 2♣ 8♦ 7♦ 6♦ Q♥ 10♥ 9♥ 7♥ 6♥ 2♠'
  },
  auction: 'W-1♠ N-P E-3♠ S-P W-4♦ N-P E-4♠ S-P W-P N-P',
};

const OpenOneSpade = {
  id: uuid.v4(),
  name: 'West Opens One Spade',
  folderName: 'Open One of a Major',
  dealerPosition: Coords.SOUTH,
  hands: {
    west: 'A♣ 6♣ 4♣ 3♣ 2♣ A♦ K♦ 9♦ Q♠ 10♠ 8♠ 5♠ 4♠',
    north: 'Q♣ 10♣ 7♣ 8♦ 7♦ K♥ J♥ 10♥ 9♥ 3♥ 7♠ 6♠ 2♠',
    east: 'K♣ J♣ 8♣ 5♣ Q♦ 6♦ 4♦ 3♦ A♥ Q♥ 5♥ A♠ K♠',
    south: '9♣ J♦ 10♦ 5♦ 2♦ 8♥ 7♥ 6♥ 4♥ 2♥ J♠ 9♠ 3♠',
  },
  auction: 'S-P W-1♠ N-P E-2♣ S-P W-5♣ N-P E-P S-P',
};
const NegativeDoubleDeal = {
  id: uuid.v4(),
  folderName: 'Opening Responses',
  name: 'Negative Double',
  dealerPosition: Coords.SOUTH,
  hands: {
    west: ['4♠', '3♠', '2♠',
      'K♥', 'Q♥', '7♥', '6♥', '4♥',
      '6♦', '4♦',
      'K♣', 'Q♣', '10♣',
    ],
    north: ['A♠', '9♠', '7♠', '3♠',
      'A♥', 'J♥',
      '9♦', '2♦',
      'K♣', 'J♣', '10♣', '6♣', '2♣'
    ],
    east: ['A♠', 'K♠', 'Q♠', '9♠', '8♠',
      'J♥', '9♥', '2♥',
      'K♦', '5♦',
      '9♣', '8♣', '7♣',
    ],
    south: ['A♠', '10♠',
      'A♥', '10♥', '5♥', '3♥',
      'A♦', 'Q♦', 'J♦', '9♦', '3♦',
      '3♣', '2♣',
    ],
  }
};

const NoTrumpSlamTryDeal = {
  id: uuid.v4(),
  folderName: 'Slams',
  name: 'No Trump Slam Try',
  dealerPosition: Coords.NORTH,
  hands: {
    east: ['9♠',
      '9♥', '6♥', '4♥',
      'K♦', '8♦', '6♦', '2♦',
      '10♣', '9♣', '7♣', '3♣', '2♣'
    ],
    south: ['A♠', 'Q♠', '3♠',
      'A♥', '7♥', '5♥', '2♥',
      'A♦', '9♦', '5♦',
      'A♣', '6♣', '5♣'
    ],
    west: ['10♠', '8♠', '7♠', '5♠',
      'K♥', 'J♥', '10♥', '8♥',
      '10♦', '4♦', '3♦',
      'K♣', '4♣'
    ],
    north: ['K♠', 'J♠', '6♠', '4♠', '2♠',
      'Q♥', '3♥',
      'Q♦', 'J♦', '7♦',
      'Q♣', 'J♣', '8♣'
    ]
  },
};

const MichaelsCuebidDeal = {
  id: uuid.v4(),
  folderName: 'Overcalls',
  name: 'Michaels Cuebid Deal',
  dealerPosition: Coords.SOUTH,
  hands: {
    west: ['7♠', '6♠', '5♠',
      '8♥',
      '10♦', '8♦', '7♦', '2♦',
      'A♣', 'J♣', '6♣', '5♣', '4♣'
    ],
    north: ['4♠', '3♠', '2♠',
      '7♥', '6♥', '4♥',
      '6♦', '4♦', '3♦',
      'K♣', '10♣', '9♣', '8♣'
    ],
    east: ['A♠', 'K♠', 'Q♠', '9♠', '8♠',
      'K♥', 'Q♥', 'J♥', '9♥', '2♥',
      'K♦', '5♦',
      '7♣'
    ],
    south: ['J♠', '10♠',
      'A♥', '10♥', '5♥', '3♥',
      'A♦', 'Q♦', 'J♦', '9♦',
      'Q♣', '3♣', '2♣'
    ]
  },
};

const PassedOut = {
  id: uuid.v4(),
  folderName: 'Opening Bids',
  name: 'Passed Out',
  dealerPosition: Coords.SOUTH,
  hands: {
    west: '4♠ 3♠ 2♠ K♥ J♥ 7♥ 6♥ Q♦ 6♦ 4♦ K♣ Q♣ 10♣',
    north: '7♠ 5♠ 3♠ A♥ Q♥ 3♥ K♦ 9♦ 7♦ J♣ 6♣ 5♣ 4♣',
    east: 'K♠ Q♠ 6♠ 9♥ 8♥ 2♥ J♦ 5♦ 9♦ 3♦ K♣ 8♣ 7♣',
    south: 'A♠ 10♠ 9♠ 8♠ 10♥ 5♥ 4♥ A♦ 5♦ 2♦ 9♣ 3♣ 2♣',
  },
};

const TakeoutDoubleDeal = {
  id: uuid.v4(),
  folderName: 'Overcalls',
  name: 'Takeout Double Deal',
  dealerPosition: Coords.SOUTH,
  hands: {
    west: ['A♣', '10♣', 'J♣', '6♣',
      'A♦', '3♦', '9♦',
      'J♠', '3♠', '9♠', '10♠',
      'K♥', '2♥',
    ],
    north: ['4♣', '5♣', 'Q♣',
      '7♦', '5♦', 'J♦', 'K♦',
      '7♠', '6♠',
      '3♥', '4♥', '6♥', '9♥',
    ],
    east: ['7♣', '8♣', '9♣',
      '8♦', '4♦', 'Q♦',
      'Q♠', 'K♠', '4♠', '5♠', '8♠',
      'Q♥', '8♥',
    ],
    south: ['2♣', '3♣', 'K♣',
      '10♦', '6♦', '2♦',
      'A♠', '2♠',
      'A♥', 'J♥', '10♥', '7♥', '5♥',
    ],
  },
};

const NorthPreemptsThreeDiamonds = {
  id: uuid.v4(),
  name: 'North Preempts Three Diamonds',
  folderName: 'Three Level Preempts',
  dealerPosition: Coords.NORTH,
  hands: {
    west: 'Q♣ 9♣ 6♣ 3♦ A♥ Q♥ 8♥ 6♥ 3♥ K♠ J♠ 10♠ 2♠',
    north: '9♥ 5♥ 4♥ A♦ J♦ 10♦ 9♦ 8♦ 7♦ 6♦ 10♣ 3♣ 2♣',
    east: '9♠ 7♠ 4♠ K♥ 10♥ 7♥ 2♥ Q♦ 5♦ 4♦ J♣ 8♣ 5♣',
    south: 'A♠ Q♠ 8♠ 6♠ 5♠ 3♠ J♥ K♦ 2♦ A♣ K♣ 7♣ 4♣',
  },
  auction: 'N-3♦ E-P S-3♠ W-P N-4♦ E-P S-5♦ W-P N-P E-P',
};


const OpensOneSpadeWithTwelveHCP = {
  id: uuid.v4(),
  name: 'Opens One Spade with 12 HCP',
  folderName: 'Open One of a Major',
  dealer: 'West',
  hands: {
    west: '8♣ 3♣ K♦ 7♦ A♥ 9♥ 7♥ 2♥ A♠ J♠ 8♠ 7♠ 6♠',
    north: 'J♣ 6♣ A♦ Q♦ 10♦ 5♦ 2♦ 10♥ 8♥ 10♠ 9♠ 4♠ 2♠',
    east: 'A♣ 10♣ 9♣ 5♣ J♦ 8♦ 4♦ 4♥ 3♥ K♠ Q♠ 5♠ 3♠',
    south: 'K♣ Q♣ 7♣ 4♣ 2♣ 9♦ 6♦ 3♦ K♥ Q♥ J♥ 6♥ 5♥',
  },
  auction: 'W-1♠ N-P E-3♠ S-P W-P N-P',
};

export const PreloadedDeals = [
  UnknownStrain,
  EastPassesSlamTry,
  MichaelsCuebidDeal,
  NegativeDoubleDeal,
  NoTrumpSlamTryDeal,
  OpenOneSpade,
  PassedOut,
  TakeoutDoubleDeal,
  NorthPreemptsThreeDiamonds,
  OpensOneSpadeWithTwelveHCP,
  NorthPreemptsThreeClubsOvercall,
  SouthResponseWithEightSpades,
];


/**
const SLAM_DEAL = [
  ['Q♥', 'J♥', '9♥', '5♥', '2♥',
    '10♦', '9♦',
    'K♣', 'Q♣', '10♣', '9♣', '8♣', '2♣'
  ],
  ['A♠', '9♠', '7♠', '4♠', '3♠',
    'K♥', '8♥', '7♥', '6♥', '3♥',
    'A♦', '6♦',
    '7♣'
  ],
  ['Q♠', '8♠', '2♠',
    '10♥', '4♥',
    'Q♦', 'J♦', '8♦', '5♦', '4♦', '3♦', '2♦',
    'J♣'
  ],
  ['K♠', 'J♠', '10♠', '6♠', '5♠',
    'A♥',
    'K♦', '7♦',
    'A♣', '6♣', '5♣', '4♣', '3♣'
  ],
];
**************/
