import React from "react";
import PropTypes from 'prop-types';

import { Spring, config } from 'react-spring/renderprops';

import { constants as uic } from '../../constants';

import UIHelper from '../UIHelper';
import { DialogTitleBar } from '../components';

class SaveDealModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isVisible: true,
      id: this.props.id,
      dealName: this.props.dealName,
      folderName: this.props.folderName,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    const target = event.target;
    this.setState({
      [target.name]: target.value,
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.props.onSubmit(event, Object.assign({}, this.state));
  }

  render() {
    const dispProps = UIHelper.getScrollDisplaySpringProps(this.state.isVisible, -500, config.gentle);

    return (
      <Spring
        from={dispProps.from}
        to={dispProps.to}
        config={dispProps.config}
      >
      {props => (
        <div style={props}>

          <div id="save-deal-modal" className="main-component__container ">
            <DialogTitleBar title="Save Deal" onClose={this.props.onClose} />
            <div className="form-div">
              <p>Saves this deal to the Saved Deals menu</p>
            </div>
            <div className="form-div">
              <form id="save-deal-form" onSubmit={this.handleSubmit}>
                <fieldset>
                  <p>
                    <label>Menu Folder Name</label>
                      <input
                        name="folderName"
                        id="folderName"
                        type="text"
                        placeholder={uic.ENTER_FOLDER_NAME_LABEL}
                        onChange={this.handleChange}
                        defaultValue={this.state.folderName} required minLength="6"></input></p>
                  <p>
                    <label>Deal Name</label>
                      <input
                        name="dealName"
                        id="dealName"
                        type="text"
                        onChange={this.handleChange}
                        defaultValue={this.state.dealName} required minLength="6"></input></p>
              </fieldset>
              <input type="submit" className="button-primary bridge-button" value="Save"></input>
            </form>
          </div>
        </div>
      </div>
      )}
      </Spring>
    );
  }
}

SaveDealModal.propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  folderName: PropTypes.string,
  dealName: PropTypes.string,
  id: PropTypes.string,
};

export default SaveDealModal;
