import React from 'react';
import PropTypes from 'prop-types';

import { constants as uic } from '../../constants';

export const BridgeBlvdTitle = function BridgeBlvdTitle({ subTitle }) {
  return (
    <div className="bridgeblvd-title">
      <span style={{color: 'var(--spade)'}}>{uic.BRIDGE_TITLE_LABEL}</span>&nbsp;
      <span style={{color: 'var(--club)'}}>{uic.BLVD_TITLE_LABEL}</span>
      { subTitle
        ? <span className="bridgeblvd-subtitle">&nbsp;-&nbsp;{subTitle}</span>
        : ''
      }
    </div>
  );
};
BridgeBlvdTitle.propTypes = {
  subTitle: PropTypes.string,
};
