import { DATA_DEFINITION_PREFIX } from '../../constants';

export const TRUMP_FIT = 'bid-options/opener-rebid-after-neg-dbl/types/rebid-original-suit';

export const REBID_ORIGINAL_SUIT = 'bid-options/opener-rebid-after-neg-dbl//types/rebid-original-suit';

export const BID_NO_TRUMP_STOPPER_IN_ENEMY_SUIT = 'bid-options/opener-rebid-after-neg-dbl/types/bid-no-trump-with-stopper';

export const NATURAL_NEW_SUIT = 'bid-options/opener-rebid-after-neg-dbl//types/bid-new-suit';

export const CUEBID = 'bid-options/opener-rebid-after-neg-dbl//types/cue-bid';

export const SHORT_FIT = 'bid-options/opener-rebid-after-neg-dbl//types/short-fit';

export const DEF_TRUMP_FIT = DATA_DEFINITION_PREFIX+'bid-options/opener-rebid-after-neg-dbl/types/rebid-original-suit';

export const DEF_REBID_ORIGINAL_SUIT = DATA_DEFINITION_PREFIX+'bid-options/opener-rebid-after-neg-dbl//types/rebid-original-suit';

export const DEF_BID_NO_TRUMP_STOPPER_IN_ENEMY_SUIT = DATA_DEFINITION_PREFIX+'bid-options/opener-rebid-after-neg-dbl/types/bid-no-trump-with-stopper';

export const DEF_NATURAL_NEW_SUIT = DATA_DEFINITION_PREFIX+'bid-options/opener-rebid-after-neg-dbl//types/bid-new-suit';

export const DEF_CUEBID = DATA_DEFINITION_PREFIX+'bid-options/opener-rebid-after-neg-dbl//types/cue-bid';

export const DEF_SHORT_FIT = DATA_DEFINITION_PREFIX+'bid-options/opener-rebid-after-neg-dbl//types/short-fit';
