import { constants as c } from '../../../constants';
import { SIMPLE, JUMP, GAME } from '../constants';

import {
  NO_FIT_BID_NEW_FIVE_CARD_SUIT,
} from './types';

export const NO_TRUMP_ADVANCE_RANGES = 'bid-options/overcall-advance-options/no-trump-advance-ranges';
export const PASS_RANGE = 'bid-options/overcall-advance-options/bid-pass-range';
export const SUIT_ADVANCE_RANGES = 'bid-options/overcall-advance-options/suit-advance-ranges';

export const OVERCALL_ADVANCE_RANGES = 'bid-options/overcall-advance-options/overcall-advance-ranges';

export const createRanges = () => ({
  [ NO_FIT_BID_NEW_FIVE_CARD_SUIT ]: {
    lower: 9,
  },
  [ PASS_RANGE ]: {
    lower: 0,
    upper: 7,
  },
  [ SUIT_ADVANCE_RANGES ]: {
    [ SIMPLE ]: {
      level: c.TWO_LEVEL,
      range: {
        lower: 7,
        upper: 11,
      },
      forcing: false,
    },
    [ JUMP ]: {
      level: c.THREE_LEVEL,
      range: {
        lower: 12,
        upper: 14,
      },
      forcing: false,
    },
    [ GAME ]: {
      level: c.FOUR_LEVEL,
      range: {
        lower: 15,
      },
      forcing: true,
    },
  },
  [ NO_TRUMP_ADVANCE_RANGES ]: {
    [ SIMPLE ]: {
      level: c.ONE_LEVEL,
      range: {
        lower: 8,
        upper: 10,
      },
    },
    [ JUMP ]: {
      level: c.TWO_LEVEL,
      range: {
        lower: 11,
        upper: 12,
      },
    },
    [ GAME ]: {
      level: c.THREE_LEVEL,
      range: {
        lower: 13,
      },
    },
  },
});
