import { createBidOptions } from '../create-bid-options';

import {
  TRUMP_FIT,
  REBID_ORIGINAL_SUIT,
  BID_NO_TRUMP_STOPPER_IN_ENEMY_SUIT,
  NATURAL_NEW_SUIT,
  CUEBID,
  SHORT_FIT,
} from './types';

import { createLabels } from './labels';

const getOptions = function getOptions(/*bidSystemConfig*/) {
  return createBidOptions([
    TRUMP_FIT,
    REBID_ORIGINAL_SUIT,
    BID_NO_TRUMP_STOPPER_IN_ENEMY_SUIT,
    NATURAL_NEW_SUIT,
    CUEBID,
    SHORT_FIT,
  ]);
};

export {
  TRUMP_FIT,
  REBID_ORIGINAL_SUIT,
  BID_NO_TRUMP_STOPPER_IN_ENEMY_SUIT,
  NATURAL_NEW_SUIT,
  CUEBID,
  SHORT_FIT,
  createLabels,
  getOptions,
};
