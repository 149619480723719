const InviteRange = {
  lower: 8,
};

const GameRange = {
  lower: 10,
};

export const JACOBY_TRANSFER_REBID_RANGES = 'bid-options/JACOBY_TRANSFER_REBID_RANGES';
export const INVITE_RANGE = 'bid-options/ranges/INVITE_RANGE';
export const GAME_RANGE = 'bid-options/ranges/GAME_RANGE';
export const createRanges = () => ({
  [ INVITE_RANGE ]: InviteRange,
  [ GAME_RANGE ]: GameRange,
});
