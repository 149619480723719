import * as R from 'ramda';

import { constants as c } from './constants';
import { PointRangeHelper } from './bid-system';
import Suit, { getIsStrainHigher } from './suit';

class Hand {
  constructor(player) {
    this.clubs = new Suit(c.CLUB);
    this.diamonds = new Suit(c.DIAMOND);
    this.hearts = new Suit(c.HEART);
    this.spades = new Suit(c.SPADE);
    this.suits = [ this.clubs, this.diamonds, this.hearts, this.spades ];
    this.player = player;
  }

  getClone() {
    const clone = new Hand(this.player);
    this.suits.forEach(suit =>
      suit.cards.forEach(card => clone.addCard(card))
    );
    return clone;
  }

  addCard(card) {
    this.getSuit(card.strain).add(card);
  }

  sortHand() {
    this.suits.forEach(suit => suit.sort());
  }

  getLengthPoints() {
    return this.suits.reduce((cnt, suit) => {
        return cnt + (suit.getLength() >= c.FIVE_CARD_SUIT
          ? suit.getLength() - c.FOUR_CARD_SUIT
          : 0);
    }, 0);
  }

  getTotalPointsLength() {
    return this.getHighCardPoints() + this.getLengthPoints();
  }

  getTotalPoints(strain) {
    if (!strain) {
      return this.getHighCardPoints();
    }
    if (strain === c.NO_TRUMP) {
      return this.getHighCardPoints()
        - (!this.getHasFiveCardSuit() || this.getIsFourThreeThreeThree()
          ? 1
          : 0);
    }
    return this.suits.reduce((cnt, suit) => {
        cnt += suit.getTotalPoints();
        if (suit.strain === strain && suit.getLength() > c.FIVE_CARD_SUIT) {
          cnt += (suit.getLength() - c.FIVE_CARD_SUIT);
        }
        return cnt;
    }, 0);
  }

  getIsFourThreeThreeThree() {
    return this.suits.reduce((acc, suit) =>
      suit.getLength() === c.THREE_CARD_SUIT
        ? [...acc, suit]
        : [...acc], []).length === 3;
  }

  getLosingTrickCount(trumpStrain) {
    return this.suits.reduce((cnt, suit) =>
      cnt + suit.getLosingTrickCount(trumpStrain === suit.strain), 0);
  }

  getQuickTrickCount() {
    return this.suits.reduce((cnt, suit) =>
      cnt + suit.getQuickTrickCount(), 0);
  }

  getPlayingTrickCount(strain) {
    return this.suits.reduce((cnt, suit) =>
       cnt + suit.getPlayingTrickCount(strain), 0);
  }

  getIsTwoSuited() {
    const suits = this.getTwoLongestSuits();
    return suits.every(suit => suit.getLength() >= c.FOUR_CARD_SUIT)
      && suits.some(suit => suit.getLength() >= c.FIVE_CARD_SUIT);
  }

  sortSuitsByLength(suits) {
    return suits.sort((a, b) =>
      a.getLength() >= b.getLength()
        ? -1
        : 1);
  }

  getTwoLongestSuits() {
    return this.sortSuitsByLength(this.suits)
      .slice(0, 2);
  }

  getHasMajors({
    suitLength1 = c.FOUR_CARD_SUIT,
    suitLength2 = suitLength1
  }) {
    return (majors => majors.length === 2
      && majors.some(suit => suit.getLength() >= suitLength2)
    )(this.getMajors(suitLength1));
  }

  getTwoLongestSuitsLength() {
    return this.getTwoLongestSuits()
      .reduce((acc, suit) => suit.getLength() + acc, 0);
  }

  getHasTwoLongSuits() {
    return this.getTwoLongestSuitsLength() >= 10
      && this.getIsTwoSuited();
  }

  getIsDistributional() {
    return this.getHasSingletonOrVoid();
  }

  /**
  * @method
  * @return length of two longest suits + hcp greater eq 20
  */
  getHasRuleOfTwenty() {
    return this.getTwoLongestSuitsLength()
      + this.getHighCardPoints() >= 20;
  }

  getMajors(suitLength = 0) {
    return this.suits.filter(suit =>
      suit.getIsMajor() && suit.getLength() >= suitLength);
  }

  getMinors(suitLength) {
    return this.suits.filter(suit =>
      suit.getIsMinor() && suit.getLength() >= suitLength);
  }

  getHasTwoFiveCardSuits() {
    return this.suits.filter(suit =>
      suit.getLength() >= c.FIVE_CARD_SUIT).length === 2;
  }

  getFiveCardMajorStrain() {
    const suits = this.getMajors(c.FIVE_CARD_SUIT);
    return suits.length > 1
      ? c.HEART
      : suits[0].strain;
  }

  getHasFiveCardSuit() {
    return this.suits.some(suit => suit.getLength() >= c.FIVE_CARD_SUIT);
  }

  getHasSixCardMinor() {
    return this.suits.some(suit =>
      suit.getIsMinor() && suit.getLength() >= c.SIX_CARD_SUIT);
  }

  getSixCardSuit() {
    return (suit => suit
      ? suit
      : Suit.getVoidSuit()
    )(this.suits.find(suit => suit.getLength() >= c.SIX_CARD_SUIT));
  }

  getIsSemiBalanced() {
    return !this.getHasSingletonOrVoid()
      && this.getDoubletonSuits().length <= 2;
  }
  getIsBalanced() {
    // at most one doubleton
    if (this.getHasControlSuits()) {
      return false;
    }
    return this.clubs.getLength() >= c.TWO_CARD_SUIT
      && this.diamonds.getLength() >= c.TWO_CARD_SUIT
      && this.hearts.getLength() >= c.TWO_CARD_SUIT
      && this.spades.getLength() >= c.TWO_CARD_SUIT;
  }

  getIsNegative() {
    return this.getHighCardPoints() <= 7;
  }

  getTotalPointsWithDistribution() {
    return this.suits.reduce((pts, suit) => pts + suit.getTotalPoints(), 0);
  }

  getBadPoints() {
    return this.suits.reduce((pts, suit) => pts + suit.getBadPoints(), 0);
  }

  getHighCardPoints() {
    return this.suits.reduce((pts, suit) => pts + suit.getHighCardPoints(), 0);
  }

  getHasLongStrongSuit(suitLength) {
    return !this.getLongStrongSuit(suitLength).getIsVoid();
  }

  getHasStrongFiveCardSuit() {
    return this.getHasLongStrongSuit(c.FIVE_CARD_SUIT);
  }

  getCardCount(cardValue) {
    return this.suits.filter(suit =>
      (suit.cards.filter(c => c.value === cardValue)).length > 0).length;
  }

  getAceCount() {
    return this.getCardCount(c.ACE);
  }

  getKingCount() {
    return this.getCardCount(c.KING);
  }

  getOutSideKingSuit(strain) {
    return this.getOutSideCardSuit(strain, c.KING);
  }

  getOutSideAceSuit(strain) {
    return this.getOutSideCardSuit(strain, c.ACE);
  }

  getHasOutsideAceOrKing(strain) {
    return this.getOutSideAceSuit(strain)
      || this.getOutSideKingSuit(strain);
  }

  getOutSideCardSuit(strain, cardValue) {
    return this.suits.find(suit => suit.strain !== strain
      && suit.getHasCard(cardValue));
  }

  getOpeningPoints() {
    let hcp = this.getHighCardPoints();
    if (this.getIsRespondMinimal()) {
      hcp += (this.getHasStrongFiveCardSuit() ? 1 : 0);
    } else {
      hcp -= (this.getAceCount() === 0 ? 1 : 0);
    }
    return hcp;
  }

  getPointsForRuleOfFifteen() {
    return this.getHighCardPoints() + this.spades.getLength();
  }

  getHasPearsonPoints() {
    return this.getPointsForRuleOfFifteen() >= c.PEARSON_POINTS;
  }

  getVoidSuits() {
    return this.suits.filter(suit => suit.getIsVoid());
  }

  getSingletonSuits() {
    return this.suits.filter(suit => suit.getIsSingleton());
  }

  getDoubletonSuits() {
    return this.suits.filter(suit => suit.getLength() === c.TWO_CARD_SUIT);
  }

  getHasSingletonOrVoid() {
    return this.getSingletonSuits().length > 0
      || this.getVoidSuits().length > 0;
  }

  getHasControlSuits() {
    return this.getHasSingletonOrVoid()
      || this.getDoubletonSuits().length > 1;
  }

  /**
  * @param {number} suitLength
  * @return {Array} contains all suits in hand with greater or equal suitLength
  */
  getSuits(suitLength) {
    return this.suits.filter(suit => suit.getLength() >= suitLength);
  }

  getExceptionalSuit() {
    return this.suits.find(suit => suit.getIsExceptional())
      || Suit.getVoidSuit();
  }

  getIsOneSuitedStrong() {
    const suit = (() => {
      const suit = this.getExceptionalSuit();
      if (!suit.getIsVoid()) {
        return suit;
      }
      return this.suits.find(suit => (suit.getLength() >= c.SIX_CARD_SUIT
        && (suit.getHighCardPoints() >= 7
          || (suit.getHighCardPoints() >= 5
            && suit.getHasHighSpotCards())))
        || (suit.getLength() >= c.SEVEN_CARD_SUIT && suit.getHighCardPoints() >= 5)) || Suit.getVoidSuit();
    })();

    const sideSuit = this.getSideSuit(suit.strain);
    if (!sideSuit.getIsVoid()) {
      return false;
    }

    if (this.getHasTwoLongSuits() && sideSuit.getIsMajor()) {
      return false;
    }

    return (this.getHighCardPoints() - suit.getHighCardPoints()) <= 5;
  }

  getLongestSuit(strain, suitLength = c.FOUR_CARD_SUIT) {
    // filter the strain
    const longSuits = this.getSuits(suitLength).filter(
      suit => suit.strain !== strain);

    return getLongestSuitIn(longSuits);
  }

  getHasStoppersInSuit(strain) {
    if (strain === c.NO_TRUMP) {
      return false;
    }
    return this.getSuit(strain).getHasStoppers();
  }

  getSuitsSortedForBid({ suitLength, sortMethod, strainFilter }) {
    if (!strainFilter) {
      strainFilter = [];
    }
    const suits = this.suits.filter(suit => suit.getLength() >= suitLength)
      .filter(suit => !strainFilter.some(strain => strain === suit.strain));

    return sortMethod(suits, this);
  }

  getLongStrongSuit(suitLength, filterStrains = []) {
    const longSuits = this.getSuits(suitLength)
      .filter(suit => !filterStrains.find(strain => suit.strain === strain));

    if (longSuits.length === 1) {
      if (longSuits[0].getHasStoppers()) {
        return longSuits[0];
      }
    }

    if (longSuits.length > 1) {
      const a = longSuits[0];
      const b = longSuits[1];
      if (a.getHasStoppers() && b.getHasStoppers()) {

        if (getIsStrainHigher(a, b)) {
          return b;
        } else {
          return a;
        }
      } else {
        if (a.getHasStoppers()) {
          return a;
        }
        if (b.getHasStoppers()) {
          return b;
        }
      }
    }
    return Suit.getVoidSuit();
  }

  getHasFirstRoundControl(strain) {
    const suit = this.getSuit(strain);
    return suit.getIsFirstRoundControl();
  }

  getHasSecondRoundControl(strain) {
    const suit = this.getSuit(strain);
    return suit.getIsSecondRoundControl();
  }

  getIsBalancedWithSuit(strain) {
    if (this.getIsBalanced()) {
      return true;
    }
    // determine if your short suit matches you partners bid
    const suits = this.suits.filter(
      suit => suit.getLength() <= c.TWO_CARD_SUIT).filter(
      suit => suit.strain !== strain);
    return suits.length === 0;
  }

  getPointRange(strain) {
    if (this.getIsSubMinimum(strain)) {
      return {
        lower: 0,
        upper: this.getMinimalPointRange().lower -1,
      };
    }
    if (this.getIsMinimal(strain)) {
      return this.getMinimalPointRange();
    }
    if (this.getIsInvitational(strain)) {
      return this.getInvitiationalPointRange();
    }
    return this.getForcingPointRange(strain);
  }

  getIsInRangeWithStrain(strain, range) {
    return PointRangeHelper.getInRange(this.getTotalPoints(strain), range);
  }

  getIsSubMinimum(strain) {
    return this.getTotalPoints(strain) < this.getMinimalPointRange().lower;
  }

  getIsMinimal(strain) {
    return this.getIsInRangeWithStrain(strain, this.getMinimalPointRange());
  }

  getIsRespondMinimal(strain) {
    return !(this.getIsInvitational(strain)
      || this.getIsForcing(strain));
  }

  getIsInvitational(strain) {
    const range = this.getInvitiationalPointRange();
    return this.getIsInRangeWithStrain(strain, range);
  }

  getMinimalPointRange() {
    return PointRangeHelper.getMinimalPointRangeFor(this.player);
  }

  getInvitiationalPointRange() {
    return PointRangeHelper.getInvitationalPointRangeFor(this.player);
  }

  getForcingPointRange() {
    return PointRangeHelper.getForcingPointRangeFor(this.player);
  }

  getIsForcing(strain) {
    const pts = this.getTotalPoints(strain);
    const range = this.getForcingPointRange();
    return pts >= range.lower;
  }

  getIsTrumpFit(strain, cnt, adjustment = 0) {
    const suit = this.getSuit(strain);
    if (suit.getIsVoid()) {
      return false;
    }
    return suit.getLength() + cnt >= c.TRUMP_FIT_COUNT + adjustment;
  }

  getSuit(strain) {
    if (R.isNil(strain)
      || strain === c.VOID_SUIT
      || strain === c.NO_TRUMP
      || strain === c.DOUBLE_STRAIN
    ) {
      // will have card length zero
      return Suit.getVoidSuit();
    }
    return this.suits.find(suit => suit.strain === strain);
  }

  getSuitLength(strain) {
    const suit = this.getSuit(strain);
    return suit.getLength();
  }

  getValueScore(strain, suitLength) {
    const suit = this.getSuit(strain);
    const sideSuit = this.getSideSuit(strain);
    return (suit.getIsGood(suitLength)
      ? c.SUIT_VALUES_ENUM.Good : 0) + (suit.getLength() > suitLength
        ? c.SUIT_VALUES_ENUM.ExtraLength : 0) + (!sideSuit.getIsVoid()
          ? c.SUIT_VALUES_ENUM.SideSuit : 0);
  }

  getSideSuit(strain) {
    const sideSuit = sortSuitsForBid(this.getSuits(c.FOUR_CARD_SUIT))
      .find(suit => suit.strain !== strain
        && suit.getIsGood()
      );

    return sideSuit || Suit.getVoidSuit();
  }

  getHasGoodSuit() {
    return this.suits.some(suit => suit.getIsGood(c.FIVE_CARD_SUIT));
  }

  getFlatSuits() {
    return this.suits.flatMap(suit =>
      suit.cards.map(card => card.toString()));
  }

  getExport() {
    return {
      suits: [this.spades.getExport(), this.hearts.getExport(), this.diamonds.getExport(), this.clubs.getExport()],
      totalPointsShort: this.getTotalPointsWithDistribution(),
      totalPointsLong: this.getTotalPointsLength(),
      hcp: this.getHighCardPoints()
    };
  }

  toJSON() {
    if (this.spades.getIsVoid()
      && this.hearts.getIsVoid()
      && this.diamonds.getIsVoid()
      && this.clubs.getIsVoid()
    ) {
      return;
    }
    return JSON.stringify(this.getExport());
  }

  toString() {
    return ` ${this.clubs} <br> ${this.diamonds} <br> ${this.hearts} <br> ${this.spades}`;
  }
}

const getStrongerSuit = function getStrongerSuit(suit1, suit2) {
  const result = suit1.compare(suit2);
  if (result < 0) {
    return suit1;
  } else if (result > 0) {
    return suit2;
  } else if (result === 0) {
    if (suit1.getLength() > suit2.getLength()) {
      return suit1;
    } else if (suit2.getLength() > suit1.getLength()) {
      return suit2;
    }
  }
  return suit1;
};

const getLongestSuitIn = function getLongestSuitIn(suits) {
  suits.sort((a, b) => {
    if (a.getLength() > b.getLength()) {
      return -1;
    } else if (a.getLength() < b.getLength()) {
      return 1;
    }
    if ((a.getIsMajor() && b.getIsMajor())
     || (a.getIsMinor() && b.getIsMinor())
    ) {
      return getIsStrainHigher(a.strain, b.strain)
        ? -1
        : 1;
    }
    return getIsStrainHigher(a.strain, b.strain)
      ? 1
      : -1;
  });
  return suits[0];
};

const sortSuitsForBid = function sortSuitsForBid(suits, partnersBid) {
  return suits.sort((a, b) => {
    let result = 0;

    if (partnersBid && partnersBid.isMajor) {
      if (a.getIsMajor() && a.strain === partnersBid.strain) {
        result = -1;
      } else if (b.getIsMajor() && b.strain === partnersBid.strain) {
        result = 1;
      }
    }

    if (result === 0 && a.getIsGood(c.SIX_CARD_SUIT)) {
      if (b.getIsGood(c.SIX_CARD_SUIT)) {
        result = b.getLength() - a.getLength();
      } else {
        result = -1;
      }
    }

    if (result === 0) {
      if (a.getIsMajor()) {
        if (b.getIsMajor()) {
          result = 0;
        } else {
          result = -1;
        }
      } else if (a.getIsMinor()) {
        if (b.getIsMinor()) {
          result = 0;
        } else if (b.getIsMajor()) {
          result = 1;
        }
      }
    }

    if (result === 0) {
      result = b.getLength() - a.getLength();
    }

    if (result !== 0) {
      return result;
    }

    if (c.BID_LADDER.indexOf(a.strain) > c.BID_LADDER.indexOf(b.strain)) {
      return -1;
    }

    return 1;
  });
};

const getHasFiveCardMajor = function getHasFiveCardMajor(hand) {
  return hand.getMajors(c.FIVE_CARD_SUIT).length > 0;
};

const getHasTwoFourCardMajors = function getHasTwoFourCardMajors(hand) {
  return hand.getMajors(c.FOUR_CARD_SUIT).length === 0;
};

const getHasFourCardMajor = function getHasFourCardMajor(hand) {
  return hand.getMajors(c.FOUR_CARD_SUIT).length > 0;
}

export {
  Hand,
  getStrongerSuit,
  getHasFiveCardMajor,
  getHasTwoFourCardMajors,
  getHasFourCardMajor,
  getLongestSuitIn,
  sortSuitsForBid,
 };
