import { createBidOptions } from '../create-bid-options';

import {
  JACOBY_ACCEPT_RANGES,
  SUPER_ACCEPT_LENGTH,
  getSuperAcceptRange,
  createRanges,
} from './ranges';

import {
  JACOBY_TRANSFER_COMPLETE,
  JACOBY_TRANSFER_SUPER_COMPLETE,
} from './types';

import { createLabels } from './labels';

const getOptions = function getOptions() {
  return createBidOptions([
    JACOBY_TRANSFER_COMPLETE,
    JACOBY_TRANSFER_SUPER_COMPLETE,
  ]);
};

export {
  JACOBY_TRANSFER_COMPLETE,
  JACOBY_TRANSFER_SUPER_COMPLETE,
  JACOBY_ACCEPT_RANGES,
  SUPER_ACCEPT_LENGTH,
  createRanges,
  getSuperAcceptRange,
  createLabels,
  getOptions,
};
