import React  from 'react';
import PropTypes from 'prop-types';

import { constants as uic } from '../../constants';

import { BidOptionsViewer } from '../guide-components';

import { DialogTitleBar } from '../components';

const BidOptionsViewerModal = function BidOptionsViewerModal({
  Labels,
  auctionContext,
  bidValue,
  onClose
}) {
  return (
    <div id="bid-options-modal" className="main-component__container">
      <DialogTitleBar title={uic.BID_OPTIONS_LABEL} onClose={onClose} />
      <BidOptionsViewer
        contextType={auctionContext.type}
        bidOptions={auctionContext.bidOptions}
        bidValue={bidValue}
      />
    </div>
  );
}
BidOptionsViewerModal.propTypes = {
  auctionContext: PropTypes.object,
  bidValue: PropTypes.string,
  onClose: PropTypes.func,
};


export default BidOptionsViewerModal;
