import GamePlayOptions from './GamePlayOptions.js';
import { BridgeBlvdTitleWithSuits } from './BridgeBlvdTitleWithSuits';
import React from 'react';
import PropTypes from 'prop-types';

import { Spring, config, animated } from 'react-spring/renderprops';

class LandingCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dealType: props.gameOptions.dealType,
      bidSystem: props.gameOptions.bidSystem,
      bidType: props.gameOptions.bidType,
      playType: props.gameOptions.playType,
    };
  }

  handleChange = e => {
    const target = e.target;
    this.setState({
      [target.name]: target.value,
    });
  }

  onStartClick = e => {
    e.preventDefault();
    this.props.onStartSession(Object.assign({}, this.state));
  }

  render() {
    return (
      <Spring
        native
        from={{ opacity: 0, marginTop: -1250 }}
        to={{ opacity: 1, marginTop: 0 }}
        config={config.slow}
      >
      {props => (
        <animated.div style={props}>
          <div className="landing-page__card  flex-container main-component__container">
            <BridgeBlvdTitleWithSuits />
            <div className="landing-page__practice-options">
              <GamePlayOptions
                handleChange={this.handleChange}
                {...this.state}
              />
              <div className="button-center_div">
                <button
                  autoFocus
                  id="icon-start-game"
                  onClick={this.onStartClick}
                  className="bridge-button button-center"
                  >Go<span>&rarr;</span></button>
              </div>
            </div>
          </div>
        </animated.div>
      )}
      </Spring>
    );
  }
}

LandingCard.propTypes = {
  gameOptions: PropTypes.object,
  onStartSession: PropTypes.func,
};

export { LandingCard };
