import * as c from './constants';
import { PresenterEvents } from './event-types';
import { Images } from './images';


export const CLOSE = {
  id: 'close',
  imgSrc: Images.CLOSEL_SVG,
  label: c.CLOSE_LABEL,
};
export const DEAL = {
  id: PresenterEvents.DEAL_ACTION,
  imgSrc: Images.DEAL_SVG,
  label: c.DEAL_CARDS_LABEL,
};
export const DEAL_DRAWER = {
  id: PresenterEvents.DEAL_ACTION,
  imgSrc: Images.ARCHIVE_SVG,
  label: c.DEALS_MENU_LABEL,
};
export const DELETE = {
  id: PresenterEvents.DELETE_CURRENT_DEAL,
  imgSrc: Images.DELETE_SVG,
  label: 'Delete',
};
export const DOWN_ARROW = {
  id: PresenterEvents.SCROLL_DOWN,
  imgSrc: Images.RIGHT_ARROW_SVG,
  rotate:'90deg',
  label: 'Down',
};
export const EXPORT_DEAL = {
  id: PresenterEvents.EXPORT_CURRENT_DEAL,
  imgSrc: Images.EXPORT_SVG,
  label: c.EXPORT_DEAL_LABEL,
};
export const IMPORT_DEAL = {
  id: PresenterEvents.IMPORT_DEAL,
  imgSrc: Images.IMPORT_SVG,
  label: 'Import',
};
export const BURGER = {
  id: 'burger-menu',
  imgSrc: Images.BURGER_MENU_SVG,
  label: 'Open menu',
};
export const PLAYER = {
  id: 'icon-player',
  imgSrc: Images.PLAYER_SVG,
  label: 'Player',
};
export const SAVE = {
  id: PresenterEvents.SAVE_CURRENT_DEAL,
  imgSrc: Images.SAVE_SVG,
  label: c.SAVE_LABEL,
};
export const SETTINGS = {
  id: PresenterEvents.SHOW_SETTINGS_PAGE,
  imgSrc: Images.SETTINGS_SVG,
  label: c.SHOW_SETTINGS_LABEL,
};
export const SHOW_AUCTION = {
  id: PresenterEvents.SHOW_AUCTION_ACTION,
  imgSrc: Images.AUCTION_SVG,
  label: c.SHOW_AUCTION_LABEL,
};
export const SHOW_CARDS = {
  id: PresenterEvents.SHOW_CARDS_ACTION,
  imgSrc: Images.SHOW_CARDS_SVG,
  label: c.TOGGLE_CARDS_LABEL,
};
export const SHOW_ABOUT = {
  id: PresenterEvents.SHOW_ABOUT_PAGE,
  imgSrc: Images.HINT_SVG,
  label: c.SHOW_ABOUT_LABEL,
};
export const SHOW_HINT = {
  id: PresenterEvents.SHOW_HINT_ACTION,
  imgSrc: Images.HINT_SVG,
  label: c.TOGGLE_HINT_LABEL,
};
export const SHOW_POINTS = {
  id: PresenterEvents.SHOW_POINTS_ACTION,
  imgSrc: Images.POINTS_SVG,
  label: c.TOGGLE_PLAYER_POINTS_LABEL,
};
export const TALLY_VIEW = {
  id: PresenterEvents.SHOW_TALLY_VIEW,
  imgSrc: Images.ABACUS_SVG,
  label: c.VIEW_TALLY_LABEL,
};
export const TRICK_VIEW = {
  id: PresenterEvents.SHOW_TRICK_VIEW,
  label: c.VIEW_TRICKS_LABEL,
  imgSrc: Images.FOUR_CARDS_SVG,
};
export const TEST = {
  id: PresenterEvents.RUN_TESTS,
  imgSrc: Images.TEST_SVG,
  label: c.TEST_LABEL,
};
export const UP_ARROW = {
  id: PresenterEvents.SCROLL_UP,
  imgSrc: Images.RIGHT_ARROW_SVG,
  rotate:'-90deg',
  label: 'Up',
};
