import React from 'react';
import PropTypes from 'prop-types';

import { SelectLayout } from './SelectLayout';

const BidContextSelect = function BidContextSelect({
  label,
  onChange,
  Labels,
  contexts
}) {
  const getSelect = () => (
    <select className="select-css" onChange={onChange}>
      {contexts.map(type => (
        <option value={type}>{Labels[ type ]}</option>
      ))}
    </select>
  );

  return (
    <SelectLayout
      containerClass={''}
      displayLabels={true}
      canEdit={true}
      labelFor={'bidContext'}
      label={label}
      getSelect={getSelect}
    />
  );
};
BidContextSelect.propTypes = {
  canEdit: PropTypes.bool,
  containerClass: PropTypes.string,
  bidSystem: PropTypes.string,
  displayLabels: PropTypes.bool,
  handleChange: PropTypes.func,
  label: PropTypes.string,
  isFocus: PropTypes.bool,
};
BidContextSelect.defaultProps = {
  canEdit: true,
  isFocus: false,
};

export { BidContextSelect };
