import { constants as c, Tags } from '../constants';
import * as BidTypes from './bid-types';

import { has } from 'ramda';

const lookup = {
  [ BidTypes.ADD_DISTRIBUTION_POINTS_FOR_TRUMP_FIT ]: {
    label: c.ADD_DISTRIBUTION_POINTS_FOR_TRUMP_FIT_LABEL,
  },
  [ BidTypes.ASK_FOR_ACES ]: {
    isArtificial: true,
    label: c.ASK_FOR_ACES_LABEL,
  },
  [ BidTypes.BLACKWOOD_ASKED_FOR_ACES ]: {
    isArtificial: true,
    label: c.ASKED_FOR_ACES_LABEL,
  },
  [ BidTypes.GERBER_ASKED_FOR_ACES ]: {
    isArtificial: true,
    label: c.ASKED_FOR_ACES_LABEL,
  },
  [ BidTypes.ASK_FOR_KINGS ]: {
    isArtificial: true,
    label: c.ASK_FOR_KINGS_LABEL,
  },
  [ BidTypes.BLACKWOOD_ASKED_FOR_KINGS ]: {
    isArtificial: true,
    label: c.ASKED_FOR_KINGS_LABEL,
  },
  [ BidTypes.GERBER_ASKED_FOR_KINGS ]: {
    isArtificial: true,
    label: c.ASKED_FOR_KINGS_LABEL,
  },
  [ BidTypes.ASTRO_REBID ]: {
    label: c.ASTRO_REBID_LABEL,
  },
  [ BidTypes.ASTRO_TWO_CLUBS ]: {
    isArtificial: true,
    label: c.ASTRO_TWO_CLUBS_LABEL,
  },
  [ BidTypes.ASTRO_TWO_DIAMONDS ]: {
    isArtificial: true,
    label: c.ASTRO_TWO_DIAMONDS_LABEL,
  },
  [ BidTypes.ASTRO_FORCING_RESPONSE ]: {
    isArtificial: true,
    label: c.ASTRO_FORCING_RESPONSE,
  },
  [ BidTypes.ASTRO_RESPONSE_PREFERS_OTHER_SUIT]: {
    isArtificial: true,
    label: c.ASTRO_RESPONSE_PREFERS_OTHER_SUIT_LABEL,
  },
  [ BidTypes.ASTRO_RESPONSE_SIX_SPADES ]: {
    label: c.ASTRO_RESPONSE_SIX_SPADES_LABEL,
  },
  [ BidTypes.ASTRO_RESPONSE]: {
    label: c.ASTRO_RESPONSE_LABEL,
  },
  [ BidTypes.BALANCED_HAND ]: {
    type: c.BALANCED_HAND_LABEL,
  },
  [ BidTypes.BLACKWOOD_FOR_ACES ]: {
    isArtificial: true,
    label: c.BLACKWOOD_FOR_ACES_LABEL,
    tag: Tags.BLACKWOOD,
  },
  [ BidTypes.CARD_COUNT ]: {
    //
  },
  [ BidTypes.CHOOSE_ONE_OF_PARTNERS_SUITS ]: {
    label: c.CHOOSE_ONE_OF_PARTNERS_SUITS_LABEL,
    isForcing: true,
  },
  [ BidTypes.COMPETITIVE_RESPONSE_TWO_FIVE_CARD_SUITS_WITH_PLAYING_STRENGTH ]: {
    label: c.COMPETITIVE_RESPONSE_TWO_FIVE_CARD_SUITS_WITH_PLAYING_STRENGTH_LABEL,
  },
  [ BidTypes.CUEBID_AFTER_NT_OPEN_OVERCALLED ]: {
    label: c.CUEBID_AFTER_NT_OPEN_OVERCALLED,
  },
  [ BidTypes.DEDUCT_HIGH_CARD_POINT ]: {
    label: c.DEDUCUCT_HIGH_CARD_POINT_LABEL,
  },
  [ BidTypes.DENY_FOUR_CARD_MAJOR ]: {
    label: c.DENY_FOUR_CARD_MAJOR_LABEL,
  },
  [ BidTypes.DRURY_RESPONSE ]: {
    isArtificial: true,
    label: c.DRURY_RESPONSE_LABEL,
  },
  [ BidTypes.DRURY_OTHER_MAJOR ]: {
    isArtificial: true,
    label: c.DRURY_OTHER_MAJOR_LABEL,
  },
  [ BidTypes.DRURY_MINIMAL_OPENER ]: {
    isArtificial: true,
    label: c.DRURY_MINIMAL_OPENER_LABEL,
  },
  [ BidTypes.DRURY_SUBMINIMAL_OPENER ]: {
    isArtificial: true,
    label: c.DRURY_SUBMINIMAL_OPENER_LABEL,
  },
  [ BidTypes.DRURY_RESPONSE_FROM_OPENER ]: {
    isArtificial: true,
    label: c.DRURY_RESPONSE_FROM_OPENER_LABEL,
  },
  [ BidTypes.FOURTH_SUIT_FORCING ]: {
    isArtificial: true,
    isForcing: true,
    label: c.FOURTH_SUIT_FORCING_LABEL,
  },
  [ BidTypes.FOURTH_SUIT_FORCING_RESPONSE ]: {
    label: c.FOURTH_SUIT_FORCING_RESPONSE_LABEL,
  },
  [ BidTypes.GAME_FORCING_HAND ]: {
    label: c.GAME_FORCING_HAND_LABEL,
    isForcing: true,
  },
  [ BidTypes.GERBER_FOR_ACES ]: {
    abbr: c.GERBER_FOR_ACES_ABBR_LABEL,
    label: c.GERBER_FOR_ACES_LABEL,
    tag: Tags.GERBER,
  },
  [ BidTypes.GRAND_SLAM ]: {
    label: c.GRAND_SLAM_LABEL,
    tag: Tags.GRAND_SLAM,
  },
  [ BidTypes.INVITE_MINOR_GAME ]: {
    label: c.INVITE_MINOR_GAME_LABEL,
  },
  [ BidTypes.INVITE_THREE_NO_TRUMP_GAME ]: {
    abbr: c.INVITE_THREE_NO_TRUMP_GAME_ABBR_LABEL,
    label: c.INVITE_THREE_NO_TRUMP_GAME_LABEL,
  },
  [ BidTypes.INVITE_THREE_NO_TRUMP_WITH_SIX_CARD_MINOR ]: {
    abbr: c.INVITE_THREE_NO_TRUMP_GAME_ABBR_LABEL,
    label: c.INVITE_THREE_NO_TRUMP_GAME_LABEL,
  },
  [ BidTypes.INVITE_MAJOR_GAME ]: {
    label: c.INVITE_MAJOR_GAME_LABEL,
  },
  [ BidTypes.JACOBY_TRANSFER ]: {
    abbr: c.JACOBY_TRANSFER_ABBR_LABEL,
    label: c.JACOBY_TRANSFER_LABEL,
    isArtificial: true,
    tag: Tags.JACOBY_TRANSFER,
  },
  [ BidTypes.JACOBY_TRANSFER_RESPONSE ]: {
    label: c.JACOBY_TRANSFER_RESPONSE_LABEL,
    isArtificial: true,
  },
  [ BidTypes.JACOBY_TRANSFER_SUPER_ACCEPTANCE_RESPONSE ]: {
    label: c.JACOBY_TRANSFER_SUPER_ACCEPTANCE_RESPONSE_LABEL,
    isArtificial: true,
    tag: Tags.JACOBY_TRANSFER_SUPER_ACCEPTANCE,
  },
  [ BidTypes.JUMP_RAISE_OF_PARTNERS_SUIT ]: {
    label: c.JUMP_RAISE_OF_PARTNERS_SUIT_LABEL,
    tag: Tags.JUMP_RAISE,
  },
  [ BidTypes.OPENER_STRONG_JUMP_SHIFT ]: {
    label: c.STRONG_JUMP_SHIFT_LABEL,
    tag: Tags.STRONG_JUMP_SHIFT,
    isForcing: true,
  },
  [ BidTypes.STRONG_JUMP_SHIFT ]: {
    label: c.STRONG_JUMP_SHIFT_LABEL,
    tag: Tags.STRONG_JUMP_SHIFT,
    isForcing: true,
  },
  [ BidTypes.LANDY ]: {
    label: c.LANDY_LABEL,
    isArtificial: true,
    tag: Tags.LANDY,
  },
  [ BidTypes.LANDY_ADVANCE ]: {
    label: c.LANDY_ADVANCE_LABEL,
  },
  [ BidTypes.LANDY_ADVANCER_PASS_STRONG_CLUBS ]: {
    label: c.LANDY_ADVANCER_PASS_STRONG_CLUBS_LABEL,
  },
  [ BidTypes.LANDY_ADVANCE_TWO_NO_TRUMP_INVITE ]: {
    label: c.NO_TRUMP_RESPONSE_LABEL,
  },
  [ BidTypes.LANDY_OVERCALLER_REBID ]: {
    label: c.LANDY_OVERCALLER_REBID_LABEL,
  },
  [ BidTypes.LANDY_OVERCALLER_REBID_BID_LONGEST ]: {
    label: c.LANDY_OVERCALLER_REBID_BID_LONGEST_LABEL,
  },
  [ BidTypes.LANDY_TWO_DIAMOND_NO_PREFERENCE ]: {
    label: c.LANDY_TWO_DIAMOND_NO_PREFERENCE_LABEL,
  },
  [ BidTypes.LANDY_TWO_NO_TRUMP ]: {
    label: c.LANDY_TWO_NO_TRUMP_LABEL,
    isArtificial: true,
  },
  [ BidTypes.LANDY_PLAY_THREE_NO_TRUMP_GAME ]: {
    abbr: c.PLAY_THREE_NO_TRUMP_GAME_ABBR_LABEL,
    label: c.PLAY_THREE_NO_TRUMP_GAME_LABEL,
  },
  [ BidTypes.LAW_OF_TOTAL_TRICKS ]: {
    label: c.LAW_OF_TOTAL_TRICKS_LABEL,
  },
  [ BidTypes.LIMIT_RAISE ]: {
    label: c.LIMIT_RAISE_LABEL,
    tag: Tags.LIMIT_RAISE,
  },
  [ BidTypes.LOSING_TRICK_COUNT ]: {
    label: c.LOSING_TRICK_COUNT_LABEL,
  },
  [ BidTypes.MAJOR_SUIT_OPEN ]: {
    abbr: c.MAJOR_SUIT_OPEN_ABBR_LABEL,
    label: c.MAJOR_SUIT_OPEN_LABEL,
    Tags: Tags.MAJOR_SUIT_OPEN,
  },
  [ BidTypes.MICHAELS_CUEBID ]: {
    label: c.MICHAELS_CUEBID_LABEL,
    isArtificial: true,
    Tags: Tags.MICHAELS_CUEBID,
  },
  [ BidTypes.MICHAELS_CUEBID_RESPONSE ]: {
    label: c.MICHAELS_CUEBID_RESPONSE_LABEL,
  },
  [ BidTypes.MICHAELS_CUEBID_UNSPECIFIED_FIVE_CARD_MINOR ]: {
    label: c.MICHAELS_CUEBID_UNSPECIFIED_FIVE_CARD_MINOR_DESC,
    isArtificial: true,
  },
  [ BidTypes.MINIMAL_HAND ]: {
    label: c.MINIMAL_HAND_LABEL,
  },
  [ BidTypes.MINIMAL_HAND_SUIT_PREFERNCE ]: {
    label: c.MINIMAL_HAND_SUIT_PREFERNCE_LABEL,
  },
  [ BidTypes.MINIMAL_OPENER_CONFIRM_TRUMPS ]: {
    label: c.MINIMAL_OPENER_CONFIRM_TRUMPS_LABEL,
  },
  [ BidTypes.MINIMAL_OPENING_HAND_NO_TRUMP_RESPONSE ]: {
    label: c.MINIMAL_OPENING_HAND_NO_TRUMP_RESPONSE_LABEL,
  },
  [ BidTypes.MINOR_SUIT_OPEN ]: {
    abbr: c.MINOR_SUIT_OPEN_ABBR_LABEL,
    label: c.MINOR_SUIT_OPEN_LABEL,
    tag: Tags.MINOR_SUIT_OPEN,
  },
  [ BidTypes.MINOR_SUIT_RAISE_DENIES_FOUR_CARD_MAJOR ]: {
    label: c.MINOR_SUIT_RAISE_DENIES_FOUR_CARD_MAJOR_LABEL,
  },
  [ BidTypes.NEGATIVE_DOUBLE ]: {
    label: c.NEGATIVE_DOUBLE_LABEL,
    strain: c.DOUBLE_STRAIN,
    isArtificial: true,
    isForcing: true,
    tag: Tags.NEGATIVE_DOUBLE,
  },
  [ BidTypes.NEGATIVE_DOUBLE_BOTH_MAJORS ]: {
    label: c.NEGATIVE_DOUBLE_BOTH_MAJORS_LABEL,
  },
  [ BidTypes.NEGATIVE_DOUBLE_ONE_MAJOR ]: {
    label: c.NEGATIVE_DOUBLE_ONE_MAJOR_LABEL,
  },
  [ BidTypes.NEGATIVE_DOUBLE_BOTH_MINORS ]: {
    label: c.NEGATIVE_DOUBLE_BOTH_MINORS_LABEL,
  },
  [ BidTypes.NEGATIVE_DOUBLE_RESPONSE ]: {
    label: c.NEGATIVE_DOUBLE_RESPONSE_LABEL,
  },
  [ BidTypes.NEW_SUIT_RESPONSE_FORCING ]: {
    label: c.NEW_SUIT_RESPONSE_FORCING_LABEL,
    isForcing: true,
  },
  [ BidTypes.NO_TRUMP_FIT ]: {
    label: c.NO_TRUMP_FIT_LABEL,
  },
  [ BidTypes.NO_TRUMP_RESPONSE_TO_SUIT_OPEN ]: {
    label: c.NO_TRUMP_RESPONSE_LABEL,
  },
  [ BidTypes.ONE_LEVEL_SUIT_OVERCALL ]: {
    abbr: c.ONE_LEVEL_SUIT_OVERCALL_ABBR_LABEL,
    label: c.ONE_LEVEL_SUIT_OVERCALL_LABEL,
  },
  [ BidTypes.OPENING_BID ]: {
    label: c.OPENING_BID_DESC,
  },
  [ BidTypes.OPEN_NO_TRUMP ]: {
    abbr: c.NO_TRUMP_OPEN_ABBR_LABEL,
    label: c.NO_TRUMP_OPEN_LABEL,
    tag: Tags.OPEN_NO_TRUMP,
  },
  [ BidTypes.OPEN_NO_TRUMP_WEAK_FIVE_CARD_SUIT_RESPONSE ]: {
    label: c.OPEN_NO_TRUMP_WEAK_FIVE_CARD_SUIT_RESPONSE_LABEL,
  },
  [ BidTypes.OPTIONAL_DOUBLE ]: {
    label: c.OPTIONAL_DOUBLE_LABEL,
  },
  [ BidTypes.OVERCALL_BID ]: {
    label: c.OVERCALL_BID_DESC,
  },
  [ BidTypes.OVERCALL_ADVANCE_BID ]: {
    label: c.OVERCALL_ADVANCE_BID_DESC,
  },
  [ BidTypes.OVERCALL_ADVANCE_STRONG_FIVE_CARD_SUIT ]: {
    label: c.OVERCALL_ADVANCE_STRONG_FIVE_CARD_SUIT_LABEL,
    isForcing: true,
  },
  [ BidTypes.OVERCALL_ADVANCE_MINIMAL_RAISE ]: {
    label: c.OVERCALL_ADVANCE_MINIMAL_RAISE_LABEL,
  },
  [ BidTypes.OVERCALL_ADVANCE_INVITATIONAL_RAISE ]: {
    label: c.OVERCALL_ADVANCE_INVITATIONAL_RAISE_LABEL,
  },
  [ BidTypes.OVERCALL_ADVANCE_GAME_FORCING_HAND_RAISE ]: {
    label: c.OVERCALL_ADVANCE_GAME_FORCING_HAND_RAISE_LABEL,
  },
  [ BidTypes.OVERCALL_ADVANCE_PREEMPT_THREE_LEVEL ]: {
    label: c.OVERCALL_ADVANCE_PREEMPT_THREE_LEVEL_LABEL,
  },
  [ BidTypes.OVERCALL_NO_TRUMP ]: {
    label: c.NO_TRUMP_OPEN_LABEL,
    tag: Tags.OVERCALL_NO_TRUMP,
  },
  [ BidTypes.OVERCALL_ADVANCE_CUE_BID ]: {
    label: c.OVERCALL_ADVANCE_CUE_BID_LABEL,
    isArtificial: true,
    isForcing: true,
  },
  [ BidTypes.OVERCALLER_IS_MINIMAL_REBIDS_SUIT ]: {
    label: c.OVERCALLER_IS_MINIMAL_REBIDS_SUIT,
  },
  [ BidTypes.PASS_CANNOT_RAISE_HIGHER ]: {
    label: c.PASS_CANNOT_RAISE_HIGHER_LABEL,
  },
  [ BidTypes.PASS_CANNOT_REBID_NO_TRUMP_LACKS_STOPPER_IN_ENEMY_SUIT ]: {
    label: c.PASS_CANNOT_REBID_NO_TRUMP_LACKS_STOPPER_IN_ENEMY_SUIT_LABEL,
  },
  [ BidTypes.PASS_CONVERT_TO_DOUBLE_BID ]: {
    label: c.PASS_CONVERT_TO_DOUBLE_BID_LABEL,
  },
  [ BidTypes.PASS_DOES_NOT_HAVE_BID ]: {
    label: c.PASS_DOES_NOT_HAVE_BID_LABEL,
  },
  [ BidTypes.PASS_RESPONSE_AFTER_RHO_TAKEOUT_DOUBLE ]: {
    label: c.PASS_RESPONSE_AFTER_RHO_TAKEOUT_DOUBLE_LABEL,
  },
  [ BidTypes.PENALTY_DOUBLE ]: {
    label: c.PENALTY_DOUBLE_LABEL,
    isArtificial: true,
    strain: c.DOUBLE_STRAIN,
    tag: Tags.PENALTY_DOUBLE,
  },
  [ BidTypes.PLAY_GAME_WITH_LTC ]: {
    label: c.PLAY_GAME_WITH_LTC_LABEL,
  },
  [ BidTypes.PLAYING_TRICK_COUNT ]: {
    label: c.PLAYING_TRICK_COUNT_LABEL,
  },
  [ BidTypes.PLAY_MAJOR_GAME_WITH_POINTS ]: {
    label: c.PLAY_MAJOR_GAME_POINTS_LABEL,
  },
  [ BidTypes.PLAY_MINOR_GAME_WITH_POINTS ]: {
    label: c.PLAY_MINOR_GAME_POINTS_LABEL,
  },
  [ BidTypes.PLAY_NO_TRUMP_OVER_SUIT ]: {
    label: c.PLAY_NO_TRUMP_OVER_SUIT_LABEL,
  },
  [ BidTypes.PLAY_NO_TRUMP_GRAND_SLAM ]: {
    label: c.PLAY_NO_TRUMP_GRAND_SLAM_LABEL,
  },
  [ BidTypes.PLAY_NO_TRUMP_SMALL_SLAM ]: {
    label: c.PLAY_NO_TRUMP_SMALL_SLAM_LABEL,
  },
  [ BidTypes.PLAY_THREE_NO_TRUMP_GAME ]: {
    abbr: c.PLAY_THREE_NO_TRUMP_GAME_ABBR_LABEL,
    label: c.PLAY_THREE_NO_TRUMP_GAME_LABEL,
  },
  [ BidTypes.PREEMPT_FOUR_LEVEL ]: {
    abbr: c.PREEMPT_FOUR_LEVEL_ABBR_LABEL,
    label: c.PREEMPT_LABEL,
    isPreempt: true,
  },
  [ BidTypes.PREEMPT_NEW_SUIT ]: {
    label: c.PREEMPT_NEW_SUIT_LABEL,
  },
  [ BidTypes.PREEMPT_RAISE ]: {
    label: c.PREEMPT_RAISE_LABEL,
  },
  [ BidTypes.PREEMPT_THREE_LEVEL ]: {
    abbr: c.PREEMPT_THREE_LEVEL_ABBR_LABEL,
    label: c.PREEMPT_LABEL,
    isPreempt: true,
    tag: Tags.PREEMPT_THREE_LEVEL,
  },
  [ BidTypes.PREEMPT_WEAK_TWO ]: {
    abbr: c.WEAK_TWO_ABBR_LABEL,
    label: c.WEAK_TWO_LABEL,
    isPreempt: true,
    tag: Tags.PREEMPT_WEAK_TWO,
  },
  [ BidTypes.PASS ]: {
    label: c.PASS,
  },
  [ BidTypes.PASS_LESS_THAN_MINIMAL_POINTS ]: {
    label: c.PASS_LESS_THAN_MINIMAL_POINTS_LABEL,
  },
  [ BidTypes.PASS_OVERCALL ]: {
    label: c.PASS_OVERCALL_LABEL,
  },
  [ BidTypes.POINTS_IN_UNBID_SUITS ]: {
    label: c.POINTS_IN_UNBID_SUITS_LABEL,
  },
  [ BidTypes.QUANTITATIVE_FOUR_NT ]: {
    abbr: c.QUANTITATIVE_FOUR_NT_ABBR_LABEL,
    label: c.QUANTITATIVE_FOUR_NT_DESC_LABEL,
  },
  [ BidTypes.RAISE_TO_MAJOR_GAME ]: {
    label: c.RAISE_TO_MAJOR_GAME_LABEL,
  },
  [ BidTypes.RANGE_PROMISE ]: {
    //
  },
  [ BidTypes.REBID_SIX_CARD_SUIT ]: {
    label: c.REBID_SIX_CARD_SUIT_LABEL,
  },
  [ BidTypes.REDOUBLE ]: {
    label: c.REDOUBLE_LABEL,
  },
  [ BidTypes.RESPONDER_NEW_SUIT_FORCING ]: {
    isForcing: true,
  },
  [ BidTypes.RESPOND_MICHAELS_CUEBID_OVERCALL_WITH_FIT ]: {
    label: c.RESPOND_MICHAELS_CUEBID_OVERCALL_WITH_FIT_LABEL,
    isArtificial: true,
    isForcing: true,
  },
  [ BidTypes.RESPOND_WITH_CUEBID_SHOWING_GAME_FORCING_HAND ]: {
    label: c.RESPOND_WITH_CUEBID_SHOWING_GAME_FORCING_HAND_LABEL,
    isArtificial: true,
  },
  [ BidTypes.RESPONSE_TO_PARTNERS_OPENING_BID ]: {
    label: c.RESPONDING_BID_DESC,
  },
  [ BidTypes.RESPONSE_TO_PARTNERS_OPENING_WHEN_OVERCALLED ]: {
    label: c.RESPONSE_TO_PARTNERS_OPENING_WHEN_OVERCALLED_LABEL,
  },
  [ BidTypes.REVERSE_BID ]: {
    label: c.REVERSE_BID_LABEL,
    isForcing: true,
    tag: Tags.REVERSE_BID,
  },
  [ BidTypes.RULE_OF_FIFTEEN_THRESHOLD ]: {
    label: c.RULE_OF_FIFTEEN_THRESHOLD_LABEL,
  },
  [ BidTypes.SIGN_OFF ]: {
    label: c.SIGN_OFF_LABEL,
    signOff: true,
  },
  [ BidTypes.SLAM_BAIL ]: {
    label: c.SLAM_BAIL_LABEL,
    signOff: true,
  },
  [ BidTypes.SLAM_SIGN_OFF_MISSING_FIRST_ROUND_CONTROLS ]: {
    signOff: true,
    reason: c.SLAM_SIGN_OFF_LACKS_CONTROLS_REASON_LABEL,
    label: c.FIRST_ROUND_CONTROLS_COUNT_LABEL,
    count: 0,
    // FIXME: move to count promise
    toString: (type) => {
      return `${type.reason} ${type.label} (${type.count})`;
    },
  },
  [ BidTypes.SMALL_SLAM ]: {
    label: c.SMALL_SLAM_LABEL,
    tag: Tags.SMALL_SLAM,
  },
  [ BidTypes.SLAM_TRY_SHOWS_CONTROL_IN_STRAIN ]: {
    label: c.SLAM_TRY_SHOWS_CONTROL_IN_STRAIN_LABEL,
  },
  [ BidTypes.STAYMAN_BID ]: {
    abbr: c.STAYMAN_BID_ABBR_LABEL,
    label: c.STAYMAN_BID_LABEL,
    isArtificial: true,
    suitLength: c.FOUR_CARD_SUIT,
    tag: Tags.STAYMAN,
  },
  [ BidTypes.STAYMAN_DENIED ]: {
    label: c.STAYMAN_DENIED_LABEL,
  },
  [ BidTypes.STAYMAN_MATCH ]: {
    label: c.STAYMAN_MATCH_LABEL,
  },
  [ BidTypes.STAYMAN_OVERCALLED_PASS_NO_FOUR_CARD_MAJOR ]: {
    label: c.STAYMAN_OVERCALLED_PASS_NO_FOUR_CARD_MAJOR_LABEL,
  },
  [ BidTypes.STAYMAN_OVERCALLED_REDOUBLE_FIVE_CLUBS_NO_FOUR_CARD_MAJOR ]: {
    label: c.STAYMAN_OVERCALLED_REDOUBLE_FIVE_CLUBS_NO_FOUR_CARD_MAJOR_LABEL,
  },
  [ BidTypes.STAYMAN_OVERCALLED_DOUBLE_HAS_FOUR_CARD_MAJOR ]: {
    label: c.STAYMAN_OVERCALLED_DOUBLE_HAS_FOUR_CARD_MAJOR_LABEL,
  },
  [ BidTypes.STAYMAN_RESPONSE ]: {
    label: c.STAYMAN_RESPONSE_LABEL,
    isArtificial: true,
  },
  [ BidTypes.STOPPER_IN_STRAIN ]: {
    label: c.STOPPER_IN_STRAIN_LABEL,
  },
  [ BidTypes.STRONG_TWO_CLUB ]: {
    abbr: c.STRONG_TWO_CLUB_ABBR_LABEL,
    label: c.STRONG_TWO_CLUB_LABEL,
    isArtificial: true,
    isForcing: true,
    tag: Tags.STRONG_TWO_CLUB,
  },
  [ BidTypes.STRONG_TWO_CLUB_REBID_BALANCED_HAND ]: {
    label: c.STRONG_TWO_CLUB_REBID_BALANCED_HAND_LABEL,
    isForcing: true,
  },
  [ BidTypes.STRONG_TWO_POSITIVE_SUIT_RESPONSE ]: {
    label: c.STRONG_TWO_POSITIVE_SUIT_RESPONSE_LABEL,
    isForcing: true,
  },
  [ BidTypes.STRONG_TWO_CLUB_NO_TRUMP_RESPONSE ]: {
    label: c.STRONG_TWO_CLUB_NO_TRUMP_RESPONSE_LABEL,
  },
  [ BidTypes.STRONG_TWO_CLUB_WAITING ]: {
    label: c.STRONG_TWO_CLUB_WAITING_LABEL,
    isArtificial: true,
  },
  [ BidTypes.STRONG_TWO_OF_A_MAJOR ]: {
    label: c.STRONG_TWO_OF_A_MAJOR_LABEL,
  },
  [ BidTypes.STRONG_TWO_OF_A_MINOR ]: {
    label: c.STRONG_TWO_OF_A_MINOR_LABEL,
  },
  [ BidTypes.STRONG_TWO_NEGATIVE_RESPONSE ]: {
    label: c.STRONG_TWO_NEGATIVE_RESPONSE_LABEL,
  },
  [ BidTypes.STRONG_TWO_STRONG_BALANCED_HAND_RESPONSE ]: {
    label: c.STRONG_TWO_STRONG_BALANCED_HAND_RESPONSE_LABEL,
  },
  [ BidTypes.STRONG_TWO_CLUB_BID_LONGEST_SUIT ]: {
    label: c.STRONG_TWO_CLUB_BID_LONGEST_SUIT_LABEL,
  },
  [ BidTypes.SUIT_PROMISE ]: {
    //
  },
  [ BidTypes.SUPPORT_PARTNERS_SUIT ]: {
    label: c.SUPPORT_PARTNERS_SUIT_LABEL,
  },
  [ BidTypes.TAKE_OUT_DOUBLE ]: {
    isForcing: true,
    label: c.TAKE_OUT_DOUBLE_LABEL,
    tag: Tags.TAKE_OUT_DOUBLE,
  },
  [ BidTypes.TAKEOUT_DOUBLE_RESPONSE_INVITATIONAL_LONG_SUIT ]: {
    label: c.TAKEOUT_DOUBLE_RESPONSE_INVITATIONAL_LONG_SUIT_LABEL,
  },
  [ BidTypes.TAKEOUT_DOUBLE_RESPONSE_WEAK_HAND_BID_LONGEST_SUIT ]: {
    label: c.TAKEOUT_DOUBLE_RESPONSE_WEAK_HAND_BID_LONGEST_SUIT_LABEL,
  },
  [ BidTypes.TAKEOUT_DOUBLE_RESPONSE_FREEBID_LONGEST_SUIT ]: {
    label: c.TAKEOUT_DOUBLE_RESPONSE_FREEBID_LONGEST_SUIT_LABEL,
  },
  [ BidTypes.TAKEOUT_DOUBLE_RESPONSE_JUMP_TO_GAME ]: {
    label: c.TAKEOUT_DOUBLE_RESPONSE_JUMP_TO_GAME_LABEL,
  },
  [ BidTypes.TAKEOUT_DOUBLE_RESPONSE_NO_TRUMP ]: {
    label: c.TAKEOUT_DOUBLE_RESPONSE_NO_TRUMP_LABEL,
  },
  [ BidTypes.TAKEOUT_DOUBLE_RESPONSE_CUE_BID ]: {
    label: c.TAKEOUT_DOUBLE_RESPONSE_CUE_BID_LABEL,
    isArtificial: true,
    isForcing: true,
  },
  [ BidTypes.TAKEOUT_DOUBLE_RESPONSE_LONG_SUIT ]: {
    label: c.TAKEOUT_DOUBLE_RESPONSE_LONG_SUIT_LABEL,
  },
  [ BidTypes.TRUMP_FIT ]: {
    label: c.TRUMP_FIT_LABEL,
  },
  [ BidTypes.TWO_FIVE_CARD_SUIT_HAND ]: {
    label: c.TWO_FIVE_CARD_SUIT_HAND_LABEL,
    isArtificial: true,
  },
  [ BidTypes.TWO_LEVEL_SUIT_OVERCALL ]: {
    label: c.TWO_LEVEL_SUIT_OVERCALL_LABEL,
  },
  [ BidTypes.UNBALANCED_HAND ]: {
    abbr: c.UNBALANCED_HAND_ABBR_LABEL,
    label: c.UNBALANCED_HAND_LABEL,
  },
  [ BidTypes.UNUSUAL_TWO_NO_TRUMP ]: {
    label: c.UNUSUAL_TWO_NO_TRUMP_LABEL,
    isArtificial: true,
    tag: Tags.UNUSUAL_TWO_NO_TRUMP,
  },
  [ BidTypes.UNUSUAL_TWO_NO_TRUMP_RESPONSE ]: {
    label: c.UNUSUAL_TWO_NO_TRUMP_RESPONSE_LABEL,
  },
  [ BidTypes.WEAK_COMPETITIVE_RAISE ]: {
    label: c.WEAK_COMPETITIVE_RAISE_LABEL,
  },
  [ BidTypes.WEAK_TWO_RESPONSE_JUMP_RAISE ]: {
    label: c.WEAK_TWO_RESPONSE_JUMP_RAISE_LABEL,
  },
  [ BidTypes.WEAK_JUMP_OVERCALL ]: {
    label: c.WEAK_JUMP_OVERCALL_LABEL,
    tag: Tags.WEAK_JUMP_OVERCALL,
  },
  [ BidTypes.WEAK_JUMP_RAISE_AFTER_OVERCALL ]: {
    label: c.WEAK_JUMP_RAISE_AFTER_OVERCALL_LABEL,
  },
  [ BidTypes.WEAK_OPEN_REBID_PLAY_GAME ]: {
    label: c.WEAK_OPEN_REBID_PLAY_GAME_LABEL,
  },
  [ BidTypes.WEAK_OPEN_REBID_RETREAT ]: {
    label: c.WEAK_OPEN_REBID_RETREAT_LABEL,
  },
  [ BidTypes.WEAK_OPEN_REBID_SHOWS_OUTSIDE_ACE_KING_IN_SUIT ]: {
    label: c.WEAK_OPEN_REBID_SHOWS_OUTSIDE_ACE_KING_IN_SUIT,
    isArtificial: true,
  },
  [ BidTypes.MINOR_SUIT_STAYMAN ]: {
    abbr: c.MINOR_SUIT_STAYMAN_ABBR_LABEL,
    label: c.MINOR_SUIT_STAYMAN_DESC_LABEL,
  },
  [ BidTypes.MINOR_SUIT_STAYMAN_RESPONSE ]: {
    label: c.MINOR_SUIT_STAYMAN_RESPONSE_LABEL,
  },
  [ BidTypes.WEAK_TWO_NO_TRUMP_RESPONSE ]: {
    label: c.WEAK_TWO_NO_TRUMP_RESPONSE_LABEL,
    isArtificial: true,
  },
  [ BidTypes.WEAK_TWO_RESPONSE_NEW_SUIT_FORCING ]: {
    label: c.NEW_SUIT_RESPONSE_FORCING_LABEL,
    isForcing: true,
  },
};

export const lookupBidTypeAttributes = function getBidTypeAttributes(bidType) {
  return lookup[ bidType];
};

export const lookupBidTypeAbbr = function lookupBidTypeAbbr(bidType) {
  return (attr => has('abbr', attr)
    ? attr.abbr
    : ''
  )(lookupBidTypeAttributes(bidType));
};

export const lookupBidTypeLabel = function lookupBidTypeLabel(bidType) {
  return (attr => has('label', attr)
    ? attr.label
    : ''
  )(lookupBidTypeAttributes(bidType));
};

export const lookupIsBidTypePreempt = function lookupIsBidTypePreempt(bidType) {
  return (attr => has('isPreempt', attr)
    ? attr.isPreempt
    : false
  )(lookupBidTypeAttributes(bidType));
};

export const lookupIsBidTypeForcing = function lookupIsBidTypeForcing(bidType) {
  return (attr => has('isForcing', attr)
    ? attr.isForcing
    : false
  )(lookupBidTypeAttributes(bidType));
};

export const lookupIsBidTypeArtificial = function lookupIsBidTypeArtificial(bidType) {
  return (attr => has('isArtificial', attr)
    ? attr.isArtificial
    : false
  )(lookupBidTypeAttributes(bidType));
};
