import { PASS_OPTION } from '../pass-option';
import { createBidOptions } from '../create-bid-options';

import {
  BID_NEW_SUIT_TWO_LEVEL,
  RAISE_PARTNERS_SUIT_TO_TWO_LEVEL,
  BID_TWO_NO_TRUMP,
  PENALTY_DOUBLE,
} from './types';

import { createLabels } from './labels';

const getOptions = function getOptions() {
  return createBidOptions([
    BID_NEW_SUIT_TWO_LEVEL,
    RAISE_PARTNERS_SUIT_TO_TWO_LEVEL,
    BID_TWO_NO_TRUMP,
    PENALTY_DOUBLE,
    PASS_OPTION,
  ]);
};

export {
  BID_NEW_SUIT_TWO_LEVEL,
  RAISE_PARTNERS_SUIT_TO_TWO_LEVEL,
  BID_TWO_NO_TRUMP,
  PENALTY_DOUBLE,
  createLabels,
  getOptions,
}
