import {
  ONE_LEVEL_NO_TRUMP,
  ONE_LEVEL_FIVE_CARD_SUIT,
  TWO_LEVEL_FIVE_CARD_SUIT,
  WEAK_JUMP_OVERCALL,
  TAKE_OUT_DOUBLE,
  TAKE_OUT_DOUBLE_SHORT_IN_ENEMY_SUIT,
  TAKE_OUT_DOUBLE_STRONG_HAND,
} from './types';

export const DIRECT_OVERCALL_RANGES = 'bid-options/direct-overcall/ranges';

export const createRanges = () => ({
  [ ONE_LEVEL_NO_TRUMP ]: {
    lower: 16,
    upper: 18,
  },
  [ ONE_LEVEL_FIVE_CARD_SUIT ]: {
    lower: 10,
  },
  [ TWO_LEVEL_FIVE_CARD_SUIT ]: {
    lower: 12,
  },
  [ WEAK_JUMP_OVERCALL ]: {
    lower: 5,
    upper: 10,
  },
  [ TAKE_OUT_DOUBLE ]: {
    lower: 12,
  },
  [ TAKE_OUT_DOUBLE_SHORT_IN_ENEMY_SUIT ]: {
    lower: 12,
  },
  [ TAKE_OUT_DOUBLE_STRONG_HAND ]: {
    lower: 16,
  },
});
