import { constants as c } from '../../../constants';

import { getOpeningBidRanges, getIsPlayWeakTwos } from '../../bid-system-config';

import { createRangeLabel, createTotalPointsLabel } from '../create-range-label';

import {
  OPEN_ONE_NO_TRUMP,
  OPEN_TWO_NO_TRUMP,
  OPEN_THREE_NO_TRUMP,
  OPEN_TWO_LEVEL_STRONG,
  OPEN_TWO_LEVEL_STRONG_BALANCED,
  OPEN_TWO_LEVEL_STRONG_UNBALANCED,
  OPEN_TWO_LEVEL_STRONG_OLD,
  OPEN_MAJOR_SUIT_AT_ONE_LEVEL,
  OPEN_MINOR_SUIT_AT_ONE_LEVEL,
  OPEN_TWO_LEVEL_WEAK_PREEMPT,
  OPEN_THREE_LEVEL_PREEMPT,
  OPEN_FOUR_LEVEL_PREEMPT,
  OPEN_NO_TRUMP,
  OPEN_SUIT_ONE_LEVEL,
  OPEN_PREEMPT,
  DEF_OPEN_NO_TRUMP,
  DEF_OPEN_SUIT_ONE_LEVEL,
  DEF_OPEN_PREEMPT,
  DEF_OPEN_ONE_NO_TRUMP,
  DEF_OPEN_TWO_NO_TRUMP,
  DEF_OPEN_THREE_NO_TRUMP,
  DEF_OPEN_MAJOR_SUIT_AT_ONE_LEVEL,
  DEF_OPEN_MINOR_SUIT_AT_ONE_LEVEL,
  DEF_OPEN_TWO_LEVEL_WEAK_PREEMPT,
  DEF_OPEN_THREE_LEVEL_PREEMPT,
  DEF_OPEN_FOUR_LEVEL_PREEMPT,
  DEF_OPEN_TWO_LEVEL_STRONG,
  DEF_OPEN_TWO_LEVEL_STRONG_BALANCED,
  DEF_OPEN_TWO_LEVEL_STRONG_UNBALANCED,
  DEF_OPEN_TWO_LEVEL_STRONG_OLD,
} from './types';

export const createLabels = function createLabels(bidSystemConfig) {

  const ranges = getOpeningBidRanges({ bidSystemConfig });
  const isWeakTwo = getIsPlayWeakTwos({ bidSystemConfig });

  const OPEN_ONE_NO_TRUMP_LABEL = c.ONE_NO_TRUMP_LABEL;
  const OPEN_TWO_NO_TRUMP_LABEL = c.TWO_NO_TRUMP_LABEL;
  const OPEN_THREE_NO_TRUMP_LABEL = c.THREE_NO_TRUMP_LABEL;
  const DEF_OPEN_ONE_NO_TRUMP_LABEL = createRangeLabel(ranges[ OPEN_ONE_NO_TRUMP ]);
  const DEF_OPEN_TWO_NO_TRUMP_LABEL = createRangeLabel(ranges[ OPEN_TWO_NO_TRUMP ]);
  const DEF_OPEN_THREE_NO_TRUMP_LABEL = createRangeLabel(ranges[ OPEN_THREE_NO_TRUMP ]);

  const OPEN_TWO_LEVEL_STRONG_LABEL = `Strong Two Club Open`;
  const OPEN_TWO_LEVEL_STRONG_UNBALANCED_LABEL = 'Unbalanced Hand';
  const OPEN_TWO_LEVEL_STRONG_BALANCED_LABEL = 'Balanced Hand';

  const OPEN_TWO_LEVEL_STRONG_OLD_LABEL = `Strong Two Level Suit Open`;

  const OPEN_MAJOR_SUIT_AT_ONE_LEVEL_LABEL = `Major suit`;
  const DEF_OPEN_MAJOR_SUIT_AT_ONE_LEVEL_LABEL = `5+ cards in suit`;
  const OPEN_MINOR_SUIT_AT_ONE_LEVEL_LABEL = `Minor suit`;
  const DEF_OPEN_MINOR_SUIT_AT_ONE_LEVEL_LABEL = `3+ cards in bid suit - deny a five card major suit`;

  const OPEN_TWO_LEVEL_WEAK_PREEMPT_LABEL = `Weak Two`;
  const DEF_OPEN_TWO_LEVEL_WEAK_PREEMPT_LABEL = `Good 6 card suit - ${createRangeLabel(ranges[ OPEN_TWO_LEVEL_WEAK_PREEMPT ])}`;

  const OPEN_THREE_LEVEL_PREEMPT_LABEL = `Three Level`;
  const DEF_OPEN_THREE_LEVEL_PREEMPT_LABEL = `7 card suit - ${createRangeLabel(ranges[ OPEN_THREE_LEVEL_PREEMPT ])}`;

  const OPEN_FOUR_LEVEL_PREEMPT_LABEL = `Four Level`;
  const DEF_OPEN_FOUR_LEVEL_PREEMPT_LABEL = `8 card suit - ${createRangeLabel(ranges[ OPEN_FOUR_LEVEL_PREEMPT ])}`;

  const OPEN_NO_TRUMP_LABEL = 'No Trump Open';
  const OPEN_SUIT_ONE_LEVEL_LABEL  = 'One Level Suit Open';
  const OPEN_PREEMPT_LABEL = 'Preempt Open';
  const DEF_OPEN_NO_TRUMP_LABEL = 'Balanced hand (at most one doubleton)';
  const DEF_OPEN_SUIT_ONE_LEVEL_LABEL  = `${createRangeLabel(ranges[ OPEN_SUIT_ONE_LEVEL ])} - length before strength`;
  const DEF_OPEN_PREEMPT_LABEL = 'Long strong one suit hand';

  return {
    [ OPEN_NO_TRUMP ]: OPEN_NO_TRUMP_LABEL,
    [ OPEN_ONE_NO_TRUMP ]: OPEN_ONE_NO_TRUMP_LABEL,
    [ OPEN_TWO_NO_TRUMP ]: OPEN_TWO_NO_TRUMP_LABEL,
    [ OPEN_THREE_NO_TRUMP ]: OPEN_THREE_NO_TRUMP_LABEL,
    [ OPEN_TWO_LEVEL_STRONG ]: OPEN_TWO_LEVEL_STRONG_LABEL,
    [ OPEN_TWO_LEVEL_STRONG_UNBALANCED ]: OPEN_TWO_LEVEL_STRONG_UNBALANCED_LABEL,
    [ OPEN_TWO_LEVEL_STRONG_BALANCED ]: OPEN_TWO_LEVEL_STRONG_BALANCED_LABEL,
    [ OPEN_TWO_LEVEL_STRONG_OLD ]: OPEN_TWO_LEVEL_STRONG_OLD_LABEL,
    [ OPEN_MAJOR_SUIT_AT_ONE_LEVEL ]: OPEN_MAJOR_SUIT_AT_ONE_LEVEL_LABEL,
    [ OPEN_MINOR_SUIT_AT_ONE_LEVEL ]: OPEN_MINOR_SUIT_AT_ONE_LEVEL_LABEL,
    [ OPEN_TWO_LEVEL_WEAK_PREEMPT ]: OPEN_TWO_LEVEL_WEAK_PREEMPT_LABEL,
    [ OPEN_THREE_LEVEL_PREEMPT ]: OPEN_THREE_LEVEL_PREEMPT_LABEL,
    [ OPEN_FOUR_LEVEL_PREEMPT ]: OPEN_FOUR_LEVEL_PREEMPT_LABEL,
    [ OPEN_SUIT_ONE_LEVEL ]: OPEN_SUIT_ONE_LEVEL_LABEL,
    [ OPEN_PREEMPT ]: OPEN_PREEMPT_LABEL,
    [ DEF_OPEN_NO_TRUMP ]: DEF_OPEN_NO_TRUMP_LABEL,
    [ DEF_OPEN_SUIT_ONE_LEVEL ]: DEF_OPEN_SUIT_ONE_LEVEL_LABEL,
    [ DEF_OPEN_PREEMPT ]: DEF_OPEN_PREEMPT_LABEL,
    [ DEF_OPEN_ONE_NO_TRUMP ]: DEF_OPEN_ONE_NO_TRUMP_LABEL,
    [ DEF_OPEN_TWO_NO_TRUMP ]: DEF_OPEN_TWO_NO_TRUMP_LABEL,
    [ DEF_OPEN_THREE_NO_TRUMP ]: DEF_OPEN_THREE_NO_TRUMP_LABEL,
    [ DEF_OPEN_MAJOR_SUIT_AT_ONE_LEVEL ]: DEF_OPEN_MAJOR_SUIT_AT_ONE_LEVEL_LABEL,
    [ DEF_OPEN_MINOR_SUIT_AT_ONE_LEVEL ]: DEF_OPEN_MINOR_SUIT_AT_ONE_LEVEL_LABEL,
    [ DEF_OPEN_TWO_LEVEL_WEAK_PREEMPT ]: DEF_OPEN_TWO_LEVEL_WEAK_PREEMPT_LABEL,
    [ DEF_OPEN_THREE_LEVEL_PREEMPT ]: DEF_OPEN_THREE_LEVEL_PREEMPT_LABEL,
    [ DEF_OPEN_FOUR_LEVEL_PREEMPT ]: DEF_OPEN_FOUR_LEVEL_PREEMPT_LABEL,
    ...(isWeakTwo
      ? {
          [ DEF_OPEN_TWO_LEVEL_STRONG ]: `Artificial forcing bid - ${createTotalPointsLabel(ranges[ OPEN_TWO_LEVEL_STRONG ])}`,
          [ DEF_OPEN_TWO_LEVEL_STRONG_UNBALANCED ]: `${createRangeLabel(ranges[ OPEN_TWO_LEVEL_STRONG_UNBALANCED ])} - Trick short of game`,
          [ DEF_OPEN_TWO_LEVEL_STRONG_BALANCED ]: `${createRangeLabel(ranges[ OPEN_TWO_LEVEL_STRONG_BALANCED ])} - Strong hand does not fall into No Trump Ranges`,
        }
      : {
        [
          DEF_OPEN_TWO_LEVEL_STRONG_OLD ]: `${createTotalPointsLabel(ranges[ OPEN_TWO_LEVEL_STRONG_OLD ])} - Strong Hand 5+ cards in Bid Suit`,
        }

    ),
  };
};
