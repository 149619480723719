import { constants as c } from '../../../constants';

import { createRangeLabel } from '../create-range-label';

import {
  TWO_NO_TRUMP,
  THREE_NO_TRUMP_OVER_THREE_MINOR,
  THREE_NO_TRUMP_OVER_TWO_MAJOR,
  RAISE,
  NEW_SUIT,
  DEF_TWO_NO_TRUMP,
  DEF_THREE_NO_TRUMP_OVER_THREE_MINOR,
  DEF_THREE_NO_TRUMP_OVER_TWO_MAJOR,
  DEF_RAISE,
  DEF_NEW_SUIT,
} from './types';

import { STRONG_TWO_OPENER_RESPONSE_TO_POSITIVE_SUIT_RANGES } from './ranges';

export const createLabels = function createLabels(bidSystemConfig) {
  const ranges = bidSystemConfig[ STRONG_TWO_OPENER_RESPONSE_TO_POSITIVE_SUIT_RANGES ];

  const TWO_NO_TRUMP_LABEL = c.TWO_NO_TRUMP_LABEL;
  const THREE_NO_TRUMP_OVER_THREE_MINOR_LABEL = `Three No Trump over minor suit response`;
  const THREE_NO_TRUMP_OVER_TWO_MAJOR_LABEL = `Three No Trump over major suit response`;
  const RAISE_LABEL = `Raise`;
  const NEW_SUIT_LABEL = `New Suit`;
  const DEF_TWO_NO_TRUMP_LABEL = `${createRangeLabel(ranges[ TWO_NO_TRUMP ])} - balanced hand`;
  const DEF_THREE_NO_TRUMP_OVER_THREE_MINOR_LABEL = `${createRangeLabel(ranges[ THREE_NO_TRUMP_OVER_THREE_MINOR ])} - balanced hand`;
  const DEF_THREE_NO_TRUMP_OVER_TWO_MAJOR_LABEL = `${createRangeLabel(ranges[ THREE_NO_TRUMP_OVER_TWO_MAJOR ])} - balanced hand`;
  const DEF_RAISE_LABEL = `${createRangeLabel(ranges[ RAISE ])} - 3+ card support - Natural`;
  const DEF_NEW_SUIT_LABEL = `${createRangeLabel(ranges[ NEW_SUIT ])} - 5+ cards - Natural`;

  return {
    [ TWO_NO_TRUMP ]: TWO_NO_TRUMP_LABEL,
    [ THREE_NO_TRUMP_OVER_THREE_MINOR ]: THREE_NO_TRUMP_OVER_THREE_MINOR_LABEL,
    [ THREE_NO_TRUMP_OVER_TWO_MAJOR ]: THREE_NO_TRUMP_OVER_TWO_MAJOR_LABEL,
    [ RAISE ]: RAISE_LABEL,
    [ NEW_SUIT ]: NEW_SUIT_LABEL,
    [ DEF_TWO_NO_TRUMP ]: DEF_TWO_NO_TRUMP_LABEL,
    [ DEF_THREE_NO_TRUMP_OVER_THREE_MINOR ]: DEF_THREE_NO_TRUMP_OVER_THREE_MINOR_LABEL,
    [ DEF_THREE_NO_TRUMP_OVER_TWO_MAJOR ]: DEF_THREE_NO_TRUMP_OVER_TWO_MAJOR_LABEL,
    [ DEF_RAISE ]: DEF_RAISE_LABEL,
    [ DEF_NEW_SUIT ]: DEF_NEW_SUIT_LABEL,
  };
};  
