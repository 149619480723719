import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';

import { PresenterEvents } from '../../constants';

import { BackIcon } from './BackIcon';
import { BridgeBlvdTitle } from './BridgeBlvdTitle';

const NavBar = function NavBar({
  backLabel,
  iconContainer,
  isPrevPageIconVisible,
  isSticky,
  isTop,
  onIconClick,
  subTitle,
}) {

  const [ scrolled, setScrolled ] = useState(false);

  const handleIconClick = e => {
    e.preventDefault();
    if (onIconClick) {
      return onIconClick(e.currentTarget.id);
    }
  };

  useEffect(() => {
    if (isSticky) {
      window.addEventListener('scroll', () => {
        if (window.pageYOffset > 100) {
          setScrolled(true);
        } else {
          setScrolled(false);
        }
      });
    }
  });

  const getStyle = () => scrolled
    ? {position:'fixed', top: 0}
    : {};

  const classList = `nav-bar__container ${isTop? 'nav-bar__container-top': 'nav-bar__container-bottom'} nav-bar__navigation`;

  return (
    <nav className={classList} style={getStyle()}>
      {isPrevPageIconVisible &&
        <div id="nav-bar__back">
          <BackIcon
            id={PresenterEvents.GO_HOME}
            title={backLabel}
            onClick={handleIconClick}
          />
        </div>
       }
      <header className="nav-bar__logo">
        <a id={PresenterEvents.GO_HOME} onClick={onIconClick} href="/">
          <h1>
            <BridgeBlvdTitle subTitle={subTitle} />
          </h1>
        </a>
      </header>

      {iconContainer}
    </nav>

  );
};

NavBar.propTypes = {
  onIconClick: PropTypes.func,
  isPrevPageIconVisible: PropTypes.bool,
  isSticky: PropTypes.bool,
  isTop: PropTypes.bool,
  subTitle: PropTypes.string,
  iconContainer: PropTypes.object,
  backLabel: PropTypes.string,
};

NavBar.defaultProps = {
  isPrevPageIconVisible: true,
  isSticky: false,
  isTop: true,
  backLabel: 'back'
};

export { NavBar };
