import { constants as c } from '../../../constants';

import { createRangeLabel } from '../create-range-label';

import {
  GAME_RAISE,
  SLOW_RAISE,
  REBID_SIX_CARD_SUIT,
  NEW_SUIT_NATURAL,
  TWO_NO_TRUMP,
  THREE_NO_TRUMP,
  DEF_GAME_RAISE,
  DEF_SLOW_RAISE,
  DEF_REBID_SIX_CARD_SUIT,
  DEF_NEW_SUIT_NATURAL,
  DEF_TWO_NO_TRUMP,
  DEF_THREE_NO_TRUMP,
} from './types';

import { STRONG_JUMP_SHIFT_RANGES } from './ranges';

export const createLabels = function createLabels(bidSystemConfig) {
  const ranges = bidSystemConfig[ STRONG_JUMP_SHIFT_RANGES ];

  const GAME_RAISE_LABEL = `Raise to Game`;
  const DEF_GAME_RAISE_LABEL = `3+ card support - game points - sign signOff`;

  const SLOW_RAISE_LABEL = `Raise below Game`;
  const DEF_SLOW_RAISE_LABEL = `3+ card support - slam interest`;

  const REBID_SIX_CARD_SUIT_LABEL = `Rebid Your Suit`;
  const DEF_REBID_SIX_CARD_SUIT_LABEL = `6+ cards in original suit`;

  const NEW_SUIT_NATURAL_LABEL = `New Suit`;
  const DEF_NEW_SUIT_NATURAL_LABEL = `4+ cards in new suit`;

  const TWO_NO_TRUMP_LABEL = c.TWO_NO_TRUMP_LABEL;
  const DEF_TWO_NO_TRUMP_LABEL = `balanced - ${createRangeLabel(ranges[ TWO_NO_TRUMP ])}`;

  const THREE_NO_TRUMP_LABEL = c.THREE_NO_TRUMP_LABEL;
  const DEF_THREE_NO_TRUMP_LABEL = `balanced - ${createRangeLabel(ranges[ THREE_NO_TRUMP ])}`;

  return {
    [ GAME_RAISE ]: GAME_RAISE_LABEL,
    [ SLOW_RAISE ]: SLOW_RAISE_LABEL,
    [ REBID_SIX_CARD_SUIT ]: REBID_SIX_CARD_SUIT_LABEL,
    [ NEW_SUIT_NATURAL ]: NEW_SUIT_NATURAL_LABEL,
    [ TWO_NO_TRUMP ]: TWO_NO_TRUMP_LABEL,
    [ THREE_NO_TRUMP ]: THREE_NO_TRUMP_LABEL,
    [ DEF_GAME_RAISE ]: DEF_GAME_RAISE_LABEL,
    [ DEF_SLOW_RAISE ]: DEF_SLOW_RAISE_LABEL,
    [ DEF_REBID_SIX_CARD_SUIT ]: DEF_REBID_SIX_CARD_SUIT_LABEL,
    [ DEF_NEW_SUIT_NATURAL ]: DEF_NEW_SUIT_NATURAL_LABEL,
    [ DEF_TWO_NO_TRUMP ]: DEF_TWO_NO_TRUMP_LABEL,
    [ DEF_THREE_NO_TRUMP ]: DEF_THREE_NO_TRUMP_LABEL,
  };
};
