import uuid from 'uuid';
import { pipe } from 'ramda';

import {
  constants as c,
  Coords,
  createBridgeGameService,
  createBridgePlayers,
  createRubberScoreKeeper,
  PreloadedDeals,
} from '../bridge';

import { getBidSystemConfig } from '../settings';

import { DealProgress, GamePause } from '../presenter';

import { GameEvents } from '../constants';

const createPlayerProps = (player, dealProgress) => {
  return {
    position: player.position,
    hand: DealProgress.getIsGTE(dealProgress, DealProgress.cardsDealt)
      ? player.hand.getExport()
      : {},
    bidValues: player.getBidValues(),
  };
};

const createGameModel = ({
  presenter,
  savedDealsService,
  players,
}) => new Game(presenter, savedDealsService, players);

/**
* @Class Game
*/
class Game {
  constructor(
    gamePresenter,
    savedDealsService,
    players
  ) {
    this.gamePresenter = gamePresenter;
    this.savedDealsService = savedDealsService;

    this.bridgeGameService = createBridgeGameService(players);

    this.onGameModelInit();

    this.getCurrentDealAsExternalExport = this.getCurrentDealAsExternalExport.bind(this);
    this.resetDefaultDealState = this.resetDefaultDealState.bind(this);
    this.displayError = this.displayError.bind(this);
  }

  displayError(error) {
    this.gamePresenter.displayError(error, this.getDealService().name);
  }

  getDealService() {
    return this.bridgeGameService.dealService;
  }

  getPreLoadedDeals() {
    return PreloadedDeals;
  }

  getDealProps() {
    return this.bridgeGameService.getProperties();
  }

  /**
  * In play status of the current game
  */
  getGameStatusProperties(dealProgress = DealProgress.newDealStart) {
    const dealProps = this.getDealProps();
    return {
      dealProgress,
      dealProps,
      players: this.getPlayerProps(dealProgress),
    };
  }

  /**
  * return if there is a currently playable auction - not passed out
  */
  getHasPlayableAuction() {
    return this.bridgeGameService.getHasPlayableAuction();
  }

  getRubberScoreKeeper() {
    if (!this.rubberScoreKeeper) {
      this.rubberScoreKeeper = createRubberScoreKeeper();
    }
    return this.rubberScoreKeeper;
  }

  addTallyData(handTallyData) {
    this.getRubberScoreKeeper().addTallyData(handTallyData);
  }

  getMatchScore() {
    return this.getRubberScoreKeeper().getMatchScore();
  }

  getVulnerability() {
    return this.getRubberScoreKeeper().getVulnerability();
  }

  getRubberMatch() {
    return this.getRubberScoreKeeper().getRubberMatch();
  }

  resetRubberScoreKeeper() {
    this.getRubberScoreKeeper().reset();
  }

  onGameModelInit() {
    this.resetDefaultDealState();
    this.gamePresenter.setGameState({
      event: GameEvents.ON_GAME_MODEL_INIT,
      payload: this.getGameStatusProperties(DealProgress.gameInit),
    });
  }

  /**
  * cleanup for next deal
  */
  clearPreviousDeal() {
    if (this.gamePresenter.getDealProgress() > 0) {
      this.bridgeGameService.reset();
      this.gamePresenter.setGameState({
        event: GameEvents.ON_NEW_DEAL_START,
        payload: this.getDealUpdateStatusProperties(DealProgress.newDealStart),
      });
    }
  }

  /**
  * return the deal update props
  */
  getDealUpdateStatusProperties(dealProgress, isIncludeGameStatus = true) {
    return isIncludeGameStatus
      ? this.getGameStatusProperties(dealProgress)
      : { dealProgress };
  }

  getPlayerProps(dealProgress) {
    return this.bridgeGameService.getPlayers().map(player =>
      createPlayerProps(player, dealProgress));
  }

  /**
  * initial state
  */
  resetDefaultDealState() {
    this.clearPreviousDeal();
  }

  setDealerForNextDeal(players, dealerPosition = Coords.SOUTH) {
    players.forEach(player => player.setIsDealer(player.position === dealerPosition));
  }

  deleteCurrentDeal() {
    const { deleteMethod } = this.getDealService();

    if (deleteMethod) {
      deleteMethod(this.displayError);
    }
    this.resetDefaultDealState();
  }

  getDealFilter() {
    return {};
  }

  dealCards(deal) {
    this.bridgeGameService.dealCards(deal);
    this.gamePresenter.setGameState({
      event: GameEvents.ON_DEAL_CARDS,
      payload: this.getDealUpdateStatusProperties(DealProgress.cardsDealt),
    });
  }

  getCurrentDealProps = () => {

    const {
      id,
      dealer,
      dealName,
      folderName,
      source,
    } = this.getDealService();

    if (!dealer) {
      return {};
    }

    return {
      id,
      name: dealName === c.RANDOM_DEAL
        ? createRandomDealName(this.getGameStatusProperties())
        : dealName,
      folderName,
      source,
    };
  }

  getDealAsExternalExport = ({ id, name, folderName, source }) => {
    return this.bridgeGameService.getExternalExport({
      id,
      name,
      folderName,
      source
    });
  }

  getCurrentDealAsExternalExport() {
    return pipe(
      this.getCurrentDealProps,
      this.getDealAsExternalExport
    )();
  }

  getExternalExportJSON() {
    return this.bridgeGameService.getExternalExportJSON();
  }

  randomDeal(isBidPractice, callback) {
    this.setDealerForNextDeal(
      this.bridgeGameService.getPlayers(),
      this.gamePresenter.getNextDealerPosition()
    );
    runDeal(this, isBidPractice, callback, this.displayError);
  }

  /**
  * @param {Object} dealProps - predetermined deal props
  */
  dealPredeterminedHands(dealProps, isBidPractice, callback) {
    const deal = this.savedDealsService.createPredetermindedDeal(dealProps);
    runDeal(this, isBidPractice, callback, this.displayError.bind(this), deal);
  }

  getCardDealer(dealProps) {
    return dealProps.name === c.RANDOM_DEAL
      ? this.randomDeal.bind(this)
      : this.dealPredeterminedHands.bind(this, dealProps);
  }

  /**
  * shared auction event
  */
  onAuctionFinish(completeAuction, nextDealProgress, callback) {

    if (completeAuction.cancelled) {
      // do nothing - new deal before completed
      return;
    }

    const onAuctionViewed = () => {
      this.gamePresenter.setGameState({
        event: GameEvents.ON_DEAL_PROGRESS,
        payload: {
          dealProgress: DealProgress.auctionViewed
        },
      });
      callback();
    };

    // save this deal
    if (completeAuction.saveTestDeal) {
      this.saveTestDeal(completeAuction.saveTestDeal);
    }

    // update game - new contract in play
    const {
      dealProgress,
      dealProps,
      players
    } = this.getGameStatusProperties(nextDealProgress);

    this.gamePresenter.setGameState({
      event: GameEvents.ON_AUCTION_COMPLETED,
      payload: {
        completeAuction: {
          ...completeAuction,
          returnToGameFunc: onAuctionViewed,
        },
        dealProgress,
        dealProps,
        players,
      },
    });
  }

  /**
  * run the user interactive auction
  */
  runInteractiveAuction(callback) {

    const dealProgress = DealProgress.auctionStarted;

    // on finish event
    const onFinish = completeAuction => {
      if (completeAuction) {
        this.onAuctionFinish(completeAuction, DealProgress.auctionView, callback);
      } else {
        // FIXME: interactive auction back button clicked?
        this.runAutoAuction(callback);
        //this.gamePresenter.closeAuction();
      }
    };

    // on error event
    const onError = error => {
      this.displayError(error);
    };

    // updates the UI with bids from the simulator
    const onAddBid = (auctionData, returnBidToAuction) => {
      const waitForUserCompletion = auctionData.newBid.position === Coords.SOUTH
        || auctionData.isSettled;

      if (waitForUserCompletion
        || GamePause.NEXT_BID_WAIT > 0
      ) {
        this.gamePresenter.setGameState({
          event: GameEvents.ON_AUCTION_UPDATE,
          payload: {
            auctionData,
            returnBidToAuction,
            waitForUserCompletion,
            players: this.getPlayerProps(dealProgress),
          },
        });
      }

      if (!waitForUserCompletion) {
        setTimeout(returnBidToAuction, GamePause.NEXT_BID_WAIT);
      }

    };

    // kickoff the auction
    this.bridgeGameService.holdAuction({
      onFinish,
      onError,
      onAddBid
    });

    this.gamePresenter.setGameState({
      event: GameEvents.ON_DEAL_PROGRESS,
      payload: { dealProgress },
    });
  }

  /**
  * runs through an auction without user input
  */
  runAutoAuction(callback) {

    const onFinish = completeAuction => {
      this.onAuctionFinish(completeAuction, DealProgress.auctionCompleted, callback);
    };

    this.bridgeGameService.holdAuction({
      onFinish,
      onError: this.displayError.bind(this),
    });
  }

  /**
  * run the selected auction
  */
  getAuctionRunner() {
    return this.gamePresenter.getIsInterActiveAuction()
      ? {
          auctionRunner: this.runInteractiveAuction.bind(this),
          wait: 0,
        }
      : {
          auctionRunner: this.runAutoAuction.bind(this),
          wait: GamePause.AUCTION_VIEW_WAIT,
        };
  }

  /**
  * run the card play simulator
  */
  playCards(callback) {

    // broadcast the next card played
    const onCardPlayed = ({ cardProps, gameReturnFunc}) => {

      const cardPlay = overrideCard => gameReturnFunc(overrideCard);

      // show the card play
      this.gamePresenter.setGameState({
        event: GameEvents.ON_PLAY_CARD,
        payload: {
          cardProps,
          gameReturnFunc: cardPlay,
        },
      });
    };

    // brodcast the trick finished trick
    const onTrickCompleted = trickData => {
      this.gamePresenter.setGameState({
        event: GameEvents.ON_TRICK_COMPLETE,
        payload: {
          ...trickData,
          dealProgress: DealProgress.trickPlayed
        },
      });
    };

    // brodcast the completed hand tally
    const onPlayComplete = handTallyData => {
      // add the tally to the score keeper
      this.addTallyData(handTallyData);

      this.gamePresenter.setGameState({
        event: GameEvents.ON_HAND_PLAYED_OUT,
        payload: {
          tallyData: this.getMatchScore(),
          gameReturnFunc: callback,
          dealProgress: DealProgress.handPlayedOut
        },
      });
    };

    // display the returned error
    const onError = error => {
      this.displayError(error);
    };

    // the card play
    this.bridgeGameService.playCards(
      onCardPlayed,
      onTrickCompleted,
      onPlayComplete,
      onError
    );
  }

  //
  runSavedDealsTest() {
    import('../deal-db/test-saved-deals')
      .then(({ testSavedDeals }) => {
        const testPlayers = createBridgePlayers(['1', '2', '3', '4'], getBidSystemConfig());
        const testGameService = createBridgeGameService(testPlayers);
        testSavedDeals({
          testGameService,
          savedDealsService: this.savedDealsService,
          gamePresenter: this.gamePresenter,
        });
      }
    );
  }

  saveTestDeal({ folderName, bidSequence }) {
    const deal = this.bridgeGameService.getExternalExport({
      id: uuid.v4(),
      name: bidSequence,
    });
    this.savedDealsService.insertFolderDeal(folderName, deal);
  }

  runTest() {
    const onFinish = completeAuction => {
      if (completeAuction.saveTestDeal) {
        this.saveTestDeal(completeAuction.saveTestDeal);
      }
    };

    const onError = error => {
      const deal = this.bridgeGameService.getExternalExport({
        id: uuid.v4(),
        name: error.message,
      });
      const savedDeal = this.savedDealsService.insertFolderDeal('Error Deals', deal);
      // deal the updates to refresh
      this.dealPredeterminedHands(savedDeal);
      this.displayError(error);
      this.bridgeGameService.runTest(onFinish, onError);
    };

    if (process.env.REACT_APP_TEST_TYPE === 'infinite') {
      //($env:REACT_APP_TEST_TYPE = "infinite") -and (npm start)
      this.bridgeGameService.runTest(onFinish, onError);
    } else if (process.env.REACT_APP_TEST_TYPE === 'saved') {
      this.runSavedDealsTest();
    }
  }
}

/**
* @function runDeal
*/
const runDeal = async function runDeal(gameModel, isBidPractice, onCompleteRun, onError, deal) {

  const clearPreviousDeal = () => {
    return new Promise(resolve => {
      gameModel.clearPreviousDeal();
      setTimeout(resolve, GamePause.CLEAR_PREVIOUS_DEAL_WAIT);
    });
  };

  const dealCards = deal => {
    return new Promise(resolve => {
      gameModel.dealCards(deal);
      setTimeout(resolve, GamePause.DEAL_CARDS_WAIT);
    });
  };

  const runAuction = () => {
    return new Promise(resolve => {
      const { auctionRunner, wait } = gameModel.getAuctionRunner();
      setTimeout(auctionRunner, wait, resolve);
    });
  };

  try {
    await clearPreviousDeal();

    await dealCards(deal);

    // auto run the auction after deal
    await runAuction();

    // auto run the card play - continueSession will loop back to the presenter
    if (!isBidPractice
      && gameModel.getHasPlayableAuction()) {
      gameModel.playCards(onCompleteRun);
    } else {
      onCompleteRun();
    }
  } catch (e) {
    onError(e);
  }

};

const createRandomDealName = ({ dealProps }) => {
  const { dealerPosition, contract } = dealProps;
  const dealer = () => `Dealer-${dealerPosition}`;
  const contractDisp = () => contract
    ? `Contract-${contract.declarer}-${contract.value}`
    : '';
  const date = new Date();

  return `${dealer()} ${contractDisp()} ${date.toLocaleString()}`;
};


export default createGameModel;
