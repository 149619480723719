import { compose, has, partial } from 'ramda';

import { constants as c } from '../../constants';
import Suit, { getIsStrainHigher } from '../../suit';

import { getForcingPointRangeFor } from '../point-range';
import {
  getTakeoutDoublePointRange,
  getMinimumResponsePoints,
  getMinimumOpenPoints 
} from '../bid-system-config'

/**
* @return {Bid} returns the last non-passed bid
*/
export const getLastBid = function getLastBid(bids) {
  const reversed = [ ...bids ].reverse();
  return reversed.find(bid => !bid.getIsPassed());
};

/**
* @return {Bid} returns the first non-passed bid
*/
export const getFirstBid = function getFirstBid(bids) {
  return bids.find(bid => !bid.getIsPassed());
};

/**
* @return {Bid} returns if the bid is the players first bid
*/
export const getIsPlayersFirstBid = function getIsPlayersFirstBid(player, bid) {
  const firstBid = getFirstBid(player.getBids());
  return firstBid && bid.equals(firstBid);
};

/**
* @param {Array} - bids
* @return {boolean} returns true if opening Bid in bids is No Trump
*/
export const getIsOpenedNoTrump = function getIsOpenedNoTrump(bids) {
  const firstBid = getFirstBid(bids);
  return firstBid && firstBid.strain === c.NO_TRUMP;
};

export const getIsPlayerStrongTwoClubOpener = function(player) {
  return getIsStrongTwoClub(player.getFirstBid())
}

export const getIsStrongTwoClub = function getIsStrongTwoClub(bid) {
  return bid && bid.getIsStrongTwoClubs();
}

export const getHasPartnerOpenedStrongTwoClub = function getHasPartnerOpenedStrongTwoClub(player) {
  return compose(
    getIsStrongTwoClub,
    getFirstBid,
  )(player.getPartnersBids());
};

export const getIsPlayerOpenedNoTrumpUpperRange = function getIsPlayerOpenedNoTrumpUpperRange(player) {
  return getIsOpenedNoTrump(player.getBids())
    && player.hand.getHighCardPoints() >= 16;
};

export const getIsPassedHand = function getIsPassedHand(player) {
  const bids = player.getAllAuctionBids();
  if (bids.length !== 0) {
    return bids.some(bid => getIsPlayerBidPass(player, bid));
  }
  return false;
};

const getIsPlayerBidPass = function getIsPlayerBidPass(player, bid) {
  return bid.player === player && bid.getIsPassed();
};

/**
* @function getIsPassedOvercall
*  return if the player has previously passed opportunity to overcall
*/
export const getIsPassedOvercall = function getIsPassedOvercall(player) {
  if (player.getAllAuctionBids().length > 0) {
    const isPassed = partial(getIsPlayerBidPass, [player]);
    const bids = [...player.getAllAuctionBids()];

    if (bids.filter(bid => isPassed(bid)).length >=2) {
      return true;
    }
    // players first pass
    const index = bids.findIndex(bid => isPassed(bid));
    if (index > -1) {
      // is there an opening bid before your pass
      return !bids.concat(0, index).every(bid => bid.getIsPassed());
    }
    return false;
  }
  return false;
};

/**
* @function
* @param {Array} bids - Array of our bids
* @param {Hand} hand - intance of one of 'Our' partnership player's hand
* @param {string} strain - suit or NT
* uses the minium of range
* @return {number}
*/
export const getOurTeamPoints = function getOurTeamPoints(bids, hand, strain) {
  let pts = 0;
  if (bids.length === 1) {
    if (!hand.player.getIsOpener()) {
      pts = getBidPoints(hand.player.getPartner());
    }
    pts += strain === c.NO_TRUMP
      ? hand.getHighCardPoints()
      : hand.getTotalPoints(strain);
  } else {
    pts = getBidPoints(hand.player.getPartner());
    pts += strain === c.NO_TRUMP
      ? hand.getHighCardPoints()
      : hand.getTotalPoints(strain);
  }
  return pts;
};

/**
* @function getIsPartnerForcing
*/
export const getIsPartnerForcing = function getIsPartnerForcing(player) {
  return getBidPoints(player.getPartner())
    >= getForcingPointRangeFor(player.getPartner()).lower;
};

/**
* @function getHasPartnerBidYourSuit
*/
export const getHasPartnerBidYourSuit = function getHasPartnerBidYourSuit(player, bid) {
  return player.getSuitsYouBid().some(suit => suit.strain === bid.strain);
};

/**
* @function
* @param {Array} bids - array of their bids
* @return {number} returns points expressed in the bids. uses the minium of range
*/
export const getTheirTeamPoints = function getTheirTeamPoints(bids) {
  let pts1, pts2;
  bids.forEach(bid => {
    if (!pts1) {
      pts1 = {
        player: bid.player,
        points: 0
      };
    } else if (!pts2
      && bid.player !== pts1.player) {
      pts2 = {
        player: bid.player,
        points: 0
      };
    }
    bid.promises.forEach(promise => {
      if (has("lower", promise)) {
        if (pts1.player === bid.player
          && promise.lower > pts1.points) {
          pts1.points = promise.lower;
        } else if (pts2
          && pts2.player === bid.player
          && promise.lower > pts2.points) {
          pts2.points = promise.lower;
        }
      }
    });
  });
  return (pts1 ? pts1.points : 0)
    + (pts2 ? pts2.points : 0);
};

export const getPartnersBidPoints = function getPartnersBidPoints(bids, hand) {
  return bids.reduce((pts, bid) => {
    if (hand.player !== bid.player) {
      if (bid.getPromisedPoints() > pts) {
        pts = bid.getPromisedPoints();
      }
    }
    return pts;
  }, 0);
};

export const getBidPoints = function getBidPoints(player) {
  return player.getBids().reduce((pts, bid) => {
    if (bid.getPromisedPoints() > pts) {
      pts = bid.getPromisedPoints();
    }
    return pts;
  }, 0);
};

export const getIsLevelJump = function getIsLevelJump(level, beatBid, strain) {
  return getJumpLevel(beatBid, strain) === level;
};

export const getJumpLevel = function getJumpLevel(beatBid, strain) {
  const level = checkBidLadder(beatBid, strain, beatBid.level + 1);
  if (getIsStrainHigher(beatBid.strain, strain)) {
    return level + 1;
  }
  return level;
};

export const getIsTakeOutDoubleHand = function getIsTakeOutDoubleHand(hand, beatBid) {

  if (beatBid.level > c.THREE_LEVEL
    || getIsPassedOvercall(hand.player)) {
    return false;
  }

  const takeoutDoublePointRange = getTakeoutDoublePointRange(hand.player);

  const pointsRequired = getIsOpenedNoTrump(hand.player.getTheirBids())
    ? takeoutDoublePointRange.noTrumpLower
    : takeoutDoublePointRange.lower;

  const hcp = hand.getHighCardPoints();
  if (hcp < pointsRequired) {
    return false;
  }

  if ((hand.player.partnersBid
    && hand.player.partnersBid.getIsPassed())
    && hand.player.getTheirBids().length >= 2
    && beatBid.level > 2
    && hcp <= takeoutDoublePointRange.threeLevelLower) {
    return false;
  }

  const isShortInTheirSuit = () => hand.getSuit(beatBid.strain).getLength() <= 3;

  const hasAtLeastThreeCardsInTheUnbidSuits = () => getUnbidSuits(hand).every(suit =>
    suit.getLength() >= c.THREE_CARD_SUIT + (hand.player.getTheirBids().length - 1));

  if ((isShortInTheirSuit() && hasAtLeastThreeCardsInTheUnbidSuits())
      || (hcp >= takeoutDoublePointRange.sixCardSuitLower && hand.getHasLongStrongSuit(c.SIX_CARD_SUIT))
      || (hcp >= takeoutDoublePointRange.strongHandLower)
    ) {
    return true;
  }
};

export const getDidTheyPrempt = function getDidTheyPrempt(theirBids) {
  return theirBids.some(bid => bid.getIsPreempt());
};

export const getDidTheyShowDistribution = function getDidTheyShowDistribution(theirBids) {
  return theirBids.some(bid => bid.getIsPreempt()
    || bid.getIsMichaelsOrUnusualNoTrump()
    || bid.getIsSixCardSuitRebid()
    || bid.getIsTrumpFit()
  );
};

export const getDoubledBid = function getDoubledBid(bids) {
  return bids.find(bid => bid.isDoubled);
};

export const getDeclarerBid = function getDeclarerBid(bids, contractBid) {
  return bids.find(bid => bid.strain === contractBid.strain
    && bid.player.getTeam() === contractBid.player.getTeam());
};

export const getLosingTrickCount = function getLosingTrickCount(bids, hand, trumpStrain) {
  const totalTricks = 25;
  const cnt = hand.getLosingTrickCount(trumpStrain);
  const  pts = getBidPoints(hand.player.getPartner());
  const  partnerCnt = pts === 0
    ? 12 : pts <= 10
      ? 9 : pts <= 12
        ? 8 : pts <= 15
          ? 7 : pts <= 18
            ? 6 : pts <= 19
              ? 5 : 4;
  return totalTricks - cnt - partnerCnt;
};

export const getPromisedStrains = function getPromisedStrains(bids) {
  const merge = (a, b) => new Set([...a, ...b]);
  let strains = new Set();
  bids.forEach((bid) => {
    strains = merge(strains, bid.getPromisedStrains());
  });
  return strains;
};

export const getIsStrainTheirs = function getIsStrainTheirs(player, strain) {
  return player.getTheirBidStrains().has(strain);
};

export const getUnbidSuits = function getUnbidSuits(hand, suitsSubset, bids = hand.player.getAllAuctionBids()) {
  const suits = suitsSubset
    ? suitsSubset
    : hand.suits;

  return suits.filter(suit => {
    return !getIsStrainBid(bids, suit.strain);
  });
};

export const getBidSuits = function getBidSuits(bids, hand) {
  return Array.from(bids.reduce((acc, bid) => {
    if (!bid.getIsNoTrump()) {
      acc.add(hand.getSuit(bid.strain));
    }
    return acc;
  }, new Set()));
};

export const getIsStrainBid = function getIsStrainBid(bids, strain) {
  return bids.some(bid => bid.strain === strain);
};

export const getUnbidMajorSuits = function getUnbidMajorSuits(hand, suitLength = c.FOUR_CARD_SUIT) {
  return getUnbidSuits(hand, hand.suits.filter(
    suit => suit.getIsMajor() && suit.getLength() >= suitLength));
};

export const getUnbidFourCardMajorSuit = function getUnbidFourCardMajorSuit(hand) {
  const suits = getUnbidMajorSuits(hand);
  if (suits.length === 0) {
    return Suit.getVoidSuit();
  }
  if (suits.length === 1) {
    return suits[0];
  }
  return suits.sort((a, b) => {
     return getIsStrainHigher(a.strain, b.strain)
       ? 1 : 0;
  })[0];
};

/**
* @function getUnbidSuit
* @return next available suit with 4 cards
*/
export const getUnbidSuit = function getUnbidSuit(hand, beatBid, suitLength = c.FOUR_CARD_SUIT) {
  const unBidSuits = getUnbidSuits(hand, hand.getSuits(suitLength), hand.player.getAllAuctionBids());
  unBidSuits.sort((a, b) => {
    const aLevel = checkBidLadder(beatBid, a.strain);
    const bLevel = checkBidLadder(beatBid, b.strain);
    if (aLevel < bLevel) {
      return -1;
    } else if (aLevel > bLevel) {
      return 1;
    }
    if (getIsStrainHigher(a.strain, b.strain)) {
      return 1;
    } else {
      return -1;
    }
  });
  return unBidSuits[0];
};

/**
* @param beatBid - must be higher than this bid
* @param strain - suit of the possible new bid
* @param checkLevel - level to test
*
* @return {boolean} returns true if equal to checklevel
*/
export const getIsAtLevel = function getIsAtLevel(beatBid, strain, checkLevel) {
  return checkBidLadder(beatBid, strain) === checkLevel;
};

/**
* @function getIsSatisfyLawOfTotalTricks
*
* @return {boolean} .
*/
export const getIsSatisfyLawOfTotalTricks = function getIsSatisfyLawOfTotalTricks(level, trumpCnt) {
  return trumpCnt >= (c.BOOK + level);
};

/**
* @param beatBid - must be higher than this bid
* @param strain - suit of the possible new bid
*
* @return {boolean} returns true if equal to three level
*/
export const getIsAtThreeLevel = function getIsAtThreeLevel(beatBid, strain) {
  return getIsAtLevel(beatBid, strain, c.THREE_LEVEL);
};

/**
 * @param {Bid} beatBid - must be higher than this bid
 * @param {string} strain - suit of the possible new bid
 *
 * @return {boolean} returns true if equal to two level
 */
 export const getIsAtTwoLevel = function getIsAtTwoLevel(beatBid, strain) {
  return getIsAtLevel(beatBid, strain, c.TWO_LEVEL);
};

/**
 * @param {Bid} beatBid - must be higher than this bid
 * @param {string} strain - suit of the possible new bid
 *
 * @return {boolean} returns true if equal to one level
 */
export const getIsAtOneLevel = function getIsAtOneLevel(beatBid, strain) {
   return getIsAtLevel(beatBid, strain, c.ONE_LEVEL);
};

export const getIsGreaterThanOneLevel = function getIsGreaterThanOneLevel(beatBid, strain) {
  return checkBidLadder(beatBid, strain) > 1;
};

/**
 * @param beatBid - must be higher than this bid
 * @param strain - suit of the possible new bid
 * @param level - starting point
 *
 * @return {int} returns the allowable level for the next bid
 */
export const checkBidLadder = function checkBidLadder(beatBid, strain, level = 1) {
  let newLevel = level;

  if (newLevel > beatBid.level) {
    return newLevel;
  }
  while (newLevel < beatBid.level) {
    newLevel += 1;
  }

  if (!getIsStrainHigher(strain, beatBid.strain)) {
    newLevel += 1;
  }
  return newLevel;
};

export const getOutSideSuitStopperCount = function getOutSideSuitStopperCount(player) {
  const strains = c.CardDeck.suits.filter(strain => player.getOurBids().findIndex(bid => bid.strain === strain) === -1);
  return strains.reduce((acc, strain) => {
      const suit = player.hand.getSuit(strain);
      return acc + (suit.getLength() === 0
        ? 2 : suit.getHasAce() && suit.getHasKing()
          ? 2 : suit.getHasAce() || suit.getHasKing()
            ? 1 : suit.getLength() === 1
              ? 1 : 0);
    } , strains.length === 1 ? 1 : 0);
};

export const getNegativePromisedStrains = function getNegativePromisedStrains(bid) {
  return (cardStrains => {
    const bidStrains = [
      bid.player.getFirstBid().strain,
      getFirstBid(bid.player.getTheirBids()).strain
    ];
    return cardStrains.filter(strain =>
      !bidStrains.find(bidStrain => bidStrain === strain));
  })(c.CardDeck.suits).filter(strain =>
      (Suit.getIsMajor(strain) && bid.getIsNegativeDoubleMajor())
      || (Suit.getIsMinor(strain) && bid.getIsNegativeDoubleMinor())
    );
};

export const getHasStoppersInTheirBidSuits = function getHasStoppersInTheirBidSuits(hand) {
  return getHasStoppersOrLengthInTheirBidSuits(hand);
};

export const getHasStoppersOrLengthInTheirBidSuits = function getHasStoppersOrLengthInTheirBidSuits(hand, isCheckHasBidLength = false) {
  const theirBids = hand.player.getTheirBids();
  const partnersBids = hand.player.getPartnersBids();
  return theirBids.every(theirBid => {
    if (theirBid.getIsTakeOutDouble()) {
      return getHasStoppersInUnbidSuits(hand);
    }
    if (theirBid.getIsNegativeDouble()) {
      return getNegativePromisedStrains(theirBid).every(strain =>
        hand.getSuit(strain).getHasStoppers());
    }
    if (theirBid.getIsNoTrump()
    ) {
      return true;
    }
    if (hand.getSuit(theirBid.strain).getHasStoppers()) {
      return true;
    }
    return partnersBids.some(partnersBid =>
      partnersBid.getIsPromisedStopperInStrain(theirBid.strain)
      || (isCheckHasBidLength
        && partnersBid.strain === theirBid.strain
        && partnersBid.getPromisedSuitLength() >= c.FOUR_CARD_SUIT)
    );
  });
};

export const getHasPartnerBidStrain = function getHasPartnerBidStrain(player, strain) {
  return player.getSuitsPartnerBid().some(bid => bid.strain === strain);
};

export const getHasStoppersInUnbidSuits = function getHasStoppersInUnbidSuits(hand) {
  return getHasStoppersInSuits(getUnbidSuits(hand));
};

export const getHasStoppersInOtherStrains = function getHasStoppersInOtherStrains(hand, ignoreStrain) {
  const suits = c.SUIT_STRAINS.filter(strain => strain !== ignoreStrain).map(strain => hand.getSuit(strain));
  return getHasStoppersInSuits(suits);
};

export const getHasStoppersInSuits = function getHasStoppersInSuits(suits) {
  return suits.every(suit => suit.getHasStoppers());
};

export const getIsSlamBid = function getIsSlamBid(bid) {
  return !bid.getIsArtificial()
    && (bid.level === c.SIX_LEVEL
    || bid.level === c.SEVEN_LEVEL);
};

export const getIsQuanatative = function getIsQuanatative(player, hcp, lower) {
  return (getBidPoints(player.getPartner()) + hcp) >= lower;
};

export const getIsMajorSuitGame = function getIsMajorSuitGame(points) {
  return points >= c.CONTRACT_MIN_PTS_REQS.MAJOR_GAME;
};

export const getIsMinorSuitGame = function getIsMinorSuitGame(points) {
  return points >= c.CONTRACT_MIN_PTS_REQS.MINOR_GAME;
};

export const getHasGameInStrain = function getHasGameInStrain(strain, team, checkLTC = true) {
  if (Suit.getIsMajor(strain)
    && (getIsMajorSuitGame(team.points) || (checkLTC && team.ltc >= 10))) {
    return true;
  }
  if (Suit.getIsMinor(strain)
    && (getIsMinorSuitGame(team.points) || (checkLTC && team.ltc >= 11))) {
    return true;
  }
  if (strain === c.NO_TRUMP
    && (team.points >= c.CONTRACT_MIN_PTS_REQS.NT_GAME)) {
    return true;
  }
  return false;
};

export const getHasBidNoTrump = function getHasBidNoTrump(bids) {
  return bids.some(bid => bid.getIsNoTrump());
};

export const getHasBidType = function getHasBidType(bids, bidType) {
  return bids.some(bid => bid.getHasPromiseType(bidType));
};

export const getHasGameInSuit = function getHasGameInSuit(player, strain) {
  const team = player.getPartnerShipValues(strain);
  const isTheyBidNoTrump = () => getIsOpenedNoTrump(player.getTheirBids());
  const hasStoppersInOutsideSuits = () => getOutSideSuitStopperCount(player) >= (Suit.getIsMinor(strain) ? 3 : 2);

  return getHasGameInStrain(strain, team, false)
    || (getHasGameInStrain(strain, team)
      && hasStoppersInOutsideSuits()
      && !(isTheyBidNoTrump() && team.getIsDistributional(team.points, strain)));
};

export const getHasPartnerShownLength = function getHasPartnerShownLength(bids) {
  bids.find(bid =>
    bid.getPromisedSuitProps().find(({ suitLength }) => suitLength
      && suitLength >= c.FIVE_CARD_SUIT));
};

export const getPartnersPromisedLengthIn = function getPartnersPromisedLengthIn(bidStrain, bids) {
  let promisedLength = 0;
  bids.forEach(bid => {
    bid.getPromisedSuitProps().forEach(({ strain, suitLength }) => {
      if (strain
        && strain === bidStrain
        && suitLength > promisedLength) {
          promisedLength = suitLength;
      }
    });
  });
  return promisedLength;
};

export const getCombinedPartnersSuitLength = function getCombinedPartnersSuitLength(hand, strain) {
  return hand.getSuit(strain).getLength()
    + getPartnersPromisedLengthIn(strain, hand.player.getPartnersBids());
};

export const getOtherMajor = function getOtherMajor(strain) {
  return strain === c.HEART
    ? c.SPADE
    : c.HEART;
};

export const getOtherMajorHasAtLeastFourCards = function getOtherMajorHasAtLeastFourCards(hand, beatBid) {
  return hand.getSuit(getOtherMajor(beatBid.strain)).getLength() >= c.FOUR_CARD_SUIT;
};

export const getStrongTwoSuit = function getStrongTwoSuit(hand) {
  return hand.getSuits(c.FOUR_CARD_SUIT).sort((a, b) => {

    if (a.getLength() > b.getLength()) {
      return -1;
    }
    if (b.getLength() > a.getLength()) {
      return 1;
    }

    if (a.getIsMajor() && b.getIsMajor()) {
      if (a.getHighCardPoints() >= b.getHighCardPoints()) {
        return -1;
      }
      return 1;
    }

    if (a.getHighCardPoints() >= b.getHighCardPoints()) {
      return -1;
    }
    return 1;
  })[0];
};

export const getPassWithMinimalResponseHandProps = function getPassWithMinimalResponseHandProps(player) {
  return {
    lower: getMinimumResponsePoints(player),
    extraText: c.MINIMAL_RESP_HAND_PASS_EXTRA_TEXT_LABEL,
  };
};

export const getPassWithMinimalOpenHandProps = function getPassWithMinimalOpenHandProps(player, promise) {
  return {
    lower: getMinimumOpenPoints(player),
    inRange: false,
    extraText: c.MINIMAL_HAND_PASS_EXTRA_TEXT_LABEL,
    promise: promise,
  };
};
