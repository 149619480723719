import { createBidOptions } from '../create-bid-options';
import { PASS_OPTION } from '../pass-option';

import {
  RAISE,
  SIMPLE_RAISE,
  JUMP_RAISE,
  STRONG_GAME_RAISE,
  WEAK_GAME_RAISE,
  NEW_SUIT,
  TWO_NO_TRUMP,
} from './types';

import {
  createRanges,
  WEAK_TWO_RESPONSE_RANGES,
} from './ranges';

import { createLabels } from './labels';

const getRange = function getRange({ bidSystemConfig }, key) {
  return bidSystemConfig[ WEAK_TWO_RESPONSE_RANGES ][ key ];
}

const getGroupType = function getGroupType(type) {
  switch (type) {
    case SIMPLE_RAISE:
    case STRONG_GAME_RAISE:
    case WEAK_GAME_RAISE:
      return RAISE;
    default:
      return type;
  }
};

const getOptions = function getOptions() {
  return createBidOptions([
    SIMPLE_RAISE,
    STRONG_GAME_RAISE,
    WEAK_GAME_RAISE,
    //JUMP_RAISE,
    //PLAY_THREE_NT,
    NEW_SUIT,
    TWO_NO_TRUMP,
    PASS_OPTION,
  ], getGroupType);
};

export {
  SIMPLE_RAISE,
  JUMP_RAISE,
  //PLAY_THREE_NT,
  NEW_SUIT,
  TWO_NO_TRUMP,
  WEAK_TWO_RESPONSE_RANGES,
  STRONG_GAME_RAISE,
  WEAK_GAME_RAISE,
  createRanges,
  createLabels,
  getOptions,
  getRange,
};
