import { createBidOptions } from '../create-bid-options';

import {
  TWO_NO_TRUMP,
  THREE_NO_TRUMP_OVER_THREE_MINOR,
  THREE_NO_TRUMP_OVER_TWO_MAJOR,
  RAISE,
  NEW_SUIT,
} from './types';

import { STRONG_TWO_OPENER_RESPONSE_TO_POSITIVE_SUIT_RANGES, createRanges } from './ranges';

import { createLabels } from './labels';

function getOptions(/*bidSystemConfig*/) {
  return createBidOptions([
    TWO_NO_TRUMP,
    THREE_NO_TRUMP_OVER_THREE_MINOR,
    THREE_NO_TRUMP_OVER_TWO_MAJOR,
    RAISE,
    NEW_SUIT,
  ]);
};

function getRanges({ bidSystemConfig }) {
  return bidSystemConfig[ STRONG_TWO_OPENER_RESPONSE_TO_POSITIVE_SUIT_RANGES ];
};

export {
  TWO_NO_TRUMP,
  THREE_NO_TRUMP_OVER_THREE_MINOR,
  THREE_NO_TRUMP_OVER_TWO_MAJOR,
  RAISE,
  NEW_SUIT,
  STRONG_TWO_OPENER_RESPONSE_TO_POSITIVE_SUIT_RANGES,
  createRanges,
  getRanges,
  getOptions,
  createLabels,
};
