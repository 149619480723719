import {
  JACOBY_TRANSFER_RESPONSE,
  LANDY_ADVANCE,
  LANDY_OVERCALLER_REBID_AFTER_TWO_NO_TRUMP_ADVANCE,
  OPEN_THE_BIDDING,
  OPENER_REBID_AFTER_NEG_DBL,
  OVERCALL_AND_TAKEOUT_DOUBLE,
  OVERCALL_NO_TRUMP_OPEN,
  OVERCALL_ADVANCE,
  PARTNER_OPENED_SUIT,
  RESPONSE_AFTER_SUIT_OVERCALL,
  RESPONSE_AFTER_STRONG_JUMP_SHIFT,
  RESPONSE_AFTER_TAKEOUT_DOUBLE,
  RESPONSE_TO_PARTNERS_TAKEOUT_DOUBLE,
  RESPONSE_AFTER_NO_TRUMP_OVERCALL,
  RESPONSE_TO_FOURTH_SUIT_FORCING,
  RESPONSE_TO_NO_TRUMP_OPEN,
  RESPONSE_TO_ONE_OF_A_SUIT_OPEN,
  RESPONSE_TO_WEAK_TWO_OPEN,
  RESPONSE_TO_STRONG_TWO_OPEN,
  STRONG_TWO_OPENER_RESPOND_TO_POSITIVE_SUIT,
} from './types';

import { Labels } from './labels';

const getTypes = function getTypes() {
  return [
    OPEN_THE_BIDDING,
    //RESPONSE_TO_ONE_OF_A_SUIT_OPEN,
    RESPONSE_TO_NO_TRUMP_OPEN,
    OVERCALL_AND_TAKEOUT_DOUBLE,
  ];
};

export {
  JACOBY_TRANSFER_RESPONSE,
  LANDY_ADVANCE,
  LANDY_OVERCALLER_REBID_AFTER_TWO_NO_TRUMP_ADVANCE,
  OPEN_THE_BIDDING,
  OPENER_REBID_AFTER_NEG_DBL,
  OVERCALL_AND_TAKEOUT_DOUBLE,
  OVERCALL_NO_TRUMP_OPEN,
  OVERCALL_ADVANCE,
  PARTNER_OPENED_SUIT,
  RESPONSE_AFTER_SUIT_OVERCALL,
  RESPONSE_AFTER_STRONG_JUMP_SHIFT,
  RESPONSE_AFTER_TAKEOUT_DOUBLE,
  RESPONSE_TO_PARTNERS_TAKEOUT_DOUBLE,
  RESPONSE_AFTER_NO_TRUMP_OVERCALL,
  RESPONSE_TO_FOURTH_SUIT_FORCING,
  RESPONSE_TO_NO_TRUMP_OPEN,
  RESPONSE_TO_ONE_OF_A_SUIT_OPEN,
  RESPONSE_TO_WEAK_TWO_OPEN,
  RESPONSE_TO_STRONG_TWO_OPEN,
  STRONG_TWO_OPENER_RESPOND_TO_POSITIVE_SUIT,
  Labels,
  getTypes,
};
