import {
  STAYMAN,
  TWO_LEVEL_FIVE_CARD_SUIT,
  MINOR_SUIT_STAYMAN,
  TWO_NO_TRUMP_GAME_INVITE,
  MINOR_SUIT_GAME_INVITE,
  PLAY_THREE_NO_TRUMP_GAME,
  QUANTITATIVE_FOUR_NT,
} from './types';

export const RESPONSE_OPEN_NO_TRUMP_RANGES = 'bid-options/response-open-no-trump/ranges';

export const createRanges = () => ({
  [ STAYMAN ]: {
    lower: 8,
  },
  [ TWO_LEVEL_FIVE_CARD_SUIT ]: {
    lower: 0,
    upper: 7,
  },
  [ MINOR_SUIT_STAYMAN ]: {
    lower: 0,
    upper: 7,
  },
  [ TWO_NO_TRUMP_GAME_INVITE ]: {
    lower: 8,
    upper: 9
  },
  [ MINOR_SUIT_GAME_INVITE ]: {
    lower: 6,
    upper: 8,
  },
  [ PLAY_THREE_NO_TRUMP_GAME ]: {
    lower: 10,
    upper: 15,
  },
  [ QUANTITATIVE_FOUR_NT ]: {
    lower: 16,
  },
});
