import { constants as uic } from '../../../constants';

import {
  TOGGLE_POINTS_VISIBLE,
  TOGGLE_HANDS_VISIBLE,
  TOGGLE_BID_HINTS_VISIBLE,
  TOGGLE_AUCTION_VISIBLE,
  APP_SETTINGS_UPDATE,
} from './types';

export const toggleDisplay = function toggleDisplayOption(action) {
  const options = {
    [TOGGLE_POINTS_VISIBLE]: () => ({
      type: TOGGLE_POINTS_VISIBLE,
      key: uic.IS_DISPLAY_HCP,
    }),
    [TOGGLE_HANDS_VISIBLE]: () => ({
      type: TOGGLE_HANDS_VISIBLE,
      key: uic.IS_DISPLAY_HANDS,
    }),
    [TOGGLE_BID_HINTS_VISIBLE]: () => ({
      type: TOGGLE_BID_HINTS_VISIBLE,
      key: uic.IS_DISPLAY_BID_HINTS,
    }),
    [TOGGLE_AUCTION_VISIBLE]: () => ({
      type: TOGGLE_AUCTION_VISIBLE,
      key: uic.IS_DISPLAY_AUCTION_VIEWER,
    }),
  };

  return options[action]();
};

export const appSettingUpdate = () => ({
  type: APP_SETTINGS_UPDATE,
  payload: null,
});
