import { SET_GAME_OPTIONS, SET_DEAL_QUEUE, SET_CURRENT_QUEUE_DEAL_ID } from './types';

export const setGameOptions = gameOptions => ({
  type: SET_GAME_OPTIONS,
  gameOptions,
});

export const setCurrentQueueDealId = dealId => ({
  type: SET_CURRENT_QUEUE_DEAL_ID,
  payload: dealId,
});

export const setDealQueue = dealQueue => ({
  type: SET_DEAL_QUEUE,
  payload: dealQueue
});
