import {
  OPEN_ONE_NO_TRUMP,
  OPEN_TWO_NO_TRUMP,
  OPEN_THREE_NO_TRUMP,
  OPEN_TWO_LEVEL_STRONG,
  OPEN_TWO_LEVEL_STRONG_BALANCED,
  OPEN_TWO_LEVEL_STRONG_UNBALANCED,
  OPEN_TWO_LEVEL_STRONG_OLD,
  OPEN_TWO_LEVEL_WEAK_PREEMPT,
  OPEN_THREE_LEVEL_PREEMPT,
  OPEN_FOUR_LEVEL_PREEMPT,
  OPEN_SUIT_ONE_LEVEL,
} from './types';

const OPENING_BID_RANGES = 'bid-options/opening-bid/ranges';

const createRanges = ({ isWeakTwo }) => ({
  [ OPEN_SUIT_ONE_LEVEL ]: {
    lower: 11,
    upper: 21,
  },
  [ OPEN_ONE_NO_TRUMP ]: {
    lower: 15,
    upper: 17,
  },
  [ OPEN_TWO_NO_TRUMP ]: {
    lower: 20,
    upper: 22,
  },
  [ OPEN_THREE_NO_TRUMP ]: {
    lower: 25,
    upper: 26,
  },
  ...(isWeakTwo
    ? {
        [ OPEN_TWO_LEVEL_WEAK_PREEMPT ]: {
          lower: 5,
          upper: 11,
        }
      }
    : {}
  ),
  [ OPEN_THREE_LEVEL_PREEMPT ]: {
    lower: 5,
    upper: 9,
  },
  [ OPEN_FOUR_LEVEL_PREEMPT ]: {
    lower: 5,
    upper: 11,
  },
  ...(isWeakTwo
    ? {
        [ OPEN_TWO_LEVEL_STRONG ]: {
          lower: 21,
        },
        [ OPEN_TWO_LEVEL_STRONG_BALANCED ]: {
          lower: 22,
        },
        [ OPEN_TWO_LEVEL_STRONG_UNBALANCED ]: {
          lower: 17,
         }
       }
    : {
        [ OPEN_TWO_LEVEL_STRONG_OLD ]: {
          lower: 21,
        },
      }
  )


});

export { createRanges, OPENING_BID_RANGES };
