import { constants as c } from '../../../constants';

import * as BidTypes from '../../bid-types';
import BidHelper from '../../bid-helper';
import {
  getIsJacobyTransfer,
  getOpenNoTrumpResponseRanges
} from '../../bid-system-config';

import { PASS_OPTION } from '../pass-option';
import { createBidOptions } from '../create-bid-options';

import {
  STAYMAN,
  JACOBY_TRANSFER,
  TWO_LEVEL_FIVE_CARD_SUIT,
  MINOR_SUIT_STAYMAN,
  TWO_NO_TRUMP_GAME_INVITE,
  MINOR_SUIT_GAME_INVITE,
  PLAY_THREE_NO_TRUMP_GAME,
  SLAM_TRY,
  QUANTITATIVE_FOUR_NT,
  GERBER_FOR_ACES,
} from './types';

import {
  RESPONSE_OPEN_NO_TRUMP_RANGES,
  createRanges,
} from './ranges';

import { createLabels } from './labels';

const getGroupType = function getGroupType(type) {
  switch (type) {
    case QUANTITATIVE_FOUR_NT:
    case GERBER_FOR_ACES:
      return SLAM_TRY;
    default:
      return type;
  }
};

const getOptions = function getOptions(player) {
  const fiveCardSuitOption = getIsJacobyTransfer(player)
    ? JACOBY_TRANSFER
    : TWO_LEVEL_FIVE_CARD_SUIT;

  const optionTypes = [
    STAYMAN,
    fiveCardSuitOption,
    MINOR_SUIT_STAYMAN,
    TWO_NO_TRUMP_GAME_INVITE,
    MINOR_SUIT_GAME_INVITE,
    PLAY_THREE_NO_TRUMP_GAME,
    QUANTITATIVE_FOUR_NT,
    GERBER_FOR_ACES,
    PASS_OPTION,
  ];

  return createBidOptions(optionTypes, getGroupType);
};

const jacobyTransferRules = [{
  bidOption: JACOBY_TRANSFER,
  type: BidTypes.JACOBY_TRANSFER,
  level: c.TWO_LEVEL,
  strain: c.DIAMOND,
  actualStrain: c.HEART,
  suitLength: c.FIVE_CARD_SUIT,
  range: {lower: 0},
  slr: {
    hasFourCardMajor: false,
    hasFiveCardMajor: true,
    hasSixCardMinor: false,
  },
},
{
  bidOption: JACOBY_TRANSFER,
  type: BidTypes.JACOBY_TRANSFER,
  level: c.TWO_LEVEL,
  strain: c.HEART,
  actualStrain: c.SPADE,
  suitLength: c.FIVE_CARD_SUIT,
  range: {lower: 0},
  slr: {
    hasFourCardMajor: false,
    hasFiveCardMajor: true,
    hasSixCardMinor: false,
  },
}];

const QuanitativeFourNT = {
  bidOption: QUANTITATIVE_FOUR_NT,
  type: BidTypes.QUANTITATIVE_FOUR_NT,
  level: c.FOUR_LEVEL,
  strain: c.NO_TRUMP,
  isArtificial: true,
  combinedPoints: 31,
};

const GerberForAces = {
  bidOption: GERBER_FOR_ACES,
  type: BidTypes.GERBER_FOR_ACES,
  level: c.FOUR_LEVEL,
  strain: c.CLUB,
  isArtificial: true,
  combinedPoints: c.CONTRACT_MIN_PTS_REQS.SMALL_SLAM,
};

const Stayman = {
  bidOption: STAYMAN,
  type: BidTypes.STAYMAN_BID,
  strain: c.CLUB,
  isArtificial: true,
  slr: { // suit length requirements
    hasFourCardMajor: true,
    hasFiveCardMajor: false,
    hasSixCardMinor: false,
  },
};

const MinorSuitStayman = {
  bidOption: MINOR_SUIT_STAYMAN,
  type: BidTypes.MINOR_SUIT_STAYMAN,
  strain: c.SPADE,
  isArtificial: true,
  slr: {
    hasFourCardMajor: false,
    hasFiveCardMajor: false,
    hasSixCardMinor: true,
  },
};

const getRules = function getRules(player, respondBid) {

  const isStrongTwoClubs = BidHelper.getIsPlayerStrongTwoClubOpener(
    respondBid.player.getPartner()
  );

  const getLevel = () => respondBid.getIsOneNoTrump()
    ? c.TWO_LEVEL
    : c.THREE_LEVEL;

  const ranges = respondBid.getIsOneNoTrump()
     ? getOpenNoTrumpResponseRanges(player)
     : {
         [ STAYMAN ]: {
           lower: 4,
         },
         [ PLAY_THREE_NO_TRUMP_GAME ]: {
           lower: 4,
           upper: 15,
         },
         [ MINOR_SUIT_STAYMAN ]: {
           lower: 4
         },
         [ MINOR_SUIT_GAME_INVITE ]: {
           lower: 6,
         },
         [ QUANTITATIVE_FOUR_NT ]: {
           lower: isStrongTwoClubs ? 24 : 12
         }
       };

  const OldWeakFiveCardRule = [{
    bidOption: TWO_LEVEL_FIVE_CARD_SUIT,
    type: BidTypes.OPEN_NO_TRUMP_WEAK_FIVE_CARD_SUIT_RESPONSE,
    level: c.TWO_LEVEL,
    // FIXME: needs own range?
    range: ranges[ respondBid.getIsOneNoTrump() ? TWO_LEVEL_FIVE_CARD_SUIT : MINOR_SUIT_STAYMAN ],
    slr: {
      hasFourCardMajor: false,
      hasFiveCardMajor: false,
      hasSixCardMinor: false,
      hasFiveCardSuit: true,
    },
  }];

  const TwoNoTrumpGameInvite = respondBid.getIsOneNoTrump()
    ? [{
        bidOption: TWO_NO_TRUMP_GAME_INVITE,
        type: BidTypes.INVITE_THREE_NO_TRUMP_GAME,
        level: c.TWO_LEVEL,
        strain: c.NO_TRUMP,
        slr: {
          hasFourCardMajor: false,
          hasFiveCardMajor: false,
          hasSixCardMinor: false,
        },
        range: ranges[ TWO_NO_TRUMP_GAME_INVITE ],
      }]
    : [];


  const rules = [
    {
      ...QuanitativeFourNT,
      range: ranges[ QUANTITATIVE_FOUR_NT ],
    },
    GerberForAces,
    {
      ...Stayman,
      level: getLevel(),
      range: ranges[ STAYMAN ],
    },
    {
      ...MinorSuitStayman,
      level: getLevel(),
      range: ranges[ MINOR_SUIT_STAYMAN ],
    },
    ...TwoNoTrumpGameInvite,
    {
      bidOption: PLAY_THREE_NO_TRUMP_GAME,
      type: BidTypes.PLAY_THREE_NO_TRUMP_GAME,
      level: c.THREE_LEVEL,
      strain: c.NO_TRUMP,
      slr: {
        hasFourCardMajor: false,
        hasFiveCardMajor: false,
      },
      range: ranges[ PLAY_THREE_NO_TRUMP_GAME ],
    },
    {
      bidOption: MINOR_SUIT_GAME_INVITE,
      type: BidTypes.INVITE_THREE_NO_TRUMP_WITH_SIX_CARD_MINOR,
      level: respondBid.level + c.TWO_LEVEL,
      strain: c.CLUB,
      actualStrain: c.CLUB,
      suitLength: c.SIX_CARD_SUIT,
      slr: {
        hasFourCardMajor: false,
        hasFiveCardMajor: false,
        hasSixCardMinor: true
      },
      range: ranges[ MINOR_SUIT_GAME_INVITE ],
    },
    {
      bidOption: MINOR_SUIT_GAME_INVITE,
      type: BidTypes.INVITE_THREE_NO_TRUMP_WITH_SIX_CARD_MINOR,
      level: respondBid.level + c.TWO_LEVEL,
      strain: c.DIAMOND,
      actualStrain: c.DIAMOND,
      suitLength: c.SIX_CARD_SUIT,
      slr: {
        hasFourCardMajor: false,
        hasFiveCardMajor: false,
        hasSixCardMinor: true,
      },
      range: ranges[ MINOR_SUIT_GAME_INVITE ],
    },
  ];

return [
  ...rules,
  ...(getIsJacobyTransfer(player)
    ? jacobyTransferRules
    : OldWeakFiveCardRule
    ),
  ];
};

export {
  RESPONSE_OPEN_NO_TRUMP_RANGES,
  createRanges,
  createLabels,
  getOptions,
  getRules,
};
