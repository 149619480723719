import {
  TWO_NO_TRUMP,
  THREE_NO_TRUMP_OVER_THREE_MINOR,
  THREE_NO_TRUMP_OVER_TWO_MAJOR,
  RAISE,
  NEW_SUIT,
} from './types';

export const STRONG_TWO_OPENER_RESPONSE_TO_POSITIVE_SUIT_RANGES = 'bid-options/STRONG_TWO_OPENER_RESPONSE_TO_POSITIVE_SUIT_RANGES';

export const createRanges = () => ({
  [ TWO_NO_TRUMP ]: {
    lower: 23,
    upper: 24,
  },
  [ THREE_NO_TRUMP_OVER_THREE_MINOR ]: {
    lower: 23,
    upper: 24,
  },
  [ THREE_NO_TRUMP_OVER_TWO_MAJOR ]: {
    lower: 25,
    upper: 27,
  },
  [ RAISE ]: {
    lower: 17,
  },
  [ NEW_SUIT ]: {
    lower: 17,
  },
});
