import React from 'react';
import PropTypes from 'prop-types';

import { useHistory } from "react-router-dom";

const MenuItem = function MenuItem(props) {
  const history = useHistory();

  const handleClick = event => {
    event.preventDefault();
    if (props.linkProps) {
      history.push(props.linkProps.to);
    } else {
      return props.onClick(event.currentTarget.id);
    }
  };

  return (
    <li key={props.id}>
      <button
        id={props.id}
        title={props.title}
        className={"folder-content__button drop-down__button"}
        onClick={handleClick}
      >
        {props.label}
      </button>
    </li>
  );
};

MenuItem.propTypes ={
  id: PropTypes.string,
  linkProps: PropTypes.object,
  label: PropTypes.string,
  title: PropTypes.string,
  onClick: PropTypes.func,
};

export { MenuItem };
