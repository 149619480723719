import { DATA_DEFINITION_PREFIX } from '../../constants';

export const OPEN_ONE_NO_TRUMP = 'opening-bid/OPEN_ONE_NO_TRUMP';
export const OPEN_TWO_NO_TRUMP = 'opening-bid/OPEN_TWO_NO_TRUMP';
export const OPEN_THREE_NO_TRUMP = 'opening-bid/OPEN_THREE_NO_TRUMP';

export const DEF_OPEN_ONE_NO_TRUMP = DATA_DEFINITION_PREFIX+'opening-bid/OPEN_ONE_NO_TRUMP';
export const DEF_OPEN_TWO_NO_TRUMP = DATA_DEFINITION_PREFIX+'opening-bid/OPEN_TWO_NO_TRUMP';
export const DEF_OPEN_THREE_NO_TRUMP = DATA_DEFINITION_PREFIX+'opening-bid/OPEN_THREE_NO_TRUMP';

export const OPEN_TWO_LEVEL_STRONG = 'opening-bid/OPEN_TWO_LEVEL_STRONG';
export const DEF_OPEN_TWO_LEVEL_STRONG = DATA_DEFINITION_PREFIX+'opening-bid/OPEN_TWO_LEVEL_STRONG';

export const OPEN_TWO_LEVEL_STRONG_OLD  = 'opening-bid/OPEN_TWO_LEVEL_STRONG_OLD';
export const DEF_OPEN_TWO_LEVEL_STRONG_OLD = DATA_DEFINITION_PREFIX+'opening-bid/OPEN_TWO_LEVEL_STRONG_OLD';

export const OPEN_TWO_LEVEL_STRONG_BALANCED = 'opening-bid/OPEN_TWO_LEVEL_STRONG_BALANCED';
export const DEF_OPEN_TWO_LEVEL_STRONG_BALANCED = DATA_DEFINITION_PREFIX+'opening-bid/OPEN_TWO_LEVEL_STRONG_BALANCED';

export const OPEN_TWO_LEVEL_STRONG_UNBALANCED = 'opening-bid/OPEN_TWO_LEVEL_STRONG_UNBALANCED';
export const DEF_OPEN_TWO_LEVEL_STRONG_UNBALANCED = DATA_DEFINITION_PREFIX+'opening-bid/OPEN_TWO_LEVEL_STRONG_UNBALANCED';

export const OPEN_MAJOR_SUIT_AT_ONE_LEVEL = 'opening-bid/OPEN_MAJOR_SUIT_AT_ONE_LEVEL';
export const DEF_OPEN_MAJOR_SUIT_AT_ONE_LEVEL = DATA_DEFINITION_PREFIX+'opening-bid/OPEN_MAJOR_SUIT_AT_ONE_LEVEL';
export const OPEN_MINOR_SUIT_AT_ONE_LEVEL = 'opening-bid/OPEN_MINOR_SUIT_AT_ONE_LEVEL';
export const DEF_OPEN_MINOR_SUIT_AT_ONE_LEVEL = DATA_DEFINITION_PREFIX+'opening-bid/OPEN_MINOR_SUIT_AT_ONE_LEVEL';

export const OPEN_TWO_LEVEL_WEAK_PREEMPT = 'opening-bid/OPEN_TWO_LEVEL_WEAK_PREEMPT';
export const OPEN_THREE_LEVEL_PREEMPT = 'opening-bid/OPEN_THREE_LEVEL_PREEMPT';
export const OPEN_FOUR_LEVEL_PREEMPT = 'opening-bid/OPEN_FOUR_LEVEL_PREEMPT';
export const DEF_OPEN_TWO_LEVEL_WEAK_PREEMPT = DATA_DEFINITION_PREFIX+'opening-bid/OPEN_TWO_LEVEL_WEAK_PREEMPT';
export const DEF_OPEN_THREE_LEVEL_PREEMPT = DATA_DEFINITION_PREFIX+'opening-bid/OPEN_THREE_LEVEL_PREEMPT';
export const DEF_OPEN_FOUR_LEVEL_PREEMPT = DATA_DEFINITION_PREFIX+'opening-bid/OPEN_FOUR_LEVEL_PREEMPT';

export const OPEN_NO_TRUMP = 'opening-bid/OPEN_NO_TRUMP';
export const OPEN_SUIT_ONE_LEVEL = 'opening-bid/OPEN_SUIT_ONE_LEVEL';
export const OPEN_PREEMPT = 'opening-bid/OPEN_PREEMPT';

export const DEF_OPEN_NO_TRUMP = DATA_DEFINITION_PREFIX+'opening-bid/OPEN_NO_TRUMP';
export const DEF_OPEN_SUIT_ONE_LEVEL  = DATA_DEFINITION_PREFIX+'opening-bid/OPEN_SUIT_ONE_LEVEL';
export const DEF_OPEN_PREEMPT = DATA_DEFINITION_PREFIX+'opening-bid/OPEN_PREEMPT';
