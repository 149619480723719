import React from 'react';
import PropTypes from 'prop-types';

import { has } from 'ramda';

import { constants as uic } from '../../constants';
import { DialogTitleBar } from '../components';

import ContinueButton from '../game-components/ContinueButton';
import {
  PlayTypeSelect,
  DealTypeSelect,
  BidTypeSelect,
  BidSystemSelect
} from '../components/GamePlayOptions.js';

//import { useKeyPress, useOutsideCloseClick } from '../hooks';

const NowPlayingModal = ({ onClose, gameOptions, dealProps}) => {
  //useKeyPress('Escape', onClose);
  //const wrapperRef = useRef(null);
  //useOutsideCloseClick(wrapperRef, onClose);

  const contentStyle = {
    padding: '1rem',
    margin: '2rem',
  };

  return (
    <div id="now-playing-modal" className="main-component__container">
      <DialogTitleBar title={uic.NOW_PLAYING_LABEL} onClose={onClose} />
      <div id="now-playing-content" style={contentStyle} className="modal-dialog__content">
        <p style={{
          fontSize: '120%',
        }}>
          <span><PlayTypeSelect canEdit={false} {...gameOptions}/></span>
          <span> - </span>
          {has('name', dealProps) &&
            <span><DealTypeSelect canEdit={false} {...gameOptions} {...dealProps}/>
            </span>
          }
          <span> </span>
          <span><BidTypeSelect canEdit={false} {...gameOptions}/></span>
          <span> </span>
          <span><BidSystemSelect canEdit={false} {...gameOptions} />
          </span>
        </p>
      </div>
      <ContinueButton
       onClick={onClose}
       id={'now_playing_ok_btn'}
       text="OK"
       title="OK"
       />
    </div>
  );
};

NowPlayingModal.propTypes = {
  gameOptions: PropTypes.object,
  dealProps: PropTypes.object,
  onClose: PropTypes.func,
};

export default NowPlayingModal;
