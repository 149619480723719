import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
//import { useTransition, animated } from 'react-spring';
//import { config } from 'react-spring/renderprops';

const IconButton = function IconButton({
  getIsToggled,
  getIsActive,
  id,
  onClick,
  rotate,
  src,
  title,
}) {
  const [ wasClicked, setWasClicked ] = useState(false);

  useEffect(() => {
    if (wasClicked) {
      setWasClicked(false);
    }
  }, [ wasClicked, setWasClicked ])

  const onIconClick = e => {
    setWasClicked(true);
    e.currentTarget.blur();
    onClick(e);
  };

  const getIsDisabled = () => !getIsActive(id);

  const activeClass = getIsDisabled()
    ? 'disabled'
    : '';

/*
  const transitions = useTransition(wasClicked, null, {
    from: { opacity: 0, transform: 'rotate(0deg)' },
    enter: { opacity: 1, transform: 'rotate(360deg)'},
    leave: { opacity: 1, transform: 'rotate(360deg)'},
    duration: 10,
    velocity: 50,
    config: config.slow,
  });
  transitions.map(obj =>
     <animated.div key={obj.key} style={obj.props}>
     </animated.div>*/

  const getClasses = () => `nav-bar__icon ${activeClass} ${getIsToggled(id) ? 'toggled' : ''}`;

  const getStyle = () => rotate
    ? {transform: `rotate(${rotate})`}
    : {};

  return (
    <button
      id={id}
      className={getClasses()}
      disabled={getIsDisabled()}
      onClick={onIconClick}
      title={title}
    >
      <img
        src={src}
        style={getStyle()}
        alt={title}
        className="nav-bar__icon-img"
        height="30px"
        width="30px"
      />
    </button>
  );
};

IconButton.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  onClick: PropTypes.func,
  src: PropTypes.string,
  rotate: PropTypes.string,
  getIsToggled: PropTypes.func,
  getIsActive: PropTypes.func,
};

IconButton.defaultProps = {
  getIsActive: () => true,
  getIsToggled: () => false,
};

export { IconButton };
