import { Coords } from './constants';
import { Deck } from './deck';

const assignSeats = function assignSeats(players) {
  const deck = new Deck();
  const cards = players.map(player => [deck.draw(), player]);

  // sort in acsending order
  cards.sort((a, b) => a[0][0].compare(b[0][0]));

  // assign the seats; last is south and dealer
  cards[0][1].position = Coords.WEST;
  cards[1][1].position = Coords.EAST;
  cards[2][1].position = Coords.NORTH;
  cards[3][1].position = Coords.SOUTH;

  players.forEach(player => player.setIsDealer(player.position === Coords.SOUTH));

  return cards;
};

/**
* @function assignPlayerToCoordObject
* @param {Object} playersCoords - holds who plays where
* @param {Object} player
*/
const assignPlayerToCoordObject = function assignPlayerToCoordObject(playerCoords, player) {
  const result = Object.assign({}, playerCoords);
  switch (player.position) {
    case Coords.EAST:
      result[Coords.EAST] = player;
      break;
    case Coords.WEST:
      result[Coords.WEST] = player;
      break;
    case Coords.NORTH:
      result[Coords.NORTH] = player;
      break;
    case Coords.SOUTH:
      result[Coords.SOUTH] = player;
      break;
      // no default
  }
  return result;
};

/**
 * return array of starting with the position param moving clockwise
 * @function getSortedPlayerPositions
 * @param {object} playersCoords - object containing each player situated at seated position
 * @param {string} postion - starting coordinate
 */
const getSortedPlayerPositions = function getSortedPlayerPositions(playersCoords, position) {
  if (position === Coords.EAST) {
    return [
      playersCoords[Coords.EAST],
      playersCoords[Coords.SOUTH],
      playersCoords[Coords.WEST],
      playersCoords[Coords.NORTH]
    ];
  }
  if (position === Coords.WEST) {
    return [
      playersCoords[Coords.WEST],
      playersCoords[Coords.NORTH],
      playersCoords[Coords.EAST],
      playersCoords[Coords.SOUTH]
    ];
  }
  if (position === Coords.NORTH) {
    return [
      playersCoords[Coords.NORTH],
      playersCoords[Coords.EAST],
      playersCoords[Coords.SOUTH],
      playersCoords[Coords.WEST]
    ];
  }
  if (position === Coords.SOUTH) {
    return [
      playersCoords[Coords.SOUTH],
      playersCoords[Coords.WEST],
      playersCoords[Coords.NORTH],
      playersCoords[Coords.EAST]
    ];
  }
  throw new Error('unknown position!');
};

const getSortProps = function(players) {
  const dealerPosition = players.reduce((position, player) => player.isDealer
    ? player.position
    : position, '');

  const playerCoords = players.reduce((coords, player) =>
    assignPlayerToCoordObject(coords, player), {});

  return {
    dealerPosition,
    playerCoords,
  };
};

/**
* sorts the players into playing position
*
* @function sortPlayersForCardPlay
* @param {Object[]} players
* @param {string} leadPosition
*/
const sortPlayersForCardPlay = function sortPlayersForCardPlay(players, leadPosition) {
  const sortProps = getSortProps(players);
  return getSortedPlayerPositions(sortProps.playerCoords, leadPosition);
};

/**
 * @function sortPlayersForBid
 * @returns returns array of players in bidding position
 */
const sortPlayersForBid = function sortPlayersForBid(players) {
  // same sort as deal
  const sortProps = getSortProps(players);
  return getSortedPlayerPositions(sortProps.playerCoords, sortProps.dealerPosition);
};

// sort the players into dealing position
const sortPlayersForDeal = function sortPlayersForDeal(players) {
  const sortProps = getSortProps(players);
  return getSortedPlayerPositions(sortProps.playerCoords, Coords.nextCoord(sortProps.dealerPosition));
};

export {assignSeats, sortPlayersForDeal, sortPlayersForBid, sortPlayersForCardPlay};
