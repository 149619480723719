import { DATA_DEFINITION_PREFIX } from '../../constants';

export const RAISE = 'weak-two-response/RAISE';
export const DEF_RAISE = DATA_DEFINITION_PREFIX+'weak-two-response/RAISE';

export const SIMPLE_RAISE = 'weak-two-response/SIMPLE_RAISE';
export const DEF_SIMPLE_RAISE = DATA_DEFINITION_PREFIX+'weak-two-response/SIMPLE_RAISE';
export const JUMP_RAISE = 'weak-two-response/JUMP_RAISE';
export const DEF_JUMP_RAISE = DATA_DEFINITION_PREFIX+'weak-two-response/STRONG_GAME_RAISE';
export const STRONG_GAME_RAISE = 'weak-two-response/STRONG_GAME_RAISE';
export const DEF_STRONG_GAME_RAISE = DATA_DEFINITION_PREFIX+'weak-two-response/STRONG_GAME_RAISE';
export const WEAK_GAME_RAISE = 'weak-two-response/WEAK_GAME_RAISE';
export const DEF_WEAK_GAME_RAISE = DATA_DEFINITION_PREFIX+'weak-two-response/WEAK_GAME_RAISE';

export const PLAY_THREE_NT = 'weak-two-response/PLAY_THREE_NT';
export const DEF_PLAY_THREE_NT = DATA_DEFINITION_PREFIX+'weak-two-response/PLAY_THREE_NT';

export const NEW_SUIT = 'weak-two-response/NEW_SUIT';
export const DEF_NEW_SUIT = DATA_DEFINITION_PREFIX+'weak-two-response/NEW_SUIT';

export const TWO_NO_TRUMP = 'weak-two-response/TWO_NO_TRUMP';
export const DEF_TWO_NO_TRUMP = DATA_DEFINITION_PREFIX+'weak-two-response/TWO_NO_TRUMP';
