import { DATA_DEFINITION_PREFIX } from '../../constants';

export const STAYMAN = 'response-open-one-no-trump/STAYMAN';
export const DEF_STAYMAN = DATA_DEFINITION_PREFIX+'response-open-one-no-trump/STAYMAN';

export const JACOBY_TRANSFER = 'response-open-one-no-trump/JACOBY_TRANSFER';
export const DEF_JACOBY_TRANSFER = DATA_DEFINITION_PREFIX+'response-open-one-no-trump/JACOBY_TRANSFER';

export const TWO_LEVEL_FIVE_CARD_SUIT = 'response-open-one-no-trump/TWO_LEVEL_FIVE_CARD_SUIT';
export const DEF_TWO_LEVEL_FIVE_CARD_SUIT = DATA_DEFINITION_PREFIX+'response-open-one-no-trump/TWO_LEVEL_FIVE_CARD_SUIT';

export const MINOR_SUIT_STAYMAN = 'response-open-one-no-trump/MINOR_SUIT_STAYMAN';
export const DEF_MINOR_SUIT_STAYMAN = DATA_DEFINITION_PREFIX+'response-open-one-no-trump/MINOR_SUIT_STAYMAN';

export const TWO_NO_TRUMP_GAME_INVITE = 'response-open-one-no-trump/TWO_NO_TRUMP_GAME_INVITE';
export const DEF_TWO_NO_TRUMP_GAME_INVITE = DATA_DEFINITION_PREFIX+'response-open-one-no-trump/TWO_NO_TRUMP_GAME_INVITE';

export const MINOR_SUIT_GAME_INVITE = 'response-open-one-no-trump/MINOR_SUIT_GAME_INVITE';
export const DEF_MINOR_SUIT_GAME_INVITE = DATA_DEFINITION_PREFIX+'response-open-one-no-trump/MINOR_SUIT_GAME_INVITE';

export const PLAY_THREE_NO_TRUMP_GAME = 'response-open-one-no-trump/PLAY_THREE_NO_TRUMP_GAME';
export const DEF_PLAY_THREE_NO_TRUMP_GAME = DATA_DEFINITION_PREFIX+'response-open-one-no-trump/PLAY_THREE_NO_TRUMP_GAME';

export const SLAM_TRY = 'response-open-one-no-trump/SLAM_TRY';
export const DEF_SLAM_TRY = DATA_DEFINITION_PREFIX+SLAM_TRY;

export const QUANTITATIVE_FOUR_NT = 'response-open-one-no-trump/QUANTITATIVE_FOUR_NT';
export const DEF_QUANTITATIVE_FOUR_NT = DATA_DEFINITION_PREFIX+QUANTITATIVE_FOUR_NT;

export const GERBER_FOR_ACES = 'response-open-one-no-trump/GERBER_FOR_ACES';
