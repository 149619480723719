import {
  JACOBY_TRANSFER_COMPLETE,
  JACOBY_TRANSFER_SUPER_COMPLETE,
} from './types';

export const JACOBY_ACCEPT_RANGES = 'bid-options/jacoby-accept/ranges';

export const createRanges = () => ({
  [ JACOBY_TRANSFER_COMPLETE ]: {
    lower: 15,
  },
  [ JACOBY_TRANSFER_SUPER_COMPLETE ]: {
    lower: 17,
  },
});

export const SUPER_ACCEPT_LENGTH = 4;

export const getSuperAcceptRange = function getSuperAcceptRange({ bidSystemConfig }) {
  return bidSystemConfig[ JACOBY_ACCEPT_RANGES ][ JACOBY_TRANSFER_SUPER_COMPLETE ];
}
