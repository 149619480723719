import {
  JACOBY_TRANSFER_REBID_RANGES,
  INVITE_RANGE,
  GAME_RANGE,
  createRanges,
} from './ranges';

function getInviteRange({ bidSystemConfig }) {
  return bidSystemConfig[ JACOBY_TRANSFER_REBID_RANGES ][ INVITE_RANGE ];
}

function getGameRange({ bidSystemConfig }) {
  return bidSystemConfig[ JACOBY_TRANSFER_REBID_RANGES ][ GAME_RANGE ];
}

export {
  JACOBY_TRANSFER_REBID_RANGES,
  INVITE_RANGE,
  GAME_RANGE,
  createRanges,
  getInviteRange,
  getGameRange,
}
