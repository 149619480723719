import { DATA_DEFINITION_PREFIX, DATA_DEF_SEP } from './constants';

import * as BidSystemConfig from './bid-system-config';
import * as BidContext from './bid-context';
import { createBidOptionLabels } from './bid-options';
import * as PointRangeHelper from './point-range';
import BidHelper  from './bid-helper';
import Bid from './bid';
import * as BidTypes from './bid-types';
import { generateNextBid } from './bid-generator';
import { BidderTypes } from './bidder-types';

import { getBidContextGrid } from './bid-context-grid';

const BidContextTypes = BidContext.getTypes();

const createBidSystemLabels = function createBidSystemLabels(bidSystemConfig) {
  return {
    ...BidContext.Labels,
    ...BidSystemConfig.Labels,
    ...createBidOptionLabels(bidSystemConfig)
  };
};

const getDataDefinitionKey = function getDataDefinitionKey(key) {
  return `${DATA_DEFINITION_PREFIX}${key}`;
};

export {
  Bid,
  BidHelper,
  BidSystemConfig,
  BidTypes,
  BidContextTypes,
  BidderTypes,
  PointRangeHelper,
  createBidSystemLabels,
  generateNextBid,
  getBidContextGrid,
  getDataDefinitionKey,
  DATA_DEF_SEP,
};
