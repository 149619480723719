import { createRangeLabel } from '../create-range-label';

import {
  JACOBY_TRANSFER_COMPLETE,
  DEF_JACOBY_TRANSFER_COMPLETE,
  JACOBY_TRANSFER_SUPER_COMPLETE,
  DEF_JACOBY_TRANSFER_SUPER_COMPLETE,
} from './types';

import { JACOBY_ACCEPT_RANGES, SUPER_ACCEPT_LENGTH } from './ranges';

export const createLabels = function createLabels(bidSystemConfig) {
  const range = bidSystemConfig[ JACOBY_ACCEPT_RANGES ];

  const JACOBY_TRANSFER_COMPLETE_LABEL = 'Complete Transfer';
  const DEF_JACOBY_TRANSFER_COMPLETE_LABEL = `Bid next suit above partner's - ${createRangeLabel(range[ JACOBY_TRANSFER_COMPLETE ]) }`;
  const JACOBY_TRANSFER_SUPER_COMPLETE_LABEL = 'Super Accept';
  const DEF_JACOBY_TRANSFER_SUPER_COMPLETE_LABEL = `Jump a level - ${createRangeLabel(range[ JACOBY_TRANSFER_SUPER_COMPLETE ]) } - ${SUPER_ACCEPT_LENGTH}+ cards in bid suit`;

  return {
    [ JACOBY_TRANSFER_COMPLETE ]: JACOBY_TRANSFER_COMPLETE_LABEL,
    [ DEF_JACOBY_TRANSFER_COMPLETE ]: DEF_JACOBY_TRANSFER_COMPLETE_LABEL,
    [ JACOBY_TRANSFER_SUPER_COMPLETE ]: JACOBY_TRANSFER_SUPER_COMPLETE_LABEL,
    [ DEF_JACOBY_TRANSFER_SUPER_COMPLETE ]: DEF_JACOBY_TRANSFER_SUPER_COMPLETE_LABEL,
  }
};
