import React from 'react';
import PropTypes from 'prop-types';

const ContinueButton = React.forwardRef(function ContinueButton(props, ref) {
  const {onClick, id, waitTime, text, title} = props;

  let timeOutId;

  if (waitTime && waitTime > 0 && !timeOutId) {
    timeOutId = setTimeout(onClick, waitTime);
  }

  const handleClick = (event) => {
    if (timeOutId) {
      clearTimeout(timeOutId);
      timeOutId = null;
    }
    onClick(event);
  };

  const getClasses = () => props.isCenter
    ? 'button-center_div'
    : '';

  const btnLabel = text || 'Continue';

  return (
    <div id={id} className={getClasses()}>
      <button
        ref={ref}
        autoFocus
        id="icon-continue"
        onClick={handleClick}
        className="bridge-button button-center"
        style={{tabIndex: 1}}
        title={title || ''}
      >{btnLabel}<span>&rarr;</span></button>
     </div>
  );
});

ContinueButton.propTypes = {
  id: PropTypes.string,
  isCenter: PropTypes.bool,
  onClick: PropTypes.func,
  text: PropTypes.string,
  title: PropTypes.string,
  waitTime: PropTypes.number,
};

ContinueButton.defaultProps = {
  isCenter: true,
};

export default ContinueButton;
