import uuid from 'uuid';

import { Coords } from '../constants';
import { Card, Deck } from '../deck';
import { sortPlayersForDeal } from '../player-sort';

const createDeal = function createDeal({
  id = uuid.v4(),
  name,
  folderName,
  hands,
  bidSequence,
  source,
  dealerPosition = Coords.SOUTH,
  timeStamp = Date.now(),
  lastPlayed = Date.now(),
  playCount = 0,

}) {
  return {
    id,
    name,
    folderName,
    hands,
    bidSequence,
    source,
    dealerPosition,
    timeStamp,
    lastPlayed,
    playCount,
  }
}

class BridgeDealService {
  constructor(dealName) {
    this.dealName = dealName;
    this.dealer = null;
  }

  dealCards(players) {
    const deck = new Deck();
    deck.reset();
    deck.shuffle();

    players = sortPlayersForDeal(players);
    // last is dealer
    this.dealer = players[3];

    for (let i = 0; i < 13; i++) {
      players.forEach(player => player.hand.addCard(deck.dealNextCard()));
    }

    this.sortHands(players);
  }

  sortHands(players) {
    players.forEach(player => player.hand.sortHand());
  }

  /**
  * returns an object with each players cards - key position, value string of cards
  */
  getHandExport(players) {
    const getHandValue = position => {
      return players.find(player => player.position === position).hand.getFlatSuits();
    };

    return {
      west: getHandValue(Coords.WEST).join(' '),
      north: getHandValue(Coords.NORTH).join(' '),
      east: getHandValue(Coords.EAST).join(' '),
      south: getHandValue(Coords.SOUTH).join(' '),
    }
  }

  getExport(players) {
    return createDeal({
      name: this.dealName + Date.now(),
      dealer: this.dealer.position,
      hands: this.getHandExport(players),
    });
  }
}

export class PredeterminedDealService extends BridgeDealService {

  constructor(props) {
    const {
      id,
      name,
      folderName,
      hands,
      bidSequence,
      deleteMethod,
      source,
      dealerPosition = Coords.SOUTH,
      timeStamp,
      lastPlayed,
      playCount,
    } = props;

    super(name);
    this.id = id;
    this.folderName = folderName;
    this.hands = hands;
    this.dealerPosition = dealerPosition;
    this.deleteMethod = deleteMethod;
    this.bidSequence = bidSequence;
    this.source = source;
    this.timeStamp = timeStamp;
    this.lastPlayed = lastPlayed;
    this.playCount = playCount;
  }

  getExport(players) {
    return createDeal({
      ...super.getExport(players),
      id: this.id,
      name: this.dealName,
      folderName: this.folderName,
      hands: this.hands,
      dealerPosition: this.dealerPosition,
      deleteMethod: this.deleteMethod,
      bidSequence: this.bidSequence,
      source: this.source,
      timeStamp: this.timeStamp,
      lastPlayed: this.lastPlayed,
      playCount: this.playCount,
    });
  }

  setBidSequence(bidSequence) {
    this.bidSequence = bidSequence;
  }

  dealCards(players) {
    this.dealPredeterminedHands(players);
  }

  setDealerAtPosition(players, position) {
    players.forEach(player => player.setIsDealer(false));
    this.dealer = players.find(player => player.position === position);
    this.dealer.setIsDealer(true);
  }

  dealPredeterminedHands(players) {
    this.setDealerAtPosition(players, this.dealerPosition);
    players.forEach(player => {
      const cards = this.hands[player.position.toLowerCase()];
      cards.forEach(cardStr => {
        const strain = cardStr.substring(cardStr.length - 1);
        const value = cardStr.substring(0, cardStr.length - 1);
        player.hand.addCard(new Card(strain, value));
      });
      player.hand.sortHand();
    });
  }
}


const createRandomDealService = function createRandomDealService(name) {
  return new BridgeDealService(name);
}

const createPredeterminedDeal = function createPredeterminedDeal(props) {
  return new PredeterminedDealService(props);
};

export { createRandomDealService, createPredeterminedDeal, createDeal };
