export const JACOBY_TRANSFER_RESPONSE = 'bid-context/JACOBY_TRANSFER_RESPONSE';
export const LANDY_ADVANCE = 'bid-context/LANDY_ADVANCE';
export const LANDY_OVERCALLER_REBID_AFTER_TWO_NO_TRUMP_ADVANCE = 'bid-context/LANDY_OVERCALLER_REBID_AFTER_TWO_NO_TRUMP_ADVANCE';
export const OPEN_THE_BIDDING = 'bid-context/OPEN_THE_BIDDING';
export const OPENER_REBID_AFTER_NEG_DBL = 'bid-context/OPENER_REBID_AFTER_NEG_DBL';
export const OVERCALL_AND_TAKEOUT_DOUBLE = 'bid-context/OVERCALL_AND_TAKEOUT_DOUBLE';
export const OVERCALL_NO_TRUMP_OPEN = 'bid-context/OVERCALL_NO_TRUMP_OPEN';
export const OVERCALL_ADVANCE = 'bid-context/OVERCALL_ADVANCE';
export const PARTNER_OPENED_SUIT = 'bid-context/PARTNER_OPENED_SUIT';
export const RESPONSE_TO_NO_TRUMP_OPEN =  'bid-context/RESPONSE_TO_NO_TRUMP_OPEN';
export const RESPONSE_AFTER_SUIT_OVERCALL = 'bid-context/RESPONSE_AFTER_SUIT_OVERCALL';
export const RESPONSE_AFTER_STRONG_JUMP_SHIFT = 'bid-context/RESPONSE_AFTER_STRONG_JUMP_SHIFT';
export const RESPONSE_AFTER_TAKEOUT_DOUBLE = 'bid-context/RESPONSE_AFTER_TAKEOUT_DOUBLE';
export const RESPONSE_TO_PARTNERS_TAKEOUT_DOUBLE = 'bid-context/RESPONSE_TO_PARTNERS_TAKEOUT_DOUBLE';
export const RESPONSE_AFTER_NO_TRUMP_OVERCALL = 'bid-context/RESPONSE_AFTER_NO_TRUMP_OVERCALL';
export const RESPONSE_TO_ONE_OF_A_SUIT_OPEN = 'bid-context/RESPONSE_TO_ONE_OF_A_SUIT_OPEN';
export const RESPONSE_TO_WEAK_TWO_OPEN = 'bid-context/RESPONSE_TO_WEAK_TWO_OPEN';
export const RESPONSE_TO_FOURTH_SUIT_FORCING = 'bid-context/RESPONSE_TO_FOURTH_SUIT_FORCING';
export const RESPONSE_TO_STRONG_TWO_OPEN = 'bid-context/RESPONSE_TO_STRONG_TWO_OPEN';
export const STRONG_TWO_OPENER_RESPOND_TO_POSITIVE_SUIT = 'bid-context/STRONG_TWO_OPENER_RESPOND_TO_POSITIVE_SUIT';
