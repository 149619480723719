import React from "react";
import PropTypes from 'prop-types';

import { BridgeBlvdTitle } from './BridgeBlvdTitle';

const FallingSuits = function FallingSuits({
  children,
  isVisible,
  isDisplayTitle,
}) {
  return (
    <section>
    {isVisible &&
      <div className="card-strain__font falling_suits">
        <div className="spade">♠</div>
        <div className="heart">♥</div>
        <div className="diamond">♦</div>
        <div className="club">♣</div>
        <div className="spade">♠</div>
        <div className="heart">♥</div>
        <div className="diamond">♦</div>
        <div className="club">♣</div>
        <div className="spade">♠</div>
        <div className="heart">♥</div>
        <div className="diamond">♦</div>
        <div className="club">♣</div>
        <div className="spade">♠</div>
        <div className="heart">♥</div>
        <div className="diamond">♦</div>
        <div className="club">♣</div>
      </div>
    }

    {isVisible
     && isDisplayTitle
     && <h1 style={{
          padding:'10%',
        }}>
          <BridgeBlvdTitle />
        </h1>
     }
     {children}
    </section>
  );
};
FallingSuits.propTypes = {
  isDisplayTitle: PropTypes.bool,
  isVisible: PropTypes.bool,
};
FallingSuits.defaultProps = {
  isDisplayTitle: false,
  isVisible: true
};

export { FallingSuits };
