import { constants as c } from '../../../constants';
import { CHEAP, JUMP, GAME } from '../constants';

const SuitResponseRange = {
  [ CHEAP ]: {
    lower: 0,
    upper: 8,
  },
  [ JUMP ]: {
    lower: 9,
    upper: 11,
  },
  [ GAME ]: {
    lower: 12,
  },
};

const NoTrumpResponseRange = {
  [ c.ONE_LEVEL ]: {
    lower: 7,
    upper: 10
  },
  [ c.TWO_LEVEL ]: {
    lower: 11,
    upper: 12
  },
  [ c.THREE_LEVEL ]: {
    lower: 13
  },
};

const FreeBidRange = {
  lower: 6,
  upper: 9,
};

const TAKE_OUT_DOUBLE_RESPONSE_RANGES = 'bid-options/takeout-double-response-ranges';
const FREE_BID_RANGE = 'bid-options/takeout-double-response-ranges/free-bid-range';
const SUIT_RESPONSE_RANGE = 'bid-options/takeout-double-response-ranges/suit-response-range';
const NO_TRUMP_RESPONSE_RANGE = 'bid-options/takeout-double-response-ranges/no-trump-response-range';

const createRanges = () => ({
  [ SUIT_RESPONSE_RANGE ]: SuitResponseRange,
  [ NO_TRUMP_RESPONSE_RANGE ]: NoTrumpResponseRange,
  [ FREE_BID_RANGE ]: FreeBidRange,
});

export {
  createRanges,
  TAKE_OUT_DOUBLE_RESPONSE_RANGES,
  FREE_BID_RANGE,
  SUIT_RESPONSE_RANGE,
  NO_TRUMP_RESPONSE_RANGE,
};
