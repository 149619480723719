export const DEAL_NEXT_IN_QUEUE = 'presenter-event-types/deal-next-in-queue';
export const DEAL_ACTION = 'presenter-event-types/deal-action';
export const DELETE_CURRENT_DEAL = 'presenter-event-types/delete-current-deal';
export const EXPORT_CURRENT_DEAL = 'presenter-event-types/export-deal-action';
export const IMPORT_DEAL = 'presenter-event-types/import-deal-action';
export const SHOW_NOW_PLAYING = 'presenter-event-types/now_playing-action';
export const REPLAY_CURRENT_DEAL = 'presenter-event-types/replay-action';
export const SAVE_CURRENT_DEAL = 'presenter-event-types/save-deal-action';

export const SCROLL_DOWN = 'presenter-event-types/scroll-down';
export const SCROLL_UP = 'presenter-event-types/scroll-up-action';

// toggle actions
export const SHOW_POINTS_ACTION = 'presenter-event-types/show-points-action';
export const SHOW_CARDS_ACTION = 'presenter-event-types/toggle-cards-visible';
export const SHOW_AUCTION_ACTION = 'presenter-event-types/toggle-auction-grid';
export const SHOW_HINT_ACTION = 'presenter-event-types/icon-hint';

export const SHOW_BID_GUIDE_PAGE = 'presenter-event-types/bid-guide-action';
export const SHOW_BID_OPTIONS = 'presenter-event-types/bid-options-action';
export const SHOW_ABOUT_PAGE = 'presenter-event-types/about-action';
export const SHOW_SETTINGS_PAGE = 'presenter-event-types/show-settings-action';


export const RUN_TESTS = 'presenter-event-types/test-action';
export const GO_HOME = 'presenter-event-types/go-home';

export const SHOW_TRICK_VIEW = 'presenter-event-types/icon-trick-view';
export const SHOW_TALLY_VIEW = 'presenter-event-types/icon-tally-view';
