import {
  TRUMP_FIT,
  REBID_ORIGINAL_SUIT,
  BID_NO_TRUMP_STOPPER_IN_ENEMY_SUIT,
  NATURAL_NEW_SUIT,
  CUEBID,
  SHORT_FIT,
  DEF_TRUMP_FIT,
  DEF_REBID_ORIGINAL_SUIT,
  DEF_BID_NO_TRUMP_STOPPER_IN_ENEMY_SUIT,
  DEF_NATURAL_NEW_SUIT,
  DEF_CUEBID,
  DEF_SHORT_FIT,
} from './types';

const TRUMP_FIT_LABEL = `Fit in Partner's Suit`;
const DEF_TRUMP_FIT_LABEL = `4+ card fit - bid partner's suit - level shows points`;

const REBID_ORIGINAL_SUIT_LABEL = 'Rebid Opening Suit';
const DEF_REBID_ORIGINAL_SUIT_LABEL = 'Rebid 6+ card suit - Good 5 cards without other option';

const BID_NO_TRUMP_STOPPER_IN_ENEMY_SUIT_LABEL = 'No Trump';
const DEF_BID_NO_TRUMP_STOPPER_IN_ENEMY_SUIT_LABEL = 'Bid No Trump at cheapest level - with stopper in enemy suit';

const NATURAL_NEW_SUIT_LABEL = 'New Suit';
const DEF_NATURAL_NEW_SUIT_LABEL = 'Natural New Suit - 4+ cards - reverse is strong hand';

const CUEBID_LABEL = 'Cue Bid';
const DEF_CUEBID_LABEL = 'Cue Bid enemy suit - strong balanced hand - no stopper in their suit';

const SHORT_FIT_LABEL = 'No Option';
const DEF_SHORT_FIT_LABEL = 'bid partners suit - 3+ card support';

export const createLabels = function createLabels(/*bidSystemConfig*/) {

  return {
    [ TRUMP_FIT ]: TRUMP_FIT_LABEL,
    [ REBID_ORIGINAL_SUIT ]: REBID_ORIGINAL_SUIT_LABEL,
    [ BID_NO_TRUMP_STOPPER_IN_ENEMY_SUIT ]: BID_NO_TRUMP_STOPPER_IN_ENEMY_SUIT_LABEL,
    [ NATURAL_NEW_SUIT ]: NATURAL_NEW_SUIT_LABEL,
    [ CUEBID ]: CUEBID_LABEL,
    [ SHORT_FIT ]: SHORT_FIT_LABEL,
    [ DEF_TRUMP_FIT ]: DEF_TRUMP_FIT_LABEL,
    [ DEF_REBID_ORIGINAL_SUIT ]: DEF_REBID_ORIGINAL_SUIT_LABEL,
    [ DEF_BID_NO_TRUMP_STOPPER_IN_ENEMY_SUIT ]: DEF_BID_NO_TRUMP_STOPPER_IN_ENEMY_SUIT_LABEL,
    [ DEF_NATURAL_NEW_SUIT ]: DEF_NATURAL_NEW_SUIT_LABEL,
    [ DEF_CUEBID ]: DEF_CUEBID_LABEL,
    [ DEF_SHORT_FIT ]: DEF_SHORT_FIT_LABEL,
  };
};
