import { constants as c } from '../../../constants';

import { createRangeLabel } from '../create-range-label';

import {
  STAYMAN,
  DEF_STAYMAN,
  JACOBY_TRANSFER,
  DEF_JACOBY_TRANSFER,
  TWO_LEVEL_FIVE_CARD_SUIT,
  DEF_TWO_LEVEL_FIVE_CARD_SUIT,
  MINOR_SUIT_STAYMAN,
  DEF_MINOR_SUIT_STAYMAN,
  TWO_NO_TRUMP_GAME_INVITE,
  DEF_TWO_NO_TRUMP_GAME_INVITE,
  MINOR_SUIT_GAME_INVITE,
  DEF_MINOR_SUIT_GAME_INVITE,
  PLAY_THREE_NO_TRUMP_GAME,
  DEF_PLAY_THREE_NO_TRUMP_GAME,
  SLAM_TRY,
  DEF_SLAM_TRY,
  QUANTITATIVE_FOUR_NT,
  DEF_QUANTITATIVE_FOUR_NT,
  GERBER_FOR_ACES,
} from './types';

import { RESPONSE_OPEN_NO_TRUMP_RANGES } from './ranges';

export const createLabels = function createLabels(bidSystemConfig) {

  const ranges = bidSystemConfig[ RESPONSE_OPEN_NO_TRUMP_RANGES ];

  const DEF_STAYMAN_LABEL = c.rangeDefTag`${createRangeLabel(ranges[ STAYMAN ])}${c.STAYMAN_DESC_LABEL}`;

  const TWO_LEVEL_FIVE_CARD_SUIT_LABEL = 'Two Level';
  const DEF_TWO_LEVEL_FIVE_CARD_SUIT_LABEL = c.rangeDefTag`${createRangeLabel(ranges[ TWO_LEVEL_FIVE_CARD_SUIT ])}
    ${c.getDefListExp('5+ cards in the suit', 'Sign off bid, partner must pass')}`;

  const DEF_JACOBY_TRANSFER_LABEL = c.getDefListExp(
    c.ARTIFICIAL_LABEL,
    'Show a 5+ card major in the next higher suit',
     '2 diamonds, partner must bid 2 hearts',
     '2 hearts, partner will transfer to spades'
   );

  const DEF_MINOR_SUIT_STAYMAN_LABEL = c.rangeDefTag`${createRangeLabel(ranges[ MINOR_SUIT_STAYMAN ])}
    ${c.getDefListExp('deny a 5 card major', 'unbalanced hand with length in both minors')}`;

  const DEF_TWO_NO_TRUMP_GAME_INVITE_LABEL = c.rangeDefTag`${createRangeLabel(ranges[ TWO_NO_TRUMP_GAME_INVITE ])}
    ${c.getDefListExp(c.BALANCED_HAND_LABEL, c.GAME_INVITE_LABEL)}`;

  const MINOR_SUIT_GAME_INVITE_LABEL = 'Minor Suit Invite';

  const DEF_MINOR_SUIT_GAME_INVITE_LABEL = c.rangeDefTag`${createRangeLabel(ranges[ MINOR_SUIT_GAME_INVITE ])}
    ${c.getDefListExp(c.GAME_INVITE_LABEL, c.SIX_CARD_MINOR_SUIT_LABEL)}`;

  const DEF_PLAY_THREE_NO_TRUMP_GAME_LABEL = c.rangeDefTag`${createRangeLabel(ranges[ PLAY_THREE_NO_TRUMP_GAME ])}
    ${c.getDefListExp(c.BALANCED_HAND_LABEL, 'combined points equal game')}`;

  const SLAM_TRY_LABEL = 'Slam Try';
  const DEF_SLAM_TRY_LABEL = 'Possible Slam';

  const QUANTITATIVE_FOUR_NT_DESC_LABEL = `4${c.NO_TRUMP}`;

  const DEF_QUANTITATIVE_FOUR_NT_LABEL = c.rangeDefTag`${createRangeLabel(ranges[ QUANTITATIVE_FOUR_NT ])}
    ${c.getDefListExp(c.QUANTITATIVE_FOUR_NT_LABEL, c.INVITE_SLAM)}`;

  const GERBER_FOR_ACES_LABEL = 'Ask partner for their ace count. ';

  return {
    [ STAYMAN ]: c.STAYMAN_LABEL,
    [ DEF_STAYMAN]: DEF_STAYMAN_LABEL,
    [ JACOBY_TRANSFER ]: c.JACOBY_TRANSFER_LABEL,
    [ DEF_JACOBY_TRANSFER ]: DEF_JACOBY_TRANSFER_LABEL,
    [ TWO_LEVEL_FIVE_CARD_SUIT ]: TWO_LEVEL_FIVE_CARD_SUIT_LABEL,
    [ DEF_TWO_LEVEL_FIVE_CARD_SUIT ]: DEF_TWO_LEVEL_FIVE_CARD_SUIT_LABEL,
    [ MINOR_SUIT_STAYMAN ]: c.MINOR_SUIT_STAYMAN_LABEL,
    [ DEF_MINOR_SUIT_STAYMAN ]: DEF_MINOR_SUIT_STAYMAN_LABEL,
    [ TWO_NO_TRUMP_GAME_INVITE ]: c.TWO_NO_TRUMP_LABEL,
    [ DEF_TWO_NO_TRUMP_GAME_INVITE ]: DEF_TWO_NO_TRUMP_GAME_INVITE_LABEL,
    [ MINOR_SUIT_GAME_INVITE ]: MINOR_SUIT_GAME_INVITE_LABEL,
    [ DEF_MINOR_SUIT_GAME_INVITE ]: DEF_MINOR_SUIT_GAME_INVITE_LABEL,
    [ PLAY_THREE_NO_TRUMP_GAME ]: c.THREE_NO_TRUMP_LABEL,
    [ DEF_PLAY_THREE_NO_TRUMP_GAME ]: DEF_PLAY_THREE_NO_TRUMP_GAME_LABEL,
    [ SLAM_TRY ]: SLAM_TRY_LABEL,
    [ DEF_SLAM_TRY ]: DEF_SLAM_TRY_LABEL,
    [ QUANTITATIVE_FOUR_NT ]: QUANTITATIVE_FOUR_NT_DESC_LABEL,
    [ DEF_QUANTITATIVE_FOUR_NT ]: DEF_QUANTITATIVE_FOUR_NT_LABEL,
    [ GERBER_FOR_ACES ]: GERBER_FOR_ACES_LABEL,
  };

};
