const WEST = 'West';
const EAST = 'East';
const NORTH = 'North';
const SOUTH = 'South';

const getCoordInitial = function getCoordInitial(position) {
  return position.slice(0, 1).toUpperCase();
};

const nextCoord = function getNextCoordFrom(position) {
  switch (position) {
    case WEST:
      return NORTH;
    case NORTH:
      return EAST;
    case EAST:
      return SOUTH;
    case SOUTH:
      return WEST;
      // no default
  }
  return undefined;
};

const prevCoord = function getPrevCoordFrom(position) {
  switch (position) {
    case WEST:
      return SOUTH;
    case NORTH:
      return WEST;
    case EAST:
      return NORTH;
    case SOUTH:
      return EAST;
      // no default
  }
  return undefined;
};

const arr = [
  WEST,
  NORTH,
  EAST,
  SOUTH,
];

export {
  WEST,
  NORTH,
  EAST,
  SOUTH,
  arr,
  getCoordInitial,
  nextCoord,
  prevCoord
};
