import { DATA_DEFINITION_PREFIX } from '../../constants';

export const GAME_RAISE = 'bid-options/strong-jump-shift-response/GAME_RAISE';
export const SLOW_RAISE = 'bid-options/strong-jump-shift-response/SLOW_RAISE';
export const REBID_SIX_CARD_SUIT = 'bid-options/strong-jump-shift-response/REBID_SIX_CARD_SUIT';
export const NEW_SUIT_NATURAL = 'bid-options/strong-jump-shift-response/NEW_SUIT_NATURAL';
export const TWO_NO_TRUMP = 'bid-options/strong-jump-shift-response/TWO_NO_TRUMP';
export const THREE_NO_TRUMP = 'bid-options/strong-jump-shift-response/THREE_NO_TRUMP';

export const DEF_GAME_RAISE = DATA_DEFINITION_PREFIX+'bid-options/strong-jump-shift-response/GAME_RAISE';
export const DEF_SLOW_RAISE = DATA_DEFINITION_PREFIX+'bid-options/strong-jump-shift-response/SLOW_RAISE';
export const DEF_REBID_SIX_CARD_SUIT = DATA_DEFINITION_PREFIX+'bid-options/strong-jump-shift-response/REBID_SIX_CARD_SUIT';
export const DEF_NEW_SUIT_NATURAL = DATA_DEFINITION_PREFIX+'bid-options/strong-jump-shift-response/NEW_SUIT_NATURAL';
export const DEF_TWO_NO_TRUMP = DATA_DEFINITION_PREFIX+'bid-options/strong-jump-shift-response/TWO_NO_TRUMP';
export const DEF_THREE_NO_TRUMP = DATA_DEFINITION_PREFIX+'bid-options/strong-jump-shift-response/THREE_NO_TRUMP';
