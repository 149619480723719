const SPLASH_UPDATES_KEY = 'SPLASH_UPDATES';
// increment next update
const SPLASH_UPDATES_COUNT = 3;

export const getDisplaySplashIntro = function getDisplaySplashIntro() {
  const updates = localStorage.getItem(SPLASH_UPDATES_KEY);
  const data = updates
    ? JSON.parse(updates)
    : {
        updateCount: 0,
      };
  const result = data.updateCount < SPLASH_UPDATES_COUNT;
  if (result) {
    localStorage.setItem(SPLASH_UPDATES_KEY, JSON.stringify({
      updateCount: SPLASH_UPDATES_COUNT,
    }));
  }
  return result;
};
