import {
  BidSystemConfig,
  BidContextTypes,
  getBidContextGrid,
  getDataDefinitionKey,
  createBidSystemLabels,
  DATA_DEF_SEP,
} from './bid-system';

import { BridgeGameService }  from './services';
import Player, { getTeamFor } from './player';
import RubberScoreKeeper from './rubber-score-keeper';

const createBridgePlayers = function createBridgePlayers(
  playerNames,
  bidSystemConfig = BidSystemConfig.getDefaultSystemConfig()
) {
  return playerNames.map(name => new Player(name, bidSystemConfig));
};

const createBridgeGameService = function createBridgeGameService(players) {
  return new BridgeGameService(players);
};

const createRubberScoreKeeper = function createRubberScoreKeeper() {
  return new RubberScoreKeeper();
};

const createBridgeLabels = function createBridgeLabels(bidSystemConfig) {
  return createBidSystemLabels(bidSystemConfig);
};

export {
  createBridgeGameService,
  createBridgePlayers,
  createBridgeLabels,
  createRubberScoreKeeper,
};

export { constants, Coords, DistributionPointsTypes, Tags } from './constants';
export { PreloadedDeals } from './deals';
export { createPredeterminedDeal } from './services';
export {
  Player,
  getTeamFor,
  BidSystemConfig,
  BidContextTypes,
  getDataDefinitionKey,
  getBidContextGrid,
  DATA_DEF_SEP,
};
