import React from 'react';
import PropTypes from "prop-types";
import { Spring, config, animated } from 'react-spring/renderprops';

import uuid from 'uuid';

import { constants as uic } from '../constants';

import BidOptionsViewerModal from './modals/BidOptionsViewerModal';
import ErrorViewer from './modals/ErrorViewer';
import ModalViewer from './modals/ModalViewer';
import DealSelectorModal from './modals/DealSelectorModal';
import ExportDealModal from './modals/ExportDealModal';
import ImportDealModal from './modals/ImportDealModal';
import InfoSlidesModal from './modals/InfoSlidesModal';
import NowPlayingModal from './modals/NowPlayingModal';
import SaveDealModal from './modals/SaveDealModal';

import { DropDown } from './components';
import { MenuItem } from './components/MenuItem';

class GameView {
  constructor(app) {
    this.app = app;
  }

  static displayError(error, dealName) {
    ErrorViewer.displayError(error, dealName);
  }

  showModal(modalProps) {
    ModalViewer.showModal(modalProps, this.app.state.bridgeLabels);
  }

  getDealSelectorContent(deals, sortMethod, openFolderName, selectedDealName) {

    // array of sorted unique folder names with a generated ID
    const folderNames = (items => {
      const folders = new Set();

      items.forEach(item => folders.add(item.folderName));

      return [ ...folders ].map(name => ({
          id: uuid.v4(),
          label: name
            ? name
            : 'Default',
          isDefaultOpen: openFolderName === name,
       }));

    })(deals).sort(sortMethod);

    // a map of the folder names and their dealnames contentS
    const dealNamesMap = ((folderNames, menuProps) => {
      const contentMap = new Map();

      folderNames.forEach(folderNameData => {

        const folderItems = menuProps.filter(item => {
          if (!item.name) {
            return false;
          }

          if (!item.folderName &&  folderNameData.label === 'Default') {
            return true;
          }
          return item.folderName === folderNameData.label;
        });

        const dealNames = folderItems.map(deal => ({
          id: deal.id,
          timeStamp: deal.timeStamp,
          label: deal.name,
          isSelected: deal.name === selectedDealName
        })).sort((a, b) => a.label.localeCompare(b.label));

        contentMap.set(folderNameData.id, dealNames);
      });

      return contentMap;

    })(folderNames, deals);

    return {
      folderNames,
      dealNamesMap,
    };
  }

  showDealsMenu(menuProps, onSelectDeal) {
    const { data, sortMethod, openFolderName, selectedDealName, title } = menuProps;

    try {
      const folderValues = data
        ? this.getDealSelectorContent(data, sortMethod, openFolderName, selectedDealName)
        : null;

      const getModalContainerTag = events => (
        <DealSelectorModal
          title={title}
          folderValues={folderValues}
          {...events}
        />
      );

      const modalProps = {
        modalClass: 'deals-menu__container',
        getModalContainerTag,
        dialogForm: {
          onSubmit: onSelectDeal,
        },
      };

      this.showModal(modalProps);

    } catch (e) {
      GameView.displayError(e);
    }
  }

  showSaveDealForm(props) {
    const getModalContainerTag = events => <SaveDealModal
      id={props.id}
      dealName={props.name}
      folderName={props.folderName}
      {...events}
    />;

    getModalContainerTag.propTypes = {
      folderName: PropTypes.string,
      name: PropTypes.string,
      id: PropTypes.string,
    };

    const modalProps = {
      getModalContainerTag,
      dialogForm: {
        id: 'save-deal-form',
        onSubmit: props.onSubmit,
      }
    };

    this.showModal(modalProps);
  }

  showBidOptionsModal({ auctionContext, bidValue }) {
    const getModalContainerTag = events => <BidOptionsViewerModal
      auctionContext={auctionContext}
      bidValue={bidValue}
      {...events}
    />;

    const modalProps = {
      getModalContainerTag,
    };

    this.showModal(modalProps);
  }

  showNowPlaying(gameOptions, dealProps) {
    const getModalContainerTag = events => <NowPlayingModal
      dealProps={dealProps}
      gameOptions={gameOptions}
      {...events}
    />;

    const modalProps = {
      getModalContainerTag,
    };

    this.showModal(modalProps);
  }

  /**
  * @param {object} valuesJSON - exported game values
  * displays the exported games values
  */
  showGameExport(valuesJSON) {
    const getModalContainerTag = events => <ExportDealModal
      content={valuesJSON}
      {...events}
    />;

    const modalProps = {
      getModalContainerTag,
    };
    this.showModal(modalProps);
  }

  showGameImport(onImportDeal) {
    const getModalContainerTag = events => <ImportDealModal
      onImportDeal={onImportDeal}
      {...events}
    />;

    const modalProps = {
      getModalContainerTag,
    };
    this.showModal(modalProps);
  }

  showHintModal(content) {
    this.showInfoSlides({title: uic.HINT_LABEL, content: [content]});
  }

  showInfoSlides(props) {
    const getModalContainerTag = events => <InfoSlidesModal
      title={props.title}
      content={props.content}
      {...events}
    />;

    getModalContainerTag.propTypes = {
      content: PropTypes.array,
      title: PropTypes.string,
    };

    const modalProps = {
      getModalContainerTag,
    };

    this.showModal(modalProps);
  }

  getDropDown({ icon, getIconButton, menuClickAction, menuItems, isTop }) {
    const bottomStyle = {
      bottom: isTop
        ? 'auto'
        : '40px',
    };

    return (
      <DropDown key="drop-down" icon={icon} getIconButton={getIconButton} >
      <Spring
        native
        from={{ opacity: 0}}
        to={{ opacity: 1}}
        config={config.stiff}
      >
      {props => (
        <animated.div style={props}>
        <ul style={bottomStyle}>
          {menuItems.map(menuItem => (
            <MenuItem
              key={menuItem.id}
              {...menuItem}
              onClick={menuClickAction}
             />
          ))}
        </ul>
        </animated.div>
      )}
      </Spring>

      </DropDown>
    );
  }

}

export default GameView;
