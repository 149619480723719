import {
  CONTROL_BID_TO_ESTABLISH_SLAM,
  CUE_BID,
  DRURY,
  FOURTH_SUIT_FORCING,
  FOURTH_SUIT_FORCING_INVITE,
  JACOBY_TRANSFER,
  JUMP_SHIFT_FLOOR,
  LIMIT_RAISE_WITH_THREE_CARD_FIT,
  MICHAELS_CUE_BID,
  NEGATIVE_DOUBLE,
  NO_TRUMP_OVERCALL_DEFENCE_TWO_FIVE_CARD_SUITS,
  OPEN_LIGHT_QUICK_TRICK_COUNT,
  OPEN_POINTS_MINIMUM,
  OPEN_PREEMPT_MINIMUM_POINTS,
  OPEN_RULE_OF_TWENTY,
  OPEN_THIRD_SEAT_LIGHT_GOOD_SUIT,
  OVERCALL_MINIMUM_POINTS,
  OVERCALL_NO_TRUMP_OPEN_CONVENTION,
  OVERCALL_NO_TRUMP_OPEN_ASTRO,
  OVERCALL_NO_TRUMP_OPEN_CAPPELLETTI,
  OVERCALL_NO_TRUMP_OPEN_LANDY,
  TWO_LEVEL_GOOD_SUIT_OVERCALL_MIMIMUM,
  RESPOND_POINTS_MINIMUM,
  WEAK_TWOS,
} from './types'

export const CONTROL_BID_TO_ESTABLISH_SLAM_LABEL = 'Control bid to establish slam';
export const CUE_BID_LABEL = 'Cue bid';
export const DRURY_LABEL = 'Drury';
export const FOURTH_SUIT_FORCING_LABEL = 'Play fourth suit forcing';
export const FOURTH_SUIT_FORCING_INVITE_LABEL = 'Play fourth suit forcing as invitational one round force, otherwise game force';
export const JACOBY_TRANSFER_LABEL = 'Jacoby transfer';
export const JUMP_SHIFT_FLOOR_LABEL = 'Jump shift floor';
export const LIMIT_RAISE_WITH_THREE_CARD_FIT_LABEL = 'Allow limit raise with a limit raise with only three card fit';
export const MICHAELS_CUE_BID_LABEL = 'Michaels cue bid and Unusual 2NT';
export const NEGATIVE_DOUBLE_LABEL = 'Negative double';
export const NO_TRUMP_OVERCALL_DEFENCE_TWO_FIVE_CARD_SUITS_LABEL = 'Defend No Trump overcall with two five card suits';
export const OPEN_LIGHT_QUICK_TRICK_COUNT_LABEL = 'Open light with quick trick count of 2.5';
export const OPEN_POINTS_MINIMUM_LABEL = 'In general a one level suit opening bid shows at least: ';
export const OPEN_PREEMPT_MINIMUM_POINTS_LABEL = 'May open bidding with preempt bid with a minium points of: ';
export const OPEN_RULE_OF_TWENTY_LABEL = 'Use Rule of Twenty to Open bidding: ';
export const OPEN_THIRD_SEAT_LIGHT_GOOD_SUIT_LABEL = 'Open bidding in third seat light with a good suit';
export const OVERCALL_MINIMUM_POINTS_LABEL  = 'Overcall minium points';
export const OVERCALL_NO_TRUMP_OPEN_CONVENTION_LABEL = 'Overcall No Trump Open Convention';
export const OVERCALL_NO_TRUMP_OPEN_ASTRO_LABEL = 'Astro';
export const OVERCALL_NO_TRUMP_OPEN_CAPPELLETTI_LABEL = 'Cappelletti';
export const OVERCALL_NO_TRUMP_OPEN_LANDY_LABEL = 'Landy';
export const TWO_LEVEL_GOOD_SUIT_OVERCALL_MIMIMUM_LABEL = 'Minium Points to overcall at two level with long strong suit';
export const RESPOND_POINTS_MINIMUM_LABEL = 'Respond to opening bid points minimum';
export const WEAK_TWOS_LABEL = 'Play Weak Twos, if false play old-fashioned Strong Two-bids';

export const Labels = Object.freeze({
  [ CONTROL_BID_TO_ESTABLISH_SLAM ]: CONTROL_BID_TO_ESTABLISH_SLAM_LABEL,
  [ CUE_BID ]: CUE_BID_LABEL,
  [ DRURY ]: DRURY_LABEL,
  [ FOURTH_SUIT_FORCING ]: FOURTH_SUIT_FORCING_LABEL,
  [ FOURTH_SUIT_FORCING_INVITE ]: FOURTH_SUIT_FORCING_INVITE_LABEL,
  [ JACOBY_TRANSFER ]: JACOBY_TRANSFER_LABEL,
  [ JUMP_SHIFT_FLOOR ]: JUMP_SHIFT_FLOOR_LABEL,
  [ LIMIT_RAISE_WITH_THREE_CARD_FIT ]: LIMIT_RAISE_WITH_THREE_CARD_FIT_LABEL,
  [ MICHAELS_CUE_BID ]: MICHAELS_CUE_BID_LABEL,
  [ NEGATIVE_DOUBLE ]: NEGATIVE_DOUBLE_LABEL,
  [ NO_TRUMP_OVERCALL_DEFENCE_TWO_FIVE_CARD_SUITS ]: NO_TRUMP_OVERCALL_DEFENCE_TWO_FIVE_CARD_SUITS_LABEL,
  [ OPEN_LIGHT_QUICK_TRICK_COUNT ]: OPEN_LIGHT_QUICK_TRICK_COUNT_LABEL,
  [ OPEN_POINTS_MINIMUM ]: OPEN_POINTS_MINIMUM_LABEL,
  [ OPEN_PREEMPT_MINIMUM_POINTS ]: OPEN_PREEMPT_MINIMUM_POINTS_LABEL,
  [ OPEN_RULE_OF_TWENTY ]: OPEN_RULE_OF_TWENTY_LABEL,
  [ OPEN_THIRD_SEAT_LIGHT_GOOD_SUIT ]: OPEN_THIRD_SEAT_LIGHT_GOOD_SUIT_LABEL,
  [ OVERCALL_MINIMUM_POINTS ]: OVERCALL_MINIMUM_POINTS_LABEL,
  [ OVERCALL_NO_TRUMP_OPEN_CONVENTION ]: OVERCALL_NO_TRUMP_OPEN_CONVENTION_LABEL,
  [ OVERCALL_NO_TRUMP_OPEN_ASTRO ]: OVERCALL_NO_TRUMP_OPEN_ASTRO_LABEL,
  [ OVERCALL_NO_TRUMP_OPEN_CAPPELLETTI ]: OVERCALL_NO_TRUMP_OPEN_CAPPELLETTI,
  [ OVERCALL_NO_TRUMP_OPEN_LANDY ]: OVERCALL_NO_TRUMP_OPEN_LANDY_LABEL,
  [ RESPOND_POINTS_MINIMUM ]: RESPOND_POINTS_MINIMUM_LABEL,
  [ TWO_LEVEL_GOOD_SUIT_OVERCALL_MIMIMUM ]: TWO_LEVEL_GOOD_SUIT_OVERCALL_MIMIMUM_LABEL,
  [ WEAK_TWOS ]: WEAK_TWOS_LABEL,
});
