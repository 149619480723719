import { DATA_DEFINITION_PREFIX } from '../../constants';

export const MINIMAL_FORCE_BID = 'takeout-double-response/MINIMAL_FORCE_BID';
export const DEF_MINIMAL_FORCE_BID = DATA_DEFINITION_PREFIX+'takeout-double-response/MINIMAL_FORCE_BID';

export const FREE_BID = 'takeout-double-response/FREE_BID';
export const DEF_FREE_BID = DATA_DEFINITION_PREFIX+'takeout-double-response/FREE_BID';

export const INVITATIONAL_STRENGTH = 'takeout-double-response/INVITATIONAL_STRENGTH';
export const DEF_INVITATIONAL_STRENGTH = DATA_DEFINITION_PREFIX+'takeout-double-response/INVITATIONAL_STRENGTH';

export const MINIMAL_NO_TRUMP = 'takeout-double-response/MINIMAL_NO_TRUMP';
export const DEF_MINIMAL_NO_TRUMP = DATA_DEFINITION_PREFIX+'takeout-double-response/MINIMAL_NO_TRUMP';

export const INVITATIONAL_NO_TRUMP = 'takeout-double-response/INVITATIONAL_NO_TRUMP';
export const DEF_INVITATIONAL_NO_TRUMP = DATA_DEFINITION_PREFIX+'takeout-double-response/INVITATIONAL_NO_TRUMP';

export const GAME_FORCE_CUEBID = 'takeout-double-response/GAME_FORCE_CUEBID';
export const DEF_GAME_FORCE_CUEBID = DATA_DEFINITION_PREFIX+'takeout-double-response/GAME_FORCE_CUEBID';

export const GAME_FORCE_SUIT = 'takeout-double-response/GAME_FORCE_SUIT';
export const DEF_GAME_FORCE_SUIT = DATA_DEFINITION_PREFIX+'takeout-double-response/GAME_FORCE_SUIT';

export const GAME_FORCE_NO_TRUMP = 'takeout-double-response/GAME_FORCE_NO_TRUMP';
export const DEF_GAME_FORCE_NO_TRUMP = DATA_DEFINITION_PREFIX+'takeout-double-response/GAME_FORCE_NO_TRUMP';
