import uuid from 'uuid';
import * as R from 'ramda';

import { createPredeterminedDeal, Coords } from '../bridge';

/**
saved_deals => dealid....
history_deals => dealid....

folder_names => id

folder_deals => folder_names.id, saved_deals.id

deal_history => id, timeStamp, source(saved_deals||history_deals), source.dealid
*/

const SAVED_DEALS = 'SAVED_DEALS';
const FOLDER_NAMES = 'FOLDER_NAMES';
const FOLDER_DEALS = 'FOLDER_DEALS';
const HISTORY_DEALS = 'HISTORY_DEALS';
const DEAL_HISTORY = 'DEAL_HISTORY';

class SavedDealsService {

  /**
  * create a deal from the saved format
  */
  createPredetermindedDeal(savedDealProps) {
    let {
      id,
      name,
      folderName,
      hands,
      auction,
      bidSequence,
      dealer,
      dealerPosition,
      deleteMethod,
      source,
      timeStamp,
      lastPlayed,
      playCount,
    } = savedDealProps;

    if (!hands) {
      this.deleteSavedDeal(id);
      return;
    }

    if (!id) {
      id = uuid.v4();
    }

    if (!dealerPosition) {
      dealerPosition = dealer;
    }

    if (!bidSequence) {
      bidSequence = auction;
    }

    const getHandinCorrectFormat = () => {
      const assignPosition = (handsArray) => {
        let handPos = Coords.SOUTH;
        const getPos = (pos) => {
          handPos = Coords.nextCoord(pos);
          return handPos.toLowerCase();
        };
        return Object.assign({}, ...handsArray.map((hand) =>
          ({[ getPos(handPos) ]: hand})));
      };

      if (Array.isArray(hands[ Coords.EAST.toLowerCase() ])) {
        return hands;
      }
      // old format needs to be object with player pos as a prop
      // and an array of cards as its value
      if (Array.isArray(hands)) {
        return assignPosition(hands);
      }
      if (typeof hands === 'string') {
        return assignPosition(JSON.parse(hands));
      }
      // current save format - put the cards back in an array
      return Object.assign({}, ...Object.keys(hands).map(k =>
        ({[ k ]: hands[ k ].split(' ')})));
    };

    return createPredeterminedDeal({
      id,
      name,
      folderName,
      hands: getHandinCorrectFormat(),
      bidSequence,
      deleteMethod,
      dealerPosition,
      source,
      timeStamp,
      lastPlayed: lastPlayed ?? timeStamp,
      playCount: playCount ?? 0,
    });
  }

  deleteDealHistory(dealId, source, onError) {
    try {
      // remove the lookup row
      this.deleteDealHistoryItem(dealId);
      // source is history deal?
      if (source === HISTORY_DEALS) {
        const deals = removeHistoryDeal(this.selectHistoryDeals(), dealId);
        return commitHistoryDeals(deals);
      }

    } catch (e) {
      onError(e);
    }
  }

  deleteFolderDealItem(dealId) {
    commitFolderDealItems(
      this.selectFolderDealItems().filter(item => item.dealId !== dealId)
    );
  }

  deleteDealHistoryItem(dealId) {
    commitDealHistoryItems(
      this.selectDealHistoryItems().filter(item => item.dealId !== dealId)
    );
  }

  deleteFolderDeals(folderName, onError) {
    try {
      const folder = this.getFolderNameMapByName().get(folderName);
      const folderDeals = this.selectFolderDealsByFolderId(folder.id);
      if (folderDeals) {
        const savedDeals = this.selectSavedDeals()
          .filter(deal =>
            !folderDeals.find(folderDeal => folderDeal.dealId === deal.id));

        commitSavedDeals(savedDeals);
      }
      const folders = removeFolderName(this.selectFolderNames(), folderName);
      return commitFolderNames(folders);

    } catch (e) {
      onError(e);
    }
  }

  /**
  * @param {string} id - id of deal to delete
  */
  deleteSavedDeal(dealId, onError) {
    try {
      // remove the folder deal lookup row
      this.deleteFolderDealItem(dealId);
      // next the deal
      const deals = removeSavedDeal(this.selectSavedDeals(), dealId);
      return commitSavedDeals(deals);

    } catch (e) {
      onError(e);
    }
  }

  getIsFolderNameExist(folderName) {
    const folders = this.getFolderNameMapByName();
    return (folders && folders.has(folderName));
  }

  insertFolderNameIfNotExist(folderName) {
    const folders = this.getFolderNameMapByName();
    if (!this.getIsFolderNameExist(folderName)) {
      return this.insertFolderName(createFolderName(uuid.v4(), folderName));
    }
    return folders.get(folderName);
  }

  insertFolderDeal(folderName, deal) {

    const folder = this.insertFolderNameIfNotExist(folderName);
    const newDeal = this.insertDeal(deal);

    const folderDeals = appendFolderDeals(this.selectFolderDealItems(), createFolderDeal(folder.id, newDeal.id));
    commitFolderDealItems(folderDeals);

    return this.selectFolderDeal(
      this.getFolderNameMap(),
      this.getSavedDealMap(),
      createFolderDeal(folder.id, newDeal.id)
    );
  }

  insertDeal(deal) {
    // update
    const savedDeals = appendSavedDeals(this.selectSavedDeals(), deal);
    // persist
    commitSavedDeals(savedDeals);

    return R.last(savedDeals);
  }

  insertFolderName(folder) {
    const folderNames = appendFolderNames(this.selectFolderNames(), folder);
    commitFolderNames(folderNames);

    return R.last(folderNames);
  }

  insertHistoryDeal(deal) {
    // update
    const historyDeals = appendHistoryDeal(this.selectHistoryDeals(), deal);
    // persist
    commitHistoryDeals(historyDeals);

    return R.last(historyDeals);
  }

  /**
  * 1. if !dealId save to HISTORY_DEALS
  * 2. link the HISTORY_DEALS or SAVED_DEALS id to DEAL_HISTORY
  */
  insertDealHistory(deal, onError) {
    if (R.isEmpty(deal)) {
      return deal;
    }

    try {
      // RandomDeal no id, otherwise deal already source-id
      const item = R.isEmpty(deal.id)
        ? {
            deal: this.insertHistoryDeal(deal),
            source: HISTORY_DEALS,
          }
        : {
            deal,
            source: deal.source,
          };

      // no repeat!
      if (this.selectDealHistoryItems().find(historyItem => historyItem.dealId === item.deal.id)
      ) {
        this.deleteDealHistoryItem(item.deal.id);
      }

      return R.pipe(
        createDealHistoryItem,
        R.partial(appendDealHistoryItem, [ this.selectDealHistoryItems() ]),
        commitDealHistoryItems,
      )(item.source, item.deal.id);

    } catch (e) {
      onError(e);
    }
  }

  incrementPlayProps(deal) {
    const updatedDeal = {
      ...deal,
      playCount: deal.playCount + 1,
      lastPlayed: Date.now(),
    };

    if (deal.source === SAVED_DEALS) {
      return this.updateFolderDeal(deal.folderName, updatedDeal);
    }

    return updatedDeal;
  }

  updateFolderDeal(folderName, deal) {
    // changed to brand new folder
    if (!this.getIsFolderNameExist(folderName)) {
      this.insertFolderDeal(folderName, deal);
    }
    //else {
      // update deal
      const oldDeal = R.clone(this.getSavedDealMap().get(deal.id));
      if (deal.folderName !== oldDeal.folderName) {
        // new relation
        this.deleteFolderDealItem(deal.id);
        const folder = this.getFolderNameMapByName().get(folderName);
        const folderDeals = appendFolderDeals(this.selectFolderDealItems(), createFolderDeal(folder.id, deal.id));
        commitFolderDealItems(folderDeals);
      }
    //}

    const updatedFolderDeal = R.last(this.selectFolderDealItems());
    const updatedDeal = this.insertDeal(deal);

    return this.selectFolderDeal(
      this.getFolderNameMap(),
      this.getSavedDealMap(),
      createFolderDeal(updatedFolderDeal.folderId, updatedDeal.id)
    );
  }

  selectFolderDealItems() {
    const folderDeals = localStorage.getItem(FOLDER_DEALS);
    if (folderDeals) {
      return JSON.parse(folderDeals);
    }
    return [  ];
  }

  //join
  selectFolderDeal(folderNameMap, dealMap, { folderId, dealId }) {
    const deal = dealMap.get(dealId);
    const folder = folderNameMap.get(folderId);

    return {
      ...deal,
      folderId: folder.id,
      folderName: folder.name,
      deleteMethod: deal
        ? this.deleteSavedDeal.bind(this, deal.id)
        : () => false,
      source: SAVED_DEALS,
    };
  }

  //join
  selectFolderDeals() {
    const folderNameMap = this.getFolderNameMap();
    const dealMap = this.getSavedDealMap();

    return this.selectFolderDealItems().map(item =>
      this.selectFolderDeal(folderNameMap, dealMap, item)
    );

  }

  // History join Deal
  selectDealHistory() {
    const savedDealMap = this.getSavedDealMap();
    const historyDealMap = this.getHistoryDealMap();

    const getDeal = dealId => historyDealMap.get(dealId)
      || savedDealMap.get(dealId)

    return this.selectDealHistoryItems().map(item => ({
      ...getDeal(item.dealId),
       //folderId: v4
       timeStamp: item.timeStamp,
       folderName: new Date(item.timeStamp).toLocaleDateString(),
       deleteMethod: this.deleteDealHistory.bind(this, item.dealId, HISTORY_DEALS),
       source: HISTORY_DEALS,
    }));
  }

  selectFolderDealsByFolderId(folderId) {
    return this.selectFolderDeals().filter(deal => deal.folderId === folderId);
  }

  selectSavedDealByName(name) {
    const savedDeals = this.selectSavedDeals();
    return savedDeals.find(deal => deal.name === name);
  }

  selectFolderNames() {
    const folderNames = localStorage.getItem(FOLDER_NAMES);
    if (folderNames) {
      return JSON.parse(folderNames).sort((a, b) => a.name.localeCompare(b.name));
    }
    return [  ];
  }

  selectHistoryDeals() {
    let historyDeals = localStorage.getItem(HISTORY_DEALS);
    if (historyDeals) {
      return JSON.parse(historyDeals).sort((a, b) =>
        a.timeStamp > b.timeStamp
          ? -1
          : 1
      );
    }
    return [  ];
  }

  selectDealHistoryItems() {
    let dealHistory = localStorage.getItem(DEAL_HISTORY);
    if (dealHistory) {
      return JSON.parse(dealHistory).sort((a, b) => a.timeStamp > b.timeStamp
        ? -1
        : 1
      );
    }
    return [  ];
  }

  selectSavedDeals() {
    let savedDeals = localStorage.getItem(SAVED_DEALS);
    if (savedDeals) {
      return JSON.parse(savedDeals).sort((a, b) => {
        if (!a.name) {
          a.name = 'I need a name';
        }
        return a.name.localeCompare(b.name);
      });

    }
    return [  ];
  }

  getFolderNameMap() {
    return new Map(this.selectFolderNames().map(folder => [ folder.id, folder ]));
  }

  getFolderNameMapByName() {
    return new Map(this.selectFolderNames().map(folder => [ folder.name, folder ]));
  }

  getSavedDealMap() {
    return new Map(this.selectSavedDeals().map(deal => [ deal.id, deal ]));
  }

  getHistoryDealMap() {
    return new Map(this.selectHistoryDeals().map(deal => [ deal.id, deal ]));
  }

}

const getDealClone = deal => R.pipe(R.clone, R.dissoc('folderName'))(deal);

const appendHistoryDeal = function appendHistoryDeal(historyDeals, deal) {
  const item = getDealClone(deal);
  item.id = uuid.v4();
  return [ ...historyDeals, item ];
};

const appendSavedDeals = function appendSavedDeals(savedDeals, deal) {
  const item = getDealClone(deal);
  if (R.isNil(item.id) || R.isEmpty(item.id)) {
    item.id = uuid.v4();
  } else {
    savedDeals = removeSavedDeal(savedDeals, item.id);
  }
  return [ ...savedDeals, item ];
};

const appendFolderDeals = function appendFolderDeals(folderDeals, folderDeal) {
  return [ ...folderDeals, folderDeal ];
};

const appendFolderNames = function appendFolderNames(folderNames, folder) {
  return [ ...folderNames, folder ];
};

const appendDealHistoryItem = function appendDealHistoryItem(dealHistoryItems, dealHistoryItem) {
  return [ ...dealHistoryItems, dealHistoryItem ];
};

const commitSavedDeals = function commitSavedDeals(savedDeals) {
  localStorage.setItem(SAVED_DEALS, JSON.stringify(savedDeals));
  return savedDeals;
};

const commitFolderNames = function commitFolderNames(folderNames) {
  localStorage.setItem(FOLDER_NAMES, JSON.stringify(folderNames));
  return folderNames;
};

const commitFolderDealItems = function commitFolderDealItems(folderDeals) {
  localStorage.setItem(FOLDER_DEALS, JSON.stringify(folderDeals));
  return folderDeals;
};

const commitDealHistoryItems = function commitDealHistoryItems(dealHistoryItems) {
  localStorage.setItem(DEAL_HISTORY, JSON.stringify(dealHistoryItems));
  return dealHistoryItems;
};

const commitHistoryDeals = function commitHistoryDeal(historyDeals) {
  localStorage.setItem(HISTORY_DEALS, JSON.stringify(historyDeals));
  return historyDeals;
};

const createFolderDeal = (folderId, dealId) => ({
  folderId,
  dealId,
});

const createFolderName = (folderId, folderName) => ({
  id: folderId,
  name: folderName,
});

const createDealHistoryItem = (source, dealId) => ({
  id: uuid.v4(),
  timeStamp: Date.now(),
  source,
  dealId,
});

const removeHistoryDeal = function removeHistoryDeal(deals, id) {
  return deals.filter(deal => deal.id !== id);
};

const removeSavedDeal = function removeSavedDeal(deals, id) {
  return deals.filter(deal => deal.id !== id);
};

const removeFolderName = function removeFolderName(deals, folderName) {
  return deals.filter(deal => deal.folderName && deal.folderName !== folderName);
};


export { SavedDealsService };
