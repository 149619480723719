const BRIDGE_TABLE_VIEW = 'BRIDGE_TABLE_VIEW';
const BIDDING_GUIDE_VIEW = 'BIDDING_GUIDE_VIEW';

const ViewModes = Object.freeze({
  BRIDGE_TABLE_VIEW,
  BIDDING_GUIDE_VIEW,
});

const createViewModeProps = ({ mode, details = {} }) => ({
  mode,
  details
})

export { ViewModes, createViewModeProps };
