import { constants as c } from '../../../constants';

import {
  PASS_OVERCALL,
  TWO_DIAMOND_NO_PREFERENCE,
  WEAK_FIT,
  GOOD_FIT,
  STRONG_FIT,
  STRONG_HAND_NO_FIT,
} from './types';

const PASS_OVERCALL_LABEL = 'Pass with Long clubs and a weak hand.';
const TWO_DIAMOND_NO_PREFERENCE_LABEL = c.LANDY_TWO_DIAMOND_NO_PREFERENCE_LABEL;
const WEAK_FIT_LABEL = 'Shows 0-10(+) points and a 3+ card support';
const GOOD_FIT_LABEL = 'Shows 11-12 points and a 4+ card support';
const STRONG_FIT_LABEL = 'Shows 13+ points and a 4+ card support';
const STRONG_HAND_NO_FIT_LABEL = 'Forcing, shows 13+ points and no fit';

export const Labels = {
  [ PASS_OVERCALL ]: PASS_OVERCALL_LABEL,
  [ TWO_DIAMOND_NO_PREFERENCE ]: TWO_DIAMOND_NO_PREFERENCE_LABEL,
  [ WEAK_FIT ]: WEAK_FIT_LABEL,
  [ GOOD_FIT ]: GOOD_FIT_LABEL,
  [ STRONG_FIT ]: STRONG_FIT_LABEL,
  [ STRONG_HAND_NO_FIT ]: STRONG_HAND_NO_FIT_LABEL,
};
