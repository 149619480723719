import React from 'react';
import PropTypes from 'prop-types';

import { constants as c } from '../../bridge';
import { constants as uic, DealOptions } from '../../constants';

import { useFocus } from '../hooks';

import { SelectLayout } from './SelectLayout';

export const BidSystemSelect = function BidSystemSelect({
  containerClass,
  displayLabels,
  label,
  canEdit,
  bidSystem,
  handleChange,
  isFocus,
}) {
  const inputRef = useFocus(isFocus);

  const ariaLabel = displayLabels
    ? ''
    : uic.SELECT_DEFAULT_BID_SYSTEM_LABEL;

  const getSelect = () => (
    <select ref={inputRef} id={uic.BID_SYSTEM} name={uic.BID_SYSTEM} aria-label={ariaLabel} className="select-css" style={{tabIndex:2}} defaultValue={bidSystem} onChange={handleChange}>
      <option value={uic.BID_SYSTEMS.bridgeBlvdBeginner}>{uic.BID_SYSTEM_LABELS[ uic.BID_SYSTEMS.bridgeBlvdBeginner ]}</option>
      <option value={uic.BID_SYSTEMS.standardAmerican}>{uic.BID_SYSTEM_LABELS[ uic.BID_SYSTEMS.standardAmerican ]}</option>
    </select>
  );

  const getEditLabel = () => (
    uic.BID_SYSTEM_LABELS[ bidSystem ]
  );

  return (<SelectLayout
    containerClass={containerClass}
    displayLabels={displayLabels}
    canEdit={canEdit}
    labelFor={'bidSystem'}
    label={label || uic.SELECT_DEFAULT_BID_SYSTEM_LABEL}
    getSelect={getSelect}
    getEditLabel={getEditLabel}
    />
  );
};
BidSystemSelect.propTypes = {
  canEdit: PropTypes.bool,
  containerClass: PropTypes.string,
  bidSystem: PropTypes.string,
  displayLabels: PropTypes.bool,
  handleChange: PropTypes.func,
  label: PropTypes.string,
  isFocus: PropTypes.bool,
};
BidSystemSelect.defaultProps = {
  canEdit: true,
  isFocus: false,
};

export const PlayTypeSelect = function PlayTypeSelect({
  containerClass,
  displayLabels,
  canEdit,
  playType,
  handleChange,
  isFocus
}) {
  const inputRef = useFocus(isFocus);

  const ariaLabel = displayLabels
    ? ''
    : uic.SELECT_DEFAULT_PRACTICE_OPTION_LABEL;

  const getSelect = () => (
    <select ref={inputRef} id="playType" name="playType" aria-label={ariaLabel} className="select-css" style={{ tabIndex:4}} defaultValue={playType} onChange={handleChange}>
      <option value={uic.PLAY_OPTIONS.bidPractice}>{uic.PLAY_OPTION_LABELS[ uic.PLAY_OPTIONS.bidPractice ]}</option>
      <option value={uic.PLAY_OPTIONS.playPractice}>{uic.PLAY_OPTION_LABELS[ uic.PLAY_OPTIONS.playPractice ]}</option>
      <option value={uic.PLAY_OPTIONS.playRubber}>{uic.PLAY_OPTION_LABELS[ uic.PLAY_OPTIONS.playRubber ]}</option>
    </select>
  );
  const getEditLabel = () => (
    uic.PLAY_OPTION_LABELS[playType]
  );

  return (<SelectLayout
    containerClass={containerClass}
    displayLabels={displayLabels}
    canEdit={canEdit}
    labelFor={'playType'}
    label={uic.SELECT_DEFAULT_PRACTICE_OPTION_LABEL}
    getSelect={getSelect}
    getEditLabel={getEditLabel}
    />
  );
};
PlayTypeSelect.propTypes = {
  canEdit: PropTypes.bool,
  containerClass: PropTypes.string,
  playType: PropTypes.string,
  displayLabels: PropTypes.bool,
  handleChange: PropTypes.func,
  isFocus: PropTypes.bool,
};
PlayTypeSelect.defaultProps = {
  isFocus: false,
};

export const DealTypeSelect = function DealTypeSelect({
  containerClass,
  displayLabels,
  canEdit,
  dealType,
  handleChange,
  folderName,
  name
}) {
  const ariaLabel = displayLabels
    ? ''
    : uic.SELECT_DEFAULT_DEAL_TYPE_LABEL;

  const getSelect = () => (
    <select id="dealType" name="dealType" aria-label={ariaLabel} className="select-css" style={{tabIndex:1}} defaultValue={dealType} onChange={handleChange}>
      <option value={DealOptions.DEAL_RANDOM_HANDS}>{uic.DEAL_OPTION_LABELS[DealOptions.DEAL_RANDOM_HANDS]}</option>
      <option value={DealOptions.DEAL_SAVED_HANDS}>{uic.DEAL_OPTION_LABELS[DealOptions.DEAL_SAVED_HANDS]}</option>
      <option value={DealOptions.DEAL_HISTORY_HANDS}>{uic.DEAL_OPTION_LABELS[DealOptions.DEAL_HISTORY_HANDS]}</option>
      <option value={DealOptions.DEAL_PRELOADED_HANDS}>{uic.DEAL_OPTION_LABELS[DealOptions.DEAL_PRELOADED_HANDS]}</option>
    </select>
  );

  const getFormattedDeal = () => {
    if (name === c.RANDOM_DEAL) {
      return (
        <React.Fragment>
          <span> </span><br />
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <br /> {uic.CURRENT_DEAL_IS_LABEL} <br />
          <span style={{
            color: 'var(--diamond)',
            fontSize: '130%',
          }}>{`${folderName}/${name}`}
          </span><br />
    </React.Fragment>
    );
  };

  const getEditLabel = () => (
    <React.Fragment>
      <span>{uic.DEAL_OPTION_LABELS[dealType]}. </span>
      {getFormattedDeal()}
    </React.Fragment>
  );

  return (<SelectLayout
    containerClass={containerClass}
    displayLabels={displayLabels}
    canEdit={canEdit}
    labelFor={'dealType'}
    label={uic.SELECT_DEFAULT_DEAL_TYPE_LABEL}
    getSelect={getSelect}
    getEditLabel={getEditLabel}
    />
  );
};

DealTypeSelect.propTypes = {
  canEdit: PropTypes.bool,
  containerClass: PropTypes.string,
  name: PropTypes.string,
  dealType: PropTypes.string,
  displayLabels: PropTypes.bool,
  folderName: PropTypes.string,
  handleChange: PropTypes.func,
};

export const BidTypeSelect = function BidTypeSelect({
  containerClass,
  displayLabels,
  canEdit,
  bidType,
  handleChange,
}) {
  const ariaLabel = displayLabels
    ? ''
    : uic.SELECT_DEFAULT_BIDDING_OPTION_LABEL;

  const getSelect = () => (
    <select id="bidType" name="bidType" aria-label={ariaLabel} className="select-css" style={{ tabIndex:3}} defaultValue={bidType} onChange={handleChange}>
      <option value={uic.BID_OPTIONS.computerBid}>{uic.BID_OPTION_LABELS[uic.BID_OPTIONS.computerBid]}</option>
      <option value={uic.BID_OPTIONS.interactiveBid}>{uic.BID_OPTION_LABELS[uic.BID_OPTIONS.interactiveBid]}</option>
    </select>
  );

  const getEditLabel = () => (
    canEdit
      ? uic.BID_OPTION_LABELS[bidType]
      : bidType === uic.BID_OPTIONS.computerBid
        ? uic.NOW_PLAYING_COMPUTER_BID_SYSTEM_LABEL
        : uic.NOW_PLAYING_YOUR_BID_SYSTEM_LABEL
  );

  return (<SelectLayout
    containerClass={containerClass}
    displayLabels={displayLabels}
    canEdit={canEdit}
    labelFor={'bidType'}
    label={uic.SELECT_DEFAULT_BIDDING_OPTION_LABEL}
    getSelect={getSelect}
    getEditLabel={getEditLabel}
    />
  );
};

BidTypeSelect.propTypes = {
  canEdit: PropTypes.bool,
  containerClass: PropTypes.string,
  bidType: PropTypes.string,
  displayLabels: PropTypes.bool,
  handleChange: PropTypes.func,
};

const GamePlayOptions = function GamePlayOptions({isFocus, ...restProps}) {
  return (
    <>
      <PlayTypeSelect isFocus={isFocus} {...restProps}/>
      <DealTypeSelect {...restProps}/>
      <BidTypeSelect {...restProps}/>
      <BidSystemSelect {...restProps} />
    </>
  );
};
GamePlayOptions.propTypes = {
  canEdit: PropTypes.bool,
  containerClass: PropTypes.string,
  dealType: PropTypes.string,
  bidSystem: PropTypes.string,
  bidType: PropTypes.string,
  displayLabels: PropTypes.bool,
  playType: PropTypes.string,
  handleChange: PropTypes.func,
  isFocus: PropTypes.bool,
};
GamePlayOptions.defaultProps = {
  containerClass: '',
  displayLabels: false,
  canEdit: true,
  isFocus: false,
};

export default GamePlayOptions;
