import React from "react";
import PropTypes from 'prop-types';

import { DialogTitleBar } from '../components';

const ExceptionModal = props => {
  return (
    <div id="exception-modal" className="main-component__container">
      <DialogTitleBar title="Error" onClose={props.onClose} />
      <div id="exception-modal-content">
        <p>{props.error.message}</p>
        <p>{props.error.stack && props.error.stack}</p>
      </div>
    </div>
  );
};
ExceptionModal.propTypes = {
  error: PropTypes.object,
  onClose: PropTypes.func,
};

export default ExceptionModal;
