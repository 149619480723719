import { createBidOptions } from '../create-bid-options';

import {
  PASS_OVERCALL,
  TWO_DIAMOND_NO_PREFERENCE,
  WEAK_FIT,
  GOOD_FIT,
  STRONG_FIT,
  STRONG_HAND_NO_FIT,
} from './types';

import { Labels } from './labels';

const getOptions = function getOptions() {
  return createBidOptions([
    PASS_OVERCALL,
    TWO_DIAMOND_NO_PREFERENCE,
    WEAK_FIT,
    GOOD_FIT,
    STRONG_FIT,
    STRONG_HAND_NO_FIT,
  ]);
};

export {
  PASS_OVERCALL,
  TWO_DIAMOND_NO_PREFERENCE,
  WEAK_FIT,
  GOOD_FIT,
  STRONG_FIT,
  STRONG_HAND_NO_FIT,
  Labels,
  getOptions,
};
