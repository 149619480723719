import { constants as c, Coords } from './constants';

const GAME_KEYS = ['gameOne', 'gameTwo', 'gameThree'];

// return if contract is north/south they are the we team
function getIsWeTeamContract(declarerPosition) {
  return declarerPosition === Coords.SOUTH || declarerPosition === Coords.NORTH;
}

// assign the points
function getTeamPoints(declarerPosition, isSuccess, points, defender = false) {
  const isThisTeamPoints = (teamHasContract) =>
    (teamHasContract && isSuccess && !defender)
      || (!teamHasContract && !isSuccess && defender);

  const isWeTeam = getIsWeTeamContract(declarerPosition);

  return {
    we: isThisTeamPoints(isWeTeam)
      ? points
      : 0,
    they: isThisTeamPoints(!isWeTeam)
      ? points
      : 0,
  };
}

// return the tallyData into a score for each team
function getTallyScore(tallyData) {
  const {
    contractDeclarer,
    contractResult,
    contractValue,
    contractPoints,
    overTrickPoints,
    underTrickPenalty,
    slamBonus,
    honorBonus,
    doubleBonus} = tallyData;

  const isSuccess = contractResult >= 0;

  return {
    contractDeclarer,
    contractResult,
    contractValue,
    contractPoints: getTeamPoints(contractDeclarer, isSuccess, contractPoints),
    overTrickPoints: getTeamPoints(contractDeclarer, isSuccess, overTrickPoints),
    underTrickPenalty: getTeamPoints(contractDeclarer, isSuccess, underTrickPenalty, true),
    slamBonus: getTeamPoints(contractDeclarer, isSuccess, slamBonus),
    honorBonus: getTeamPoints(contractDeclarer, isSuccess, honorBonus),
    doubleBonus: getTeamPoints(contractDeclarer, isSuccess, doubleBonus),
  };
}

// initial scores o zero
function getStartScore() {
  return {
    aboveTheLinePoints: {we: 0, they: 0},
    gameOne: {we: 0, they: 0},
    gameTwo: {we: 0, they: 0},
    gameThree: {we: 0, they: 0},
    totalPoints: {we: 0, they: 0},
  };
}

// c.POINTS_FOR_GAME to make game
function isGameInPlay(score) {
  return score.we < c.POINTS_FOR_GAME && score.they < c.POINTS_FOR_GAME;
}

// find the game in play - return its key
function getGameInPlayKey(scoreAccumulator) {
  return GAME_KEYS.find((key) => isGameInPlay(scoreAccumulator[key]));
}

// accumulate the scores
function getUpdatedPoints(oldPoints, ...newPoints) {
  return newPoints.reduce((accumalate, item) => {
    return {
      we: accumalate.we + item.we,
      they: accumalate.they + item.they,
    };
  }, oldPoints);
}

function getRubberWinner({we, they}) {
  return we > they
    ? c.NORTH_SOUTH: c.EAST_WEST;
}

function getGameWinner(score) {
  if (!isGameInPlay(score)) {
    return score.we >= c.POINTS_FOR_GAME ?
      c.NORTH_SOUTH : c.EAST_WEST;
  }
  return null;
}

function getWins(scoreAccumulator) {
  return GAME_KEYS.reduce((wins, key) => {
    const {we, they} = wins;
    const winner = getGameWinner(scoreAccumulator[key]);
    if (winner) {
      if (winner === c.NORTH_SOUTH) {
        return {
          we: we + 1,
          they,
        };
      }
      return {
        we,
        they: they + 1,
      };
    }
    return {
      we,
      they,
    };
  }, {we: 0, they: 0});
}

function getRubberBonusPoints(loserWinCnt) {
  return loserWinCnt === 1
    ? 500
    : 700;
}

function getRubberBonus(scoreAccumulator) {
  const wins = getWins(scoreAccumulator);
  if (wins.we === 2) {
    return {
      we: getRubberBonusPoints(wins.they),
      they: 0,
    };
  }
  if (wins.they === 2) {
    return {
      we: 0,
      they: getRubberBonusPoints(wins.we),
    };
  }
  return null;
}

function getScoreAccumulator(tallies) {
  const scoreAccumulator = tallies.reduce((scoreAccumulator, tallyData) => {
    const tally = getTallyScore(tallyData);
    if (tally.contractResult >= 0) {
      const gameKey = getGameInPlayKey(scoreAccumulator);
      scoreAccumulator[gameKey] = getUpdatedPoints(scoreAccumulator[gameKey], tally.contractPoints);
      scoreAccumulator.aboveTheLinePoints = getUpdatedPoints(
        scoreAccumulator.aboveTheLinePoints,
        tally.overTrickPoints,
        tally.slamBonus,
        tally.honorBonus,
        tally.doubleBonus
      );
    } else {
      scoreAccumulator.aboveTheLinePoints = getUpdatedPoints(scoreAccumulator.aboveTheLinePoints, tally.underTrickPenalty);
    }
    return scoreAccumulator;

  }, getStartScore());

  scoreAccumulator.totalPoints = getUpdatedPoints(
    scoreAccumulator.totalPoints,
    scoreAccumulator.aboveTheLinePoints,
    scoreAccumulator.gameOne,
    scoreAccumulator.gameTwo,
    scoreAccumulator.gameThree
  );

  const rubberBonus = getRubberBonus(scoreAccumulator);
  if (rubberBonus) {
    scoreAccumulator['rubberBonus'] = rubberBonus;
    scoreAccumulator.totalPoints = getUpdatedPoints(scoreAccumulator.totalPoints, rubberBonus);
  }

  return scoreAccumulator;
}

/**
* @class RubberScoreKeeper - maintains a list of point tallies for each hand played
*/
class RubberScoreKeeper {
  constructor() {
    this.tallies = [];
  }

  reset() {
    this.tallies = [];
  }

  addTallyData(tallyData) {
    this.tallies = [...this.tallies, tallyData];
  }

  getVulnerability() {
    const scoreAccumulator = getScoreAccumulator(this.tallies);
    const wins = getWins(scoreAccumulator);
    return {
      [c.NORTH_SOUTH]: wins.we > 0,
      [c.EAST_WEST]: wins.they > 0,
    };
  }

  /**
  * if a rubberBonus exists the match is complete
  */
  getRubberMatch() {
    const {rubberBonus, totalPoints} = getScoreAccumulator(this.tallies);
    if (rubberBonus) {
      return Object.assign({}, {
        winner: getRubberWinner(totalPoints)
      }, {
        [c.NORTH_SOUTH]: totalPoints.we,
        [c.EAST_WEST]: totalPoints.they,
      });
    }
    return {
      winner: null,
      [c.NORTH_SOUTH]: totalPoints.we,
      [c.EAST_WEST]: totalPoints.they
    };
  }

  getMatchScore() {
    // last one is for display
    const currentTally = getTallyScore(this.tallies[this.tallies.length -1]);
    // accumalates the tallies into the cuurent match score values
    const scoreAccumulator = getScoreAccumulator(this.tallies);

    return Object.assign({}, currentTally, scoreAccumulator);
  }
}

export default RubberScoreKeeper;
