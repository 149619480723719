import {
  TWO_NO_TRUMP,
  THREE_NO_TRUMP,
} from './types';


export const STRONG_JUMP_SHIFT_RANGES = 'bid-options/strong-jump-shift-response/ranges';

export const createRanges = () => ({
  [ TWO_NO_TRUMP ]: {
    lower: 12,
    upper: 14,
  },
  [ THREE_NO_TRUMP ]: {
    lower: 15,
    upper: 17,
  },
});
