import { DialogTitleBar } from '../components';

import React from "react";
import PropTypes from 'prop-types';

class ImportDealModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      importDealData: '',
    };

    this.onChange = this.onChange.bind(this);
    this.onImportDealClick = this.onImportDealClick.bind(this);
  }

  onImportDealClick(event) {
    // on export we remove formatting on JSON.stringify result.
    // here we added back for JSON.parse. So, both ui have the same display
    let words = this.state.importDealData.replace(/\r?\n|\r/g, '').split('  ');
    const result = words.reduce((prev, curr) => {
      if (curr === '{') {
        return prev + curr;
      }
      if (curr.indexOf(':') > -1) {
        const temp = curr.split(':');
        const temp1 = temp[1].trim();
        if (temp1 === '{') {
          return `${prev} "${temp[0]}": ${temp1}`;
        } else if (temp1 === '}') {
          return `${prev} "${temp[0].trim()}":"" }`;
        } else if (temp1.indexOf('}') > 0) {
          const temp2 = curr.split('}');
          return `${prev} "${temp[0].trim()}": "${temp2[0]}" }`;
        }
        return `${prev} "${temp[0].trim()}":"${temp1}",`;
      }
      if (curr === '}') {
        return prev.slice(0, -1)  + curr + ',';
      }
      return prev + curr;
    }, '');
    this.props.onImportDeal(result);
    this.props.onClose(event);
  }

  onChange(event) {
    this.setState({importDealData: event.target.innerHTML});
  }

  render() {
    return (
      <div id="import-deal-modal" className="main-component__container">
        <DialogTitleBar title="Import Deal" onClose={this.props.onClose}/>
        <div id="import-deal-content">
          <pre><code contentEditable="true"
            onBlur={this.onChange}
            onInput={this.onChange}
            tabIndex="0"
            spellCheck="false">
          </code></pre>
        </div>
        <div>
          <a
            id="icon-import-deal"
            onClick={this.onImportDealClick}
            className="button-primary bridge-button"
            href="#">Import<span>&rarr;</span></a>
        </div>
      </div>
    );

  }
}

ImportDealModal.propTypes = {
  onClose: PropTypes.func,
  onImportDeal: PropTypes.func,
};

export default ImportDealModal;
