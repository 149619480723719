import React from 'react';

import { BridgeBlvdTitle } from './BridgeBlvdTitle';

export const BridgeBlvdTitleWithSuits = props => (
  <div className="landing-page__title">
    <h2>
      <div className="landing-page__title-vert">
        <BridgeBlvdTitle />
      </div>
    </h2>
    <div className="landing-page__title-suits">
      <div className="landing-page__title-vert card-strain__font">
        <span className="spade">♠</span>
        <span className="heart">♥</span>
        <span className="diamond">♦</span>
        <span className="club">♣</span>
        <span className="spade">♠</span>
        <span className="heart">♥</span>
        <span className="diamond">♦</span>
        <span className="club">♣</span>
        <span className="spade">♠</span>
        <span className="heart">♥</span>
        <span className="diamond">♦</span>
        <span className="club">♣</span>
      </div>
    </div>
  </div>
);
