
const AUCTION_SHOW_AUCTION = 'AUCTION_SHOW_AUCTION';
const AUCTION_SHOW_CARDS = 'AUCTION_SHOW_CARDS';
const AUCTION_CLOSE = 'AUCTION_CLOSE';
const AUCTION_NO_OP = 'AUCTION_NO_OP';

const AuctionViewStates = Object.freeze({
  AUCTION_SHOW_AUCTION,
  AUCTION_SHOW_CARDS,
  AUCTION_CLOSE,
  AUCTION_NO_OP,
});

export { AuctionViewStates };
