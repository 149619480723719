import { useDispatch } from 'react-redux';
import { compose } from 'ramda';

import { GameStateActions } from '../../state';

import { createAuctionCellProps } from '../game-views/auction/helper'

export default function useAuctionDispatch() {
  const dispatch = useDispatch();

  const setViewModeProps = compose(
    dispatch,
    GameStateActions.setViewModeProps,
  );

  const setSelectedAuctionCell = compose(
    dispatch,
    GameStateActions.setSelectedAuctionCell,
    createAuctionCellProps,
  );

  const setCardsVisible = compose(
    dispatch,
    GameStateActions.setOrigialHandsVisible,
  );

  const setViewAuction = compose(
    dispatch,
    GameStateActions.setViewAuction,
  );

  return {
    setCardsVisible,
    setViewAuction,
    setViewModeProps,
    setSelectedAuctionCell,
  };

};
