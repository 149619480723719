import {
  SIMPLE_RAISE,
  STRONG_GAME_RAISE,
  NEW_SUIT,
  TWO_NO_TRUMP,
  WEAK_GAME_RAISE,
} from './types';

export const WEAK_TWO_RESPONSE_RANGES = 'bid-options/weak-two-response/ranges';

export const createRanges = () => ({
  [ SIMPLE_RAISE ]: {
    lower: 6,
    upper: 13,
    isDistPoints: true,
  },
  [ NEW_SUIT ]: {
    lower: 10,
  },
  [ WEAK_GAME_RAISE ]: {
    lower: 6,
    upper: 9,
    isDistPoints: true,
  },
  [ STRONG_GAME_RAISE ]: {
    lower: 16,
    isDistPoints: true,
  },
  [ TWO_NO_TRUMP ]: {
    lower: 14,
  }
});
