import * as R from 'ramda';

export const PointRange = Object.freeze({
  opener: {
    minimal: {
      lower: 12,
      upper: 15,
    },
    invitational: {
      lower: 16,
      upper: 18,
    },
    forcing: {
      lower: 19,
    },
  },
  overCaller: {
    minimal: {
      lower: 8,
      upper: 11,
    },
    invitational: {
      lower: 12,
      upper: 15,
    },
    forcing:  {
      lower: 16,
    },
  },
  responder: {
    minimal: {
      lower: 6,
      upper: 9,
    },
    invitational: {
      lower: 10,
      upper: 12,
    },
    forcing: {
      lower: 13,
    },
  },
  advancer: {
    minimal: {
      lower: 7,
      upper: 9,
    },
    invitational: {
      lower: 10,
      upper: 11,
    },
    forcing:  {
      lower: 12,
    },
  },
});

export const getInRange = function getInRange(pts, range) {
  if (pts < range.lower) {
    return false;
  }
  if (R.has('upper', range)) {
    return pts <= range.upper;
  }
  return true;
};

export const getIsMinimal = function getIsMinimal(player, points) {
  return points <= getMinimalPointRangeFor(player).upper;
};

export const getMinimalPointRangeFor = function getMinimalPointRangeFor(player) {
  if (player.getIsOpener()) {
    return PointRange.opener.minimal;
  }
  if (player.getIsOvercaller()) {
    return PointRange.overCaller.minimal;
  }
  if (player.getIsResponder()) {
    return PointRange.responder.minimal;
  }
  if (player.getIsAdvancer()) {
    return PointRange.advancer.minimal;
  }
  // default no player has opened the auction
  return PointRange.opener.minimal;
};

export const getInvitationalPointRangeFor = function getInvitationalPointRangeFor(player) {
  if (player.getIsOpener()) {
    return PointRange.opener.invitational;
  }
  if (player.getIsOvercaller()) {
    return PointRange.overCaller.invitational;
  }
  if (player.getIsResponder()) {
    return PointRange.responder.invitational;
  }
  if (player.getIsAdvancer()) {
    return PointRange.advancer.invitational;
  }
  // default no player has opened the auction
  return PointRange.opener.invitational;
};

export const getForcingPointRangeFor = function getForcingPointRangeFor(player) {
  if (player.getIsOpener()) {
    return PointRange.opener.forcing;
  }
  if (player.getIsOvercaller()) {
    return PointRange.overCaller.forcing;
  }
  if (player.getIsResponder()) {
    return PointRange.responder.forcing;
  }
  if (player.getIsAdvancer()) {
    return PointRange.advancer.forcing;
  }
  // default no player has opened the auction
  return PointRange.opener.forcing;
};
