import { constants as uic, DealOptions } from '../constants';
import { constants as c } from '../bridge';

import { BidSystemConfig } from '../bridge';

import { assoc, compose, has, isNil, mergeDeepRight } from 'ramda';

const APP_SETTINGS = 'APP_SETTINGS';

const ThemeModes = {
  DARK: 'dark',
  LIGHT: 'light',
};

const Theme = {
  darkMode: null,
};

const THEME = 'theme';
const GAME_OPTIONS = 'gameOptions';
const DISPLAY_OPTIONS = 'displayOptions';
const BID_SYSTEMS = 'bidSystems';

const DefaultSettings = Object.freeze({
  [ THEME ]: Theme,
  [ GAME_OPTIONS ]: {
    dealType: DealOptions.DEAL_RANDOM_HANDS,
    bidSystem: uic.BID_SYSTEMS.standardAmerican,
    bidType: uic.BID_OPTIONS.computerBid,
    playType: uic.PLAY_OPTIONS.bidPractice,
  },
  [ DISPLAY_OPTIONS ]: {
    [ uic.IS_DISPLAY_HCP ]: true,
    [ uic.IS_DISPLAY_HANDS ]: true,
    [ uic.IS_DISPLAY_BID_HINTS ]: true,
    [ uic.IS_DISPLAY_TEN_CARD_AS_CHAR ]: true,
    [ uic.IS_DISPLAY_HAND_IMG ]: false,
  },
  [ BID_SYSTEMS ]: {
    [ c.STANDARD_AMERICAN ]: BidSystemConfig.getDefaultSystemConfig(c.STANDARD_AMERICAN),
    [ c.BRIDGE_BLVD_BEGINNER ]: BidSystemConfig.getDefaultSystemConfig(c.BRIDGE_BLVD_BEGINNER),
  },
});

const getSettings = function getSettings() {
  const settings = localStorage.getItem(APP_SETTINGS);
  return settings
    ? JSON.parse(settings)
    : DefaultSettings;
};

const checkTheme = function checkTheme(settings) {
  if (has(THEME, settings)) {
    return settings;
  }

  return commitSettings({
    ...settings,
    [ Theme ]: Theme,
  });
};

const checkDisplayTen = function checkDisplayTen(settings) {
  if (has(uic.IS_DISPLAY_TEN_CARD_AS_CHAR, settings[ DISPLAY_OPTIONS ])) {
    return settings;
  }

  return commitSettings({
    ...settings,
    [ DISPLAY_OPTIONS ]: assoc(
      uic.IS_DISPLAY_TEN_CARD_AS_CHAR,
      DefaultSettings[ DISPLAY_OPTIONS ][ uic.IS_DISPLAY_TEN_CARD_AS_CHAR ],
      settings[ DISPLAY_OPTIONS ]
    )
  });
};

const checkDisplayHandImg = function checkDisplayHandImg(settings) {
  if (has(uic.IS_DISPLAY_HAND_IMG, settings[ DISPLAY_OPTIONS ])) {
    return settings;
  }

  return commitSettings({
    ...settings,
    [ DISPLAY_OPTIONS ]: assoc(
      uic.IS_DISPLAY_HAND_IMG,
      DefaultSettings[ DISPLAY_OPTIONS ][ uic.IS_DISPLAY_HAND_IMG ],
      settings[ DISPLAY_OPTIONS ]
    )
  });
};

const getAppSettings = function getAppSettings() {
  return compose(
    checkDisplayHandImg,
    checkDisplayTen,
    checkTheme
  )(getSettings());
};

const getGameOptionsSettings = function getGameOptionsSettings() {
  return getAppSettings()[ GAME_OPTIONS ];
};

const getDisplayOptionsSettings = function getDisplayOptionsSettings() {
  return getAppSettings()[ DISPLAY_OPTIONS ];
};

const updateAppSetting = function updateAppSetting({ groupKey, subKeys, name, value }) {
  const settings = JSON.parse(JSON.stringify(getAppSettings()));

  if (subKeys && subKeys.length > 0) {
    if (subKeys.length === 1) {
      settings[ groupKey ][ subKeys[ 0 ] ][ name ] = value;
    } else if (subKeys.length === 3) {
      settings[ groupKey ][ subKeys[ 0 ] ][ subKeys[ 1 ] ][ subKeys[ 2 ] ][ name ] = value;
    } else {
      throw new Error(`Unknown subKey count! ${subKeys}`)
    }
  } else {
    settings[ groupKey ][ name ] = value;
  }

  commitSettings(settings);
};

const commitSettings = function commitSettings(settings) {
  localStorage.setItem(APP_SETTINGS, JSON.stringify(settings));
  return settings;
};

const getThemeMode = function getThemeMode(isPrefersDark) {
  const { theme } = getAppSettings();

  if (isNil(theme.darkMode)
    && isPrefersDark
  ) {
    return ThemeModes.DARK;
  }

  if (theme.darkMode) {
    return theme.darkMode
      ? ThemeModes.DARK
      : ThemeModes.LIGHT;
  }

  return ThemeModes.LIGHT;
};

const setThemeMode = function setThemeMode(isDark) {
   updateAppSetting({
     groupKey: 'theme',
     name: 'darkMode',
     value: isDark,
   });
};

const getIsDarkMode = function getIsDarkMode(isPrefersDark) {
  return getThemeMode(isPrefersDark) === ThemeModes.DARK;
};

const getBidSystemConfig = function getBidSystemConfig(
  bidSystemName = getAppSettings().gameOptions.bidSystem
) {
  return mergeDeepRight(
    BidSystemConfig.getDefaultSystemConfig(bidSystemName),
    getAppSettings().bidSystems[ bidSystemName ]
  );
};

export {
  getIsDarkMode,
  getBidSystemConfig,
  setThemeMode,
  getThemeMode,
  getAppSettings,
  getGameOptionsSettings,
  getDisplayOptionsSettings,
  ThemeModes,
  updateAppSetting
};
