import { DATA_DEFINITION_PREFIX } from '../../constants';

export const MAJOR_SUIT_FIT = 'response-one-level-suit-open/MAJOR_SUIT_FIT';
export const MAJOR_SUIT_SIMPLE_RAISE = 'response-one-level-suit-open/MAJOR_SUIT_SIMPLE_RAISE';
export const MAJOR_SUIT_LIMIT_RAISE  = 'response-one-level-suit-open/MAJOR_SUIT_LIMIT_RAISE';
export const MAJOR_SUIT_WEAK_GAME_RAISE = 'response-one-level-suit-open/MAJOR_SUIT_WEAK_GAME_RAISE';

export const NEW_SUIT_RESPONSE = 'response-one-level-suit-open/NEW_SUIT_RESPONSE';
export const NEW_SUIT_ONE_LEVEL = 'response-one-level-suit-open/NEW_SUIT_ONE_LEVEL';
export const NEW_SUIT_TWO_LEVEL = 'response-one-level-suit-open/NEW_SUIT_TWO_LEVEL';
export const NEW_SUIT_JUMP_SHIFT = 'response-one-level-suit-open/NEW_SUIT_JUMP_SHIFT';

export const MINOR_SUIT_RAISE = 'response-one-level-suit-open/MINOR_SUIT_RAISE';
export const MINOR_SUIT_SIMPLE_RAISE = 'response-one-level-suit-open/MINOR_SUIT_SIMPLE_RAISE';
export const MINOR_SUIT_LIMIT_RAISE = 'response-one-level-suit-open/MINOR_SUIT_LIMIT_RAISE';

export const NO_TRUMP_RESPONSE = 'response-one-level-suit-open/NO_TRUMP_RESPONSE';
export const ONE_LEVEL_NO_TRUMP = 'response-one-level-suit-open/ONE_LEVEL_NO_TRUMP';
export const TWO_LEVEL_NO_TRUMP = 'response-one-level-suit-open/TWO_LEVEL_NO_TRUMP';
export const THREE_LEVEL_NO_TRUMP ='response-one-level-suit-open/THREE_LEVEL_NO_TRUMP';

export const DRURY_RESPONSE = 'response-one-level-suit-open/DRURY_RESPONSE';

export const DEF_MAJOR_SUIT_FIT = DATA_DEFINITION_PREFIX+MAJOR_SUIT_FIT;
export const DEF_MAJOR_SUIT_SIMPLE_RAISE = DATA_DEFINITION_PREFIX+MAJOR_SUIT_SIMPLE_RAISE;
export const DEF_MINOR_SUIT_SIMPLE_RAISE = DATA_DEFINITION_PREFIX+MINOR_SUIT_SIMPLE_RAISE;
export const DEF_MAJOR_SUIT_LIMIT_RAISE = DATA_DEFINITION_PREFIX+MAJOR_SUIT_LIMIT_RAISE;
export const DEF_MINOR_SUIT_LIMIT_RAISE = DATA_DEFINITION_PREFIX+MINOR_SUIT_LIMIT_RAISE;
export const DEF_MAJOR_SUIT_WEAK_GAME_RAISE = DATA_DEFINITION_PREFIX+MAJOR_SUIT_WEAK_GAME_RAISE;
export const DEF_NEW_SUIT_RESPONSE = DATA_DEFINITION_PREFIX+NEW_SUIT_RESPONSE;
export const DEF_NEW_SUIT_ONE_LEVEL = DATA_DEFINITION_PREFIX+NEW_SUIT_ONE_LEVEL;
export const DEF_NEW_SUIT_TWO_LEVEL = DATA_DEFINITION_PREFIX+NEW_SUIT_TWO_LEVEL;
export const DEF_NEW_SUIT_JUMP_SHIFT  = DATA_DEFINITION_PREFIX+NEW_SUIT_JUMP_SHIFT;
export const DEF_MINOR_SUIT_RAISE = DATA_DEFINITION_PREFIX+MINOR_SUIT_RAISE;
export const DEF_NO_TRUMP_RESPONSE = DATA_DEFINITION_PREFIX+NO_TRUMP_RESPONSE;
export const DEF_ONE_LEVEL_NO_TRUMP = DATA_DEFINITION_PREFIX+ONE_LEVEL_NO_TRUMP;
export const DEF_TWO_LEVEL_NO_TRUMP = DATA_DEFINITION_PREFIX+TWO_LEVEL_NO_TRUMP;
export const DEF_THREE_LEVEL_NO_TRUMP = DATA_DEFINITION_PREFIX+THREE_LEVEL_NO_TRUMP;
export const DEF_DRURY_RESPONSE = DATA_DEFINITION_PREFIX+DRURY_RESPONSE;
