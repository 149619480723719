import React from 'react';
import PropTypes from 'prop-types';

import { BackIcon } from './BackIcon';
import { CloseIcon } from './CharIcon';

const DialogTitleBar = ({ className, title, onBackIconClick, onClose }) => {
  const classNames = [ "dialog__title-bar", className ].filter(Boolean).join(" ").trim();
  return (
    <div className={classNames}>
      {onBackIconClick
         && <BackIcon onClick={onBackIconClick} />
      }

      <h2>{title}</h2>

      {onClose
         && <CloseIcon id="dlg-title-close" onClick={onClose} />
      }

    </div>
  );
};
DialogTitleBar.propTypes = {
  onBackIconClick: PropTypes.func,
  onClose: PropTypes.func,
  title: PropTypes.string,
  style: PropTypes.object,
};
DialogTitleBar.defaultProps = {
  className: '',
}

export { DialogTitleBar };
