import { constants as c } from '../constants';
import { Card } from '../deck';

class Suit {
  constructor(strain) {
    this.strain = strain;
    this.cards = [];
  }

  static createSuit(strain, cards) {
    const suit = new Suit(strain);
    suit.setCards(cards);
    return suit;
  }

  static getVoidSuit() {
    return new Suit(c.VOID_SUIT);
  }

  static getIsNoTrump(strain) {
    return strain === c.NO_TRUMP;
  }

  static getIsMajor(strain) {
    return strain === c.HEART || strain === c.SPADE;
  }

  static getIsMinor(strain) {
    return strain === c.CLUB || strain === c.DIAMOND;
  }

  static getName(strain) {
    return c.SUIT_NAMES[strain];
  }

  static getStrainFromName(name) {
    return c.STRAINS[name];
  }

  static getIsSuit(strain) {
    return strain && (Suit.getIsMajor(strain) || Suit.getIsMinor(strain));
  }

  add(card) {
    this.cards.push(card);
  }

  getSuitName() {
    return Suit.getName(this.strain);
  }

  getIsMajor() {
    return Suit.getIsMajor(this.strain);
  }

  getIsMinor() {
    return Suit.getIsMinor(this.strain);
  }

  getLength() {
    return this.cards.length;
  }

  getIsVoid() {
    return this.getLength() === c.VOID_SUIT_LENGTH;
  }

  getIsSingleton() {
    return this.getLength() === c.ONE_CARD_SUIT;
  }

  getIsDoubleton() {
    return this.getLength() === c.TWO_CARD_SUIT;
  }

  getHasAceIsOnlyHonor() {
    return this.getHasAce() && this.getHighCardPoints() === Card.getHcpValue(c.ACE);
  }

  getHonorCount() {
    return this.cards.reduce((cnt, card) => cnt + (card.hcpValue > 0 ? 1 : 0), 0);
  }

  getIsFirstRoundControl() {
    return this.getHasAce() || this.getIsVoid();
  }

  getIsSecondRoundControl() {
    return this.getHasKing() || this.getIsSingleton();
  }

  getTotalPoints() {
    let pts = this.getHighCardPoints();
    switch (true) {
      case this.cards.length === c.VOID_SUIT_LENGTH:
        pts += 3;
        break;
      case this.cards.length === c.ONE_CARD_SUIT:
        // do not double count points for singleton
        pts = pts > 1
          ? pts
          : 2;
        break;
      case this.cards.length === c.TWO_CARD_SUIT:
        // if suit is doubleton and has quicktricks count both otherwise just the pts
        pts = pts > 1
          ? (this.getQuickTrickCount() > 0
            ? pts + 1
            : pts)
          : 1;
        break;

      // no default
    }
    return pts;
  }

  getHasHighSpotCards() {
    const ten = this.getHasCard("10");
    const nine = this.getHasCard("9");
    const eight = this.getHasCard("8");

    return (ten && nine)
      || (ten && eight)
      || (nine && eight)
      || ten;
  }

  getBadPoints() {
    if (this.getIsSingleton()
      || this.getIsDoubleton()
    ) {
      if (this.getHasQueen() && this.getHasJack()) {
        return Card.getHcpValue(c.QUEEN) + Card.getHcpValue(c.JACK);
      }
      if (this.getHasQueen()) {
        return Card.getHcpValue(c.QUEEN)
      }
      if (this.getHasJack()) {
        return Card.getHcpValue(c.JACK);
      }
    }
    return 0;
  }

  getHighCardPoints() {
    return this.cards.reduce((pts, card) => pts + card.hcpValue, 0);
  }

  getLosingTrickCount(isTrump) {
    const ace = this.getHasAce();
    const king = this.getHasKing();
    const queen = this.getHasQueen();

    if (ace && king && queen) {
      return 0;
    }

    if (ace && king) {
      return this.cards.length >= c.THREE_CARD_SUIT ? 0.5 : 0;
    }

    if (ace && queen) {
      return 1;
    }

    if (ace) {
      return this.cards.length >= c.THREE_CARD_SUIT
        ? (isTrump ? 1 : 1.5 ) : this.cards.length >= c.TWO_CARD_SUIT
        ? 1 : 0;
    }

    if (king && queen) {
      return (isTrump ? 1 : 1.5);
    }

    if (king) {
      return this.cards.length >= 3
        ? (isTrump ? 1 : 2) : (isTrump ? 1 : 1.5 );
    }

    if (queen) {
      return this.cards.length >= 2
        ? (isTrump ? 2 : 2.5 ) : (isTrump ? 1 : 1.5 );
    }

    return this.cards.length === c.VOID_SUIT_LENGTH
      ? 0 : this.cards.length === c.ONE_CARD_SUIT
        ? (isTrump ? 1 : 1.5 ) : this.cards.length === c.TWO_CARD_SUIT
          ? (isTrump ? 2 : 2.5 ) : 3;
  }

  getPlayingTrickCount(strain) {
    const ace = this.getHasAce();
    const king = this.getHasKing();
    const queen = this.getHasQueen();
    const jack = this.getHasJack();
    const ten = this.getHasTen();

    return (() => {
      if (ace && king && queen) {
        return 3;
      }

      if ((ace && king && jack)
        || (ace && queen && jack)
      ) {
        return 2.5;
      }

      if ((ace && king)
        || (ace && queen && ten)
        || (king && queen && jack)
      ) {
        return 2;
      }

      if ((ace && queen)
        || (ace && jack && this.cards.length >= 3)
        || (king && queen && this.cards.length >= 3)
        || (king && jack && ten)
      ) {
        return 1.5;
      }

      if (ace
        || (king && queen)
        || (king && jack)
        || (king && ten && this.cards.length >= 3)
        || (queen && jack && this.cards.length >= 3)
      ) {
        return 1;
      }

      if ((king && this.cards.length >= 2)
        || (queen && this.cards.length >= 3)
        || (jack && ten && this.cards.length >= 3)
      ) {
        return 0.5;
      }

      return 0;

    })() + (
      this.strain === strain && this.cards.length > c.THREE_CARD_SUIT
        ? this.cards.length - c.THREE_CARD_SUIT
        : 0
    );
  }

  getIsExceptional() {
    const hasLengthAndHighHonors = this.getLength() >= c.SIX_CARD_SUIT
      && this.getHighCardPoints() >= 7;

    const hasLengthAndPlayingStrength = this.getLength() >= c.SIX_CARD_SUIT
      && this.getHasTouchingHonors()
      && this.getHasHighSpotCards();

    const hasExtraLength = this.getLength() >= c.SEVEN_CARD_SUIT
      && this.getHighCardPoints() >= 5
      && (this.getHasTouchingHonors()
        || this.getHasHighSpotCards());

    return hasLengthAndHighHonors || hasLengthAndPlayingStrength || hasExtraLength;
  }

  getQuickTrickCount() {
    const ace = this.getHasAce();
    const king = this.getHasKing();
    const queen = this.getHasQueen();

    if (ace && king && queen) {
      return 3;
    }
    if (ace && king) {
      return 2;
    }
    if (ace && queen) {
      return 1.5;
    }
    if (ace) {
      return 1;
    }
    if (king && queen) {
      return 1;
    }
    if (king && this.cards.length >= c.TWO_CARD_SUIT) {
      return 0.5;
    }
    return 0;
  }

  getHasAce() {
    return this.getHasCard(c.ACE);
  }

  getHasKing() {
    return this.getHasCard(c.KING);
  }

  getHasQueen() {
    return this.getHasCard(c.QUEEN);
  }

  getHasJack() {
    return this.getHasCard(c.JACK);
  }

  getHasTen() {
    return this.getHasCard(c.TEN);
  }

  getHasStoppers() {
    const ace = this.getHasAce();
    const king = this.getHasKing();
    const queen = this.getHasQueen();
    //const jack = this.getHasJack();

    return (ace)
      || (king && this.getLength() >= c.TWO_CARD_SUIT)
      || (queen && this.getLength() >= c.THREE_CARD_SUIT);
      //|| (jack && this.getLength() >= c.FOUR_CARD_SUIT);
  }

  getHasTouchingHonors() {
    const king = this.getHasKing();
    const queen = this.getHasQueen();
    const jack = this.getHasJack();
    const ten = this.getHasCard(c.TEN);

    return (king && queen)
      || (queen && jack && ten);
  }

  getIsTenace() {
    const ace = this.getHasAce();
    const king = this.getHasKing();
    const queen = this.getHasQueen();
    const jack = this.getHasJack();

    return (ace && queen && !king)
      || (king && jack && !queen);
  }

  getInstantWinnerCount() {
    const ace = this.getHasAce();
    const king = this.getHasKing();
    const queen = this.getHasQueen();
    const jack = this.getHasJack();

    if (ace && king && queen && jack) {
      return 4;
    }
    if (ace && king && queen) {
      return 3;
    }
    if (ace && king) {
      return 2;
    }
    if (ace) {
      return 1;
    }
    return 0;
  }

  setCards(cards) {
    this.cards = cards;
  }

  getHasCard(cardValue) {
    return this.cards.findIndex(card => card.value === cardValue) >= 0;
  }

  getIsGood(suitLength = c.THREE_CARD_SUIT) {
    if (this.cards.length < suitLength) {
      return false;
    }

    return (hcp => hcp >= 6
      || (hcp >= 4
        && (this.getHasTouchingHonors()
          || this.getHasHighSpotCards()))
    )(this.getHighCardPoints());

  }

  getIsStrong() {
    return this.getIsGood(c.FIVE_CARD_SUIT)
      && this.getHonorCount() >= 3
      && (this.getHasTouchingHonors()
        || this.getHasHighSpotCards());
  }

  sort() {
    this.cards.sort((a, b) => a.compare(b));
  }

  compare(that) {
    const thisHcp = this.getHighCardPoints();
    const thatHcp = that.getHighCardPoints();

    const thisLengthPts = this.getLength() <= c.THREE_CARD_SUIT
      ? 0
      : this.getLength() <= c.FIVE_CARD_SUIT
        ? 2
        : 4;

    const thatLengthPts = that.getLength() <= c.THREE_CARD_SUIT
      ? 0
      : that.getLength() <= c.FIVE_CARD_SUIT
        ? 2
        : 4;

    return (thatHcp + thatLengthPts) - (thisHcp + thisLengthPts);
  }

  getCardsAsString() {
    let result = this.cards.join();
    if (result.length > 0) {
      const re = new RegExp(this.strain, 'g');
      result = result.replace(re, '');
    }
    return result;
  }

  getExport() {
    return {
      strain: this.strain,
      name: this.getSuitName(),
      cards: this.getCardsAsString()
    };
  }

  toString() {
    return this.strain + this.getCardsAsString();
  }
}

export default Suit;
