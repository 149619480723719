
import React from "react";
import PropTypes from 'prop-types';

import { constants as c } from '../../bridge';

const Label = ({ label, className = '', style = {} }) => label
  ? <span style={style} className={className}>{label}</span>
  : null;

export const BidDisplay = function BidDisplay({
  id,
  style,
  label,
  bidValues,
}) {
  // if there is a level then there is a valid strain
  const className = `card-strain__font ${bidValues.level ? c.lowerCaseStrainName(bidValues.strain) : ''}`;

  return (
    <div key={id} style={style} className="bid-display">
      <Label style={{marginRight: '.4rem'}}label={label} />
      <Label label={bidValues.level} />
      <Label className={className} label={bidValues.strain} />
      <Label label={bidValues.isDoubled ? c.DOUBLE_STRAIN : null} />
    </div>
  );
};
BidDisplay.propTypes = {
  id: PropTypes.string,
  style: PropTypes.object,
  label: PropTypes.string,
  bidValues: PropTypes.object,
};
BidDisplay.defaultProps = {
  style: {},
}
