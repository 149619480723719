import { DATA_DEFINITION_PREFIX } from '../../constants';

export const BID_NEW_SUIT_ON_LEVEL_ONE = 'response-after-opp-suit-overcall/BID_NEW_SUIT_ON_LEVEL_ONE';
export const BID_ONE_NO_TRUMP_STOPPER_IN_ENEMY_SUIT_MINIMAL = 'response-after-opp-suit-overcall/BID_ONE_NO_TRUMP_STOPPER_IN_ENEMY_SUIT_MINIMAL';
export const DEFEND_MICHAELS_CUEBID_RESPONSE = 'response-after-opp-suit-overcall/DEFEND_MICHAELS_CUEBID_RESPONSE';

export const RAISE_PARTNERS_SUIT_TO_TWO_LEVEL_MINIMAL = 'response-after-opp-suit-overcall/RAISE_PARTNERS_SUIT_TO_TWO_LEVEL_MINIMAL';
export const NEGATIVE_DOUBLE = 'response-after-opp-suit-overcall/NEGATIVE_DOUBLE';
export const NEGATIVE_DOUBLE_THREE_LEVEL = 'response-after-opp-suit-overcall/NEGATIVE_DOUBLE_THREE_LEVEL';

export const TWO_OVER_ONE_RESPONSE = 'response-after-opp-suit-overcall/TWO_OVER_ONE_RESPONSE';
export const BID_TWO_NO_TRUMP_STOPPER_IN_ENEMY_SUIT_INVITATIONAL = 'response-after-opp-suit-overcall/BID_TWO_NO_TRUMP_STOPPER_IN_ENEMY_SUIT_INVITATIONAL';
export const JUMP_RAISE_OF_PARTNERS_SUIT = 'response-after-opp-suit-overcall/JUMP_RAISE_OF_PARTNERS_SUIT';
export const NEW_SUIT_FORCING = 'response-after-opp-suit-overcall/NEW_SUIT_FORCING';
export const BID_THREE_NO_TRUMP_STOPPER_IN_ENEMY_SUIT_FORCING = 'response-after-opp-suit-overcall/BID_THREE_NO_TRUMP_STOPPER_IN_ENEMY_SUIT_FORCING';
export const CUEBID_RESPONSE = 'response-after-opp-suit-overcall/CUEBID_RESPONSE';
export const RAISE_TO_MAJOR_GAME = 'response-after-opp-suit-overcall/RAISE_TO_MAJOR_GAME';

export const DEF_BID_NEW_SUIT_ON_LEVEL_ONE = DATA_DEFINITION_PREFIX+'response-after-opp-suit-overcall/BID_NEW_SUIT_ON_LEVEL_ONE';
export const DEF_BID_ONE_NO_TRUMP_STOPPER_IN_ENEMY_SUIT_MINIMAL = DATA_DEFINITION_PREFIX+'response-after-opp-suit-overcall/BID_ONE_NO_TRUMP_STOPPER_IN_ENEMY_SUIT_MINIMAL';
export const DEF_RAISE_PARTNERS_SUIT_TO_TWO_LEVEL_MINIMAL = DATA_DEFINITION_PREFIX+'response-after-opp-suit-overcall/RAISE_PARTNERS_SUIT_TO_TWO_LEVEL_MINIMAL';
export const DEF_NEGATIVE_DOUBLE = DATA_DEFINITION_PREFIX+'response-after-opp-suit-overcall/NEGATIVE_DOUBLE';
export const DEF_NEGATIVE_DOUBLE_THREE_LEVEL = DATA_DEFINITION_PREFIX+'response-after-opp-suit-overcall/NEGATIVE_DOUBLE_THREE_LEVEL';
export const DEF_TWO_OVER_ONE_RESPONSE = DATA_DEFINITION_PREFIX+'response-after-opp-suit-overcall/TWO_OVER_ONE_RESPONSE';
export const DEF_BID_TWO_NO_TRUMP_STOPPER_IN_ENEMY_SUIT_INVITATIONAL = DATA_DEFINITION_PREFIX+'response-after-opp-suit-overcall/BID_TWO_NO_TRUMP_STOPPER_IN_ENEMY_SUIT_INVITATIONAL';
export const DEF_JUMP_RAISE_OF_PARTNERS_SUIT = DATA_DEFINITION_PREFIX+'response-after-opp-suit-overcall/JUMP_RAISE_OF_PARTNERS_SUIT';
export const DEF_NEW_SUIT_FORCING = DATA_DEFINITION_PREFIX+'response-after-opp-suit-overcall/NEW_SUIT_FORCING';
export const DEF_BID_THREE_NO_TRUMP_STOPPER_IN_ENEMY_SUIT_FORCING = DATA_DEFINITION_PREFIX+'response-after-opp-suit-overcall/BID_THREE_NO_TRUMP_STOPPER_IN_ENEMY_SUIT_FORCING';
export const DEF_CUEBID_RESPONSE = DATA_DEFINITION_PREFIX+'response-after-opp-suit-overcall/CUEBID_RESPONSE';
