import {
  MAJOR_SUIT_SIMPLE_RAISE,
  MAJOR_SUIT_LIMIT_RAISE,
  MAJOR_SUIT_WEAK_GAME_RAISE,
  NEW_SUIT_ONE_LEVEL,
  NEW_SUIT_TWO_LEVEL,
  NEW_SUIT_JUMP_SHIFT,
  ONE_LEVEL_NO_TRUMP,
  TWO_LEVEL_NO_TRUMP,
  THREE_LEVEL_NO_TRUMP,
} from './types';

export const RESPONSE_ONE_LEVEL_SUIT_OPEN_RANGES = 'bid-options/RESPONSE_ONE_LEVEL_SUIT_OPEN_RANGES';

export const createRanges = () => ({
  [ MAJOR_SUIT_SIMPLE_RAISE ]: {
    lower: 6,
    upper: 10,
  },
  [ MAJOR_SUIT_LIMIT_RAISE ]: {
    lower: 11,
    upper: 12,
  },
  [ MAJOR_SUIT_WEAK_GAME_RAISE ]: {
    lower: 6,
    upper: 10,
  },
  [ NEW_SUIT_ONE_LEVEL ]: {
    lower: 6,
  },
  [ NEW_SUIT_TWO_LEVEL ]: {
    lower: 10,
  },
  [ NEW_SUIT_JUMP_SHIFT ]: {
    lower: 17,
  },
  [ ONE_LEVEL_NO_TRUMP ]: {
    lower: 6,
    upper: 10
  },
  [ TWO_LEVEL_NO_TRUMP ]: {
    lower: 13,
    upper: 16,
  },
  [ THREE_LEVEL_NO_TRUMP ]: {
    lower: 17,
  },
});
