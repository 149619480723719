import { useState, useEffect } from "react";
import { useSelector } from 'react-redux';

import { compose, not, isEmpty } from 'ramda';

import {
  selectIsBidHintsVisible,
  selectSelectedAuctionCellProps,
  selectHighlightPlayerPositon
} from '../../state/selectors';

import { getIsCellIdEqualsProps } from '../game-views/auction/helper'

import useAuctionDispatch from './useAuctionDispatch';

export default function useHighlightedAuctionCell(onIsBidClickChange) {
  const { setSelectedAuctionCell } = useAuctionDispatch();

  const isBidHintsVisible = useSelector(state => selectIsBidHintsVisible(state));
  const selectedAuctionCellProps = useSelector(state => selectSelectedAuctionCellProps(state));
  const highlightedPosition = useSelector(state => selectHighlightPlayerPositon(state));

  const [ isBidClicked, setIsBidClicked ] = useState(false);

  useEffect(() => {
    setIsBidClicked(highlightedPosition !== '');
  }, [ highlightedPosition ]);

  const getStartCellProps = () => selectedAuctionCellProps.defaultCell;

  const getSelectedAuctionCell = () => {
    if (isBidHintsVisible
      && !isBidClicked
      && isEmpty(selectedAuctionCellProps.selected)
    ) {
      return selectedAuctionCellProps.defaultCell;
    }
    if (isBidHintsVisible
      || isBidClicked
    ) {
      if (selectedAuctionCellProps.selected) {
        return selectedAuctionCellProps.selected;
      }
      return selectedAuctionCellProps.defaultCell;
    }
    return {};
  };

  const checkForOnIsBidClickChange = flag => {
    if (onIsBidClickChange) {
      onIsBidClickChange(flag);
    }
    return flag;
  };

  const setIsBidClickedState = cellId => compose(
    flag => {setIsBidClicked(flag); return flag },
    checkForOnIsBidClickChange,
    not,
    getIsCellIdEqualsProps
  )(cellId, getSelectedAuctionCell());

  /**
  * @function updateSelectedCellState
  * @param cellId - id string
  */
  const updateSelectedCellState = cellId => {
    // flip the clicked state
    const isBidClicked = setIsBidClickedState(cellId);
    // if new state not clicked - selected auctioncell is empty
    setSelectedAuctionCell(isBidClicked ? cellId : '');
  }

  const highlightedCell = getSelectedAuctionCell();
  const isDisplayBidHints = (isBidHintsVisible || isBidClicked)
    && !isEmpty(highlightedCell);

  return {
    highlightedCell,
    isDisplayBidHints,
    getStartCellProps,
    setHighlightedCell: updateSelectedCellState,
    setIsBidClicked,
  }


}
