import { DATA_DEFINITION_PREFIX } from '../../constants';

export const TWO_D_WAITING = 'bid-options/strong-two/TWO_D_WAITING';
export const DEF_TWO_D_WAITING = DATA_DEFINITION_PREFIX+'bid-options/strong-two/TWO_D_WAITING';

export const TWO_OF_MAJOR = 'bid-options/strong-two/TWO_OF_MAJOR';
export const DEF_TWO_OF_MAJOR = DATA_DEFINITION_PREFIX+'bid-options/strong-two/TWO_OF_MAJOR';

export const TWO_NO_TRUMP = 'bid-options/strong-two/TWO_NO_TRUMP';
export const DEF_TWO_NO_TRUMP = DATA_DEFINITION_PREFIX+'bid-options/strong-two/TWO_NO_TRUMP';

export const THREE_OF_MINOR = 'bid-options/strong-two/THREE_OF_MINOR';
export const DEF_THREE_OF_MINOR = DATA_DEFINITION_PREFIX+'bid-options/strong-two/THREE_OF_MINOR';
