import { assoc, compose, has} from 'ramda';

import { constants as c } from '../constants';

import * as BidContext from './bid-context';
import * as BidOptions from './bid-options';
import { getPromisesToAbbr } from './bid-promises';
import { createBidPromises } from './rule-promises';

const getIsStrainAppliesToRule = function getIsStrainAppliesToRule(strain, rule) {
  return (has('strain', rule) && rule.strain === strain)
    || (has('acceptsStrain', rule) && rule.acceptsStrain(strain));
};

const createBidColumnData = function createBidColumnData(strain, level, rules) {

  const checkStrain = rule => {
    if (!has('strain', rule)) {
      return assoc('strain', strain, rule);
    }
    return rule;
  };

  //:: rule -> s
  const getPromiseStrings = compose(
    getPromisesToAbbr,
    promises => {
      return promises
    },
    createBidPromises,
    checkStrain,
  );

  return (rules => rules.reduce((acc, rule, i) => (
    acc = [
      ...acc,
      i > 0 ? 'or': '',
      getPromiseStrings(rule)
    ]), [])

  )(rules.filter(rule => rule.level === level
    && getIsStrainAppliesToRule(strain, rule))).join(' ');
};

const getLevels = function getLevels(rules) {
  return rules.reduce((acc, rule) => {
    if (!acc.includes(rule.level)) {
      acc = [ ...acc, rule.level ];
    }
    return acc;
  }, []).sort();
};

const getBiddingTable = function getBiddingTable(rules) {
  return getLevels(rules).map(level => ({
      [ c.LEVEL ]: level,
      [ c.CLUB ]: createBidColumnData(c.CLUB, level, rules),
      [ c.DIAMOND ]: createBidColumnData(c.DIAMOND, level, rules),
      [ c.HEART ]: createBidColumnData(c.HEART, level, rules),
      [ c.SPADE ]: createBidColumnData(c.SPADE, level,  rules),
      [ c.NO_TRUMP ]: createBidColumnData(c.NO_TRUMP, level, rules)
    })
  );
};

const getBidRules = (bidContextType, bidSystemConfig) => {
  const lookup = {
    [ BidContext.OPEN_THE_BIDDING ]: BidOptions.getOpenBidRules,
    [ BidContext.RESPONSE_TO_NO_TRUMP_OPEN ]: BidOptions.getNoTrumpOpenResponseRules,
    [ BidContext.RESPONSE_TO_ONE_OF_A_SUIT_OPEN ]: BidOptions.getSuitOpenResponseBidRules,
    [ BidContext.OVERCALL_AND_TAKEOUT_DOUBLE ]: BidOptions.getOverCallSuitRules,
  };
  const fn = lookup[ bidContextType ];
  return  fn(bidSystemConfig);
}

const getBidContextGrid = function getBidContextGrid(
  bidSystemConfig,
  bidContextType
) {
  return compose(
    getBiddingTable,
    getBidRules
  )(bidContextType, bidSystemConfig);

};

export { getBidContextGrid };
