const FULL_SCREEN_WIDTH = 992;

class UIHelper {
  static disableLink(link) {
    link.parentElement.classList.add('disabled');
    link.setAttribute('data-href', link.href);
    link.href = '';
    link.setAttribute('aria-disabled', 'true');
  }

  static enableLink(link) {
    link.parentElement.classList.remove('disabled');
    link.href = link.getAttribute('data-href');
    link.removeAttribute('aria-disabled');
  }

  static moveIt(element, xpos, ypos) {
    const move = ("translate3d( " + xpos + "px, " + ypos + "px, 0 )");
    element.style.webkitTransform = move;
    element.style.MozTransform = move;
    element.style.msTransform = move;
    element.style.OTransform = move;
    element.style.transform = move;
  }

  static getFadeDisplaySpringProps(isVisible, speed) {
    const fadeIn = {
      from: { opacity: 0, height: 0 },
      to: { opacity: 1, height: 'auto' },
      config: speed,
    };

    const fadeOut = {
      from: { opacity: 1, height: 'auto' },
      to: { opacity: 0, height: 0 },
      config: speed,
    };

    return isVisible
      ? fadeIn
      : fadeOut;
  }

  static getScrollDisplaySpringProps(isVisible, offMargin, speed) {
    const fadeIn = {
      from: { opacity: 0, marginTop: offMargin },
      to: { opacity: 1, marginTop: 0 },
      config: speed,
    };

    const fadeOut = {
      from: { opacity: 1, marginTop: 0 },
      to: { opacity: 0, marginTop: offMargin },
      config: speed,
    };

    return isVisible
      ? fadeIn
      : fadeOut;
  }

  static getIsFullScreenLayout() {
    return document.body.clientWidth >= FULL_SCREEN_WIDTH
      && !UIHelper.getIsPortrait();
  }

  static getIsPortrait() {
    return window.innerHeight > window.innerWidth;
  }

}

export default UIHelper;
