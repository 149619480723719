import Suit from '../../suit';

import * as BidTypes from '../bid-types';

import { createBidOptions } from './create-bid-options';

import * as OpeningBidOptions from './opening-bid';
import * as OvercallAdvanceOptions from './overcall-advance-options';
import * as OvercallNoTrumpOpen from './overcall-no-trump-open-options';
import * as DirectOvercallOptions from './direct-overcall';
//import * as LandyConvention from './landy';
import * as JacobyAcceptOptions from './jacoby-accept';
import * as JacobyTransferRebidOptions from './jacoby-transfer-rebid';
import * as LandyAdvance from './landy-advance';
import * as LandyOvercallerRebidAfterTwoNoTrump from './landy-overcaller-rebid';
import * as OpenerRebidAfterNegDbl from './opener-rebid-after-neg-dbl';
import * as ResponseNoTrumpOpenOptions from './response-open-no-trump';
import * as ResponseAfterNoTrumpOvercallOptions from './response-after-no-trump-overcall';
import * as ResponseToSuitOpenOptions from './response-one-level-suit-open';
import * as ResponseAfterOppTakeDouble from './response-after-takeout-double';
import * as ResponseAfterOppSuitOvercall from './response-after-opp-suit-overcall';
import * as StrongJumpShiftResponse from './strong-jump-shift-response';
import * as StrongTwoOpenResponseOptions from './response-strong-two-open';
import * as TakeoutDoubleResponseOptions from './takeout-double-response';
import * as WeakTwoResponseOptions from './weak-two-response';
import * as PassOption from './pass-option';
import * as StrongTwoOpenerRebidPositiveSuit from './strong-two-opener-rebids';

const setSelectedOption = function setSelectedOption(bidOptions, selectedOption) {
  const isSelected = option => {
    return option === selectedOption
  };

  return {
    ...bidOptions,
    selectedOption,
    isSelected
  };
};

const getOpeningBidOptions = function getOpeningBidOptions(player) {
  return OpeningBidOptions.getOptions(player);
};

const getOpenBidRules = function getOpenBidRules(bidSystemConfig) {
  return [
    ...getOpenSuitRules({ bidSystemConfig }),
    ...getNoTrumpOpenRules({ bidSystemConfig })
  ];
};

const getNoTrumpOpenRules = ({ bidSystemConfig }) => OpeningBidOptions.getNoTrumpOpenRules({ bidSystemConfig });

const getNoTrumpOvercallRules = ({ bidSystemConfig }) => DirectOvercallOptions.getNoTrumpOvercallRules({ bidSystemConfig });

const getOpenSuitRules = ({ bidSystemConfig }) => OpeningBidOptions.getOpenSuitRules({ bidSystemConfig });

const getSuitOpenResponseBidRules = function getSuitOpenResponseBidRules(bidSystemConfig) {
};

const getStrongTwoRuleForStrain = function getStrongTwoRuleForStrain(player, strain) {
  return OpeningBidOptions.getOldStrongTwoOpenRule(player).find(rule =>
    (Suit.getIsMajor(strain) && rule.type === BidTypes.STRONG_TWO_OF_A_MAJOR)
      || (Suit.getIsMinor(strain) && rule.type === BidTypes.STRONG_TWO_OF_A_MINOR)
  );
};

const getOpenSuitRule = function getOpenSuitRule(player, strain) {
  return OpeningBidOptions.getOneLevelSuitOpenRule(player).find(rule =>
    (Suit.getIsMajor(strain) && rule.type === BidTypes.MAJOR_SUIT_OPEN)
      || (Suit.getIsMinor(strain) && rule.type === BidTypes.MINOR_SUIT_OPEN)
  );
};

const getNoTrumpOpenResponseRules = ResponseNoTrumpOpenOptions.getRules;
const getOverCallSuitRules = DirectOvercallOptions.getOverCallSuitRules;

const createBidOptionLabels = function createBidOptionLabels(bidSystemConfig) {
  return Object.assign(
    {},
    JacobyAcceptOptions.createLabels(bidSystemConfig),
    OpeningBidOptions.createLabels(bidSystemConfig),
    DirectOvercallOptions.createLabels(bidSystemConfig),
    LandyAdvance.Labels,
    OpenerRebidAfterNegDbl.createLabels(bidSystemConfig),
    OvercallAdvanceOptions.createLabels(bidSystemConfig),
    ResponseToSuitOpenOptions.createLabels(bidSystemConfig),
    ResponseNoTrumpOpenOptions.createLabels(bidSystemConfig),
    PassOption.Labels,
    ResponseAfterOppTakeDouble.Labels,
    ResponseAfterNoTrumpOvercallOptions.createLabels(),
    ResponseAfterOppSuitOvercall.createLabels(bidSystemConfig),
    StrongJumpShiftResponse.createLabels(bidSystemConfig),
    StrongTwoOpenResponseOptions.createLabels(bidSystemConfig),
    StrongTwoOpenerRebidPositiveSuit.createLabels(bidSystemConfig),
    TakeoutDoubleResponseOptions.createLabels(bidSystemConfig),
    WeakTwoResponseOptions.createLabels(bidSystemConfig),
  );
};

export {
  CHEAP,
  SIMPLE,
  JUMP,
  GAME,
} from './constants';

export {
  OpeningBidOptions,
  OvercallAdvanceOptions,
  DirectOvercallOptions,
  JacobyAcceptOptions,
  JacobyTransferRebidOptions,
  //LandyConvention,
  LandyAdvance,
  LandyOvercallerRebidAfterTwoNoTrump,
  OpenerRebidAfterNegDbl,
  ResponseNoTrumpOpenOptions,
  ResponseAfterNoTrumpOvercallOptions,
  ResponseToSuitOpenOptions,
  OvercallNoTrumpOpen,
  PassOption,
  ResponseAfterOppTakeDouble,
  ResponseAfterOppSuitOvercall,
  StrongJumpShiftResponse,
  StrongTwoOpenResponseOptions,
  StrongTwoOpenerRebidPositiveSuit,
  TakeoutDoubleResponseOptions,
  WeakTwoResponseOptions,
  createBidOptions,
  getOverCallSuitRules,
  getNoTrumpOpenRules,
  getNoTrumpOvercallRules,
  getNoTrumpOpenResponseRules,
  getSuitOpenResponseBidRules,
  getStrongTwoRuleForStrain,
  getOpenBidRules,
  getOpenSuitRule,
  getOpenSuitRules,
  getOpeningBidOptions,
  createBidOptionLabels,
  setSelectedOption,
};
