
import { createRangeLabel } from '../create-range-label';

import { DIRECT_OVERCALL_RANGES } from './ranges';

import {
  ONE_LEVEL_NO_TRUMP,
  ONE_LEVEL_FIVE_CARD_SUIT,
  TWO_LEVEL_FIVE_CARD_SUIT,
  WEAK_JUMP_OVERCALL,
  DEF_ONE_LEVEL_NO_TRUMP,
  DEF_ONE_LEVEL_FIVE_CARD_SUIT,
  DEF_TWO_LEVEL_FIVE_CARD_SUIT,
  DEF_WEAK_JUMP_OVERCALL,
  TAKE_OUT_DOUBLE,
  DEF_TAKE_OUT_DOUBLE,
  TAKE_OUT_DOUBLE_SHORT_IN_ENEMY_SUIT,
  TAKE_OUT_DOUBLE_STRONG_HAND,
  TWO_SUITED_OVERCALLS,
  DEF_TWO_SUITED_OVERCALLS,
  MICHAELS_CUEBID,
  DEF_MICHAELS_CUEBID,
  UNUSUAL_TWO_NO_TRUMP,
  DEF_UNUSUAL_TWO_NO_TRUMP,
} from './types';

export const createLabels = function createLabels(bidSystemConfig) {

  const Ranges =  bidSystemConfig[ DIRECT_OVERCALL_RANGES ];

  const ONE_LEVEL_NO_TRUMP_LABEL = 'One No Trump';
  const ONE_LEVEL_FIVE_CARD_SUIT_LABEL = 'One Level Overcall';
  const TWO_LEVEL_FIVE_CARD_SUIT_LABEL = 'Two Level Overcall';
  const WEAK_JUMP_OVERCALL_LABEL = 'Weak Jump Overcall';

  const DEF_ONE_LEVEL_NO_TRUMP_LABEL = `Balanced hand with a stopper in the enemy suit - ${createRangeLabel(Ranges[ ONE_LEVEL_NO_TRUMP ])}`;
  const DEF_ONE_LEVEL_FIVE_CARD_SUIT_LABEL = `Five card suit - ${createRangeLabel(Ranges[ ONE_LEVEL_FIVE_CARD_SUIT ])}`;
  const DEF_TWO_LEVEL_FIVE_CARD_SUIT_LABEL = `Six card suit or good Five card suit - ${createRangeLabel(Ranges[ TWO_LEVEL_FIVE_CARD_SUIT ])}`;
  const DEF_WEAK_JUMP_OVERCALL_LABEL = `Long strong suit - ${createRangeLabel(Ranges[ WEAK_JUMP_OVERCALL ])}`;

  const TAKE_OUT_DOUBLE_LABEL = 'Takeout Double';
  const DEF_TAKE_OUT_DOUBLE_LABEL = 'forcing - if opp does not bid, partner must';

  const TAKE_OUT_DOUBLE_SHORT_IN_ENEMY_SUIT_LABEL = `3+ cards in the unbid suits - ${createRangeLabel(Ranges[ TAKE_OUT_DOUBLE_SHORT_IN_ENEMY_SUIT ])}`;
  const TAKE_OUT_DOUBLE_STRONG_HAND_LABEL = `Strong one-suited hand - ${createRangeLabel(Ranges[ TAKE_OUT_DOUBLE_STRONG_HAND ])}`;

  const MICHAELS_CUEBID_LABEL = `Michael's Cue Bid`;
  const DEF_MICHAELS_CUEBID_LABEL = 'cue bid oppenents suit. Minor bid shows 5-5 majors. Major shows the other major and an unknown minor';
  const TWO_SUITED_OVERCALLS_LABEL = 'Two-Suited Hand';
  const DEF_TWO_SUITED_OVERCALLS_LABEL = 'artificial bid - shows two-suited hand - five or more cards in the implied suits';
  const UNUSUAL_TWO_NO_TRUMP_LABEL = 'Unusual Two No Trump';
  const DEF_UNUSUAL_TWO_NO_TRUMP_LABEL = `over clubs (hearts/diamonds) - over diamonds (hearts/clubs) - over major (clubs/diamonds)`;

  return {
    [ ONE_LEVEL_NO_TRUMP ]: ONE_LEVEL_NO_TRUMP_LABEL,
    [ ONE_LEVEL_FIVE_CARD_SUIT ]: ONE_LEVEL_FIVE_CARD_SUIT_LABEL,
    [ TWO_LEVEL_FIVE_CARD_SUIT ]: TWO_LEVEL_FIVE_CARD_SUIT_LABEL,
    [ WEAK_JUMP_OVERCALL ]: WEAK_JUMP_OVERCALL_LABEL,
    [ TAKE_OUT_DOUBLE ]: TAKE_OUT_DOUBLE_LABEL,
    [ DEF_TAKE_OUT_DOUBLE ]: DEF_TAKE_OUT_DOUBLE_LABEL,
    [ TAKE_OUT_DOUBLE_SHORT_IN_ENEMY_SUIT ]: TAKE_OUT_DOUBLE_SHORT_IN_ENEMY_SUIT_LABEL,
    [ TAKE_OUT_DOUBLE_STRONG_HAND ]: TAKE_OUT_DOUBLE_STRONG_HAND_LABEL,
    [ TWO_SUITED_OVERCALLS ]: TWO_SUITED_OVERCALLS_LABEL,
    [ DEF_TWO_SUITED_OVERCALLS ]: DEF_TWO_SUITED_OVERCALLS_LABEL,
    [ MICHAELS_CUEBID ]: MICHAELS_CUEBID_LABEL,
    [ DEF_MICHAELS_CUEBID ]: DEF_MICHAELS_CUEBID_LABEL,
    [ UNUSUAL_TWO_NO_TRUMP ]: UNUSUAL_TWO_NO_TRUMP_LABEL,
    [ DEF_UNUSUAL_TWO_NO_TRUMP ]: DEF_UNUSUAL_TWO_NO_TRUMP_LABEL,
    [ DEF_ONE_LEVEL_NO_TRUMP ]: DEF_ONE_LEVEL_NO_TRUMP_LABEL,
    [ DEF_ONE_LEVEL_FIVE_CARD_SUIT ]: DEF_ONE_LEVEL_FIVE_CARD_SUIT_LABEL,
    [ DEF_TWO_LEVEL_FIVE_CARD_SUIT ]: DEF_TWO_LEVEL_FIVE_CARD_SUIT_LABEL,
    [ DEF_WEAK_JUMP_OVERCALL ]: DEF_WEAK_JUMP_OVERCALL_LABEL,
  };


};
