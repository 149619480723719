import { constants as c, Coords, DistributionPointsTypes } from '../constants';
import Suit from '../suit';

import * as BidContext from './bid-context';
import * as BidTypes from './bid-types';
import { getInRange } from './point-range';
import * as prom from './bid-promises';
import { lookupIsBidTypeForcing, lookupIsBidTypeArtificial } from './bid-type-lookup';

import {
  defaultTo,
  isEmpty,
  has,
} from 'ramda';

/**
 * @Class Bid
 * -Models a bridge bid
 */
class Bid {

  /**
  * create a bid
  * @param {Player} player - player that made this bid
  * @param {number} level - level of the bid
  * @param {string} strain - suit or NT
  * @param {Array} promises - any given bid may promise multiple promises that
  *                           describe a players hand (pts, suit length...)
  */
  constructor(player, level, strain, ...promises) {
    this.player = player;
    this.level = level;
    this.strain = strain;
    this.promises = promises;
    this.isDoubled = false;
    this.index = 0;
  }

  static createBid(player, level, strain, ...promises) {
    const bid = new Bid(player, level, strain, ...promises);
    return bid;
  }

  static createPassBid(player, ...promises) {
    return new Bid(player, null, null, ...promises);
  }

  static createPassBidType(player, type) {
    return Bid.createPassBid(player, prom.createPromise({ type }));
  }

  static createPassWithMinimalHand(player, rangeProps) {
    return Bid.createPassBid(player,
      prom.createPromise({
        type: BidTypes.MINIMAL_HAND,
      }), ...(rangeProps
       ? [prom.createRangePromise(rangeProps), ...(rangeProps.promise
           ? [rangeProps.promise]
           : [])
         ]
       : [])
    );
  }

  static createCannotRaisePassBid(player) {
    return Bid.createPassBidType(player, BidTypes.PASS_CANNOT_RAISE_HIGHER);
  }

  static createPenaltyDouble(player, ...promises) {
    return new Bid(
      player,
      null,
      c.DOUBLE_STRAIN,
      ...promises,
      prom.createPromise({
        type: BidTypes.PENALTY_DOUBLE,
      })
    );
  }

  static createPenaltyDoubleType(player, type) {
    return Bid.createPenaltyDouble(player, prom.createPromise({ type }));
  }

  static createOptionalDouble(player, points) {
    return new Bid(player,
      null,
      c.DOUBLE_STRAIN,
      prom.createPromise({
        type: BidTypes.OVERCALL_BID,
      }),
      prom.createPromise({
        type: BidTypes.OPTIONAL_DOUBLE,
      }),
      prom.createRangePromise({
        lower: points,
    }));
  }

  static createNegativeDoubleBid(player, ...promises) {
    return Bid.createBid(
      player,
      null,
      c.DOUBLE_STRAIN,
      prom.createPromise({
        type: BidTypes.NEGATIVE_DOUBLE,
      }),
      ...promises
    );
  }

  static createTakeOutDouble(player, ...promises) {
    return new Bid(player,
      null,
      c.DOUBLE_STRAIN,
      prom.createPromise({
        type: BidTypes.OVERCALL_BID,
      }),
      prom.createPromise({
        type: BidTypes.TAKE_OUT_DOUBLE,
      }),
      // FIXME: needs own config value not a constant!
      prom.createRangePromise({
        lower: c.MIN_POINTS_TO_OPEN,
      }), ...promises);
  }

  static updatePromisePoints(promises, range) {
    let updated = false;
    promises.forEach((promise) => {
      if (has('lower', promise)) {
        promise.setLower(range.lower);
        updated = true;
      }
      if (has('upper', promises)) {
        promise.setUpper(range.upper);
        updated = true;
      }
    });

    if (!updated) {
      promises.push(prom.createRangePromise({
        lower: range.lower,
        upper: range.upper,
      }));
    }
  }

  static getPromisesHasType(promises, type) {
    return promises.some(promise =>
      has('type', promise)
      && (promise.type === type)
    );
  }

  static getIsPromisedSignOff(promises) {
    return promises.some(promise => promise.type && promise.type.signOff);
  }

  static getPromisesSlamTry(promises) {
    return (promises.length > 0)
      && (Bid.getPromisesHasType(promises, BidTypes.BLACKWOOD_FOR_ACES)
        || Bid.getPromisesHasType(promises, BidTypes.GERBER_FOR_ACES)
        || Bid.getPromisesHasType(promises, BidTypes.SLAM_TRY_SHOWS_CONTROL_IN_STRAIN));
  }

  /**
  * @return {boolean}
  */
  equals(bid) {
    return this.player === bid.player
      && this.equalsStrainLevel(bid);
  }

  equalsStrainLevel({ strain, level }) {
    return this.strain === strain
    && this.level === level
  }

  addAuctionContext(contextType, bidOptions) {
    this.context = {
      type: contextType,
      bidOptions,
    };
    return this;
  }

  update(strain, level) {
    if (this.strain !== strain
      || this.level !== level) {

      this.originalValue = {
        strain: this.strain,
        level: this.level,
      };
      this.strain = strain;
      this.level = level;
    }
  }

  getAuctionRound() {
    return Math.ceil((this.index + 1) / 4);
  }

  getIsFirstRound() {
    return this.getAuctionRound() === 1;
  }

  // prepend
  addPromise(bidPromise) {
    this.promises = [bidPromise, ...this.promises];
    return this;
  }

  appendPromise(...bidPromises) {
    this.promises = [...this.promises, ...bidPromises];
    return this;
  }

  setEvalOptions(options) {
    this.options = options;
  }

  getIsOneLevel() {
    return this.level === c.ONE_LEVEL;
  }

  getIsTwoLevel() {
    return this.level === c.TWO_LEVEL;
  }

  getIsThreeLevel() {
    return this.level === c.THREE_LEVEL;
  }

  getIsFourLevel() {
    return this.level === c.FOUR_LEVEL;
  }

  /**
  * this bid doubles a prev bid of opponents
  */
  getIsDouble() {
    return this.strain
      && this.strain === c.DOUBLE_STRAIN;
  }

  getIsWeakJumpOvercall() {
    return this.getHasPromiseType(BidTypes.WEAK_JUMP_OVERCALL);
  }

  getIsNegativeDouble() {
    return this.getHasPromiseType(BidTypes.NEGATIVE_DOUBLE);
  }

  getIsNegativeDoubleMajor() {
    return this.getHasPromiseType(BidTypes.NEGATIVE_DOUBLE_BOTH_MAJORS)
      || this.getHasPromiseType(BidTypes.NEGATIVE_DOUBLE_ONE_MAJOR);
  }

  getIsNegativeDoubleMinor() {
    return this.getHasPromiseType(BidTypes.NEGATIVE_DOUBLE_BOTH_MINORS);
  }

  getIsSuit() {
    return !this.getIsPassed()
      && !this.getIsNoTrump()
      && !this.getIsDouble();
  }

  getIsPreempt() {
    return this.getHasPromiseType(BidTypes.PREEMPT_WEAK_TWO)
      || this.getHasPromiseType(BidTypes.PREEMPT_THREE_LEVEL)
      || this.getHasPromiseType(BidTypes.PREEMPT_FOUR_LEVEL)
      || this.getHasPromiseType(BidTypes.WEAK_JUMP_OVERCALL);
  }

  getIsStrongTwoClubs() {
    return this.getHasPromiseType(BidTypes.STRONG_TWO_CLUB);
  }

  getIsForcing() {
    const isForcing = !this.getIsGameLevel()
      && this.promises.some(promise => lookupIsBidTypeForcing(promise.type));

    return isForcing || this.getIsArtificial();
  }

  getIsInvitational() {
    const range = this.getPointRange();
    if (!isEmpty(range)) {
      return getInRange(this.player.hand.getInvitiationalPointRange(), range.lower);
    }
    return false;
  }

  getIsGameLevel() {
    return this.getIsArtificial()
      ? false
      : Bid.getIsStrainLevelGame(this.strain, this.level);
  }

  static getIsStrainLevelGame(strain, level) {
    return (Suit.getIsMajor(strain) && level === c.FOUR_LEVEL)
      || (strain === c.NO_TRUMP && level === c.THREE_LEVEL)
      || (Suit.getIsMinor(strain) && level === c.FIVE_LEVEL);
  }

  getIsNoTrump() {
    return this.strain === c.NO_TRUMP;
  }

  getIsOneNoTrump() {
    return this.strain === c.NO_TRUMP && this.getIsOneLevel();
  }

  getIsJacobyTransfer() {
    return this.getHasPromiseType(BidTypes.JACOBY_TRANSFER);
  }

  getIsJumpShift() {
    return this.getHasPromiseType(BidTypes.STRONG_JUMP_SHIFT);
  }

  getIsCueBidShowingGameForcingHand() {
    return this.getHasPromiseType(BidTypes.RESPOND_WITH_CUEBID_SHOWING_GAME_FORCING_HAND);
  }

  getIsDefendMichaelsWithCueBidFit() {
    return this.getHasPromiseType(BidTypes.RESPOND_MICHAELS_CUEBID_OVERCALL_WITH_FIT);
  }

  getIsSixCardSuitRebid() {
    return this.getHasPromiseType(BidTypes.REBID_SIX_CARD_SUIT);
  }

  getIsMichaelsOrUnusualNoTrump() {
    return this.getHasPromiseType(BidTypes.MICHAELS_CUEBID)
      || this.getHasPromiseType(BidTypes.MICHAELS_CUEBID_UNSPECIFIED_FIVE_CARD_MINOR)
      || this.getHasPromiseType(BidTypes.UNUSUAL_TWO_NO_TRUMP);
  }

  getIsDruryConvention() {
    return this.getHasPromiseType(BidTypes.DRURY_RESPONSE);
  }

  getIsOvercallAdvanceCueBid() {
    return this.getHasPromiseType(BidTypes.OVERCALL_ADVANCE_CUE_BID);
  }

  getIsStayman() {
    return this.getHasPromiseType(BidTypes.STAYMAN_BID);
  }

  getIsMajor() {
    return this.strain === c.HEART || this.strain === c.SPADE;
  }

  getIsMinor() {
    return this.strain === c.CLUB || this.strain === c.DIAMOND;
  }

  getIsPassed() {
    return !(this.level || this.strain);
  }

  getIsPenaltyDouble() {
    return this.getHasPromiseType(BidTypes.PENALTY_DOUBLE);
  }

  getIsCueBid() {
    return this.getIsArtificial();
  }

  getIsTwoNoTrump() {
    return this.strain === c.NO_TRUMP && this.getIsTwoLevel();
  }

  getIsThreeNoTrump() {
    return this.strain === c.NO_TRUMP && this.getIsThreeLevel();
  }

  getPromisedSuitLength() {
    const suitPromise = this.getSuitLengthPromise();
    return suitPromise
      ? suitPromise.suitLength
      : 0;
  }

  getPointRangePromise() {
    return this.promises.find(promise => has('getRange', promise));
  }

  getPointRange() {
    return defaultTo({}, this.getPointRangePromise());
  }

  getSuitLengthPromise() {
    return this.promises.find(promise => has('suitLength', promise));
  }

  getSuitLengthPromises() {
    return this.promises.reduce((promises, promise) => {
      if (has('suitLength', promise)) {
        return [...promises, promise];
      }
      return promises;
    }, []);
  }

  getPromisedAceCnt() {
    const promise = this.promises.find(promise =>
       has('type', promise)
       && promise.type === BidTypes.CARD_COUNT
    );
    if (promise && promise.cardValue === c.ACE) {
      return promise.cardCount;
    }
    return 0;
  }

  getPromisedPoints() {
    return this.promises.reduce((pts, promise) => {
      const temp = has('lower', promise)
        ? Number(promise.lower)
        : 0;

      if (temp > pts) {
        pts = temp;
      }
      return pts;
    }, 0);
  }

  getPromiseTags() {
    return this.promises.reduce((acc, promise) => has('tag', promise.type)
      ? [...acc, promise.type.tag]
      : acc, []);
  }

  getHasPromiseType(type) {
    return Bid.getPromisesHasType(this.promises, type);
  }

  getIsArtificial() {
    return this.promises.some(promise => lookupIsBidTypeArtificial(promise.type));
  }

  getPromisedSuitProps() {
    return this.getSuitLengthPromises().map(promise => ({
      strain: promise.strain,
      suitLength: promise.suitLength,
      lessThan: promise.lessThan
    }));
  }

  /**
  * @return {Set} contain the strains promised by the bid.
  */
  getPromisedStrains() {
    const strains = new Set();
    this.promises.forEach(promise => {
      if (has('strain', promise)) {
        strains.add(promise.strain);
      }
    });
    return strains;
  }

  /**
  * @param {number} index
  * @return {Promise} the promise at the index
  */
  getPromise(index) {
    return this.promises[index];
  }

  getPromisedType(bidType) {
    return this.promises.find(promise => promise === bidType);
  }

  static getBidPromisesTypeStrain(promises, bidType, strain) {
    return promises.some(promise =>
      (has('strain', promise)
        && promise.strain === strain)
      && (has('type', promise)
        && promise.type === bidType));
  }

  static getBidPromisesTrumpFit(strain, promises) {
    return Bid.getBidPromisesTypeStrain(promises, BidTypes.TRUMP_FIT, strain)
      || Bid.getPromisesHasType(promises, BidTypes.TRUMP_FIT)
      || Bid.getPromisesHasType(promises, BidTypes.MINIMAL_HAND_SUIT_PREFERNCE);
  }

  getIsTrumpFit() {
    return this.getIsPromisedTrumpFit(this.strain);
  }

  getIsPromisedTrumpFit(strain) {
    return Bid.getBidPromisesTrumpFit(strain, this.promises);
  }

  getIsPromisedStopperInStrain(strain) {
    return Bid.getBidPromisesTypeStrain(this.promises, BidTypes.STOPPER_IN_STRAIN, strain);
  }

  getIsTakeOutDouble() {
    return Bid.getPromisesHasType(this.promises, BidTypes.TAKE_OUT_DOUBLE);
  }

  getPromisesExport() {
    return prom.getPromisesToString(this.promises);
  }

  setOverrideBid(overrideBid) {
    this.overrideBid = overrideBid;
  }

  /**
  * set true to determine that this is the bid that was doubled.
  */
  setIsDoubled(flag) {
    this.isDoubled = flag;
  }

  setPlayer(player) {
    this.player = player;
  }

  getShortString() {
    const position = Coords.getCoordInitial(this.player.position);
    const value = this.getIsPassed()
      ? 'P' : (this.getIsDouble()
        ? 'X' : this.level + this.strain
      );
    return `${position}-${value}`;
  }

  getDistributionPointsType() {
    if (this.getHasPromiseType(BidTypes.ADD_DISTRIBUTION_POINTS_FOR_TRUMP_FIT)) {
      return DistributionPointsTypes.SHORT_POINTS;
    }
    if (this.context && this.context.type === BidContext.OPEN_THE_BIDDING) {
      return DistributionPointsTypes.LONG_POINTS;
    }
  }

  getExport(isInteractive = false) {
    const bidValue = this.toString();

    return {
      auctionContext: this.context,
      bidValue,
      display: isInteractive
        ? c.QUESTION_MARK_LABEL
        : bidValue,
      index: this.index,
      isMajor: this.getIsMajor(),
      distributionPointsType: this.getDistributionPointsType(),
      level: this.level,
      position: this.player.position,
      promises: this.getPromisesExport(),
      strain: this.strain,
      tags: this.getPromiseTags(),
      overrideBid: this.overrideBid?.getExport(),
    };
  }

  toJSON() {
    return JSON.stringify({
      position: this.player.position,
      desc: this.toString(),
    });
  }

  toString() {
    return this.getIsPassed() ? c.PASS : (this.getIsDouble() ? this.strain : this.level + this.strain);
  }
}

export default Bid;
