import React from "react";
import PropTypes from 'prop-types';

import { constants as c } from '../../bridge';

import { BidDisplay } from '../components';
import { WrappedStrains } from '../components/WrappedStrains';

const BidStrainHeaders = function BidStrainHeaders() {
  return Object.values(c.STRAINS).map((strain, i) => (
    <th key={i} scope="col">
      <BidDisplay
        id={strain}
        bidValues={{
          level: ' ',
          strain,
        }}
      />
    </th>
  ));
};

const BidLevelRowData = function BidLevelRowData({ rowData }) {
  return Object.values(c.STRAINS).map((strain, i) => (
      <td key={i}>
        <WrappedStrains text={rowData[ strain ]} />
      </td>
  ));
};

const BidLevelRows = function BidLevelRows({ bidDataRows }) {
  return bidDataRows.map((rowData, i) => {
    return(
      <tr key={i}>
       <th scope="col">{rowData[ c.LEVEL ]}</th>
        <BidLevelRowData rowData={rowData} />
      </tr>
    );
  });
};
BidLevelRows.propTypes = {
   bidDataRows: PropTypes.array,
};

const BidChart = function BidChart({ caption, bidDataRows })  {
  return (
    <table ariaLabel={caption} className="bid-table" tabIndex="0">
      <thead>
        <tr>
          <th style={{width: '2rem'}} scope="col"></th>
          <BidStrainHeaders />
        </tr>
      </thead>
      <tbody>
        <BidLevelRows bidDataRows={ bidDataRows }/>
      </tbody>
    </table>
  );
};
BidChart.propTypes = {
  caption: PropTypes.string,
  bidDataRows: PropTypes.array,
  isDisplayCaption: PropTypes.bool,
};

export { BidChart };
