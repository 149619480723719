import { PASS_OPTION } from '../pass-option';
import { createBidOptions } from '../create-bid-options';

import {
  MINIMAL_FORCE_BID,
  FREE_BID,
  INVITATIONAL_STRENGTH,
  MINIMAL_NO_TRUMP,
  INVITATIONAL_NO_TRUMP,
  GAME_FORCE_CUEBID,
  GAME_FORCE_SUIT,
  GAME_FORCE_NO_TRUMP,
} from './types';

import {
  TAKE_OUT_DOUBLE_RESPONSE_RANGES,
  FREE_BID_RANGE,
  SUIT_RESPONSE_RANGE,
  NO_TRUMP_RESPONSE_RANGE,
  createRanges,
} from './ranges';

import { createLabels } from './labels';

const getOptions = function getOptions() {
  return createBidOptions([
    MINIMAL_FORCE_BID,
    FREE_BID,
    INVITATIONAL_STRENGTH,
    MINIMAL_NO_TRUMP,
    INVITATIONAL_NO_TRUMP,
    GAME_FORCE_CUEBID,
    GAME_FORCE_SUIT,
    GAME_FORCE_NO_TRUMP,
    PASS_OPTION,
  ]);
};

export {
  MINIMAL_FORCE_BID,
  FREE_BID,
  INVITATIONAL_STRENGTH,
  MINIMAL_NO_TRUMP,
  INVITATIONAL_NO_TRUMP,
  GAME_FORCE_CUEBID,
  GAME_FORCE_SUIT,
  GAME_FORCE_NO_TRUMP,
  createLabels,
  TAKE_OUT_DOUBLE_RESPONSE_RANGES,
  FREE_BID_RANGE,
  SUIT_RESPONSE_RANGE,
  NO_TRUMP_RESPONSE_RANGE,
  createRanges,
  getOptions,
};
