import React from 'react';
import PropTypes from 'prop-types';

class Pagination extends React.Component {
  constructor(props) {
      super(props);
      this.state = {
        pager: {}
      };
    }

  componentDidMount() {
    if (this.props.items && this.props.items.length) {
      this.setPage(this.props.initialPage);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.items !== prevProps.items) {
      this.setPage(this.props.initialPage);
    }
  }

  setPage(page) {
    const items = this.props.items;
    let pager = this.state.pager;
    if (page < 1 || page > pager.totalPages) {
        return;
    }
    pager = this.getPager(items.length, page, this.props.pageSize);
    const pageOfItems = items.slice(pager.startIndex, pager.endIndex + 1);
    this.setState(
      { pager: pager
    });
    this.props.onChangePage(pageOfItems);
  }

  getPager(totalItems, currentPage, pageSize) {
    currentPage = currentPage || 1;
    pageSize = pageSize || 15;

    const totalPages = Math.ceil(totalItems / pageSize);
    let startPage;
    let endPage;
    if (totalPages <= 10) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (currentPage <= 6) {
        startPage = 1;
        endPage = 10;
      } else if (currentPage + 4 >= totalPages) {
        startPage = totalPages - 9;
        endPage = totalPages;
      } else {
        startPage = currentPage - 5;
        endPage = currentPage + 4;
      }
    }

    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    const pages = [...Array((endPage + 1) - startPage).keys()].map(i => startPage + i);

    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages
    };
  }

  getPagingIcons() {
    return ;
  }

  render() {
    const  pager = this.state.pager;
    const isDisplayButtons = pager.pages && pager.pages.length > 0;
    const displayInline = {display: 'inline'};
    const disablePrev = pager.currentPage === 1;
    const disableNext = pager.currentPage === pager.totalPages;
    return (
      <div id="page-nav__container">
      { isDisplayButtons &&
        <ul>
          <li style={displayInline} className={disablePrev ? 'disabled' : ''}>
            <button tabIndex={disablePrev ? '-1' : 0} onClick={() => this.setPage(pager.currentPage - 1)}>Prev</button>
          </li>
          <li style={displayInline} className={disableNext ? 'disabled' : ''}>
            <button tabIndex={disableNext ? '-1' : 0} onClick={() => this.setPage(pager.currentPage + 1)}>Next</button>
          </li>
        </ul>
      }
      </div>
    );
  }
}
Pagination.propTypes =  {
  items: PropTypes.array.isRequired,
  onChangePage:PropTypes.func.isRequired,
  initialPage: PropTypes.number,
  pageSize: PropTypes.number,
};

Pagination.defaultProps = {
  initialPage: 1,
  pageSize: 15,
};

export default Pagination;
