import { useRef, useEffect } from 'react';

export default function useFocus(isFocus) {
  const inputRef = useRef();

  useEffect(() => {
    if (isFocus) {
      inputRef.current.focus();
    }
  }, [ isFocus ]);

  return inputRef;
};
