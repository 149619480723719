import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';

import { useKeyPress } from '../hooks';

/**
* @function TabButton
*/
const TabButton = ({ id, isOpen, onClick, label }) => {
  const classList = `tab-button${isOpen ? ' active': ''}`;
  const handleClick = !isOpen
    ? onClick
    : null;

  return (
    <button
      id={id}
      role="tab"
      aria-selected={isOpen}
      className={classList}
      tabIndex={isOpen ? '0': '-1'}
      onClick={handleClick}
     >
      {label}
    </button>
  );
};
TabButton.propTypes = {
  id: PropTypes.string,
  isOpen: PropTypes.bool,
  onClick: PropTypes.func,
  label: PropTypes.string,
};

/**
* @function Tabs
*/
const Tabs = function Tabs({
  tablistAriaLabel,
  tabNames,
  tabContainerId,
  onTabOpen,
  getIsTabOpen
}) {

  const refs = tabNames.reduce((acc, tab) => {
    acc[ tab.id ] = React.createRef();
    return acc;
  }, {});

  const getOpenTabId = () => tabNames.find(tab => getIsTabOpen(tab))?.id;
  const getFocusTabIndex = () => tabNames.findIndex(tab => tab.id === focusTabId);

  const [ focusTabId, setFocusTabId ]  = useState(getOpenTabId());

  const handleTabOpen = e => {
    e.preventDefault();
    onTabOpen(e.currentTarget.id);
  };

  const tabNav = dir => {
    // if in tabs list arrow keys nav tabs
    if (refs[ getOpenTabId() ]?.current.parentNode === document.activeElement.parentNode.parentNode) {
      const index = ((check, max) => check > max
        ? 0
        : check < 0
          ? max
          : check
      )(getFocusTabIndex() + dir, tabNames.length -1);

      refs[ tabNames[ index ].id ].current.firstChild.focus();
      setFocusTabId(tabNames[ index ].id);
    }
  };

  useKeyPress('ArrowUp', tabNav, [-1]);
  useKeyPress('ArrowLeft', tabNav, [-1]);

  useKeyPress('ArrowDown', tabNav, [1]);
  useKeyPress('ArrowRight', tabNav, [1]);

  useEffect(() => {
    focusTabId && refs[ focusTabId ].current.scrollIntoView(true);
  }, []);

  return (
    <ul id={tabContainerId} role="tablist" aria-label={tablistAriaLabel} tabIndex="0">
      {tabNames.map(tab =>
        <li
          key={tab.id}
          ref={refs[ tab.id ]}
          style={{listStyleType: 'none', marginBottom: 0}}
        >
          <TabButton
            isOpen={getIsTabOpen(tab)}
            id={tab.id}
            onClick={handleTabOpen}
            label={tab.label}
          />
        </li>
      )}
    </ul>
  );
};

const TabsSection = function TabsSection({ id, isShowTabs, children, ...restProps }) {
  return (
    <section id={id}>
      {isShowTabs
        && <Tabs {...restProps} />
       }
      {children}
    </section>
  );
}
TabsSection.propTypes = {
  // a opened tabs children
  children: PropTypes.node,
  // open? == active
  getIsTabOpen: PropTypes.func,
  // component id
  id: PropTypes.string,
  // show the tabs or just the children
  isShowTabs: PropTypes.bool,
  // handle tab clicked
  onTabOpen: PropTypes.func,
  // container id
  tabContainerId: PropTypes.string,
  tablistAriaLabel: PropTypes.string,
  // the tabs
  tabNames: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })).isRequired,
}
TabsSection.defaultProps = {
  isShowTabs: true,
};

export { TabButton, Tabs, TabsSection };
