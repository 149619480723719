export const CONTROL_BID_TO_ESTABLISH_SLAM = 'controlBidToEstablishSlam';
export const CUE_BID = 'cueBid';
export const DRURY = 'drury';
export const FOURTH_SUIT_FORCING = 'fourthSuitForcing';
export const FOURTH_SUIT_FORCING_INVITE = 'fourthSuitForcingInvite';
export const JACOBY_TRANSFER = 'jacobyTransfer';
export const JUMP_SHIFT_FLOOR = 'jumpShiftFloor';
export const LIMIT_RAISE_WITH_THREE_CARD_FIT = 'limitRaiseWithOnlyThreeCardFit';
export const MICHAELS_CUE_BID = 'michaelsCueBid';
export const NEGATIVE_DOUBLE = 'negativeDouble';
export const NO_TRUMP_OVERCALL_DEFENCE_TWO_FIVE_CARD_SUITS = 'noTrumpOvercallDefenceTwoFiveCardSuits';
export const OPEN_LIGHT_QUICK_TRICK_COUNT = 'openLightQuickTrickCount';
export const OPEN_POINTS_MINIMUM = 'openPointsMinimum';
export const OPEN_PREEMPT_MINIMUM_POINTS = 'openPreemptMiniumPoints';
export const OPEN_RULE_OF_TWENTY = 'openRuleOfTwenty';
export const OPEN_THIRD_SEAT_LIGHT_GOOD_SUIT = 'openThirdSeatLightGoodSuit';
export const OVERCALL_MINIMUM_POINTS = 'overcallMiniumPoints';
export const OVERCALL_NO_TRUMP_OPEN_CONVENTION = 'overcallNoTrumpOpenConvention';
export const OVERCALL_NO_TRUMP_OPEN_ASTRO = 'overcallNoTrumpOpenAstro';
export const OVERCALL_NO_TRUMP_OPEN_CAPPELLETTI = 'overcallNoTrumpOpenCappelletti';
export const OVERCALL_NO_TRUMP_OPEN_LANDY = 'overcallNoTrumpOpenLandy';
export const TWO_LEVEL_GOOD_SUIT_OVERCALL_MIMIMUM = 'twoLevelGoodSuitOvercallMin';
export const TAKE_OUT_DOUBLE_POINT_RANGE = 'takeoutDoublePointRange';
export const RESPOND_POINTS_MINIMUM = 'respondPointsMin';
export const WEAK_TWOS = 'weakTwos';
