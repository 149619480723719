export const ADD_DISTRIBUTION_POINTS_FOR_TRUMP_FIT = 'ADD_DISTRIBUTION_POINTS_FOR_TRUMP_FIT';
export const ASK_FOR_ACES = 'ASK_FOR_ACES';
export const ASKED_FOR_ACES = 'ASKED_FOR_ACES';
export const ASK_FOR_KINGS = 'ASK_FOR_KINGS';
export const BLACKWOOD_ASKED_FOR_KINGS = 'BLACKWOOD_ASKED_FOR_KINGS';
export const BLACKWOOD_ASKED_FOR_ACES = 'BLACKWOOD_ASKED_FOR_ACES';
export const GERBER_ASKED_FOR_KINGS = 'GERBER_ASKED_FOR_KINGS';
export const GERBER_ASKED_FOR_ACES = 'GERBER_ASKED_FOR_ACES';
export const ASTRO_TWO_CLUBS = 'ASTRO_TWO_CLUBS';
export const ASTRO_TWO_DIAMONDS = 'ASTRO_TWO_DIAMONDS';
export const ASTRO_FORCING_RESPONSE = 'ASTRO_FORCING_RESPONSE';
export const ASTRO_REBID = 'ASTRO_REBID';
export const ASTRO_RESPONSE_PREFERS_OTHER_SUIT = 'ASTRO_RESPONSE_PREFERS_OTHER_SUIT';
export const ASTRO_RESPONSE = 'ASTRO_RESPONSE';
export const ASTRO_RESPONSE_SIX_SPADES = 'ASTRO_RESPONSE_SIX_SPADES';
export const BALANCED_HAND = 'BALANCED_HAND';
export const BLACKWOOD_FOR_ACES = 'BLACKWOOD_FOR_ACES';
export const CARD_COUNT = 'CARD_COUNT';
export const CHOOSE_ONE_OF_PARTNERS_SUITS = 'CHOOSE_ONE_OF_PARTNERS_SUITS';
export const COMPETITIVE_RESPONSE_TWO_FIVE_CARD_SUITS_WITH_PLAYING_STRENGTH = 'COMPETITIVE_RESPONSE_TWO_FIVE_CARD_SUITS_WITH_PLAYING_STRENGTH';
export const CUEBID_AFTER_NT_OPEN_OVERCALLED = 'CUEBID_AFTER_NT_OPEN_OVERCALLED';
export const DEDUCT_HIGH_CARD_POINT = 'DEDUCT_HIGH_CARD_POINT';
export const DENY_FOUR_CARD_MAJOR = `DENY_FOUR_CARD_MAJOR`;
export const DOUBLE = 'DOUBLE';
export const DRURY_RESPONSE = 'DRURY_RESPONSE';
export const DRURY_OTHER_MAJOR = 'DRURY_OTHER_MAJOR';
export const DRURY_MINIMAL_OPENER = 'DRURY_MINIMAL_OPENER';
export const DRURY_SUBMINIMAL_OPENER = 'DRURY_SUBMINIMAL_OPENER';
export const DRURY_RESPONSE_FROM_OPENER = 'DRURY_RESPONSE_FROM_OPENER';
export const FOURTH_SUIT_FORCING = 'FOURTH_SUIT_FORCING';
export const FOURTH_SUIT_FORCING_RESPONSE = 'FOURTH_SUIT_FORCING_RESPONSE';
export const GAME_FORCING_HAND = 'GAME_FORCING_HAND';
export const GERBER_FOR_ACES = 'GERBER_FOR_ACES';
export const GRAND_SLAM = 'GRAND_SLAM';
export const INVITE_THREE_NO_TRUMP_GAME = 'INVITE_THREE_NO_TRUMP_GAME';
export const INVITE_THREE_NO_TRUMP_WITH_SIX_CARD_MINOR = 'INVITE_THREE_NO_TRUMP_WITH_SIX_CARD_MINOR';
export const INVITE_MAJOR_GAME = 'INVITE_MAJOR_GAME';
export const INVITE_MINOR_GAME = 'INVITE_MINOR_GAME';
export const JACOBY_TRANSFER = 'JACOBY_TRANSFER';
export const JACOBY_TRANSFER_RESPONSE = 'JACOBY_TRANSFER_RESPONSE';
export const JACOBY_TRANSFER_SUPER_ACCEPTANCE_RESPONSE = 'JACOBY_TRANSFER_SUPER_ACCEPTANCE_RESPONSE';
export const JUMP_RAISE_OF_PARTNERS_SUIT = 'JUMP_RAISE_OF_PARTNERS_SUIT';
export const LANDY = 'LANDY';
export const LANDY_ADVANCE = 'LANDY_ADVANCE';
export const LANDY_ADVANCER_PASS_STRONG_CLUBS = 'LANDY_ADVANCER_PASS_STRONG_CLUBS';
export const LANDY_ADVANCE_TWO_NO_TRUMP_INVITE = 'LANDY_ADVANCE_TWO_NO_TRUMP_INVITE';
export const LANDY_OVERCALLER_REBID = 'LANDY_OVERCALLER_REBID';
export const LANDY_OVERCALLER_REBID_BID_LONGEST = 'LANDY_OVERCALLER_REBID_BID_LONGEST';
export const LANDY_TWO_DIAMOND_NO_PREFERENCE = 'LANDY_TWO_DIAMOND_NO_PREFERENCE';
export const LANDY_TWO_NO_TRUMP = 'LANDY_TWO_NO_TRUMP';
export const LANDY_PLAY_THREE_NO_TRUMP_GAME = 'LANDY_PLAY_THREE_NO_TRUMP_GAME';
export const LAW_OF_TOTAL_TRICKS = 'LAW_OF_TOTAL_TRICKS';
export const LIMIT_RAISE = 'LIMIT_RAISE';
export const LOSING_TRICK_COUNT = 'LOSING_TRICK_COUNT';
export const MAJOR_SUIT_OPEN = 'MAJOR_SUIT_OPEN';
export const MICHAELS_CUEBID = 'MICHAELS_CUEBID';
export const MICHAELS_CUEBID_RESPONSE = 'MICHAELS_CUEBID_RESPONSE';
export const MICHAELS_CUEBID_UNSPECIFIED_FIVE_CARD_MINOR = 'MICHAELS_CUEBID_UNSPECIFIED_FIVE_CARD_MINOR';
export const MINIMAL_HAND = 'MINIMAL_HAND';
export const MINIMAL_HAND_SUIT_PREFERNCE = 'MINIMAL_HAND_SUIT_PREFERNCE';
export const MINIMAL_OPENER_CONFIRM_TRUMPS = 'MINIMAL_OPENER_CONFIRM_TRUMPS';
export const MINIMAL_OPENING_HAND_NO_TRUMP_RESPONSE = 'MINIMAL_OPENING_HAND_NO_TRUMP_RESPONSE';
export const MINOR_SUIT_OPEN = 'MINOR_SUIT_OPEN';
export const MINOR_SUIT_RAISE_DENIES_FOUR_CARD_MAJOR = 'MINOR_SUIT_RAISE_DENIES_FOUR_CARD_MAJOR';
export const MINOR_SUIT_STAYMAN = 'MINOR_SUIT_STAYMAN';
export const MINOR_SUIT_STAYMAN_RESPONSE = 'MINOR_SUIT_STAYMAN_RESPONSE';
export const NEGATIVE_DOUBLE = 'NEGATIVE_DOUBLE';
export const NEGATIVE_DOUBLE_BOTH_MAJORS = 'NEGATIVE_DOUBLE_BOTH_MAJORS';
export const NEGATIVE_DOUBLE_ONE_MAJOR = 'NEGATIVE_DOUBLE_ONE_MAJOR';
export const NEGATIVE_DOUBLE_BOTH_MINORS = 'NEGATIVE_DOUBLE_BOTH_MINORS';
export const NEGATIVE_DOUBLE_RESPONSE = 'NEGATIVE_DOUBLE_RESPONSE';
export const NEW_SUIT_RESPONSE_FORCING = 'NEW_SUIT_RESPONSE_FORCING';
export const NO_TRUMP_FIT = 'NO_TRUMP_FIT';
export const NO_TRUMP_RESPONSE_TO_SUIT_OPEN = 'NO_TRUMP_RESPONSE_TO_SUIT_OPEN';
export const ONE_LEVEL_SUIT_OVERCALL = 'ONE_LEVEL_SUIT_OVERCALL';
export const OPENING_BID = 'OPENING_BID';
export const OPEN_NO_TRUMP = 'OPEN_NO_TRUMP';
export const OPEN_NO_TRUMP_WEAK_FIVE_CARD_SUIT_RESPONSE = 'OPEN_NO_TRUMP_WEAK_FIVE_CARD_SUIT_RESPONSE';
export const OPENER_STRONG_JUMP_SHIFT = 'OPENER_STRONG_JUMP_SHIFT';
export const OPTIONAL_DOUBLE = 'OPTIONAL_DOUBLE';
export const OVERCALL_BID = 'OVERCALL_BID';
export const OVERCALL_ADVANCE_BID = 'OVERCALL_ADVANCE_BID';
export const OVERCALL_ADVANCE_STRONG_FIVE_CARD_SUIT = 'OVERCALL_ADVANCE_STRONG_FIVE_CARD_SUIT';
export const OVERCALL_ADVANCE_MINIMAL_RAISE = 'OVERCALL_ADVANCE_MINIMAL_RAISE';
export const OVERCALL_ADVANCE_INVITATIONAL_RAISE = 'OVERCALL_ADVANCE_INVITATIONAL_RAISE';
export const OVERCALL_ADVANCE_GAME_FORCING_HAND_RAISE = 'OVERCALL_ADVANCE_GAME_FORCING_HAND_RAISE';
export const OVERCALL_ADVANCE_PREEMPT_THREE_LEVEL = 'OVERCALL_ADVANCE_PREEMPT_THREE_LEVEL';
export const OVERCALL_NO_TRUMP = 'OVERCALL_NO_TRUMP';
export const OVERCALL_ADVANCE_CUE_BID = 'OVERCALL_ADVANCE_CUE_BID';
export const OVERCALLER_IS_MINIMAL_REBIDS_SUIT = 'OVERCALLER_IS_MINIMAL_REBIDS_SUIT';
export const PASS_CANNOT_RAISE_HIGHER = 'PASS_CANNOT_RAISE_HIGHER';
export const PASS_CANNOT_REBID_NO_TRUMP_LACKS_STOPPER_IN_ENEMY_SUIT = 'PASS_CANNOT_REBID_NO_TRUMP_LACKS_STOPPER_IN_ENEMY_SUIT';
export const PASS_CONVERT_TO_DOUBLE_BID = 'PASS_CONVERT_TO_DOUBLE_BID';
export const PASS_DOES_NOT_HAVE_BID = 'PASS_DOES_NOT_HAVE_BID';
export const PASS_RESPONSE_AFTER_RHO_TAKEOUT_DOUBLE = 'PASS_RESPONSE_AFTER_RHO_TAKEOUT_DOUBLE';
export const PENALTY_DOUBLE = 'PENALTY_DOUBLE';
export const PLAY_GAME_WITH_LTC = 'PLAY_GAME_WITH_LTC';
export const PLAY_MAJOR_GAME_WITH_POINTS = 'PLAY_MAJOR_GAME_WITH_POINTS';
export const PLAY_MINOR_GAME_WITH_POINTS = 'PLAY_MINOR_GAME_WITH_POINTS';
export const PLAY_NO_TRUMP_OVER_SUIT = 'PLAY_NO_TRUMP_OVER_SUIT';
export const PLAY_NO_TRUMP_GRAND_SLAM = 'PLAY_NO_TRUMP_GRAND_SLAM';
export const PLAY_NO_TRUMP_SMALL_SLAM = 'PLAY_NO_TRUMP_SMALL_SLAM';
export const PLAY_THREE_NO_TRUMP_GAME = 'PLAY_THREE_NO_TRUMP_GAME';
export const PLAYING_TRICK_COUNT = 'PLAYING_TRICK_COUNT';
export const PREEMPT_FOUR_LEVEL = 'PREEMPT_FOUR_LEVEL';
export const PREEMPT_NEW_SUIT = 'PREEMPT_NEW_SUIT';
export const PREEMPT_RAISE = 'PREEMPT_RAISE';
export const PREEMPT_THREE_LEVEL = 'PREEMPT_THREE_LEVEL';
export const PREEMPT_WEAK_TWO = 'PREEMPT_WEAK_TWO';
export const PASS = 'PASS';
export const PASS_LESS_THAN_MINIMAL_POINTS = 'PASS_LESS_THAN_MINIMAL_POINTS';
export const PASS_OVERCALL = 'PASS_OVERCALL';
export const POINTS_IN_UNBID_SUITS = 'POINTS_IN_UNBID_SUITS';
export const QUANTITATIVE_FOUR_NT = 'QUANTITATIVE_FOUR_NT';
export const RANGE_PROMISE = 'RANGE_PROMISE';
export const RAISE_TO_MAJOR_GAME = 'RAISE_TO_MAJOR_GAME';
export const REBID_SIX_CARD_SUIT = 'REBID_SIX_CARD_SUIT';
export const REDOUBLE = 'REDOUBLE';
export const RESPONDER_NEW_SUIT_FORCING = 'RESPONDER_NEW_SUIT_FORCING';
export const RESPOND_MICHAELS_CUEBID_OVERCALL_WITH_FIT = 'RESPOND_MICHAELS_CUEBID_OVERCALL_WITH_FIT';
export const RESPOND_WITH_CUEBID_SHOWING_GAME_FORCING_HAND = 'RESPOND_WITH_CUEBID_SHOWING_GAME_FORCING_HAND';
export const RESPONSE_TO_PARTNERS_OPENING_BID = 'RESPONSE_TO_PARTNERS_OPENING_BID';
export const RESPONSE_TO_PARTNERS_OPENING_WHEN_OVERCALLED = 'RESPONSE_TO_PARTNERS_OPENING_WHEN_OVERCALLED';
export const REVERSE_BID = 'REVERSE_BID';
export const RULE_OF_FIFTEEN_THRESHOLD = 'RULE_OF_FIFTEEN_THRESHOLD';
export const SIGN_OFF = 'SIGN_OFF';
export const SLAM_BAIL = 'SLAM_BAIL';
export const SLAM_SIGN_OFF_MISSING_FIRST_ROUND_CONTROLS = 'SLAM_SIGN_OFF_MISSING_FIRST_ROUND_CONTROLS';
export const SMALL_SLAM = 'SMALL_SLAM';
export const SLAM_TRY_SHOWS_CONTROL_IN_STRAIN = 'SLAM_TRY_SHOWS_CONTROL_IN_STRAIN';
export const STAYMAN_BID = 'STAYMAN_BID';
export const STAYMAN_DENIED = 'STAYMAN_DENIED';
export const STAYMAN_MATCH = 'STAYMAN_MATCH';
export const STAYMAN_OVERCALLED_PASS_NO_FOUR_CARD_MAJOR = 'STAYMAN_OVERCALLED_PASS_NO_FOUR_CARD_MAJOR';
export const STAYMAN_OVERCALLED_REDOUBLE_FIVE_CLUBS_NO_FOUR_CARD_MAJOR = 'STAYMAN_OVERCALLED_REDOUBLE_FIVE_CLUBS_NO_FOUR_CARD_MAJOR';
export const STAYMAN_OVERCALLED_DOUBLE_HAS_FOUR_CARD_MAJOR = 'STAYMAN_OVERCALLED_DOUBLE_HAS_FOUR_CARD_MAJOR';
export const STAYMAN_RESPONSE = 'STAYMAN_RESPONSE';
export const STOPPER_IN_STRAIN = 'STOPPER_IN_STRAIN';
export const STRONG_JUMP_SHIFT = 'STRONG_JUMP_SHIFT';
export const STRONG_TWO_CLUB = 'STRONG_TWO_CLUB';
export const STRONG_TWO_CLUB_REBID_BALANCED_HAND = 'STRONG_TWO_CLUB_REBID_BALANCED_HAND';
export const STRONG_TWO_CLUB_NO_TRUMP_RESPONSE = 'STRONG_TWO_CLUB_NO_TRUMP_RESPONSE';
export const STRONG_TWO_POSITIVE_SUIT_RESPONSE = 'STRONG_TWO_POSITIVE_SUIT_RESPONSE';
export const STRONG_TWO_CLUB_WAITING = 'STRONG_TWO_CLUB_WAITING';
export const STRONG_TWO_OF_A_MAJOR = 'STRONG_TWO_OF_A_MAJOR';
export const STRONG_TWO_OF_A_MINOR = 'STRONG_TWO_OF_A_MINOR';
export const STRONG_TWO_NEGATIVE_RESPONSE = 'STRONG_TWO_NEGATIVE_RESPONSE';
export const STRONG_TWO_STRONG_BALANCED_HAND_RESPONSE = 'STRONG_TWO_STRONG_BALANCED_HAND_RESPONSE';
export const STRONG_TWO_CLUB_BID_LONGEST_SUIT = 'STRONG_TWO_CLUB_BID_LONGEST_SUIT';
export const SUIT_PROMISE = 'SUIT_PROMISE';
export const SUPPORT_PARTNERS_SUIT = 'SUPPORT_PARTNERS_SUIT';
export const TAKE_OUT_DOUBLE = 'TAKE_OUT_DOUBLE';
export const TAKEOUT_DOUBLE_RESPONSE_INVITATIONAL_LONG_SUIT = 'TAKEOUT_DOUBLE_RESPONSE_INVITATIONAL_LONG_SUIT';
export const TAKEOUT_DOUBLE_RESPONSE_WEAK_HAND_BID_LONGEST_SUIT = 'TAKEOUT_DOUBLE_RESPONSE_WEAK_HAND_BID_LONGEST_SUIT';
export const TAKEOUT_DOUBLE_RESPONSE_FREEBID_LONGEST_SUIT = 'TAKEOUT_DOUBLE_RESPONSE_FREEBID_LONGEST_SUIT';
export const TAKEOUT_DOUBLE_RESPONSE_JUMP_TO_GAME = 'TAKEOUT_DOUBLE_RESPONSE_JUMP_TO_GAME';
export const TAKEOUT_DOUBLE_RESPONSE_NO_TRUMP = 'TAKEOUT_DOUBLE_RESPONSE_NO_TRUMP';
export const TAKEOUT_DOUBLE_RESPONSE_CUE_BID = 'TAKEOUT_DOUBLE_RESPONSE_CUE_BID';
export const TAKEOUT_DOUBLE_RESPONSE_LONG_SUIT = 'TAKEOUT_DOUBLE_RESPONSE_LONG_SUIT';
export const TRUMP_FIT = 'TRUMP_FIT';
export const TWO_FIVE_CARD_SUIT_HAND = 'TWO_FIVE_CARD_SUIT_HAND';
export const TWO_LEVEL_SUIT_OVERCALL = 'TWO_LEVEL_SUIT_OVERCALL';
export const UNBALANCED_HAND ='UNBALANCED_HAND';
export const UNUSUAL_TWO_NO_TRUMP = 'UNUSUAL_TWO_NO_TRUMP';
export const UNUSUAL_TWO_NO_TRUMP_RESPONSE = 'UNUSUAL_TWO_NO_TRUMP_RESPONSE';
export const WEAK_COMPETITIVE_RAISE = 'WEAK_COMPETITIVE_RAISE';
export const WEAK_JUMP_OVERCALL = 'WEAK_JUMP_OVERCALL';
export const WEAK_JUMP_RAISE_AFTER_OVERCALL = 'WEAK_JUMP_RAISE_AFTER_OVERCALL';
export const WEAK_OPEN_REBID_PLAY_GAME = 'WEAK_OPEN_REBID_PLAY_GAME';
export const WEAK_OPEN_REBID_RETREAT = 'WEAK_OPEN_REBID_RETREAT';
export const WEAK_OPEN_REBID_SHOWS_OUTSIDE_ACE_KING_IN_SUIT = 'WEAK_OPEN_REBID_SHOWS_OUTSIDE_ACE_KING_IN_SUIT';
export const WEAK_TWO_NO_TRUMP_RESPONSE = 'WEAK_TWO_NO_TRUMP_RESPONSE';
export const WEAK_TWO_RESPONSE_JUMP_RAISE = 'WEAK_TWO_RESPONSE_JUMP_RAISE';
export const WEAK_TWO_RESPONSE_NEW_SUIT_FORCING = 'WEAK_TWO_RESPONSE_NEW_SUIT_FORCING';
