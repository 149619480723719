import { DATA_DEFINITION_PREFIX } from '../../constants';

export const TWO_NO_TRUMP = 'bid-options/strong-two-opener-rebids/TWO_NO_TRUMP';
export const THREE_NO_TRUMP_OVER_THREE_MINOR = 'bid-options/strong-two-opener-rebids/THREE_NO_TRUMP_OVER_THREE_MINOR';
export const THREE_NO_TRUMP_OVER_TWO_MAJOR = 'bid-options/strong-two-opener-rebids/THREE_NO_TRUMP_OVER_TWO_MAJOR';
export const RAISE = 'bid-options/strong-two-opener-rebids/RAISE';
export const NEW_SUIT = 'bid-options/strong-two-opener-rebids/NEW_SUIT';

export const DEF_TWO_NO_TRUMP = DATA_DEFINITION_PREFIX+'bid-options/strong-two-opener-rebids/TWO_NO_TRUMP';
export const DEF_THREE_NO_TRUMP_OVER_THREE_MINOR = DATA_DEFINITION_PREFIX+'bid-options/strong-two-opener-rebids/THREE_NO_TRUMP_OVER_THREE_MINOR';
export const DEF_THREE_NO_TRUMP_OVER_TWO_MAJOR = DATA_DEFINITION_PREFIX+'bid-options/strong-two-opener-rebids/THREE_NO_TRUMP_OVER_TWO_MAJOR';
export const DEF_RAISE = DATA_DEFINITION_PREFIX+'bid-options/strong-two-opener-rebids/RAISE';
export const DEF_NEW_SUIT = DATA_DEFINITION_PREFIX+'bid-options/strong-two-opener-rebids/NEW_SUIT';
