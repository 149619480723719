import React, { useRef } from 'react';
import ReactDOM from 'react-dom';

import FocusTrap from 'focus-trap-react';

import { Spring, config, animated } from 'react-spring/renderprops';


import { BridgeLabelContext } from '../BridgeLabelContext';

import { useKeyPress, useOutsideCloseClick } from '../hooks';

const toggleScrollLock = () => {
  document.querySelector('html').classList.toggle('scroll-lock');
};

class ModalViewer {

  static showModal(modalProps, bridgeLabels) {
    const modalView = new ModalViewer();
    toggleScrollLock();
    modalView.show(modalProps, bridgeLabels);
  }

  closeModal = (event) => {
    event && event.preventDefault();
    const modalContainer = document.getElementById(ModalViewer.MODAL_CONTAINER);
    modalContainer.style.display = "none";
    toggleScrollLock();
    ReactDOM.unmountComponentAtNode(modalContainer);
  }

  submitModal = (event, submitData) => {
    event.preventDefault();
    if (this.dialogForm) {
      this.dialogForm.onSubmit(submitData);
    }
    this.closeModal(event);
  }

  show(modalProps, bridgeLabels)  {
    const modalContainer = document.getElementById(ModalViewer.MODAL_CONTAINER);
    modalContainer.style.display = "block";

    if (modalProps.dialogForm) {
      this.dialogForm = modalProps.dialogForm;
    }

    ReactDOM.render(
      <ModalWrapper
        bridgeLabels={bridgeLabels}
        onClose={this.closeModal}
        onSubmit={this.submitModal}
        {...modalProps}
      />
    , modalContainer);
  }
}
ModalViewer.MODAL_CONTAINER = "modal-container";

const ModalWrapper = function ModalWrapper({
  bridgeLabels,
  onClose,
  onSubmit,
  getModalContainerTag,
  modalClass,
}) {
  const wrapperRef = useRef(null);
  useOutsideCloseClick(wrapperRef, onClose);
  useKeyPress('Escape', onClose);
  return (
    <Spring
      native
      from={{ marginLeft: -1000 }}
      to={{ marginLeft: 0 }}
      config={config.slow}
    >
    {props => (
      <animated.div style={props}>
        <BridgeLabelContext.Provider value={bridgeLabels} >
          <FocusTrap>
          <div ref={wrapperRef} className={`modal-dialog ${modalClass || ''}`}>
            {getModalContainerTag({
               onClose,
               onSubmit,
             })}
          </div>
          </FocusTrap>
        </BridgeLabelContext.Provider>
      </animated.div>
    )}
    </Spring>

  );
}

export default ModalViewer;
