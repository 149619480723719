import { createBidOptions } from '../create-bid-options';

import {
  TWO_D_WAITING,
  DEF_TWO_D_WAITING,
  TWO_OF_MAJOR,
  DEF_TWO_OF_MAJOR,
  TWO_NO_TRUMP,
  DEF_TWO_NO_TRUMP,
  THREE_OF_MINOR,
  DEF_THREE_OF_MINOR,
} from './types';

import {
  createRanges,
  STRONG_TWO_RESPONSE_RANGES,
  GAME_FORCE_RANGE
} from './ranges';

import { createLabels } from './labels';

const getOptions = function getOptions() {
  return createBidOptions([
    TWO_D_WAITING,
    TWO_OF_MAJOR,
    TWO_NO_TRUMP,
    THREE_OF_MINOR,
  ]);
};

export {
  TWO_D_WAITING,
  DEF_TWO_D_WAITING,
  TWO_OF_MAJOR,
  DEF_TWO_OF_MAJOR,
  TWO_NO_TRUMP,
  DEF_TWO_NO_TRUMP,
  THREE_OF_MINOR,
  DEF_THREE_OF_MINOR,
  STRONG_TWO_RESPONSE_RANGES,
  GAME_FORCE_RANGE,
  createRanges,
  createLabels,
  getOptions,
}
