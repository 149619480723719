import { createRangeLabel } from '../create-range-label';

import {
  BID_NEW_SUIT_ON_LEVEL_ONE,
  BID_ONE_NO_TRUMP_STOPPER_IN_ENEMY_SUIT_MINIMAL,
  DEFEND_MICHAELS_CUEBID_RESPONSE,
  RAISE_PARTNERS_SUIT_TO_TWO_LEVEL_MINIMAL,
  NEGATIVE_DOUBLE,
  //NEGATIVE_DOUBLE_THREE_LEVEL,
  TWO_OVER_ONE_RESPONSE,
  BID_TWO_NO_TRUMP_STOPPER_IN_ENEMY_SUIT_INVITATIONAL,
  JUMP_RAISE_OF_PARTNERS_SUIT,
  NEW_SUIT_FORCING,
  BID_THREE_NO_TRUMP_STOPPER_IN_ENEMY_SUIT_FORCING,
  CUEBID_RESPONSE,
  RAISE_TO_MAJOR_GAME,
  DEF_BID_NEW_SUIT_ON_LEVEL_ONE,
  DEF_BID_ONE_NO_TRUMP_STOPPER_IN_ENEMY_SUIT_MINIMAL,
  DEF_RAISE_PARTNERS_SUIT_TO_TWO_LEVEL_MINIMAL,
  DEF_NEGATIVE_DOUBLE,
  //DEF_NEGATIVE_DOUBLE_THREE_LEVEL,
  DEF_TWO_OVER_ONE_RESPONSE,
  DEF_BID_TWO_NO_TRUMP_STOPPER_IN_ENEMY_SUIT_INVITATIONAL,
  DEF_JUMP_RAISE_OF_PARTNERS_SUIT,
  DEF_NEW_SUIT_FORCING,
  DEF_BID_THREE_NO_TRUMP_STOPPER_IN_ENEMY_SUIT_FORCING,
  DEF_CUEBID_RESPONSE,
} from './types';

import {
  RESPONSE_AFTER_OPP_SUIT_OVERCALL_RANGES,
} from './ranges';

export const createLabels = function createLabels(bidSystemConfig) {

  const ranges = bidSystemConfig[ RESPONSE_AFTER_OPP_SUIT_OVERCALL_RANGES ];

  const BID_NEW_SUIT_ON_LEVEL_ONE_LABEL = 'Bid New Suit';
  const DEF_BID_NEW_SUIT_ON_LEVEL_ONE_LABEL = `${createRangeLabel(ranges[ BID_NEW_SUIT_ON_LEVEL_ONE])} - new suit on the one level`;

  const BID_ONE_NO_TRUMP_STOPPER_IN_ENEMY_SUIT_MINIMAL_LABEL = 'Bid 1 NT';
  const DEF_BID_ONE_NO_TRUMP_STOPPER_IN_ENEMY_SUIT_MINIMAL_LABEL = `${createRangeLabel(ranges[ BID_ONE_NO_TRUMP_STOPPER_IN_ENEMY_SUIT_MINIMAL])} - stopper in the enemy suit`;

  const RAISE_PARTNERS_SUIT_TO_TWO_LEVEL_MINIMAL_LABEL = 'Raise to Two level';
  const DEF_RAISE_PARTNERS_SUIT_TO_TWO_LEVEL_MINIMAL_LABEL = `${createRangeLabel(ranges[ RAISE_PARTNERS_SUIT_TO_TWO_LEVEL_MINIMAL ])} - fit in partner's suit`;

  const NEGATIVE_DOUBLE_LABEL = 'Negative Double';
  const DEF_NEGATIVE_DOUBLE_LABEL = `${createRangeLabel(ranges[ NEGATIVE_DOUBLE ])} - 4+ cards in at least one of the unbid suits`;

  //const NEGATIVE_DOUBLE_THREE_LEVEL_ABEL = 'Negative Double';
  //const DEF_NEGATIVE_DOUBLE_LABEL = `${createRangeLabel(ranges[ NEGATIVE_DOUBLE ])} - 4+ cards in at least one of the unbid suits`;

  const TWO_OVER_ONE_RESPONSE_LABEL = 'Two-Over-One';
  const DEF_TWO_OVER_ONE_RESPONSE_LABEL = `${createRangeLabel(ranges[ TWO_OVER_ONE_RESPONSE ])} - New suit on two level - forcing`;

  const BID_TWO_NO_TRUMP_STOPPER_IN_ENEMY_SUIT_INVITATIONAL_LABEL = '2 NT';
  const DEF_BID_TWO_NO_TRUMP_STOPPER_IN_ENEMY_SUIT_INVITATIONAL_LABEL = `${createRangeLabel([ BID_TWO_NO_TRUMP_STOPPER_IN_ENEMY_SUIT_INVITATIONAL ])} - stopper in the enemy suit Invitational not forcing`;

  const JUMP_RAISE_OF_PARTNERS_SUIT_LABEL = 'Jump Raise';
  const DEF_JUMP_RAISE_OF_PARTNERS_SUIT_LABEL = `${createRangeLabel(ranges[ JUMP_RAISE_OF_PARTNERS_SUIT ])} - Jump a level raise partners suit - Invitational not forcing`;

  const NEW_SUIT_FORCING_LABEL = 'Game Forcing hand';
  const DEF_NEW_SUIT_FORCING_LABEL = `${createRangeLabel(ranges[ NEW_SUIT_FORCING ])} - first bid a new suit - jump raise next turn`;

  const BID_THREE_NO_TRUMP_STOPPER_IN_ENEMY_SUIT_FORCING_LABEL = '3 NT';
  const DEF_BID_THREE_NO_TRUMP_STOPPER_IN_ENEMY_SUIT_FORCING_LABEL = `${createRangeLabel(ranges[ BID_THREE_NO_TRUMP_STOPPER_IN_ENEMY_SUIT_FORCING ])} - stopper in the enemy suit`;

  const CUEBID_RESPONSE_LABEL = 'Cue Bid';
  const DEF_CUEBID_RESPONSE_LABEL = `${createRangeLabel(ranges[ CUEBID_RESPONSE ])} - support for opener’s suit - first- or second-round control in the enemy suit`;

  const RAISE_TO_MAJOR_GAME_LABEL = `${createRangeLabel(ranges[ RAISE_TO_MAJOR_GAME ])} - Raise to Game in Partner's Major`;

  const DEFEND_MICHAELS_CUEBID_RESPONSE_LABEL = 'Response over their cuebid overcall';

  return {
    [ BID_NEW_SUIT_ON_LEVEL_ONE ]: BID_NEW_SUIT_ON_LEVEL_ONE_LABEL,
    [ BID_ONE_NO_TRUMP_STOPPER_IN_ENEMY_SUIT_MINIMAL ]: BID_ONE_NO_TRUMP_STOPPER_IN_ENEMY_SUIT_MINIMAL_LABEL,
    [ DEFEND_MICHAELS_CUEBID_RESPONSE ]: DEFEND_MICHAELS_CUEBID_RESPONSE_LABEL,
    [ RAISE_PARTNERS_SUIT_TO_TWO_LEVEL_MINIMAL ]: RAISE_PARTNERS_SUIT_TO_TWO_LEVEL_MINIMAL_LABEL,
    [ NEGATIVE_DOUBLE ]: NEGATIVE_DOUBLE_LABEL,
    [ TWO_OVER_ONE_RESPONSE ]: TWO_OVER_ONE_RESPONSE_LABEL,
    [ BID_TWO_NO_TRUMP_STOPPER_IN_ENEMY_SUIT_INVITATIONAL ]: BID_TWO_NO_TRUMP_STOPPER_IN_ENEMY_SUIT_INVITATIONAL_LABEL,
    [ JUMP_RAISE_OF_PARTNERS_SUIT ]: JUMP_RAISE_OF_PARTNERS_SUIT_LABEL,
    [ NEW_SUIT_FORCING ]: NEW_SUIT_FORCING_LABEL,
    [ BID_THREE_NO_TRUMP_STOPPER_IN_ENEMY_SUIT_FORCING ]: BID_THREE_NO_TRUMP_STOPPER_IN_ENEMY_SUIT_FORCING_LABEL,
    [ CUEBID_RESPONSE ]: CUEBID_RESPONSE_LABEL,
    [ RAISE_TO_MAJOR_GAME ]: RAISE_TO_MAJOR_GAME_LABEL,
    [ DEF_BID_NEW_SUIT_ON_LEVEL_ONE ]: DEF_BID_NEW_SUIT_ON_LEVEL_ONE_LABEL,
    [ DEF_BID_ONE_NO_TRUMP_STOPPER_IN_ENEMY_SUIT_MINIMAL ]: DEF_BID_ONE_NO_TRUMP_STOPPER_IN_ENEMY_SUIT_MINIMAL_LABEL,
    [ DEF_RAISE_PARTNERS_SUIT_TO_TWO_LEVEL_MINIMAL ]: DEF_RAISE_PARTNERS_SUIT_TO_TWO_LEVEL_MINIMAL_LABEL,
    [ DEF_NEGATIVE_DOUBLE ]: DEF_NEGATIVE_DOUBLE_LABEL,
    [ DEF_TWO_OVER_ONE_RESPONSE ]: DEF_TWO_OVER_ONE_RESPONSE_LABEL,
    [ DEF_BID_TWO_NO_TRUMP_STOPPER_IN_ENEMY_SUIT_INVITATIONAL ]: DEF_BID_TWO_NO_TRUMP_STOPPER_IN_ENEMY_SUIT_INVITATIONAL_LABEL,
    [ DEF_JUMP_RAISE_OF_PARTNERS_SUIT ]: DEF_JUMP_RAISE_OF_PARTNERS_SUIT_LABEL,
    [ DEF_NEW_SUIT_FORCING ]: DEF_NEW_SUIT_FORCING_LABEL,
    [ DEF_BID_THREE_NO_TRUMP_STOPPER_IN_ENEMY_SUIT_FORCING ]: DEF_BID_THREE_NO_TRUMP_STOPPER_IN_ENEMY_SUIT_FORCING_LABEL,
    [ DEF_CUEBID_RESPONSE ]: DEF_CUEBID_RESPONSE_LABEL,
  };
};
