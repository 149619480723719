import React from 'react';
import PropTypes from 'prop-types';
import { useSpring, animated, config } from 'react-spring';

import { CloseCollapseIcon, OpenCollapseIcon } from './CharIcon';

const DataTypeCollapse = function DataTypeCollapse({
  className,
  children,
  isOpen,
  ...restProps
}) {
  const fadeIn = {
    from: { opacity: 0, height: 0 },
    to: { opacity: 1, height: 'auto' },
    config: config.slow,
  };

  const fadeOut = {
    from: { opacity: 1, height: 'auto' },
    to: { opacity: 0, height: 0 },
    config: config.slow,
  };

  const props = useSpring(isOpen
    ? fadeIn
    : fadeOut
  );

  return (
    <>
      <dt className={className}>
        <CollapseControl isOpen={isOpen} {...restProps} />
      </dt>
      <animated.div style={props}>{children}</animated.div>
    </>
  );
};
DataTypeCollapse.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string,
  isOpen: PropTypes.bool,
  label: PropTypes.string,
  onClick: PropTypes.func,
};

const CollapseControl = function CollapseControl({isOpen, label, ...restProps }) {
  return (
    <div>
      <span>{label}</span>
      {isOpen
        ? <CloseCollapseIcon  {...restProps}/>
        : <OpenCollapseIcon {...restProps}/>}
    </div>
  );
};
CollapseControl.propTypes = {
  id: PropTypes.string,
  isOpen: PropTypes.bool,
  label: PropTypes.string,
  onClick: PropTypes.func,
};

export { CollapseControl, DataTypeCollapse };
