import * as R from 'ramda';

// linear progression of a single deal
const DealProgress = {
  gameInit: -1,
  newDealStart: 0,
  cardsDealt: 1,
  auctionStarted: 2,
  auctionCompleted: 3,
  auctionView: 4,
  auctionViewed: 5,
  leadCardPlayed: 6,
  dummyCardsDown: 7,
  trickPlayed: 8,
  handPlayedOut: 9,
  queryNextDeal: 10,
  getIsAuctionView: progress =>
    R.gte(progress, DealProgress.auctionView),
  getIsAuctionViewed: progress =>
    R.gte(progress, DealProgress.auctionView),
  getIsAuctionInProgress: progress =>
    progress === DealProgress.auctionStarted,
  getIsAuctionComplete: progress =>
    R.gte(progress, DealProgress.auctionCompleted),
  getIsPlayInProgress: progress =>
    R.gte(progress, DealProgress.leadCardPlayed),
  getIsCardsDealt: progress =>
    R.lte(progress, DealProgress.newDealStart),
  getIsGameInit: progress =>
    progress === DealProgress.gameInit,
  getIsNewDealStart: progress =>
    progress === DealProgress.newDealStart,
  getIsGTE: (newProgress, baseProgress) =>
    newProgress >= baseProgress,
};
Object.freeze(DealProgress);

export { DealProgress } ;
