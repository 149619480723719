// animation/autoplay setTimeout defaults values
const GamePause = {
  // pause prior to display of auction - internal
  AUCTION_VIEW_WAIT: 500,
  // slight pause prior to prior to starting deal run through - internal
  CLEAR_PREVIOUS_DEAL_WAIT: 800,
  // after cards before auction
  DEAL_CARDS_WAIT: 400,
  // pause before next deal - internal
  HAND_COMPLETE_WAIT: 200,
  // pause before card display - internal
  BEFORE_CARD_WAIT: 500,
  // pause after card is displayed
  AFTER_CARD_WAIT: 1200,
  // pause after last played / before showing the trick viewer
  BEFORE_SHOW_TRICK_VIEW_WAIT: 800,
  // wait for interactive bid to display
  NEXT_BID_WAIT: 0,//2000,
  // automated waits - set to value other than -1 to automate play.
  // when value is > -1 pause for user prompt until value times out
  AUTO_AUCTION_VIEW_WAIT: -1,
  AUTO_PLAY_NEXT_CARD_WAIT: -1,
  AUTO_NEXT_DEAL_QUERY_WAIT: -1,
  AUTO_TALLY_VIEW_WAIT: -1,
  AUTO_TRICK_VIEW_WAIT: -1,
};
Object.freeze(GamePause);

export { GamePause };
