import { PresenterEvents } from '../constants';

export const createActionLookup = function createActionLookup(presenter) {
    return {
      [ PresenterEvents.DEAL_NEXT_IN_QUEUE ]: () => presenter.dealNextInQueue(),
      [ PresenterEvents.DEAL_ACTION ]: () => presenter.handleDealAction(presenter.getDealType()),
      [ PresenterEvents.DELETE_CURRENT_DEAL ]: () => presenter.deleteCurrentDeal(),
      [ PresenterEvents.EXPORT_CURRENT_DEAL ]: () => presenter.exportDeal(),
      [ PresenterEvents.GO_HOME ]: () => presenter.setGoHome(),
      [ PresenterEvents.IMPORT_DEAL ]: () => presenter.importDeal(),
      [ PresenterEvents.REPLAY_CURRENT_DEAL ]: () => presenter.replayCurrentDeal(),
      [ PresenterEvents.RUN_TESTS ]: () => presenter.runTest(),
      [ PresenterEvents.SAVE_CURRENT_DEAL ]: () => presenter.saveCurrentDeal(),
      [ PresenterEvents.SCROLL_DOWN ]: () => window.scrollTo(0, document.body.scrollHeight),
      [ PresenterEvents.SCROLL_UP ]: () => window.scrollTo(0, 0),
      [ PresenterEvents.SHOW_HINT_ACTION ]: () => presenter.showHint(),
      [ PresenterEvents.SHOW_AUCTION_ACTION ]: () => presenter.toggleAuctionViewer(),
      [ PresenterEvents.SHOW_BID_OPTIONS ]: () => presenter.showBidOptionsViewer(),
      [ PresenterEvents.SHOW_CARDS_ACTION ]: () => presenter.toggleCardsVisible(),
      [ PresenterEvents.SHOW_NOW_PLAYING ]: () => presenter.view.showNowPlaying(
        presenter.getGameOptions(),
        presenter.gameModel.getCurrentDealProps()
      ),
      [ PresenterEvents.SHOW_POINTS_ACTION ]: () => presenter.togglePoints(),
      [ PresenterEvents.SHOW_TALLY_VIEW ]: () => presenter.showTallyViewer(),
      [ PresenterEvents.SHOW_TRICK_VIEW ]: () => presenter.showTrickViewer(),
    };
  };
