import { createRangeLabel } from '../create-range-label';

import { OVERCALL_ADVANCE_RANGES } from './ranges';

import {
  HAS_MAJOR_SUIT_TRUMP_FIT_WITH_PARTNERS_OVERCALL,
  GAME_FORCING_HAND_CUE_BID,
  NO_FIT_HAS_STOPPERS_THEIR_SUITS,
  NO_FIT_BID_NEW_FIVE_CARD_SUIT,
  MINOR_SUIT_TRUMP_FIT_WITH_PARTNER,
  DEF_HAS_MAJOR_SUIT_TRUMP_FIT_WITH_PARTNERS_OVERCALL,
  DEF_GAME_FORCING_HAND_CUE_BID,
  DEF_NO_FIT_HAS_STOPPERS_THEIR_SUITS,
  DEF_NO_FIT_BID_NEW_FIVE_CARD_SUIT,
  DEF_MINOR_SUIT_TRUMP_FIT_WITH_PARTNER,
} from './types';

export const createLabels = function createLabels(bidSystemConfig) {

  const Ranges = bidSystemConfig[ OVERCALL_ADVANCE_RANGES ];

  const HAS_MAJOR_SUIT_TRUMP_FIT_WITH_PARTNERS_OVERCALL_LABEL = `Major Suit Trump Fit`;
  const DEF_HAS_MAJOR_SUIT_TRUMP_FIT_WITH_PARTNERS_OVERCALL_LABEL = `Raise to show fit and points`;

  const GAME_FORCING_HAND_CUE_BID_LABEL = `Cue Bid`;
  const DEF_GAME_FORCING_HAND_CUE_BID_LABEL = `Game forcing hand - cue bid enemy suit`;

  const NO_FIT_HAS_STOPPERS_THEIR_SUITS_LABEL = `No Trump`;
  const DEF_NO_FIT_HAS_STOPPERS_THEIR_SUITS_LABEL = `stoppers in their bid strains`;

  const NO_FIT_BID_NEW_FIVE_CARD_SUIT_LABEL = `Five Card Suit`;
  const DEF_NO_FIT_BID_NEW_FIVE_CARD_SUIT_LABEL = `${createRangeLabel(Ranges[ NO_FIT_BID_NEW_FIVE_CARD_SUIT ]) } - 5+ cards in bid suit`;

  const MINOR_SUIT_TRUMP_FIT_WITH_PARTNER_LABEL = `Minor Suit trump Fit`;
  const DEF_MINOR_SUIT_TRUMP_FIT_WITH_PARTNER_LABEL = `Raise to show fit and points`;

  return {
    [ HAS_MAJOR_SUIT_TRUMP_FIT_WITH_PARTNERS_OVERCALL ]: HAS_MAJOR_SUIT_TRUMP_FIT_WITH_PARTNERS_OVERCALL_LABEL,
    [ GAME_FORCING_HAND_CUE_BID ]: GAME_FORCING_HAND_CUE_BID_LABEL,
    [ NO_FIT_HAS_STOPPERS_THEIR_SUITS ]: NO_FIT_HAS_STOPPERS_THEIR_SUITS_LABEL,
    [ NO_FIT_BID_NEW_FIVE_CARD_SUIT ]: NO_FIT_BID_NEW_FIVE_CARD_SUIT_LABEL,
    [ MINOR_SUIT_TRUMP_FIT_WITH_PARTNER ]: MINOR_SUIT_TRUMP_FIT_WITH_PARTNER_LABEL,
    [ DEF_HAS_MAJOR_SUIT_TRUMP_FIT_WITH_PARTNERS_OVERCALL ]: DEF_HAS_MAJOR_SUIT_TRUMP_FIT_WITH_PARTNERS_OVERCALL_LABEL,
    [ DEF_GAME_FORCING_HAND_CUE_BID ]: DEF_GAME_FORCING_HAND_CUE_BID_LABEL,
    [ DEF_NO_FIT_HAS_STOPPERS_THEIR_SUITS ]: DEF_NO_FIT_HAS_STOPPERS_THEIR_SUITS_LABEL,
    [ DEF_NO_FIT_BID_NEW_FIVE_CARD_SUIT ]: DEF_NO_FIT_BID_NEW_FIVE_CARD_SUIT_LABEL,
    [ DEF_MINOR_SUIT_TRUMP_FIT_WITH_PARTNER ]: DEF_MINOR_SUIT_TRUMP_FIT_WITH_PARTNER_LABEL,
  };

};
