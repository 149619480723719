import React from 'react';
import PropTypes from 'prop-types';

import { useFocus } from '../hooks';

const CheckBox = function CheckBox({ name, checked, onChange, isFocus }) {
  const inputRef = useFocus(isFocus);
  return (
    <input
      ref={inputRef}
      style={{margin: '2rem'}}
      name={name}
      type="checkbox"
      checked={checked}
      onChange={onChange}
    />
  );
};
CheckBox.propTypes = {
  name: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  isFocus: PropTypes.bool,
};

const Slider = function Slider(props) {
  return (
    <span className="switch">
      <CheckBox {...props} />
      <span className="slider round">
      </span>
    </span>
  );
};
Slider.propTypes = {
  name: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  isFocus: PropTypes.bool,
};
Slider.defaultProps = {
  isFocus: false,
};

export { Slider, CheckBox };
