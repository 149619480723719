import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import { assoc, pipe, has } from 'ramda';

import { constants as uic } from '../../constants';
import { getDataDefinitionKey, DATA_DEF_SEP } from '../../bridge';

import { BidDisplay, DataTypeCollapse } from '../components';

import { createBidValuesFrom } from '../game-views/auction/helper';

import { BridgeLabelContext } from '../BridgeLabelContext';

const getSelectedOptionClassName = isSelected => isSelected
  ? 'bid-options__selected-container'
  : '';

const getDescriptionClass = isSelected => isSelected
  ? 'bid-options__selected'
  : '';

const BidDisplayWithCheckMark = ({ bidValue }) => (
  <BidDisplay
    label={`${uic.CHECK_MARK} Bid `}
    id={'selected-bid-option'}
    bidValues={createBidValuesFrom(bidValue)}
  />
);

const BidOptionDataDefinition = function BidOptionDataDefinition({
  definition,
  className,
  children
}) {
  return (
    <dd className={className}>{definition || children}</dd>
  );
};
BidOptionDataDefinition.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  definition: PropTypes.string,
};

const SelectedDataDefinition = function SelectedDataDefinition({ bidValue, isSelected }) {
  if (!isSelected) {
    return null;
  }
  return (
    <div className={getDescriptionClass(isSelected)} >
      <BidDisplayWithCheckMark bidValue={bidValue} />
    </div>
  );
};

/**
* @component BidOption
*/
const BidOption = function BidOption({
  bidValue,
  isSelected,
  isOpen,
  onCollapseClick,
  type,
}) {
  const Labels = React.useContext(BridgeLabelContext);
  const defs = Labels[ getDataDefinitionKey(type) ];
  const className = getDescriptionClass(isSelected);

  return (
    <DataTypeCollapse
      label={Labels[ type ]}
      id={type}
      onClick={onCollapseClick}
      isOpen={isOpen}
      className={"bid-options__title"}
    >
    {isOpen
      && <div className={getSelectedOptionClassName(isSelected)}>
      {defs && defs.split(DATA_DEF_SEP).map((definition, i) => (
        <BidOptionDataDefinition
          key={i}
          className={className}
          definition={definition}
        />
       )
      )
      }
      <SelectedDataDefinition isSelected={isSelected} bidValue={bidValue} />
      </div>}
    </DataTypeCollapse>
  );
};
BidOption.propTypes = {
  bidValue: PropTypes.string,
  isSelected: PropTypes.bool,
  isOpen: PropTypes.bool,
  onCollapseClick: PropTypes.func,
  type: PropTypes.string,
};

/**
* @component BidOptionsSubLevelDefList
*/
const BidOptionsSubLevelDefList = function BidOptionsSubLevelDefList({
  bidValue,
  contentDefLabel,
  isParentOpen,
  bidOptions,
  isSelected
}) {
  const getDefaultOpenOptions = () => bidOptions
    .reduce((openOptions, type) => ({
      ...openOptions,
      [ type ]: isSelected(type)
    }), {});

  let [ openOptions, setOpenOptions ] = useState({
    [ bidValue ]: getDefaultOpenOptions()
  });

  // if the bidvalue is not keyed - add
  if (!has(bidValue, openOptions)) {
    openOptions = assoc(bidValue, getDefaultOpenOptions(), openOptions);
  }

  const onCollapseClick = e => {
    const updates = {
      ...openOptions,
      [ bidValue ]: {
        ...openOptions[ bidValue ],
        [ e.currentTarget.id ]: !openOptions[ e.currentTarget.id ],
      },
    };
    setOpenOptions(updates);
  };

  const getSelectedContentClass = pipe(
    () => bidOptions.some(option => isSelected(option)),
    getSelectedOptionClassName,
  );

  const getContentClassList = () => `bid-options__content ${getSelectedContentClass()}`;

  return (
    <dd className="bid-options__sub-level-def">
      {isParentOpen &&
        <>
        <div className={getContentClassList()}>{contentDefLabel}
        {bidOptions.length === 1 &&
          <SelectedDataDefinition isSelected={isSelected(bidOptions[0])} bidValue={bidValue} />
        }
        </div>
        {bidOptions.length > 1 &&
          <dl className="bid-options__sub-level-list">
            {bidOptions.map((option, index) => (
              <BidOption
                key={index}
                bidValue={bidValue}
                isOpen={isParentOpen && openOptions[ bidValue ][ option ]}
                isSelected={isSelected(option)}
                onCollapseClick={onCollapseClick}
                type={option}
             />
           ))}
          </dl>
        }
        </>
      }
    </dd>
  );
};
BidOptionsSubLevelDefList.propTypes = {
  isParentOpen: PropTypes.bool,
  contentDefLabel: PropTypes.string,
  bidOptions: PropTypes.array,
  isSelected: PropTypes.func,
  bidValue: PropTypes.string,
};

/**
* @component TopLevelBidOptions
*/
const TopLevelBidOption = function TopLevelBidOption({
  isOpen,
  onCollapseClick,
  groupKey,
  bidOptions,
  bidValue,
  isSelected
}) {
  const Labels = React.useContext(BridgeLabelContext);

  return (
    <DataTypeCollapse
      label={Labels[ groupKey ]}
      id={groupKey}
      onClick={onCollapseClick}
      isOpen={isOpen}
      className={"bid-options__title"}
    >
      <BidOptionsSubLevelDefList
        bidValue={bidValue}
        bidOptions={bidOptions}
        contentDefLabel={Labels[ getDataDefinitionKey(groupKey) ]}
        isParentOpen={isOpen}
        isSelected={isSelected}
      />
    </DataTypeCollapse>
  );
};
TopLevelBidOption.propTypes = {
  isOpen: PropTypes.bool,
  groupKey: PropTypes.string,
  bidOptions: PropTypes.array,
  isSelected: PropTypes.func,
  bidValue: PropTypes.string,
};

/**
* @component BidOptionsDefList
*/
const BidOptionsDefList = function BidOptionsDefList({ bidValue, bidOptions }) {
  const topLevelBidOptions = bidOptions.groupBidOptionsByType();

  // find the bidoption that isSelected for the bidValue - this is open as default
  const getDefaultOpenOptionGroups = useCallback(() => ([ ...topLevelBidOptions.keys() ]
    .reduce((openGroups, typeKey) => ({
      ...openGroups,
      [ typeKey ]: topLevelBidOptions.get(typeKey).some(option => bidOptions.isSelected(option))
    }), {})
  ), [ bidOptions, topLevelBidOptions ]);

  // store the options by bidvalue as key - each bidvalue in the view auction can be a key
  let [ openOptionGroups, setOpenOptionGroups ] = useState({
    [ bidValue ]: getDefaultOpenOptionGroups()
  });

  // if the bidvalue is not keyed - add - if the defaults change it will push to state in the event
  if (!has(bidValue, openOptionGroups)) {
    openOptionGroups = assoc(bidValue, getDefaultOpenOptionGroups(), openOptionGroups);
  }

  const onCollapseClick = e => {
    // update the nested bidValue -> option/target
    const updates = {
      ...openOptionGroups,
      [ bidValue ]: {
        ...openOptionGroups[ bidValue ],
        [ e.currentTarget.id ]: !openOptionGroups[ bidValue ][ e.currentTarget.id ]
      },
    }
    setOpenOptionGroups(updates);
  };

  return (
    <dl className="bid-options">
      {
        [ ...topLevelBidOptions.keys() ].map((key, i) => (
          <TopLevelBidOption
            key={i}
            isOpen={openOptionGroups[ bidValue ][ key ]}
            groupKey={key}
            bidOptions={topLevelBidOptions.get(key)}
            isSelected={bidOptions.isSelected}
            onCollapseClick={onCollapseClick}
            bidValue={bidValue}
          />
        ))
      }
    </dl>
  );
};
BidOptionsDefList.propTypes = {
  bidValue: PropTypes.string,
  bidOptions: PropTypes.object,
};

/**
* @component BidOptionsViewer
*
* container for
* BidOptionsDefList ->
* TopLevelBidOption ->
* DataTypeCollapse / BidOptionsSubLevelDefList ->
* SelectedDataDefinition / BidOptions ->
* BidOptionDataDefinition / SelectedDataDefinition
*/
const BidOptionsViewer = function BidOptionsViewer({
  bidValue,
  bidOptions,
  contextType,
}) {
  const Labels = React.useContext(BridgeLabelContext);
  return (
    <section className="bid-options__section" >
      <h3>{Labels[ contextType ]}</h3>
      <BidOptionsDefList
        bidValue={bidValue}
        bidOptions={bidOptions}
      />
    </section>
  );
};
BidOptionsViewer.propTypes = {
  bidValue: PropTypes.string,
  contextType: PropTypes.string,
  onClose: PropTypes.func,
};

export default BidOptionsViewer;
