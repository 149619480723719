import { createBidOptions } from '../create-bid-options';
import { PASS_OPTION } from '../pass-option';

import {
  RESPOND_WITH_REDOUBLE,
  RESPOND_ONE_NO_TRUMP,
  SIMPLE_RAISE_TRUMP_SUPPORT,
  JUMP_RAISE_TRUMP_SUPPORT,
  RESPOND_AT_ONE_LEVEL_NEW_SUIT,
  RESPOND_AT_TWO_LEVEL_NEW_SUIT,
} from './types';

import { Labels } from './labels';

const getOptions = function getOptions() {
  return createBidOptions([
    RESPOND_WITH_REDOUBLE,
    RESPOND_ONE_NO_TRUMP,
    SIMPLE_RAISE_TRUMP_SUPPORT,
    JUMP_RAISE_TRUMP_SUPPORT,
    RESPOND_AT_ONE_LEVEL_NEW_SUIT,
    RESPOND_AT_TWO_LEVEL_NEW_SUIT,
    PASS_OPTION,
  ]);
};

export {
  RESPOND_WITH_REDOUBLE,
  RESPOND_ONE_NO_TRUMP,
  SIMPLE_RAISE_TRUMP_SUPPORT,
  JUMP_RAISE_TRUMP_SUPPORT,
  RESPOND_AT_ONE_LEVEL_NEW_SUIT,
  RESPOND_AT_TWO_LEVEL_NEW_SUIT,
  Labels,
  getOptions,
};
