import {
  BID_NEW_SUIT_ON_LEVEL_ONE,
  BID_ONE_NO_TRUMP_STOPPER_IN_ENEMY_SUIT_MINIMAL,
  RAISE_PARTNERS_SUIT_TO_TWO_LEVEL_MINIMAL,
  NEGATIVE_DOUBLE,
  NEGATIVE_DOUBLE_THREE_LEVEL,
  TWO_OVER_ONE_RESPONSE,
  BID_TWO_NO_TRUMP_STOPPER_IN_ENEMY_SUIT_INVITATIONAL,
  JUMP_RAISE_OF_PARTNERS_SUIT,
  NEW_SUIT_FORCING,
  BID_THREE_NO_TRUMP_STOPPER_IN_ENEMY_SUIT_FORCING,
  CUEBID_RESPONSE,
  RAISE_TO_MAJOR_GAME,
} from './types';

export const RESPONSE_AFTER_OPP_SUIT_OVERCALL_RANGES = 'bid-options/response=after-suit-overcall/ranges';

export const createRanges = () => ({
  [ BID_NEW_SUIT_ON_LEVEL_ONE ]: {
    lower: 6,
  },
  [ BID_ONE_NO_TRUMP_STOPPER_IN_ENEMY_SUIT_MINIMAL ]: {
    lower: 7,
    upper: 10,
  },
  [ RAISE_PARTNERS_SUIT_TO_TWO_LEVEL_MINIMAL ]: {
    lower: 6,
    upper: 10,
  },
  [ NEGATIVE_DOUBLE ]: {
    lower: 7,
  },
  [ NEGATIVE_DOUBLE_THREE_LEVEL ]: {
    lower: 9,
  },
  [ TWO_OVER_ONE_RESPONSE ]: {
    lower: 9,
  },
  [ BID_TWO_NO_TRUMP_STOPPER_IN_ENEMY_SUIT_INVITATIONAL ]: {
    lower: 11,
    upper: 12,
  },
  [ JUMP_RAISE_OF_PARTNERS_SUIT ]: {
    lower: 11,
    upper: 12,
  },
  [ NEW_SUIT_FORCING ]: {
    lower: 13,
    upper: 16,
  },
  [ BID_THREE_NO_TRUMP_STOPPER_IN_ENEMY_SUIT_FORCING ]: {
    lower: 12,
    upper: 15,
  },
  [ CUEBID_RESPONSE ]: {
    lower: 17,
  },
  [ RAISE_TO_MAJOR_GAME ]: {
    lower: 5,
    upper: 11,
  },
});
