import { PASS_OPTION } from '../pass-option';
import { createBidOptions } from '../create-bid-options';

import {
  MAJOR_SUIT_FIT,
  MAJOR_SUIT_SIMPLE_RAISE,
  MAJOR_SUIT_LIMIT_RAISE,
  MAJOR_SUIT_WEAK_GAME_RAISE,
  NEW_SUIT_RESPONSE,
  NEW_SUIT_ONE_LEVEL,
  NEW_SUIT_TWO_LEVEL,
  NEW_SUIT_JUMP_SHIFT,
  MINOR_SUIT_RAISE,
  MINOR_SUIT_SIMPLE_RAISE,
  MINOR_SUIT_LIMIT_RAISE,
  NO_TRUMP_RESPONSE,
  ONE_LEVEL_NO_TRUMP,
  TWO_LEVEL_NO_TRUMP,
  THREE_LEVEL_NO_TRUMP,
  DRURY_RESPONSE,
} from './types';

import {
  RESPONSE_ONE_LEVEL_SUIT_OPEN_RANGES,
  createRanges,
} from './ranges';

import { createLabels } from './labels';

const getGroupType = function getGroupType(type) {
  switch (type) {
    case DRURY_RESPONSE:
    case MAJOR_SUIT_SIMPLE_RAISE:
    case MAJOR_SUIT_LIMIT_RAISE:
    case MAJOR_SUIT_WEAK_GAME_RAISE:
      return MAJOR_SUIT_FIT;
    case NEW_SUIT_ONE_LEVEL:
    case NEW_SUIT_TWO_LEVEL:
    case NEW_SUIT_JUMP_SHIFT:
      return NEW_SUIT_RESPONSE;
    case MINOR_SUIT_SIMPLE_RAISE:
    case MINOR_SUIT_LIMIT_RAISE:
      return MINOR_SUIT_RAISE;
    case ONE_LEVEL_NO_TRUMP:
    case TWO_LEVEL_NO_TRUMP:
    case THREE_LEVEL_NO_TRUMP:
      return NO_TRUMP_RESPONSE;
    default:
      return type;
  }
};

const getOptions = function getOptions() {
  return createBidOptions([
    MAJOR_SUIT_SIMPLE_RAISE,
    MAJOR_SUIT_LIMIT_RAISE,
    MAJOR_SUIT_WEAK_GAME_RAISE,
    NEW_SUIT_ONE_LEVEL,
    NEW_SUIT_TWO_LEVEL,
    NEW_SUIT_JUMP_SHIFT,
    MINOR_SUIT_SIMPLE_RAISE,
    MINOR_SUIT_LIMIT_RAISE,
    ONE_LEVEL_NO_TRUMP,
    TWO_LEVEL_NO_TRUMP,
    THREE_LEVEL_NO_TRUMP,
    DRURY_RESPONSE,
    PASS_OPTION,
  ], getGroupType);
};

export {
  MAJOR_SUIT_FIT,
  MAJOR_SUIT_SIMPLE_RAISE,
  MAJOR_SUIT_LIMIT_RAISE,
  MAJOR_SUIT_WEAK_GAME_RAISE,
  NEW_SUIT_RESPONSE,
  NEW_SUIT_ONE_LEVEL,
  NEW_SUIT_TWO_LEVEL,
  NEW_SUIT_JUMP_SHIFT,
  MINOR_SUIT_RAISE,
  MINOR_SUIT_SIMPLE_RAISE,
  MINOR_SUIT_LIMIT_RAISE,
  NO_TRUMP_RESPONSE,
  ONE_LEVEL_NO_TRUMP,
  TWO_LEVEL_NO_TRUMP,
  THREE_LEVEL_NO_TRUMP,
  DRURY_RESPONSE,
  RESPONSE_ONE_LEVEL_SUIT_OPEN_RANGES,
  createRanges,
  createLabels,
  getOptions,
};
