import { constants as c } from '../../../constants';

import { getIsStrainHigher } from '../../../suit';

import BidHelper from '../../bid-helper';
import * as BidTypes from '../../bid-types';
import { getInRange } from '../../point-range';
import { getIsMichaelsCueBid } from '../../bid-system-config';

import { PASS_OPTION } from '../pass-option';
import { createBidOptions } from '../create-bid-options';


import {
  ONE_LEVEL_NO_TRUMP,
  ONE_LEVEL_FIVE_CARD_SUIT,
  TWO_LEVEL_FIVE_CARD_SUIT,
  WEAK_JUMP_OVERCALL,
  TAKE_OUT_DOUBLE,
  TAKE_OUT_DOUBLE_SHORT_IN_ENEMY_SUIT,
  TAKE_OUT_DOUBLE_STRONG_HAND,
  TWO_SUITED_OVERCALLS,
  MICHAELS_CUEBID,
  UNUSUAL_TWO_NO_TRUMP,
} from './types';

import {
  DIRECT_OVERCALL_RANGES,
  createRanges,
} from './ranges';

import { createLabels } from './labels';

const getGroupType = function getGroupType(type) {
  switch (type) {
    case TAKE_OUT_DOUBLE_SHORT_IN_ENEMY_SUIT:
    case TAKE_OUT_DOUBLE_STRONG_HAND:
      return TAKE_OUT_DOUBLE;
    case MICHAELS_CUEBID:
    case UNUSUAL_TWO_NO_TRUMP:
      return TWO_SUITED_OVERCALLS;
    default:
      return type;
  }
};

const getOptions = function getOptions(player) {
  const optionTypes = getIsMichaelsCueBid(player)
    ? [
      ONE_LEVEL_NO_TRUMP,
      ONE_LEVEL_FIVE_CARD_SUIT,
      TWO_LEVEL_FIVE_CARD_SUIT,
      WEAK_JUMP_OVERCALL,
      TAKE_OUT_DOUBLE_SHORT_IN_ENEMY_SUIT,
      TAKE_OUT_DOUBLE_STRONG_HAND,
      MICHAELS_CUEBID,
      UNUSUAL_TWO_NO_TRUMP,
      PASS_OPTION,
    ]
    : [
      ONE_LEVEL_NO_TRUMP,
      ONE_LEVEL_FIVE_CARD_SUIT,
      TWO_LEVEL_FIVE_CARD_SUIT,
      WEAK_JUMP_OVERCALL,
      TAKE_OUT_DOUBLE_SHORT_IN_ENEMY_SUIT,
      TAKE_OUT_DOUBLE_STRONG_HAND,
      PASS_OPTION,
    ];

    return createBidOptions(optionTypes, getGroupType);

};

const getShouldWeakJumpOvercall = function getShouldWeakJumpOvercall(suit, rule, hand, beatBid) {
  if (suit.getLength() < c.SIX_CARD_SUIT
    || hand.getHighCardPoints() - suit.getHighCardPoints() > 5
  ) {
    return false;
  }

  const level = BidHelper.getJumpLevel(beatBid, suit.strain);
  if (suit.getLength() === c.SIX_CARD_SUIT) {
    return level === c.TWO_LEVEL;
  }

  if (suit.getLength() > c.SIX_CARD_SUIT) {
    return level >= c.TWO_LEVEL
      && !hand.player.getIsBalancingSeat();
  }

  return false;
};

const getShouldOvercallAtOneLevel = function getShouldOvercallAtOneLevel(suit, rule, hand) {
  return suit.getLength() >= c.FIVE_CARD_SUIT
    && (suit.getIsGood()
      || (suit.getHasAce() && suit.getHasHighSpotCards())
      || (suit.getHighCardPoints() >= 3 && hand.getHighCardPoints() >= 12)
      || (suit.getLength() >= c.SIX_CARD_SUIT && suit.getHighCardPoints() >= 3)
    );
};

const getShouldOvercallAtTwoLevel = function getShouldOvercallAtTwoLevel(suit, rule, hand) {
  return getInRange(hand.getTotalPointsLength(), rule.range)
    && (getIsSuitFiveCardsWithHonorsAndHighSpotCards(suit)
      || getIsSuitFiveCardsWithThreeHonors(suit)
      || getIsSuitSixCardsAndGood(suit));
};

const getIsSuitSixCardsAndGood = function getIsSuitSixCardsAndGood(suit) {
  return suit.getLength() >= c.SIX_CARD_SUIT
    && suit.getIsGood();
};

const getIsSuitFiveCardsWithHonorsAndHighSpotCards = function getIsSuitFiveCardsWithHonorsAndHighSpotCards(suit) {
  return (suit.getLength() >= c.FIVE_CARD_SUIT
    && suit.getHighCardPoints() >= 5
    && suit.getHasHighSpotCards());
};

const getIsSuitFiveCardsWithThreeHonors = function getIsSuitFiveCardsWithThreeHonors(suit) {
  return (suit.getLength() >= c.FIVE_CARD_SUIT
    && (suit.getHighCardPoints() >= 8
      || (!suit.getHasAce()
        && suit.getHighCardPoints() >= 6))
    );
};

const getOverCallSuitRules = function getOverCallSuitRules(bidSystemConfig) {
  const ranges = bidSystemConfig[ DIRECT_OVERCALL_RANGES ];

  return [
    {
      bidOption: WEAK_JUMP_OVERCALL,
      type: BidTypes.WEAK_JUMP_OVERCALL,
      level: c.TWO_LEVEL,
      acceptsStrain: strain =>
        [ c.CLUB, c.DIAMOND, c.HEART, c.SPADE ].some(s => s === strain),
      range: ranges[ WEAK_JUMP_OVERCALL ],
      suitLength: c.SIX_CARD_SUIT,
      getIsMeetsRequirements(generator, rule, suit) {
        return getIsMeetsRequirements(
          generator,
          rule,
          suit,
          getShouldWeakJumpOvercall
        );
      },
    },
    {
      bidOption: ONE_LEVEL_FIVE_CARD_SUIT,
      type: BidTypes.ONE_LEVEL_SUIT_OVERCALL,
      level: c.ONE_LEVEL,
      acceptsStrain: strain =>
        [ c.DIAMOND, c.HEART, c.SPADE ].some(s => s === strain),
      range: ranges[ ONE_LEVEL_FIVE_CARD_SUIT ],
      suitLength: c.FIVE_CARD_SUIT,
      getIsMeetsRequirements(generator, rule, suit) {
        return getIsMeetsRequirements(
          generator,
          rule,
          suit,
          getShouldOvercallAtOneLevel
        );
      },
    },
    {
      bidOption: TWO_LEVEL_FIVE_CARD_SUIT,
      type: BidTypes.TWO_LEVEL_SUIT_OVERCALL,
      level: c.TWO_LEVEL,
      range: ranges[ TWO_LEVEL_FIVE_CARD_SUIT ],
      suitLength: c.FIVE_CARD_SUIT,
      getShouldOvercall: getShouldOvercallAtTwoLevel,
      getIsMeetsRequirements(generator, rule, suit) {
        return getIsMeetsRequirements(
          generator,
          rule,
          suit,
          getShouldOvercallAtTwoLevel
        );
      },
    },
  ];
};

const getNoTrumpOvercallRules = ({ bidSystemConfig }) => [{
    bidOption: ONE_LEVEL_NO_TRUMP,
    level: c.ONE_LEVEL,
    range: bidSystemConfig[ DIRECT_OVERCALL_RANGES ][ ONE_LEVEL_NO_TRUMP ],
  },
];

const getIsRuleBeatsTheirBid = function(rule, strain, beatBid) {
  return (rule.level > beatBid.level)
    || ((rule.level === beatBid.level)
      && getIsStrainHigher(strain, beatBid.strain, beatBid.level));
};

const getSuitHasLength = function getSuitHasLength(rule, suitLength) {
  return suitLength >= rule.suitLength;
};

const getCanBidSuit = function getSuitIsTheirs(player, suit) {
  return !BidHelper.getIsStrainTheirs(player, suit.strain);
};

const getIsAllConditions = function(rule, suit, hand, beatBid) {
  return getCanBidSuit(hand.player, suit)
    && getIsRuleBeatsTheirBid(rule, suit.strain, beatBid)
    // HCP or Stong long distributional
    && (getInRange(hand.getHighCardPoints(), rule.range)
         || (suit.getIsExceptional()
           && getInRange(hand.getTotalPointsLength(), rule.range)))
    && getSuitHasLength(rule, suit.getLength());
};

const getIsMeetsRequirements = function getIsMeetsRequirements(generator, rule, suit, shouldOvercall) {
  const { hand, beatBid } = generator;
  return shouldOvercall(suit, rule, hand, beatBid)
   && getIsAllConditions(rule, suit, hand, beatBid);
};

export {
  ONE_LEVEL_NO_TRUMP,
  ONE_LEVEL_FIVE_CARD_SUIT,
  TWO_LEVEL_FIVE_CARD_SUIT,
  WEAK_JUMP_OVERCALL,
  TAKE_OUT_DOUBLE,
  TAKE_OUT_DOUBLE_SHORT_IN_ENEMY_SUIT,
  TAKE_OUT_DOUBLE_STRONG_HAND,
  TWO_SUITED_OVERCALLS,
  MICHAELS_CUEBID,
  UNUSUAL_TWO_NO_TRUMP,
  DIRECT_OVERCALL_RANGES,
  createRanges,
  createLabels,
  getNoTrumpOvercallRules,
  getOptions,
  getIsAllConditions,
  getOverCallSuitRules,
};
