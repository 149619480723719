/* eslint react/prop-types: 0 */
import { constants as uic } from '../../constants';
import MoreLink from './MoreLink.js';

import React from "react";
import PropTypes from 'prop-types';

const InfoContainerActions = function InfoContainerActions(props) {

  const CLOSE_ACTION = 'info-close';
  const NEXT_ACTION = 'info-next';
  const PREV_ACTION = 'info-prev';
  const PLAY_ACTION = 'info-play';
  const STOP_ACTION = 'info-stop';

  const clickActions = {
    [ CLOSE_ACTION ]: () => props.onClose(),
    [ NEXT_ACTION ]: () => props.onNextClick(),
    [ PREV_ACTION ]: () => props.onPrevClick(),
    [ PLAY_ACTION ]: () => {
      props.onPlayClick();
    },
    [ STOP_ACTION ]: () => {
      props.onStopPlayClick();
    },
  };

  const handleClick = event => {
    event.preventDefault();
    clickActions[ event.target.id ]();
  };
  return (
    <div className="info-container__actions">
      {props.info && props.info.title && <h3 className="info-container__title">{props.info.title}</h3>}
      <div className="info-bar" tabIndex="0">
        {props.onPrevClick
          && <button tabIndex="-1" title={uic.PREVIOUS_LABEL} id={PREV_ACTION} onClick={handleClick}>&lt;</button>
        }
        {props.onPlayClick
          && !props.isPlayMode
          && <button tabIndex="-1" title={uic.PLAY_LABEL} id={PLAY_ACTION} onClick={handleClick}>{uic.RIGHT_TRIANGLE}</button>
        }
        {props.onStopPlayClick
          && props.isPlayMode
          && <button tabIndex="-1" title={uic.STOP_LABEL} id={STOP_ACTION} onClick={handleClick}>{uic.SOLID_SQUARE}</button>
        }
        {props.onNextClick
          && <button tabIndex="-1" title={uic.NEXT_LABEL} id={NEXT_ACTION} onClick={handleClick}>&gt;</button>
        }
        {props.onClose
          && <button tabIndex="-1" title={uic.CLOSE_LABEL} id={CLOSE_ACTION} onClick={handleClick}>&times;</button>
        }
      </div>
     </div>
  );
};

const InfoContainer = function InfoContainer(props) {

  const getFormattedInfo = () => {
    return Array.isArray(props.info)
      ? props.info
      : (
        <p>
          {props.info.content}
          {props.info.moreClick
            && <MoreLink
                 id="props.info"
                 label={props.moreLabel}
                 onClick={props.info.moreClick}
                />}
        </p>
      );
  };

  return (
    <section tabIndex="0" className="info-container">
      <InfoContainerActions {...props} />
      {props.info && getFormattedInfo()}
    </section>
  );
};

InfoContainer.propTypes = {
  info: PropTypes.shape({
    title: PropTypes.string,
    content: PropTypes.node,
    moreClick: PropTypes.func,
  }),
  isPlayMode: PropTypes.bool,
  moreLabel: PropTypes.string,
  onClose: PropTypes.func,
  onNextClick: PropTypes.func,
  onPrevClick: PropTypes.func,
};

export default InfoContainer;
