export const BLACKWOOD = 'Blackwood';
export const GERBER = 'Gerber';
export const GRAND_SLAM = 'Grand Slam';
export const JACOBY_TRANSFER = 'Jacoby Transfer';
export const JACOBY_TRANSFER_SUPER_ACCEPTANCE = 'Jacoby Transfer Super Acceptance';
export const JUMP_RAISE = 'Jump Raise';
export const JUMP_SHIFT = 'Jump Shift';
export const LIMIT_RAISE = 'Limit raise';
export const LANDY = 'Landy';
export const MAJOR_SUIT_OPEN = 'Major Suit Open';
export const MICHAELS_CUEBID = 'Michaels Cue Bid';
export const MINOR_SUIT_OPEN = 'Minor Suit Open';
export const NEGATIVE_DOUBLE = 'Negative Double';
export const OPEN_NO_TRUMP = 'Open No Trump';
export const OVERCALL_NO_TRUMP = 'Overcall No Trump';
export const PENALTY_DOUBLE = 'Penalty Double';
export const PREEMPT_WEAK_TWO = 'Weak Two';
export const PREEMPT_THREE_LEVEL = 'Three Level Preempt';
export const REVERSE_BID = 'Reverse Bid';
export const SMALL_SLAM = 'Small Slam';
export const STAYMAN = 'Stayman';
export const STRONG_TWO_CLUB = 'Strong Two Club';
export const TAKE_OUT_DOUBLE = 'Takeout Double';
export const UNUSUAL_TWO_NO_TRUMP = 'Unusal Two No Trump';
export const WEAK_JUMP_OVERCALL = 'Weak Jump Overcall';
