import { constants as Bridge } from '../bridge';

import { DealOptions } from './deal-options';

export const IS_DISPLAY_HCP = 'isDisplayHcp';
export const IS_DISPLAY_HANDS = 'isDisplayHands';
export const IS_DISPLAY_BID_HINTS = 'isDisplayBidHints';
export const IS_DISPLAY_AUCTION_VIEWER = 'isDisplayAuctionViewer';
export const IS_DISPLAY_TEN_CARD_AS_CHAR = 'isDisplayTenCardAsChar';
export const IS_DISPLAY_HAND_IMG = 'isDisplayHandImage';

export const CONTACT_LABEL = 'Contact';
export const CONTACT_ADDRESSS = 'bridgeblvd@gmail.com';
export const CHECK_MARK = '\u2713';

export const BRIDGE_TITLE_LABEL = 'Bridge';
export const BLVD_TITLE_LABEL = 'Blvd';

export const PREVIOUS_LABEL = 'Previous';
export const PLAY_LABEL = 'Play';
export const STOP_LABEL = 'Stop';
export const NEXT_LABEL = 'Next';
export const CLOSE_LABEL = 'Close';

export const SELECT_DEFAULT_BID_SYSTEM_LABEL = 'Select the default Bid System';
export const SELECT_DEFAULT_BIDDING_OPTION_LABEL = 'Select the default Bidding option';
export const SELECT_DEFAULT_PRACTICE_OPTION_LABEL = 'Select the default practice option';
export const SELECT_DEFAULT_DEAL_TYPE_LABEL = 'Select the default Deal Type';

export const TAB_THEME_LABEL = 'Theme';
export const TAB_LANDING_OPT_LABEL = 'Landing Options';
export const TAB_GAME_OPT_LABEL = 'Game Display';
export const TAB_BID_SYSTEM = 'Bid System';

export const TAB_CHART_LABEL = 'Chart';
export const TAB_OPTION_LABEL = 'Options';

export const AUCTION_LABEL = 'Auction';
export const ABOUT_LABEL = 'About';
export const BID_OPTIONS_LABEL = 'Bid Options';
export const BID_CHEAT_SHEET_LABEL = 'Cheat Sheet';
export const EDIT_BID_SYSTEM_LABEL = 'Edit Bid System';
export const GUIDE_LABEL = 'guide';
export const SHOW_ABOUT_LABEL = ABOUT_LABEL;
export const CURRENT_DEAL_IS_LABEL = 'The Current Deal Is'
export const SELECT_OPTIONS_LABEL = 'Select Options';
export const DEAL_CARDS_LABEL = 'Deal Cards';
export const DEALS_MENU_LABEL = 'Open Deals Menu';
export const ENTER_FOLDER_NAME_LABEL = 'Enter a Folder name';
export const EXPORT_DEAL_LABEL = 'Export Deal';
export const HISTORY_LABEL = 'History';
export const MORE_LABEL = 'more';
export const DONE_LABEL = 'done';
export const PASS_LABEL = 'pass';
export const DOUBLE_X_LABEL = 'X';
export const REPLAY_LABEL = 'Replay';
export const SAVE_LABEL = 'Save Deal';
export const TEST_LABEL = 'Test';
export const PLAY_CARD_LABEL = 'Play Card';
export const SHOW_BID_HINT = 'show bid hint';
export const PLAY_CARD_CLICK_DESC = 'computer will play a card';
export const HINT_LABEL = 'Hint';
export const INFO_LABEL = 'Info';
export const RETURN_TO_BRIDGE_TABLE_LABEL = 'return to the bridge table';
export const RETURN_TO_GAME_OPTIONS_CARD_LABEL  = 'return to game options';
export const SHOW_CARDS_LABEL = 'Show Cards';
export const NO_SAVED_DEALS_LABEL = 'Currently No Saved Deals.';
export const SHOW_AUCTION_LABEL = 'Show Auction';
export const HIDE_POINTS_LABEL = 'Hide Points';
export const SHOW_POINTS_LABEL = 'Show Points';
export const SHOW_SETTINGS_LABEL = 'Settings';
export const SETTINGS_LABEL = 'Settings';

export const NAV_BAR_ICON = 'nav-bar__icon';

export const FOLDERS_ID = 'folders-id';

export const RANDOM_DEAL_LABEL = 'With Random Deals';
export const SAVED_HANDS_LABEL = 'With Saved Hands';
export const PRELOADED_HANDS_LABEL = 'With Preloaded Hands';
export const HISTORY_HANDS_LABEL = 'With Deal History';

export const DEAL_OPTION_LABELS = Object.freeze({
  [ DealOptions.DEAL_RANDOM_HANDS ]: RANDOM_DEAL_LABEL,
  [ DealOptions.DEAL_PRELOADED_HANDS ]: PRELOADED_HANDS_LABEL,
  [ DealOptions.DEAL_SAVED_HANDS ]: SAVED_HANDS_LABEL,
  [ DealOptions.DEAL_HISTORY_HANDS ]: HISTORY_HANDS_LABEL,
});

export const BID_SYSTEM  = 'bidSystem';
export const BID_SYSTEMS = Object.freeze({
  standardAmerican: Bridge.STANDARD_AMERICAN,
  bridgeBlvdBeginner: Bridge.BRIDGE_BLVD_BEGINNER,
});

export const STANDARD_AMERICAN_LABEL = 'Standard American';
export const BRIDGE_BLVD_BEGINNER_LABEL = 'Bridge Blvd (Beginner)';
export const BID_SYSTEM_LABELS = Object.freeze({
  [BID_SYSTEMS.standardAmerican]: STANDARD_AMERICAN_LABEL,
  [BID_SYSTEMS.bridgeBlvdBeginner]: BRIDGE_BLVD_BEGINNER_LABEL,
});

export const BID_OPTIONS = Object.freeze({
  interactiveBid: 'practice-bid',
  computerBid: 'computer-bid',
});

export const COMPUTER_BIDS_LABEL = 'The Computer Bids';
export const YOU_BID_LABEL = 'You Bid';

export const BID_OPTION_LABELS = Object.freeze({
  [BID_OPTIONS.interactiveBid]: YOU_BID_LABEL,
  [BID_OPTIONS.computerBid]: COMPUTER_BIDS_LABEL,
});

export const PLAY_OPTIONS = Object.freeze({
  bidPractice: 'bid-practice',
  playPractice: 'play-practice',
  playRubber: 'rubber-match',
});

export const getIsPlayTypeBidPractice = function getIsPlayTypeBidPractice(playType) {
  return playType === PLAY_OPTIONS.bidPractice;
};

export const BID_PRACTICE_LABEL = 'Practice Bidding';
export const PLAY_PRACTICE_LABEL = 'Practice Play';
export const PLAY_RUBBER_LABEL = 'Play Rubber Bridge';
export const PLAY_OPTION_LABELS = Object.freeze({
  [PLAY_OPTIONS.bidPractice]: BID_PRACTICE_LABEL,
  [PLAY_OPTIONS.playPractice]: PLAY_PRACTICE_LABEL,
  [PLAY_OPTIONS.playRubber]: PLAY_RUBBER_LABEL,
});

export const NOW_PLAYING_LABEL = 'Now Playing';
export const NOW_PLAYING_YOUR_BID_SYSTEM_LABEL = 'Your Bid System is';
export const NOW_PLAYING_COMPUTER_BID_SYSTEM_LABEL = 'The Computer\'s Bid System is';

export const SAVED_DEALS_LABEL = 'Saved Deals';
export const CANNED_DEALS_LABEL = 'Canned Deals';

export const FOLDER_DEALS_LABEL = 'Deal Folders';
export const FOLDER_SETTINGS_LABEL = 'Settings'

export const TOGGLE_CARDS_LABEL = 'Toggle Cards Visible';
export const TOGGLE_PLAYER_POINTS_LABEL = 'Toggle Player Points';
export const TOGGLE_HINT_LABEL = 'Toggle Hint display';

export const CONTRACT_LABEL = 'Contract';
export const VIEW_TRICKS_LABEL = 'View Tricks';
export const VIEW_TALLY_LABEL = 'View Tally';

export const WE = 'We';
export const THEY = 'They';

export const STATUS_CHANGE = 'statusChange';

export const CARD_IMG_PATH = './src/images/cards/';


export const LEFT_ARROW_HOOK = `\u21A9\uFE0E`;
export const RIGHT_TRIANGLE = `\u25B6\uFE0E`;
export const SOLID_SQUARE = `\u25FC\uFE0E`;

export const TRIANGLE_UP = `\u25B4`;
export const TRIANGLE_DOWN = `\u25BE`;

export const ICON_COLLAPSE_OPEN_CHAR = TRIANGLE_DOWN;
export const ICON_COLLAPSE_CLOSE_CHAR = TRIANGLE_UP;

export const ICON_CLOSE_CHAR = `\u00D7`;

export const DEAL_NEXT_IN_QUEUE_LABEL = 'Q';
export const DEAL_NEXT_IN_QUEUE_DESC = 'Deal Next in Queue';
