import * as GameStateTypes from './types';

const {
  SET_AUCTION_COMPLETE_PROPS,
  SET_AUCTION_UPDATE,
  SET_VIEW_AUCTION,
  SET_CARDS_DEALT_PROPS,
  SET_DEAL_COMPLETE_PROPS,
  SET_DEAL_PROGRESS,
  SET_ON_CARD_PLAY_PROPS,
  SET_HIGHLIGHTED_PLAYER,
  SET_NEXT_DEALER,
  SET_NEXT_TRICK,
  SET_ORIGINAL_HANDS_VISIBLE,
  SET_RUBBER_IN_PROGRESS,
  SET_SELECTED_AUCTION_CELL,
  SET_START_CARD_PLAY_PROPS,
  SET_START_PROPS,
  SET_TALLY_PROPS,
  SET_TRICK_COMPLETE_PROPS,
  SET_VIEW_MODE
} = GameStateTypes;

export const setAuctionCompleteProps = props => ({
  type: SET_AUCTION_COMPLETE_PROPS,
  props,
});

export const setAuctionUpdate = update => ({
  type: SET_AUCTION_UPDATE,
  update,
});

export const setViewAuction = () => ({
  type: SET_VIEW_AUCTION,
  payload: null,
});

export const setCardsDealtProps = props => ({
  type: SET_CARDS_DEALT_PROPS,
  props,
});

export const setOnCardPlayProps = props => ({
  type: SET_ON_CARD_PLAY_PROPS,
  props,
});

export const setDealCompleteProps = props => ({
  type: SET_DEAL_COMPLETE_PROPS,
  props,
});

export const setDealProgress = dealProgress => ({
  type: SET_DEAL_PROGRESS,
  dealProgress,
});

export const setGameStateStartProps = props => ({
  type: SET_START_PROPS,
  props,
});

export const setHighlightPlayer = position => ({
  type: SET_HIGHLIGHTED_PLAYER,
  position,
});

export const setNextDealer = () => ({
  type: SET_NEXT_DEALER,
  payload: null,
});

export const setNextTrick = () => ({
  type: SET_NEXT_TRICK,
  payload: null,
});

export const setOrigialHandsVisible = () => ({
  type: SET_ORIGINAL_HANDS_VISIBLE,
  payload: null,
});

export const setRubberInProgress = isRubberInProgress => ({
  type: SET_RUBBER_IN_PROGRESS,
  isRubberInProgress,
});


export const setSelectedAuctionCell = props => {
  return {
    type: SET_SELECTED_AUCTION_CELL,
    props,
  };
};

export const setStartCardPlayProps = props => ({
  type: SET_START_CARD_PLAY_PROPS,
  props,
});

export const setTallyProps = props => ({
  type: SET_TALLY_PROPS,
  props,
});

export const setTrickCompleteProps = props => ({
  type: SET_TRICK_COMPLETE_PROPS,
  props,
});

export const setViewModeProps = payload => {
  return {
  type: SET_VIEW_MODE,
  payload,
};};
