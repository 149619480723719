import { Coords } from '../../../bridge';
import { DealProgress, ViewModes, createViewModeProps } from '../../../presenter';

import * as GameStateTypes from './types';

import * as R from 'ramda';

const {
  SET_AUCTION_COMPLETE_PROPS,
  SET_AUCTION_UPDATE,
  SET_VIEW_AUCTION,
  SET_CARDS_DEALT_PROPS,
  SET_DEAL_COMPLETE_PROPS,
  SET_DEAL_PROGRESS,
  SET_ON_CARD_PLAY_PROPS,
  SET_HIGHLIGHTED_PLAYER,
  SET_NEXT_DEALER,
  SET_NEXT_TRICK,
  SET_ORIGINAL_HANDS_VISIBLE,
  SET_RUBBER_IN_PROGRESS,
  SET_SELECTED_AUCTION_CELL,
  SET_START_CARD_PLAY_PROPS,
  SET_START_PROPS,
  SET_TALLY_PROPS,
  SET_TRICK_COMPLETE_PROPS,
  SET_VIEW_MODE,
} = GameStateTypes;

const defaultGameState = {
  auctionUpdate: {},
  completeAuction: {},
  dealProgress: DealProgress.gameInit,
  dealProps: {
    dealName: null,
    dealerPosition: null,
    contract: null,
  },
  highlightCardPlayerPositon: '',
  isAuctionPlayback: false,
  isOriginalHandsVisible: false,
  nextCardToPlay: null,
  nextDealerPosition: Coords.EAST,
  originalHands: null,
  playingHands: null,
  playingTrick: [],
  rubberInPlay: false,
  selectedAuctionCell: null,
  tallyData: null,
  tricks: [],
  vulnerability: null,
  viewModeProps: createViewModeProps({
    mode: ViewModes.BRIDGE_TABLE_VIEW
  }),
};

const reducer = function getGameState(state = defaultGameState, action) {
  const { type, props } = action;

  switch (type) {

    case SET_AUCTION_COMPLETE_PROPS: {
      const completeAuction = createCompleteAuction(props);
      return Object.assign(
        {},
        state,
        completeAuction,
        createDealProps(props),
        createDealProgressProps(props),
        createPlayersProps(props),
      );
    }

    case SET_AUCTION_UPDATE:
      return {
        ...state,
        ...createAuctionUpdate(action.update),
        ...createPlayersProps(action.update),
      };


    case SET_VIEW_AUCTION:
      return {
        ...state,
        ...createDealProgressProps({ dealProgress: DealProgress.auctionView }),
      };

    case SET_DEAL_COMPLETE_PROPS:
      return Object.assign(
        {},
        state,
        props,
        createOriginalHandsVisible(true),
        createNextTrickProps(),
      );

    case SET_CARDS_DEALT_PROPS:
    case SET_START_PROPS:
      return Object.assign(
        {},
        state,
        createDealProgressProps(props),
        createDealProps(props),
        createPlayersProps(props),
        createOriginalHandsVisible(false),
        createSelectedAuctionCell(
          state.selectedAuctionCell,
          {
            defaultCell: {
              position: props.dealProps.dealerPosition,
              rowNum: 0,
            },
            selected: {},
          }
        ),
        {tricks: []},
      );

    case SET_ON_CARD_PLAY_PROPS:
      return {
        ...state,
        ...createEndCardPlayProps(props),
        ...createDealProgressProps(props),
      };

    case SET_ORIGINAL_HANDS_VISIBLE:
      return Object.assign(
        {},
        state,
        createOriginalHandsVisible(true)
      );

    case SET_START_CARD_PLAY_PROPS: {
      return Object.assign(
        {},
        state,
        createHighlightCardPlayerPositon(props.cardProps.position),
        createDealProgressProps(props),
        createStartCardPlayProps(props)
      );
    }

    case SET_DEAL_PROGRESS:
      return {
        ...state,
        ...createDealProgressProps(action.dealProgress),
      };

    case SET_RUBBER_IN_PROGRESS:
      return Object.assign(
        {},
        state,
        {
          isRubberInProgress: action.isRubberInProgress
        }
      );

    case SET_NEXT_DEALER: {
      const position = Coords.nextCoord(state.nextDealerPosition);
      return Object.assign(
        {},
        state,
        createNextDealerPosition(position),
        createSelectedAuctionCell(
          state.selectedAuctionCell,
          {
            selected: {},
          },
        ),
      );
    }

    case SET_NEXT_TRICK:
      return Object.assign({}, state, createNextTrickProps());

    case SET_HIGHLIGHTED_PLAYER:
      return Object.assign(
        {},
        state,
        //createHighlightCardPlayerPositon(action.position),
        createSelectedAuctionCell(
          state.selectedAuctionCell,
          {
            selected: {
              position: action.position,
              rowNum: getPlayerFirstBidRow(state, action.position),
            },
          }
        ),
      );

    case SET_SELECTED_AUCTION_CELL: {
      return Object.assign(
        {},
        state,
        //createHighlightCardPlayerPositon(props.position),
        createSelectedAuctionCell(
          state.selectedAuctionCell,
          { selected: props }
        ),
      );
    }

    case SET_TALLY_PROPS:
      return Object.assign(
        {},
        state,
        createOriginalHandsVisible(true),
        createTallyProps(props),
        createDealProgressProps(props),
      );

    case SET_TRICK_COMPLETE_PROPS:
      return Object.assign(
        {},
        state,
        createTrickCompleteProps(state.tricks, props),
        createDealProgressProps(props),
      );

    case SET_VIEW_MODE:
      return Object.assign(
        {},
        state,
        {viewModeProps: action.payload},
      );

    default:
      return state;
  }
};

const createAuctionUpdate = update => ({ auctionUpdate: update });

const createCompleteAuction = ({ completeAuction }) => ({ completeAuction });

const createDealProps = ({ dealProps }) => ({ dealProps });

const createDealProgressProps = ({ dealProgress }) => ({ dealProgress });

const createEndCardPlayProps = ({ playingHands, playingTrick, nextCardToPlay }) => {
  return {
    playingHands,
    playingTrick,
    nextCardToPlay,
  };
};

const createNextDealerPosition = nextDealerPosition => ({ nextDealerPosition });

const createSelectedAuctionCell = (selectedAuctionCell, props) => ({
  selectedAuctionCell: {
    ...selectedAuctionCell,
    ...props
  }
});

const createHighlightCardPlayerPositon = position => ({
  highlightCardPlayerPositon: position
});

const createNextTrickProps = () => ({
  playingTrick: [],
});

const createOriginalHandsVisible = value => ({ isOriginalHandsVisible: value });

const createStartCardPlayProps = ({ playNextCard, cardProps, hasPlayButton }) => ({
  nextCardToPlay: {
    cardProps,
    hasPlayButton,
    playNextCard,
  },
});

const createPlayersProps = ({ players }) => ({
  originalHands: R.clone(players),
  playingHands: R.clone(players),
});

const createTallyProps = ({ tallyData, gameReturnFunc }) => ({
  tallyData: Object.assign({}, tallyData, { gameReturnFunc })
});

const createTrickCompleteProps = (tricks, { cardsPlayed, winner, eastWestCount, northSouthCount, gameReturnFunc }) => ({
  tricks: [ ...tricks, { cardsPlayed, winner, eastWestCount, northSouthCount, gameReturnFunc } ],
});

const getPlayerFirstBidRow = function getPlayerFirstBidRow({ completeAuction, auctionUpdate }, position) {
  const startAtOne = (({ auction }) => auction.bids.some(bid =>
    bid.position === position
    && bid.bidValue === ''
  ))(completeAuction || auctionUpdate);

  return startAtOne
    ? 1
    : 0;
};

export default reducer;
