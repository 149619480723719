import { PASS_OPTION } from '../pass-option';
import { createBidOptions } from '../create-bid-options';

import {
  HAS_MAJOR_SUIT_TRUMP_FIT_WITH_PARTNERS_OVERCALL,
  GAME_FORCING_HAND_CUE_BID,
  NO_FIT_HAS_STOPPERS_THEIR_SUITS,
  NO_FIT_BID_NEW_FIVE_CARD_SUIT,
  MINOR_SUIT_TRUMP_FIT_WITH_PARTNER,
} from './types';

import {
  OVERCALL_ADVANCE_RANGES,
  PASS_RANGE,
  SUIT_ADVANCE_RANGES,
  NO_TRUMP_ADVANCE_RANGES,
  createRanges,
} from './ranges';

import { createLabels } from './labels';

const getOptions = function getOptions() {
  return createBidOptions([
    HAS_MAJOR_SUIT_TRUMP_FIT_WITH_PARTNERS_OVERCALL,
    GAME_FORCING_HAND_CUE_BID,
    NO_FIT_HAS_STOPPERS_THEIR_SUITS,
    NO_FIT_BID_NEW_FIVE_CARD_SUIT,
    MINOR_SUIT_TRUMP_FIT_WITH_PARTNER,
    PASS_OPTION,
  ]);
};

export {
  HAS_MAJOR_SUIT_TRUMP_FIT_WITH_PARTNERS_OVERCALL,
  GAME_FORCING_HAND_CUE_BID,
  NO_FIT_HAS_STOPPERS_THEIR_SUITS,
  NO_FIT_BID_NEW_FIVE_CARD_SUIT,
  MINOR_SUIT_TRUMP_FIT_WITH_PARTNER,
  OVERCALL_ADVANCE_RANGES,
  PASS_RANGE,
  SUIT_ADVANCE_RANGES,
  NO_TRUMP_ADVANCE_RANGES,
  createRanges,
  createLabels,
  getOptions,
};
