import reducer from "./reducers";

import * as DisplayOptionActions from "./actions";
import * as DisplayOptionsActionTypes from './types';

const {
  TOGGLE_POINTS_VISIBLE,
  TOGGLE_HANDS_VISIBLE,
  TOGGLE_BID_HINTS_VISIBLE,
  TOGGLE_AUCTION_VISIBLE,
} = DisplayOptionsActionTypes;

export {
  DisplayOptionActions,
  TOGGLE_POINTS_VISIBLE,
  TOGGLE_HANDS_VISIBLE,
  TOGGLE_BID_HINTS_VISIBLE,
  TOGGLE_AUCTION_VISIBLE,
};

export default reducer;
