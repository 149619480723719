import { createRangeLabel, createTotalPointsLabel } from '../create-range-label';

import {
  TWO_D_WAITING,
  DEF_TWO_D_WAITING,
  TWO_OF_MAJOR,
  DEF_TWO_OF_MAJOR,
  TWO_NO_TRUMP,
  DEF_TWO_NO_TRUMP,
  THREE_OF_MINOR,
  DEF_THREE_OF_MINOR,
} from './types';

import { STRONG_TWO_RESPONSE_RANGES, GAME_FORCE_RANGE } from './ranges';

export const createLabels = function createLabels(bidSystemConfig) {

  const ranges = bidSystemConfig[ STRONG_TWO_RESPONSE_RANGES ];

  const TWO_D_WAITING_LABEL = 'Two Diamond Waiting';
  const DEF_TWO_D_WAITING_LABEL = `${createTotalPointsLabel(ranges[ TWO_D_WAITING ])} - Artificial - Waiting`;
  const TWO_OF_MAJOR_LABEL = `Two of a Major Suit`;
  const DEF_TWO_OF_MAJOR_LABEL = `${createTotalPointsLabel(ranges[ GAME_FORCE_RANGE ])} - 5+ cards in suit`;
  const TWO_NO_TRUMP_LABEL = `Two No Trump`;
  const DEF_TWO_NO_TRUMP_LABEL = `${createRangeLabel(ranges[ GAME_FORCE_RANGE ])} - Balanced Hand`;
  const THREE_OF_MINOR_LABEL = `Three of a Minor Suit`;
  const DEF_THREE_OF_MINOR_LABEL = `${createTotalPointsLabel(ranges[ GAME_FORCE_RANGE ])} - 5+ cards in suit`;

  return {
    [ TWO_D_WAITING ]: TWO_D_WAITING_LABEL,
    [ DEF_TWO_D_WAITING ]: DEF_TWO_D_WAITING_LABEL,
    [ TWO_OF_MAJOR ]: TWO_OF_MAJOR_LABEL,
    [ DEF_TWO_OF_MAJOR ]: DEF_TWO_OF_MAJOR_LABEL,
    [ TWO_NO_TRUMP ]: TWO_NO_TRUMP_LABEL,
    [ DEF_TWO_NO_TRUMP ]: DEF_TWO_NO_TRUMP_LABEL,
    [ THREE_OF_MINOR ]: THREE_OF_MINOR_LABEL,
    [ DEF_THREE_OF_MINOR ]: DEF_THREE_OF_MINOR_LABEL,
  };
};
