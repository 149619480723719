import { constants as c } from '../constants';

import * as BidTypes from './bid-types';
import { lookupBidTypeAbbr, lookupBidTypeLabel } from './bid-type-lookup';

export const getPromisesToString = function getPromisesToString(promises) {
  return promises
  ? promises.map(promise => promise.toString()).join(' ')
  : '';
};

export const getPromisesToAbbr = function getPromisesToAbbr(promises) {
  return promises
  ? promises.map(promise => promise.toAbbr()).join('; ')
  : '';
};

export const createPromise = ({
  metaData,
  responseFunction,
  type,
}) => ({
  metaData,
  responseFunction,
  type,
  get() {
    return this;
  },
  toString() {
    if (this.metaData) {
      return `${this.metaData} ${lookupBidTypeLabel(this.type)}`;
    }
    return lookupBidTypeLabel(this.type);
  },
  toAbbr() {
    return lookupBidTypeAbbr(this.type);
  }
});

export const createSignOffPromise = ({
  points,
  ltc,
}) => ({
  points,
  ltc,
  type: BidTypes.SIGN_OFF,
  get() {
    return this;
  },
  setPoints(pnts) {
    this.points = pnts;
  },
  toString() {
    const ltcString =  this.ltc
      ? ` and possible trick count of ${this.ltc}`
      : '';

    return this.points
      ? `Pass with ${this.points} combined points${ltcString}.`
      : BidTypes.SIGN_OFF.label;
  }
});

export const createCardCountPromise = ({
  cardValue,
  cardCount,
}) => ({
  type: BidTypes.CARD_COUNT,
  cardValue,
  cardCount,
  get() {
    return this;
  },
  toString() {
    return `Bid shows ${cardCount} ${cardValue}'s`;
  }
});

export const createRangePromise = ({
  lower,
  upper,
  extraText,
  isDistPoints = false,
  inRange = true,
}) => ({
  type: BidTypes.RANGE_PROMISE,
  lower,
  upper,
  extraText,
  isDistPoints,
  inRange,
  get() {
    return this;
  },
  setLower(val) {
    this.lower = val;
  },
  setUpper(val) {
    this.upper = val;
  },
  getRange() {
    if (this.lower && this.upper) {
      return {
        lower: this.lower,
        upper: this.upper,
      };
    }
    if (this.lower) {
      return {
        lower: this.lower,
      };
    }
    if (this.upper) {
      return {
        upper: this.upper,
      };
    }
  },
  toString() {
    const getLessOrGreaterRangeStr = () => {
      const greaterLessStr = () => inRange
        ? 'at least'
        : '0 to ';

      const getLowerBound = () => inRange
        ? this.lower
        : this.lower -1;

      return `${greaterLessStr()} ${getLowerBound()}`;
    };

    const pointsTypeLabel = isDistPoints
      ? 'total points with distribution'
      : 'high card points';

    const msg = extraText
      ? `, ${extraText}`
      : '';

    if (!this.upper || this.upper === c.NO_UPPER_RANGE) {
      return `Bid promises ${getLessOrGreaterRangeStr()} ${pointsTypeLabel}${msg}. `;
    }
    return `Bid promises between ${this.lower} and ${this.upper} ${pointsTypeLabel}${msg}. `;
  },
  toAbbr() {
    if (!this.upper || this.upper === c.NO_UPPER_RANGE) {
      return `${this.lower}+ hcp`;
    }
    return `${this.lower} to ${this.upper} hcp`;
  }
});

export const createTrickPromise = ({
  type,
  count,
}) => ({
  count,
  type,
  get() {
    return this;
  },
  setCount(count) {
    this.count = count;
  },
  toString() {
    return `${lookupBidTypeLabel(this.type)} ${this.count}.`;
  },
  toAbbr() {
    return this.toString();
  }
});

export const createLawOfTotalTricksPromise = ({
  trumpCnt,
}) => ({
  type: BidTypes.LAW_OF_TOTAL_TRICKS,
  trumpCnt,
  setTrumpCnt(trumpCnt) {
    this.trumpCnt = trumpCnt;
  },
  toString() {
    return `${BidTypes.LAW_OF_TOTAL_TRICKS.label} - can make ${this.trumpCnt} tricks.`;
  },
});

export const createDistPointsTrumpFitPromises = ({
  strain,
  suitLength,
  combinedSuitLength,
}) => ([
  createDistPointsPromise(),
  createTrumpFitPromise({
    strain,
    suitLength,
    combinedSuitLength,
  }),
]);

export const createTrumpFitPromise = ({
  strain,
  suitLength,
  combinedSuitLength,
}) => ({
  type: BidTypes.TRUMP_FIT,
  strain,
  suitLength,
  combinedSuitLength,
  setStrain(strain) {
    this.strain = strain;
    return this;
  },
  toString() {
    return `Trump fit in suit (${this.strain}). Combined hands have at least ${combinedSuitLength} trumps.`;
  }
});

export const createSuitPromise = ({
  strain,
  suitLength = 0,
  unBid = false,
  lessThan = 0,
}) => ({
  type: BidTypes.SUIT_PROMISE,
  strain,
  suitLength,
  unBid,
  lessThan,
  get() {
    return this;
  },
  toString() {
    if (this.suitLength) {
      const unBidSuit = unBid
        ? ' Suit has not been previously bid.'
        : '';
      return `${this.suitLength}+ cards in suit (${this.strain}).${unBidSuit}`;
    }
  },
  toAbbr() {
    return `${this.suitLength}+ ${this.strain}`;
  }
});

export const createGameForcingHandPromise = () => ({
  type: BidTypes.GAME_FORCING_HAND,
  get() {
    return this;
  },
  toString() {
    return lookupBidTypeLabel(this.type);
  },
});

export const createStopperPromise = ({
  strain,
}) => ({
  type: BidTypes.STOPPER_IN_STRAIN,
  strain,
  get() {
    return this;
  },
  toString() {
    return `${c.STOPPER_IN_STRAIN_LABEL} (${this.strain})`;
  },
});

export const createDistPointsPromise = () => {
  return createPromise({
    type: BidTypes.ADD_DISTRIBUTION_POINTS_FOR_TRUMP_FIT
  });
};

export const createMinimalOpenPromise = () => {
  return createPromise({
    type: BidTypes.MINIMAL_OPENING_HAND_NO_TRUMP_RESPONSE,
  });
};

export const createSuitLessThanPromise = (strain, lessThan) => createSuitPromise({
  strain,
  lessThan,
});

export const createSuitLengthPromise = (strain, suitLength) => createSuitPromise({
  strain,
  suitLength,
});

export const getNoTrumpSuitPromises = strain => {

  switch (strain) {
    case c.SPADE:
      return [createSuitLessThanPromise(c.SPADE, c.THREE_CARD_SUIT)];

    case c.HEART:
       return [
         createSuitLessThanPromise(c.SPADE, c.FOUR_CARD_SUIT),
         createSuitLessThanPromise(c.HEART, c.THREE_CARD_SUIT)
       ];
    case c.DIAMOND:
       return [
         createSuitLessThanPromise(c.SPADE, c.FOUR_CARD_SUIT),
         createSuitLessThanPromise(c.HEART, c.FOUR_CARD_SUIT),
         createSuitLessThanPromise(c.DIAMOND, c.FOUR_CARD_SUIT)
       ];
    case c.CLUB:
      return [
        createSuitLessThanPromise(c.SPADE, c.FOUR_CARD_SUIT),
        createSuitLessThanPromise(c.HEART, c.FOUR_CARD_SUIT),
        createSuitLessThanPromise(c.DIAMOND, c.FOUR_CARD_SUIT),
        createSuitLessThanPromise(c.CLUB, c.FOUR_CARD_SUIT)
      ];
    case c.NO_TRUMP:
      return [
        createSuitLengthPromise(c.SPADE, c.TWO_CARD_SUIT),
        createSuitLengthPromise(c.HEART, c.TWO_CARD_SUIT),
        createSuitLengthPromise(c.DIAMOND, c.TWO_CARD_SUIT),
        createSuitLengthPromise(c.CLUB, c.TWO_CARD_SUIT)
      ];
    case c.DOUBLE_STRAIN:
      return [
        createSuitLengthPromise(c.SPADE, c.TWO_CARD_SUIT),
        createSuitLengthPromise(c.HEART, c.TWO_CARD_SUIT),
        createSuitLengthPromise(c.DIAMOND, c.TWO_CARD_SUIT),
        createSuitLengthPromise(c.CLUB, c.TWO_CARD_SUIT)
      ];
    default: {
      throw new Error('unknown promise strain!');
    }
  }
};
