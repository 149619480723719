
import React from 'react';
import PropTypes from 'prop-types';

import noun_left from '../../../images/noun_Left_406164.svg';

import { IconButton } from './IconButton';

const BackIcon = function BackIcon({ id, onClick, title }) {
  return (<IconButton
    id={id || "back-icon"}
    src={noun_left}
    title={title}
    onClick={onClick} />
  );
};

BackIcon.propTypes = {
  onClick: PropTypes.func,
  id: PropTypes.string,
  title: PropTypes.string,
};

export { BackIcon };
